import { Scalar, scalarValueSchema } from '../scalars';
import { z } from '../zod-openapi';

/**
 * Type of process.env; hoisted here to remain independent of `process` that does not exist in all runtime environments
 */
export interface ProcessEnv {
  [k: string]: string | undefined;
}

export const environmentNamesList = ['local', 'dev', 'test', 'staging', 'prod'] as const;
export const configurationNamesList = [...environmentNamesList, 'common'] as const;
export const configurationReferencesList = [...configurationNamesList, 'global'] as const;

/**
 * Names of environments (aka "stages") where our applications may run.
 **/
export const environmentNameSchema = z.enum(environmentNamesList);

/**
 * Names of environments (aka "stages") where our applications may run.
 **/
export type EnvironmentName = z.infer<typeof environmentNameSchema>;

export const configurationNameSchema = z.enum(configurationNamesList);

export type ConfigurationName = z.infer<typeof configurationNameSchema>;

export const configurationReferenceValueSchema = z.enum(configurationReferencesList);

export type ConfigurationReferenceValue = z.infer<typeof configurationReferenceValueSchema>;

/**
 * libs/configuration requires some Environment variables to be always set.
 */
export const configurationEnvSchema = z.object({
  STAGE: environmentNameSchema.openapi({
    description: 'STAGE is required to select associated configuration',
    examples: [...environmentNamesList],
  }),
});

export type ConfigurationEnv = z.infer<typeof configurationEnvSchema>;

export const configurationSettingsAlwaysSchema = configurationEnvSchema.extend({
  serviceName: z.string().openapi({
    description: 'name of service from its package.json',
    example: '@tiketti/my-api',
  }),
  API_URL: z
    .string()
    .url()
    .openapi({
      description: 'Where the correct TP API instance can be reached',
      examples: ['http://localhost:8888', 'https://api.tiketti.dev', 'https://api.tiketti.fi'],
    }),
});

export type ConfigurationSettingsAlways = z.infer<typeof configurationSettingsAlwaysSchema>;

export const sharedConfigurationSchema = z
  .object({
    AWS_REGION: z.literal('eu-central-1').openapi({ description: 'System location' }),
    CONFIGURATION_MANAGEMENT_KEY: z.string().optional().openapi({
      description: 'x-api-key header value for managing configuration via /configuration endpoint',
    }),
    DATABASE_URL: z
      .string()
      .url()
      .regex(/^postgres(ql|):/, 'DATABASE_URL protocol must be "postgres"')
      .openapi({
        description: 'URL with secret credentials to TP main database containing iam, organizations, etc.',
        examples: [
          'postgres://postgres:postgres@localhost:5432/tp',
          'postgres://postgres_test:postgres_test@localhost:5432/tp',
        ],
      }),
    DATABASE_SECONDARY_URL: z
      .string()
      .url()
      .regex(/^postgres(ql|):/, 'DATABASE_SECONDARY_URL protocol must be "postgres"')
      .openapi({
        description: 'URL with secret credentials to TP secondary database containing notifications',
        examples: [
          'postgres://postgres:postgres@localhost:5432/tpsecondary',
          'postgres://postgres_test:postgres_test@localhost:5432/tpsecondary',
        ],
      }),
    DASHBOARD_API_KEY: z.string().openapi({
      description:
        'API key used to allow Dashboard app to access APIs; must have correct permissions in tp database iam schema.',
    }),
    JWT_ACCESS_TOKEN_SECRET: z.string().openapi({
      description: 'Shared secret used to check the signature of Authorization JWT is genuine',
    }),
    NEW_RELIC_URL: z
      .string()
      .url()
      .or(z.literal('console'))
      .openapi({
        description: 'Logging endpoint or "console" for no remote logging',
        examples: ['https://log-api.eu.newrelic.com/log/v1', 'console'],
      }),
    NEW_RELIC_TOKEN: z.string().openapi({ description: 'Token for access to NEW_RELIC_URL' }),
    NODE_ENV: z.enum(['development', 'test', 'production']).openapi({
      description: 'The NODE_ENV setting to adjust build and runtime behaviors; please use more accurate STAGE instead',
      examples: ['development', 'test', 'production'],
    }),
    STATIC_CONTENT_URL: z
      .string()
      .url()
      .openapi({
        description: 'Base URL for static content site static.tiketti.fi',
        examples: ['https://static.tiketti.dev', 'https://static.tiketti.fi'],
      }),
  })
  .strict();

export const apiSpecificConfigurationAlwaysSchema = z.object({
  PORT: z.coerce.number().openapi({ description: 'Service listening at port', example: 5000 }),
  URL_PATH: z
    .string()
    .regex(/^\/v[\d.]+(\/[\w-]+)+$/, 'URL path must look like /v<versionNumber>/<path-element>...')
    .openapi({
      description: 'Number of the port where API should listen at; only one process can listen on one port',
      example: '/v1/example',
    }),
});

export const apiConfigurationAlwaysSchema = configurationSettingsAlwaysSchema.merge(
  apiSpecificConfigurationAlwaysSchema,
);

export const configurationValueSsmRefSchema = z.object({ ssmRef: z.string().regex(/^(\/\w+)+/) }).strict();

export const configurationValueSchema = z.union([scalarValueSchema, configurationValueSsmRefSchema, z.object({})]);

export const configurationReferenceSchema = z.object({
  BASE_CONFIGURATION: z
    .string() // import from package.json makes it `string`
    .openapi({
      description: `Name another configuration section to use as basis for this configuration section, or "global" to use configuration for current STAGE from workspace root`,
      examples: [...configurationReferencesList],
    }),
});

export const configurationDeclarationSchema = configurationSettingsAlwaysSchema.merge(
  configurationReferenceSchema.partial(),
);

export type SsmRef = { ssmRef: string };
export type ConfigurationReference = { BASE_CONFIGURATION: string | string[] }; // comes as string from package.json
export type ConfigurationDeclaration<O extends object = {}> = O & ConfigurationReference;

/**
 * Each stage aka environment requires separate configuration,
 * but `common` is only necessary if some settings are shared between them.
 */
export type ConfigurationMultiStageDeclaration<K extends string, MAY extends object, MUST extends object> = Record<
  ConfigurationName | K,
  Partial<{ [k in Extract<keyof MAY, string>]: MAY[k] | SsmRef }> & MUST
>;

/**
 * Local configuration must specify settings for each stage including BASE_CONFIGURATION
 * to ensure that the service will be configured correctly for each environment.
 */
export type ConfigurationLocalMultiStageDeclaration<CONF extends object = {}> = Partial<
  ConfigurationMultiStageDeclaration<never, CONF, ConfigurationReference>
>;

/**
 * Global configuration may contain any traits
 */
export type ConfigurationGlobalMultiStageDeclaration<CONF extends object = {}> = ConfigurationMultiStageDeclaration<
  string,
  CONF,
  {}
>;

/**
 * Resolved configuration values have strings in place of SSM references
 */
export type ConfigurationValueResolved<T> = T extends SsmRef ? string : T;
export type ConfigurationValueResolvedAny = Scalar | object;

export type ConfigurationResolved<
  O extends object,
  K extends string,
  T extends Record<K, ConfigurationDeclaration<O>>,
> = {
  [SettingName in keyof T[K]]: ConfigurationValueResolved<T[K][SettingName]>;
} & ConfigurationSettingsAlways;

export type ConfigurationObject<CONF extends object> = {
  get: <K extends Extract<keyof CONF, string>>(key: K) => CONF[K];
  getAll: () => CONF;
  set: (newValues: Partial<CONF>) => ConfigurationObject<CONF>;
  refreshSecrets: (ssmGlobs?: string[]) => Promise<void>;
};

export const ssmPathGlobsSchema = z
  .object({
    ssmPathGlobs: z
      .array(
        z
          .string()
          .regex(
            /^((\/\w|\*)[\w-]*)+$/,
            'SSM path with optional single-path-element "*" or multiple-path-element "**" globs',
          ),
      )
      .openapi({
        description: 'Array of SSM path globs',
        examples: [['/foo/bar/baz'], ['/foo/*/baz'], ['**z']],
      })
      .optional(),
  })
  .strict();

/**
 * Default configuration of an application must have all keys (of required environment variables) defined for documenting the whole set in code.
 *
 * It may contain `null` values for keys that must be filled in from SSM during deployment, or set in `.dev.vars` for local development.
 *
 * Use `zodParseJson` to allow non-string values to be defined as JSON in Environment variable strings.
 *
 * Example:
 *
 * ```typescript
 * export const sampleEnvSchema = z.object({
 *   // each value will be validated by the middleware
 *   SAMPLE: z.string(), // middleware will pass a copy of this value
 *   PARSED: zodParseJson(z.number()), // middleware will pass a number parsed from JSON string in Environment variable
 *   HANDLER: z.function().transform(useOriginalValue), // `useOriginalValue` makes middleware pass the original value
 * });
 *
 * export type SampleEnv = Omit<z.infer<typeof sampleEnvSchema>, 'HANDLER'> & { HANDLER: (s: string) => null };
 *
 * const defaults: DefaultConfiguration<SampleEnv> = { SAMPLE: 'default sample value', PARSED: 123, HANDLER: null };
 *
 * export const appConfiguration: DefaultConfigurationForAllEnvironments<SampleEnv> = {
 *   local: defaults, dev: defaults, testing: defaults, staging: defaults, production: { ...defaults, SAMPLE: 'sample in production' }
 * }
 * ```
 *
 * See [`hono-api/README`](../../../hono-api/README.md) for more.
 **/
export type DefaultConfiguration<E extends object, K extends keyof E = keyof E, V = E[K] | null> = Record<K, V>;

/**
 * Default configuration of an application must have all keys (of required environment variables) defined for documenting the whole set in code.
 *
 * It may contain `null` values for keys that must be filled in from SSM during deployment, or set in `.dev.vars` for local development.
 **/
export type DefaultConfigurationForEnvironment<
  E extends object,
  D extends DefaultConfiguration<E> = DefaultConfiguration<E>,
  K extends keyof D = keyof D,
  V = D[K] extends null ? string | null : D[K],
> = Record<K, V>;

/**
 * Default configuration of an application must have all keys (of required environment variables) defined for all environments.
 *
 * Each of them may contain `null` values for keys that must be filled in from SSM during deployment, or set in `.dev.vars` for local development.
 * Runtime environment variable use will fail if any values remain unset.
 *
 * This configuration may then be passed to `createApi` or `createMiddlewareToExposeEnv` will use these values as defaults for Env settings.
 *
 * Use `zodParseJson` to allow non-string values to be defined as JSON in Environment variable strings.
 *
 * Example:
 *
 * ```typescript
 * export const sampleEnvSchema = z.object({
 *   // each value will be validated by the middleware
 *   SAMPLE: z.string(), // middleware will pass a copy of this value
 *   PARSED: zodParseJson(z.number()), // middleware will pass a number parsed from JSON string in Environment variable
 *   HANDLER: z.function().transform(useOriginalValue), // `useOriginalValue` makes middleware pass the original value
 * });
 *
 * export type SampleEnv = Omit<z.infer<typeof sampleEnvSchema>, 'HANDLER'> & { HANDLER: (s: string) => null };
 *
 * const defaults: DefaultConfiguration<SampleEnv> = { SAMPLE: 'default sample value', PARSED: 123, HANDLER: null };
 *
 * export const configurationDefaults: DefaultConfigurationForAllEnvironments<SampleEnv> = {
 *   local: defaults, dev: defaults, testing: defaults, staging: defaults, production: { ...defaults, SAMPLE: 'sample in production' }
 * }
 *
 * const { app } = createApi<SampleEnvSchema>({ name: 'sample-api', configurationDefaults });
 * ```
 *
 * See [`hono-api/README`](../../../hono-api/README.md) for more.
 **/
export type DefaultConfigurationForAllEnvironments<E extends object> = Record<
  EnvironmentName,
  DefaultConfigurationForEnvironment<E>
>;
