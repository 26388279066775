import { z } from '../zod-openapi';
import { countryCodeSchema, localizationSchema } from '../world';

export const countryResultSchema = z.object({
  countryCode: countryCodeSchema,
  name: localizationSchema,
});

export type CountryResult = z.infer<typeof countryResultSchema>;

export const countryQuerySchema = z.object({
  name: z.string().optional(),
});

export type CountryQuery = z.infer<typeof countryQuerySchema>;

export const countriesResultSchema = z.object({
  countries: z.array(countryResultSchema),
});

export type CountriesResult = z.infer<typeof countriesResultSchema>;
