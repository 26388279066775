import { z, ZodTypeAny } from './zod-openapi';

/**
 * Accepts data matching given ZodSchema. Parses original value as JSON if it is a string.
 * Useful for accepting non-string values from Environment variables defined as strings.
 *
 * @param schema ZodSchema of the expected resulting type
 */
export const zodParseJson = <T extends ZodTypeAny>(schema: T) =>
  schema.or(
    z
      .string()
      .transform((v) => JSON.parse(v))
      .pipe(schema),
  );
