import { emailSchema, phoneNumberSchema, postalAddressBaseSchema } from '../address';
import { z } from '../zod-openapi';

export const deliveryMethodList = ['pdf', 'kivra', 'post', 'sms'] as const;

export const deliveryMethodSchema = z.enum(deliveryMethodList).openapi('deliveryMethod', {
  description: 'How the customer would like to receive their order',
  example: 'pdf',
});

export const DeliveryMethod = deliveryMethodSchema.enum;

export const deliveryMethodByEmailSchema = deliveryMethodSchema.extract(['pdf', 'kivra']);

export const deliveryMethodByPhoneSchema = deliveryMethodSchema.extract(['sms']);

export const deliveryMethodByMailSchema = deliveryMethodSchema.extract(['post']);

export const deliveryContactSchema = z.object({
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  address: postalAddressBaseSchema,
  deleted: z.string().nullable().optional(),
});

export const deliveryContactPartialSchema = deliveryContactSchema.partial();

export const deliveryByEmailSchema = z
  .object({
    method: deliveryMethodByEmailSchema,
  })
  .merge(deliveryContactPartialSchema)
  .merge(deliveryContactSchema.pick({ email: true }))
  .strict();

export const deliveryByPhoneSchema = z
  .object({
    method: deliveryMethodByPhoneSchema,
  })
  .merge(deliveryContactPartialSchema)
  .merge(deliveryContactSchema.pick({ phoneNumber: true }))
  .strict();

export const deliveryByMailSchema = z
  .object({
    method: deliveryMethodByMailSchema,
  })
  .merge(deliveryContactPartialSchema)
  .merge(deliveryContactSchema.pick({ address: true }))
  .strict();

export const deliverySchema = z.discriminatedUnion('method', [
  deliveryByEmailSchema,
  deliveryByPhoneSchema,
  deliveryByMailSchema,
]);

export type Delivery = z.infer<typeof deliverySchema>;

export const deliveryDraftSchema = z.discriminatedUnion('method', [
  deliveryByEmailSchema.partial().merge(deliveryByEmailSchema.pick({ method: true })),
  deliveryByPhoneSchema.partial().merge(deliveryByPhoneSchema.pick({ method: true })),
  deliveryByMailSchema.partial().merge(deliveryByMailSchema.pick({ method: true })),
]);
