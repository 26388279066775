export enum LogLevels {
  ALL = 'ALL',
  MARK = 'MARK',
  TRACE = 'TRACE',
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
  FATAL = 'FATAL',
  OFF = 'OFF',
}

export const logLevelStrings = Object.fromEntries(Object.keys(LogLevels).map((l) => [l.toLowerCase(), l]));
