import { z, ZodString } from './zod-openapi';
import { ZodObject } from 'zod';

/**
 * Returns a tuple with elements
 * 0. Zod schema for object matching `{ [fieldName]: nanoId(length) }`
 * 1. Zod schema for string matching `nanoId(length)`
 *
 * @param fieldName name of field
 * @param length length of field value
 */
export const getIdAndValueSchema = <N extends string>(
  fieldName: N,
  length: number,
): [ZodObject<Record<N, ZodString>>, ZodString] => {
  const valueSchema = getIdValueSchema(length, fieldName);
  const idSchema = z.object({ [fieldName as N]: valueSchema } as Record<N, ZodString>).strict();
  return [idSchema, valueSchema];
};

export const getIdSchema = <N extends string>(fieldName: N, length: number) =>
  getIdAndValueSchema(fieldName, length)[0];

const sampleString = (length: number) =>
  Array.from(new Array(length))
    .map((_, i) => String.fromCharCode(65 + (i % 26)))
    .join('');

export const getIdValueSchema = (length: number, fieldName?: string) =>
  z
    .string()
    .regex(/^[\w_-]+$/)
    .length(length)
    .openapi({
      description: `${fieldName ?? 'Identifier'} consisting of ${length} characters possibly including upper or lowercase letters, numbers, dashes "-", and underscores "_".`,
      example: sampleString(length),
    });
