import { z } from '../zod-openapi';
import { countryCodeSchema } from '../world';

export const postcodeSchema = z.object({
  postcode: z.string().optional(),
  cityId: z.number().optional(),
  countryCode: countryCodeSchema.optional(),
});

export type Postcode = z.infer<typeof postcodeSchema>;

export const postcodeQuerySchema = z
  .object({
    countryCode: countryCodeSchema.optional(),
    cityName: z.string().optional(),
    postcode: z.string().optional(),
  })
  .strict();

export type PostcodeQuery = z.infer<typeof postcodeQuerySchema>;

export const postcodesResultSchema = z.object({
  postcodes: z.array(postcodeSchema),
});

export type PostcodesResult = z.infer<typeof postcodesResultSchema>;
