import { TagSchema } from '../tag';
import { z } from '../zod-openapi';

export enum BadgeStyles {
  Black = 'black-badge',
  Purple = 'purple-badge',
  DarkGreen = 'dark-green-badge',
  Blue = 'blue-badge',
  Green = 'green-badge',
  Red = 'red-badge',
}

const BadgesPropsSchema = z.object({
  value: z.string().optional(),
  color: z.nativeEnum(BadgeStyles).optional(),
  containerStyle: z.string().optional(),
  valueStyle: z.string().optional(),
});

export const ImageSchema = z.object({
  imageUrl: z.string().optional(),
  alt: z.string().optional(),
  imageHeight: z.string().optional(),
  imageWidth: z.string().optional(),
});

export const ContactCardDraftSchema = z.object({
  name: z.string().min(1),
  email: z.string().min(1),
  phone: z.string().optional().nullable(),
  roles: z.array(TagSchema).optional().nullable(),
  image: ImageSchema.optional().nullable(),
});

export type ContactCardDraft = z.infer<typeof ContactCardDraftSchema>;

export const ContactCardSchema = ContactCardDraftSchema.extend({
  id: z.string().optional(),
});

export type ContactCardType = z.infer<typeof ContactCardSchema>;

export const ContactFormSchema = z.object({
  draft: ContactCardDraftSchema,
  isNew: z.boolean(),
});

export type ContactForm = z.infer<typeof ContactFormSchema>;
