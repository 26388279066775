import { MultiLangValue } from '../multi-lang';
import { lengthAwarePaginationSchema } from '../pagination';
import { z, zodDateMaybeSerializedSchema } from '../zod-openapi';

export const UserDBOSchema = z.object({
  id: z.string(),
  email: z.string(),
  phone: z.string().optional().nullable(),
  name: z.string(),
  oldId: z.number().nullable(),
  emailValidatedAt: zodDateMaybeSerializedSchema.optional().nullable(),
  phoneValidatedAt: zodDateMaybeSerializedSchema.optional().nullable(),
  validFrom: zodDateMaybeSerializedSchema,
  validTo: zodDateMaybeSerializedSchema.optional().nullable(),
});

export const UserDBOResultSchema = z.object({
  users: z.array(UserDBOSchema),
  pagination: lengthAwarePaginationSchema,
});

export interface UserDBO {
  id: string;
  email: string;
  phone?: string;
  name: string;
  oldId: number;
  emailValidatedAt?: Date;
  phoneValidatedAt?: Date;
  validFrom: Date;
  validTo?: Date;
  tenantId: string;
}

export interface UserDBOWithRights extends UserDBO {
  userId: string;
  rights: string[];
  oldOrganizerRights: { organizerId: number; hasReportAccess: boolean }[];
}

export interface UserDBOWithAll {
  id: string;
  email: string;
  phone?: string;
  name: string;
  oldId: number;
  oldOrganizerRights: { organizerId: number; hasReportAccess: boolean }[];
  emailValidatedAt?: Date;
  phoneValidatedAt?: Date;
  validFrom: Date;
  validTo?: Date;
  roles: Record<string, string[]>;
  usergroups?: Record<string, Record<string, string[]>>;
  rights: string[];
}

export interface UsergroupDBO {
  id: string;
  organizationId?: string;
  name: MultiLangValue;
  purpose: MultiLangValue;
  oldId?: number;
}

export interface RoleDBO {
  id: string;
  name: MultiLangValue;
  purpose: MultiLangValue;
}

export interface AccessRuleDBO {
  id: string;
  path: string;
  useCaseId: string;
  roleIds: string[];
  conditionNames: string[];
}

export interface UserGroupDBO {
  user_id: string;
  usergroup_id: string;
}
