import { lengthAwarePaginationSchema } from '../pagination';
import { z } from '../zod-openapi';

export const translationObjectSchema = z.object({
  en: z.string().nullable(),
  fi: z.string().nullable(),
  sv: z.string().nullable(),
  default: z.string().nullable(),
});

export const translationSchema = z.object({
  id: z.string(),
  app: z.string(),
  module: z.string(),
  key: z.string(),
  translations: translationObjectSchema,
  translationLastModified: z.record(z.any()),
  originalText: z.string(),
  userDescription: z.string().nullable(),
  codeDescription: z.string().nullable(),
  lastMergedAt: z.string().or(z.date()),
  deprecated: z.boolean(),
});

export type ITranslationObject = z.infer<typeof translationObjectSchema>;
export type ITranslation = z.infer<typeof translationSchema>;

export const translationsResultSchema = z.object({
  translations: z.array(translationSchema.partial()),
  pagination: lengthAwarePaginationSchema,
});

export type ITranslationsResult = z.infer<typeof translationsResultSchema>;

export const translationResultSchema = z.object({
  translation: translationSchema,
});
export type ITranslationResult = z.infer<typeof translationResultSchema>;
