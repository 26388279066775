import { apiResponseSucceededWithDataSchema } from '../api';
import { z } from '../zod-openapi';

export const userSessionSchema = z
  .object({
    phone: z.string(),
    name: z.string(),
    phoneValidatedAt: z.string(),
    validFrom: z.string(),
    oldId: z.number(),
    userId: z.string(),
    email: z.string().email().optional().openapi({ description: 'Included only when `details`requested' }),
    emailValidatedAt: z.string(),
    validTo: z.string(),
    roles: z
      .record(
        z.string().openapi({
          description: 'roleId',
          example: 'NanoId08',
        }),
        z
          .array(
            z.string().openapi({
              description: 'organizationId; `*` means all',
              examples: ['NanoId8c', '*'],
            }),
          )
          .openapi({ description: 'organizationIds; `*` means all', examples: [['NanoId8c'], ['*']] }),
      )
      .optional()
      .openapi({ description: 'Included only when `roles` requested' }),
    usergroups: z
      .record(
        z.string().openapi({
          description: 'roleId',
          example: 'NanoId08',
        }),
        z.record(
          z.string().openapi({ description: 'groupId', example: 'NanoId8b' }),
          z.array(
            z.string().openapi({
              description: 'organizationId; `*` means all',
              examples: ['NanoId8c', '*'],
            }),
          ),
        ),
      )
      .optional()
      .openapi({
        description: 'Maps roles to groups to organizationIds. Included only when `roles` and `details` requested',
        example: { onionEater: { onionEaters: ['*'] } },
      }),
    rights: z
      .array(z.string().openapi({ description: 'rightCodeName', example: 'dashboard/SEE' }))
      .optional()
      .openapi({
        description: 'Included only when `rights` requested',
        example: ['dashboard/SEE', 'notification/SEE'],
      }),
    oldOrganizerRights: z
      .array(
        z.object({
          organizerId: z.number(),
          hasReportAccess: z.boolean(),
        }),
      )
      .openapi({ description: 'Include old organizer rights', example: [{ organizerId: 1, hasReportAccess: true }] }),
  })
  .partial()
  .strict();

export type UserSession = z.infer<typeof userSessionSchema>;

export const userSessionApiResponseSchema = apiResponseSucceededWithDataSchema(z.object({ user: userSessionSchema }));

export type UserSessionApiResponse = z.infer<typeof userSessionApiResponseSchema>;

export const jwtTokenRegex = /^[\w-]+\.[\w-]+\.[\w-]+$/;
export const jwtTokenSchema = z.string().regex(jwtTokenRegex).openapi({
  description: 'JSON Web Token: base64 encoded Header.Payload.Signature',
  example: 'xxxxx.yyyyy.zzzzz'
})

export const jwtRefreshTokenSchema = z.object({ refresh_token: jwtTokenSchema }).strict();

export type JwtRefreshToken = z.infer<typeof jwtRefreshTokenSchema>;

export const jwtTokenPairSchema = jwtRefreshTokenSchema
  .extend({ access_token: jwtTokenSchema })
  .strict();

export type JwtTokenPair = z.infer<typeof jwtTokenPairSchema>;
export type GetString = () => Promise<string>;

export type FetchLike = (
  url: string,
  options?: object,
) => Promise<{ status: number; statusText: string; json: () => Promise<unknown> }>;

