import { z } from 'zod';

export const FolderSchema = z.object({
  name: z.string(),
});

export type Folder = z.infer<typeof FolderSchema>;

export const FolderListResponseSchema = z.object({
  folders: z.array(z.string()),
});

export type FolderListResponse = z.infer<typeof FolderListResponseSchema>;
