import { lengthAwarePaginationSchema } from '../pagination';
import { TagSchema } from '../tag';
import { z } from '../zod-openapi';
export const priceRuleChangeRuleTypes = ['sales_start_date', 'order_date', 'event_date'] as const;

export const priceTypes = ['service_fee', 'transaction_fee', 'delivery_fee'] as const; // TODO: Add more types as those are known/needed

export const priceActionTriggers = [
  'ORDER_TICKET_SOLD',
  'ORDER_CREATED',
  'ORDER_TICKET_RETURNED',
  'ORDER_TICKET_UPGRADED',
] as const;

export const priceConditionTriggers = ['FROM_ORDER_TOTAL', 'FROM_TICKET_PRICE'] as const;

export const localization = z.object({
  en: z.string().nullable(),
  fi: z.string().nullable(),
});

export type ILocalization = z.infer<typeof localization>;

// Base schemas and types without the ids.
export const basePriceRuleConditionSchema = z.object({
  from: z.number(),
  to: z.number(),
  priceRuleConditionId: z.string(),
  calculateWithVat: z.boolean().optional(),
});
export type IPriceRuleConditionBase = z.infer<typeof basePriceRuleConditionSchema>;

export const basePriceRuleActionSchema = z.object({
  amount: z.number(),
  vatId: z.string(),
  priceRuleActionId: z.string(),
});

export type IPriceRuleActionBase = z.infer<typeof basePriceRuleActionSchema>;

export const basePriceRuleSchema = z.object({
  chargedFrom: z.string(),
  validFrom: z.string().or(z.date()),
  validTo: z.string().or(z.date()).nullable().optional(),
  changeRule: z.enum(priceRuleChangeRuleTypes),
  condition: basePriceRuleConditionSchema.nullable(),
  actions: z.array(basePriceRuleActionSchema),
});
export type IPriceRuleBase = z.infer<typeof basePriceRuleSchema>;

export const basePriceSchema = z.object({
  name: localization,
  description: localization,
  priceGroupTagId: z.string(),
  rules: z.array(basePriceRuleSchema),
  priceType: z.enum(priceTypes).nullable().optional(),
});

export type IPriceBase = z.infer<typeof basePriceSchema>;

// Full schemas and types with the ids.
export const completePriceRuleConditionSchema = basePriceRuleConditionSchema.merge(
  z.object({
    id: z.string().optional(),
    trigger: z.enum(priceConditionTriggers).nullable(),
  }),
);

export type IPriceRuleConditionComplete = z.infer<typeof completePriceRuleConditionSchema>;

export const completePriceRuleActionSchema = basePriceRuleActionSchema.merge(
  z.object({
    id: z.string().optional(),
    trigger: z.enum(priceActionTriggers).nullable(),
  }),
);

export type IPriceRuleActionComplete = z.infer<typeof completePriceRuleActionSchema>;

export const completePriceRuleSchema = basePriceRuleSchema.merge(
  z.object({
    id: z.string().optional(),
    condition: completePriceRuleConditionSchema.nullable(),
    actions: z.array(completePriceRuleActionSchema),
  }),
);

export type IPriceRuleComplete = z.infer<typeof completePriceRuleSchema>;

export const rulesListSchema = z.object({
  rules: z.array(completePriceRuleSchema),
  pagination: lengthAwarePaginationSchema,
});

export type RulesList = z.infer<typeof rulesListSchema>;

export const completePriceSchema = basePriceSchema.merge(
  z.object({
    id: z.string(),
    rules: z.array(completePriceRuleSchema),
    activeVersionId: z.string().optional(),
    priceListId: z.string().nullable().optional(),
    masterPriceId: z.string().nullable().optional(),
  }),
);

export type IPriceComplete = z.infer<typeof completePriceSchema>;

export const pricesListSchema = z.object({
  prices: z.array(completePriceSchema),
  pagination: lengthAwarePaginationSchema,
});

export type PricesList = z.infer<typeof pricesListSchema>;

// Price lists
export const basePriceListSchema = z.object({
  name: localization,
  organizationId: z.string(),
  validityPeriod: z.object({
    from: z.string().or(z.date()),
    to: z.string().or(z.date()).nullable().optional(),
  }),
  createdAt: z.string().or(z.date()).nullable().optional(),
  updatedAt: z.string().or(z.date()).nullable().optional(),
});

export type IPriceListBase = z.infer<typeof basePriceListSchema>;

export const basePriceListWithPricesSchema = z.object({
  priceList: basePriceListSchema,
  prices: z.array(completePriceSchema),
});

export type IPriceListWithPricesBase = z.infer<typeof basePriceListWithPricesSchema>;

export const completePriceListSchema = basePriceListSchema.merge(
  z.object({
    id: z.string(),
  }),
);

export type IPriceListComplete = z.infer<typeof completePriceListSchema>;

export const completePriceListWithPricesSchema = basePriceListWithPricesSchema.merge(
  z.object({
    priceList: completePriceListSchema,
    prices: z.array(completePriceSchema),
  }),
);

export type IPriceListWithPricesComplete = z.infer<typeof completePriceListWithPricesSchema>;

export const priceListsWithPricesResponseSchema = z.object({
  priceLists: z.array(completePriceListWithPricesSchema),
});

export type IPriceListsWithPricesResponse = z.infer<typeof priceListsWithPricesResponseSchema>;

export const priceListWithPricesResponseSchema = z.object({
  priceList: completePriceListWithPricesSchema,
});

export type IPriceListWithPricesResponse = z.infer<typeof priceListWithPricesResponseSchema>;
//Price constants
export const priceConstantsSchema = z.object({
  vats: z.array(z.object({ id: z.string(), rate: z.number() })),
  categories: z.array(TagSchema),
  conditions: z.array(
    z.object({ id: z.string(), name: localization, trigger: z.string().nullable(), unit: z.string().nullable() }),
  ),
  actions: z.array(
    z.object({
      id: z.string(),
      name: localization,
      trigger: z.string().nullable(),
      unit: z.string().nullable(),
      modifier: z.string().nullable(),
    }),
  ),
});

export type IPriceConstants = z.infer<typeof priceConstantsSchema>;
