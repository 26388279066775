import { z } from '../zod-openapi';

export const responsePaginateSchema = z.object({
  currentPage: z.number(),
  from: z.number(),
  lastPage: z.number(),
  nextPage: z.number().nullable(),
  perPage: z.number(),
  prevPage: z.number().nullable(),
  to: z.number(),
  total: z.number(),
});

export type ResponsePaginate = z.infer<typeof responsePaginateSchema>;
