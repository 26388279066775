import { environmentNameSchema } from '../configuration';
import { z } from '../zod-openapi';

const urlInfo = {
  description: 'HTTPS callback URL. Placeholders {orderId} and {paymentId} will be replaced with respective values.',
  example: `https://api.tiketti.dev/orders/{orderId}/payments/{paymentId}/paid`,
};

export const paytrailConfigurationSchema = z.object({
  ENVIRONMENT: environmentNameSchema.default('dev'),
  PAYTRAIL_API_URL: z.string().default(''),
  PAYTRAIL_CALLBACK_SUCCESS_URL: z.string().default('').openapi(urlInfo),
  PAYTRAIL_CALLBACK_CANCEL_URL: z.string().default('').openapi(urlInfo),
  PAYTRAIL_MERCHANT_ID: z.string().default(''),
  PAYTRAIL_SECRET_KEY: z.string().default(''),
  LEGACY_ORDER_API_URL: z.string(),
  LEGACY_ORDER_API_KEY: z.string(),
  LEGACY_ORDER_API_SALT: z.string(),
  LEGACY_ORDER_API_HASH: z.string(),
  CORS_ORIGINS: z.string().nullable().optional(),
});

export type PaytrailConfiguration = z.infer<typeof paytrailConfigurationSchema>;

// https://docs.paytrail.com/#/?id=item
const paytrailItemSchema = z.object({
  unitPrice: z.number().int().openapi({
    description:
      "Price per unit, in each country's minor unit, e.g. for Euros use cents. By default price should include VAT, unless usePricesWithoutVat is set to true. Maximum value is 2147483647, minimum value is -2147483648. Negative value can't be used for Shop-in-Shop payments or when usePricesWithoutVat is set to true.",
  }),
  units: z.number().int().openapi({
    description: 'Quantity, how many items ordered. Negative values are not supported. Maximum value of 99999998.',
  }),
  vatPercentage: z
    .number()
    .openapi({ description: 'VAT percentage. Values between 0 and 100 are allowed with one number in decimal part.' }),
  productCode: z
    .string()
    .max(100)
    .openapi({ description: 'Merchant product code. May appear on invoices of certain payment methods.' }),
  // optional fields omitted
});

export type PaytrailItem = z.infer<typeof paytrailItemSchema>;

// https://docs.paytrail.com/#/?id=item
const paytrailCustomerSchema = z.object({
  email: z.string().email().max(200),
  firstName: z
    .string()
    .max(50)
    .optional()
    .openapi({ description: 'First name (required for OPLasku and Walley/Collector).' }),
  lastName: z
    .string()
    .max(50)
    .optional()
    .openapi({ description: 'Last name (required for OPLasku and Walley/Collector).' }),
  phone: z.string().optional(),
  vatId: z.string().optional(),
  companyName: z.string().optional(),
});

// https://docs.paytrail.com/#/?id=callbackurl
const paytrailCallbackUrlSchema = z.object({
  success: z.string().url().openapi({ description: 'Called on successful payment' }),
  cancel: z.string().url().openapi({ description: 'Called on cancelled payment' }),
});

// https://docs.paytrail.com/#/?id=create-payment
const paytrailCreatePaymentRequestSchema = z
  .object({
    stamp: z.string().max(200).openapi({ description: 'Merchant unique identifier for the order' }),
    reference: z.string().max(200).openapi({ description: 'Order reference' }),
    amount: z.number().int().openapi({
      description:
        "Total amount of the payment in currency's minor units, e.g. for Euros use cents. Must match the total sum of items and must be more than zero. By default amount should include VAT, unless usePricesWithoutVat is set to true.",
    }),
    currency: z.literal('EUR').openapi({ description: 'Currency, only EUR supported at the moment' }),
    language: z
      .enum(['FI', 'SV', 'EN'])
      .openapi({ description: "Payment's language, currently supported are FI, SV, and EN" }),
    customer: paytrailCustomerSchema,
    items: z.array(paytrailItemSchema).optional().openapi({
      description:
        'Always required for Shop-in-Shop payments. Required if VAT calculations are wanted in settlement reports.',
    }),
    redirectUrls: paytrailCallbackUrlSchema.openapi({
      description: 'Where to redirect browser after a payment is paid or cancelled.',
    }),
    callbackUrls: paytrailCallbackUrlSchema.openapi({
      description: 'Which url to ping after this payment is paid or cancelled',
    }),
    usePricesWithoutVat: z.boolean().optional().openapi({
      description:
        "If true, amount and items.unitPrice should be sent to API not including VAT, and final amount is calculated by Paytrail's system using the items' unitPrice and vatPercentage (with amounts rounded-xs to closest cent). Also, when true, items must be included and all item unit prices must be positive.",
    }),
    // some optional fields omitted
  })
  .strict();

export type PaytrailCreatePaymentRequest = z.infer<typeof paytrailCreatePaymentRequestSchema>;

// https://docs.paytrail.com/#/?id=formfield
const formFieldSchema = z
  .object({ name: z.string(), value: z.string() })
  .openapi({ description: 'The form field values are rendered as hidden <input> elements in the form' });

const parametersSchema = z.array(formFieldSchema).openapi({ description: 'name-value pairs for HTML input fields' });

// https://docs.paytrail.com/#/?id=apple-pay
const applePaySchema = z.object({
  parameters: parametersSchema,
});

// https://docs.paytrail.com/#/?id=provider
export const paytrailProviderSchema = z.object({
  url: z.string().url().openapi({ description: 'Form target URL. Use POST as method' }),
  icon: z.string().url().openapi({ description: 'URL to PNG version of the provider icon' }),
  svg: z
    .string()
    .url()
    .openapi({ description: 'URL to SVG version of the provider icon. Using the SVG icon is preferred.' }),
  group: z.enum(['mobile', 'bank', 'creditcard', 'credit']).openapi({
    description:
      'Provider group. Provider groups allow presenting same type of providers in separate groups which usually makes it easier for the customer to select a payment method.',
  }),
  name: z.string().openapi({ description: 'Display name of the provider.' }),
  id: z.string().openapi({ description: 'ID of the provider' }),
  parameters: parametersSchema,
});

export type PaytrailProvider = z.infer<typeof paytrailProviderSchema>;

export const paytrailProvidersSchema = z
  .array(paytrailProviderSchema)
  .openapi({ description: 'Array of providers. Render these elements as HTML forms' });

// https://docs.paytrail.com/#/?id=create-payment
export const paytrailPaymentSchema = z.object({
  transactionId: z.string().openapi({ description: 'Assigned transaction ID for the payment' }),
  href: z.string().url().openapi({
    description:
      'URL to hosted payment gateway. Redirect (HTTP GET) user here if the payment forms cannot be rendered directly inside the web shop.',
  }),
  terms: z.string().openapi({ description: 'Localized text with a link to the terms of payment' }),
  groups: z.array(z.object({})).openapi({
    description:
      'Array of payment method group data with localized names and URLs to icons. Contains only the groups found in the providers of the response',
  }),
  reference: z.string().openapi({ description: 'The bank reference used for the payments' }),
  providers: paytrailProvidersSchema,
  customProviders: z
    .object({ applepay: applePaySchema })
    .partial()
    .openapi({
      description:
        'Providers which require custom implementation. Currently used only by Apple Pay: https://docs.paytrail.com/#/?id=apple-pay',
    })
    .optional(), // apparently missing from responses from paytrail test API 2025-03-11
});

export type PaytrailPayment = z.infer<typeof paytrailPaymentSchema>;

export const paytrailStatusSchema = z.enum(['new', 'ok', 'pending', 'delayed', 'fail']).openapi({
  description: `new	Payment has been created but nothing more. Never returned as a result, but can be received from the GET /payments/{transactionId} endpoint
ok	Payment was accepted by the provider and confirmed successfully
fail	Payment was cancelled by the user or rejected by the provider
pending	Payment was initially approved by the provider but further processing is required, used in e.g. these cases:
1. anti-fraud check is ongoing
2. invoice requires manual activation
3. Refund has been initiated but waiting for approval (only used for merchants which require refund approvals)
delayed	A rare status related to a single payment method that is not generally enabled. May take days to complete. If completed, will be reported as ok via the callback or the redirect URL. This can be handled the same way as pending.
`,
});

export type PaytrailStatus = 'new' | 'ok' | 'pending' | 'delayed' | 'fail';

export const paytrailPaymentResultSchema = z
  .object({
    'checkout-account': z.coerce.number().openapi({ description: 'Paytrail account ID' }),
    'checkout-algorithm': z
      .string()
      .openapi({ description: 'Used signature algorithm. The same as used by merchant when creating the payment.' }),
    'checkout-amount': z.coerce
      .number()
      .openapi({ description: 'Payment amount in currency minor unit, e.g. cents. Maximum value of 99999999.' }),
    'checkout-settlement-reference': z.string().optional().openapi({
      description:
        'Payment reference of the settlement in which the succeeded transaction will be included in. This field will be provided only for specific Suomi.fi -merchants and only when calling success-callback.',
    }),
    'checkout-stamp': z.string().openapi({ description: 'Merchant provided stamp. Maximum of 200 characters.' }),
    'checkout-reference': z
      .string()
      .openapi({ description: 'Merchant provided reference. Maximum of 200 characters.' }),
    'checkout-transaction-id': z.string().optional().openapi({
      description:
        'Paytrail provided transaction ID. Important: Store the value. It is needed for other actions such as refund or payment information query. Note: In case of refund request that fails in semantic validation (e.g. insufficent account balance), this field will not be provided since the refund transaction does not exist yet.',
    }),
    'checkout-status': paytrailStatusSchema,
    'checkout-provider': z.string().openapi({
      description:
        'The payment method provider the client used. Current values are documented on providers tab. The values are subject to change without notice.',
    }),
    signature: z.string().openapi({ description: 'HMAC signature calculated from other parameter' }),
  })
  .strict();

export type PaytrailPaymentResultSchema = z.infer<typeof paytrailPaymentResultSchema>;

export const paytrailCallbackHeadersSchema = z.object({
  'x-forwarded-for': z.string().openapi({
    description: 'We use this to record IP of client',
    example: '93.184.215.14',
  }),
  'user-agent': z.string().openapi({ description: 'Contains "checkout" if from Paytrail' }),
});

export const paytrailPaymentResultAndHeadersSchema = paytrailPaymentResultSchema
  .merge(paytrailCallbackHeadersSchema)
  .strict();

export type PaytrailPaymentResultAndHeaders = z.infer<typeof paytrailPaymentResultAndHeadersSchema>;
