import {
  ApiEndpointRequestDeclaration,
  ApiEndpointResponsesDeclaration,
  apiResponseSucceededWithDataSchema,
} from '../api';
import { languageTagSchema } from '../world';
import { z } from '../zod-openapi';
import { contentAddressSchema } from './address';

export const acceptLanguageSchema = z.object({
  'accept-language': z
    .string()
    .openapi({ description: 'Language codes for response localization', example: 'fi, sv;q=0.5' }),
});

const headers = acceptLanguageSchema;

const fieldsQuery = z
  .object({
    fields: z
      .string()
      .optional()
      .openapi({ description: 'List of fields to include in output', example: 'city,country' }),
  })
  .strict();

export const addressesQuerySchema = fieldsQuery
  .merge(
    z.object({
      textQuery: z.string().openapi({ description: 'Free search text', example: 'Hel' }),
    }),
  )
  .strict();

export const googlePlacesResponseSchema = z
  .object({
    places: z.array(z.record(z.string(), z.any())),
  })
  .strict();

export const googlePlacesResponseDeclaration: ApiEndpointResponsesDeclaration = {
  200: {
    description: 'Response from Google Places',
    content: { 'application/json': { schema: apiResponseSucceededWithDataSchema(googlePlacesResponseSchema) } },
  },
};

export const schemasToQueryGooglePlaces = {
  headers,
  query: addressesQuerySchema,
  out: googlePlacesResponseSchema,
};

export const googlePredictionsQuerySchema = z
  .object({
    input: z.string().openapi({ description: 'Free text to match some address(es)', example: 'Urho' }),
  })
  .strict();

export const googlePredictionsSchema = z
  .object({
    places: z.array(z.record(z.string(), z.any())),
  })
  .strict();

export const schemasToQueryGooglePredictions = {
  headers,
  query: googlePredictionsQuerySchema,
  out: googlePredictionsSchema,
};

const addressFields = Object.keys(contentAddressSchema.shape);

export const addressRequestSchemas: ApiEndpointRequestDeclaration = {
  headers,
  params: z
    .object({ id: z.string().openapi({ description: 'Google Place identifier', example: 'xyzzy123' }) })
    .strict(),
  query: z
    .object({
      fields: z
        .string()
        .optional()
        .openapi({
          description: `Comma-separated list of fields to include in output: ${addressFields.map((k) => `\`${k}\``).join(', ')}.`,
          example: addressFields.join(','),
        }),
    })
    .strict(),
};

export const addressSchema = z.object({
  address: contentAddressSchema.partial(),
});

export const schemasToGetGooglePlace = {
  headers,
  param: z.object({ id: z.string() }),
  query: fieldsQuery,
  out: addressSchema,
};
export const googleAddressComponentsSchema = z.object({
  addressComponents: z.array(
    z.object({
      types: z.array(z.string()),
      languageCode: languageTagSchema,
      longText: z.string(),
      shortText: z.string(),
    }),
  ),
});
