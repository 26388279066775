import { timezoneNames } from './generated/timezones';
import { z } from '../zod-openapi';

export * from './generated/timezones';

export const timezoneNameSchema = z.enum(timezoneNames).openapi('TimezoneName', { example: 'Europe/Helsinki' });
export const timezoneListSchema = z.array(timezoneNameSchema);
export const timezoneInfoSchema = z
  .object({
    name: z.string().openapi({ example: 'Europe/Helsinki' }),
    windowsName: z.string().openapi({ example: 'Eastern European Time' }),
    abbreviation: z.string().openapi({ example: 'EET' }),
    utcOffsetSeconds: z.number().openapi({ example: 7200 }),
    utcOffsetText: z.string().openapi({ example: '+02:00' }),
    dstOffsetSeconds: z.number().openapi({ example: 10800 }),
    dstOffsetText: z.string().openapi({ example: '+03:00' }),
  })
  .openapi('timezone');

export type TimezoneName = z.infer<typeof timezoneNameSchema>;
export type TimezoneList = z.infer<typeof timezoneListSchema>;
export type TimezoneInfo = z.infer<typeof timezoneInfoSchema>;
