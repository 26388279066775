import { emailSchema, phoneNumberSchema } from '../address';
import { z } from '../zod-openapi';

export const companySchema = z
  .object({
    name: z.string().openapi({ example: 'Nakki ja Sämpylä Tmi' }),
    businessId: z.string().openapi({ example: '0116189-3' }),
    customerReference: z.string().openapi({ example: 'yövuorotyösuhde-etu' }),
  })
  .strict()
  .openapi('CustomerCompanyReference', { description: 'Reference to a company in an order by an end-user' });

export type Company = z.infer<typeof companySchema>;

export const companyDraftSchema = companySchema.partial();

export type CompanyDraft = z.infer<typeof companyDraftSchema>;

export const customerSchema = z.object({
  id: z.string(),
  firstName: z.string().openapi({ example: 'Matti' }),
  lastName: z.string().openapi({ example: 'Meikäläinen' }),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  postcode: z.string(),
  company: companySchema.optional(),
  deleted: z.string().nullable().optional(),
});

export type Customer = z.infer<typeof customerSchema>;

export const customerDraftSchema = customerSchema.partial();
export type CustomerDraft = z.infer<typeof customerDraftSchema>;
