import { z } from '../zod-openapi';

export interface IPaginatedQueryOptions {
  include?: {
    [key: string]: boolean;
  };
  perPage: number;
  currentPage: number;
  conditions?: Record<string, string>;
  sort?: {
    column: string;
    order: 'asc' | 'desc';
  }[];
}

export interface IPaginateResponseSchema {
  total: number;
  lastPage: number;
  prevPage: number | null;
  nextPage: number | null;
  perPage: number;
  currentPage: number;
  from: number;
  to: number;
}

export const paginateResponseSchema = z
  .object({
    total: z.number().int().openapi({
      example: 100,
      description: 'Total number of items across all pages',
    }),
    lastPage: z.number().int().openapi({
      example: 10,
      description: 'Number of the last page',
    }),
    prevPage: z.number().int().nullable().openapi({
      example: 1,
      description: 'Number of the previous page, or null if on the first page',
    }),
    nextPage: z.number().int().nullable().openapi({
      example: 3,
      description: 'Number of the next page, or null if on the last page',
    }),
    perPage: z.number().int().openapi({
      example: 10,
      description: 'Number of items displayed per page',
    }),
    currentPage: z.number().int().openapi({
      example: 2,
      description: 'Current page number',
    }),
    from: z.number().int().openapi({
      example: 11,
      description: 'Index of the first item on the current page',
    }),
    to: z.number().int().openapi({
      example: 20,
      description: 'Index of the last item on the current page',
    }),
  })
  .openapi({
    description: 'Pagination information for the response',
  });

export type PaginateResponse = z.infer<typeof paginateResponseSchema>;
