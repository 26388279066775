import { priceSchema } from '../price';
import { z } from '../zod-openapi';
import { reservationStateSchema } from './state';

export const requesterInfoSchema = z
  .object({
    callback: z.object({
      url: z.string(),
      token: z.string(),
    }),
  })
  .strict();

export type RequesterInfo = z.infer<typeof requesterInfoSchema>;

export const reserveRequestItemSchema = z
  .object({
    ticketTypeId: z.string(),
    quantity: z.number().int().gt(0).lt(1000),
    price: priceSchema,
    nameOnTicket: z
      .object({
        firstName: z.string(),
        lastName: z.string(),
      })
      .optional(),
  })
  .strict();
const reserveStateEnum = z.enum(['failed', 'pending']);

export const reserveRequestProductSchema = reserveRequestItemSchema
  .omit({
    ticketTypeId: true,
  })
  .extend({
    productTypeId: z.string(),
  });

export type ReserveRequestProduct = z.infer<typeof reserveRequestProductSchema>;
export type ReserveRequestItem = z.infer<typeof reserveRequestItemSchema>;

export const reserveRequestItemsSchema = z.object({
  cartId: z.string(),
  reservations: z.array(z.union([reserveRequestItemSchema, reserveRequestProductSchema])),
});

export const reserveRequestSchema = requesterInfoSchema.merge(reserveRequestItemsSchema);

export type ReserveRequest = z.infer<typeof reserveRequestSchema>;

export type ReservationApiRequest = ReserveRequest;

export const reserveResponseItemSchema = z
  .object({
    ticketTypeId: z.string(),
    reservationId: z.string(),
    state: reserveStateEnum,
  })
  .strict();

export const reserveResponseProductSchema = reserveResponseItemSchema
  .omit({
    ticketTypeId: true,
  })
  .extend({
    productTypeId: z.string(),
  });

export const reserveResponseSchema = z.object({
  cartId: z.string(),
  reservations: z.array(z.union([reserveResponseItemSchema, reserveResponseProductSchema])),
});

export const InventoryReservationDbItemSchema = z.object({
  id: z.string(),
  available: z.number(),
  reserved: z.number(),
  quota: z.number(),
  reserveTimeSeconds: z.number(),
  type: z.enum(['ticket', 'product']),
});

export const InventoryReservationDbItemsSchema = z.array(InventoryReservationDbItemSchema);

export type InventoryReservationDbItem = z.infer<typeof InventoryReservationDbItemSchema>;
export type InventoryReservationDbItems = z.infer<typeof InventoryReservationDbItemsSchema>;

export type ReserveResponse = z.infer<typeof reserveResponseSchema>;

export const RemoveReservationByIdOutputSchema = z.object({
  reservationId: z.string(),
  state: reservationStateSchema,
});
export type RemoveReservationByIdOutput = z.infer<typeof RemoveReservationByIdOutputSchema>;

export const ReserveProcessResultSchema = z.object({
  ticketTypeId: z.string(),
  reservationId: z.string(),
  state: z.enum(['reserved', 'failed']),
  message: z.string().optional(),
  cartId: z.string(),
  callback: z
    .object({
      url: z.string(),
      token: z.string(),
    })
    .optional(),
  validUntil: z.string().optional(),
});

export type ReserveProcessResult = z.infer<typeof ReserveProcessResultSchema>;
