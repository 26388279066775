import { priceSchema } from '../price';
import { reservationSchema, reservationStoreProductUpdateSchema } from '../reservation';
import { z } from '../zod-openapi';

export const productDraftSchema = z.object({
  productTypeId: z.string(),
  reservationId: z.string().optional(),
  price: priceSchema.optional(),
});

export type ProductDraft = z.infer<typeof productDraftSchema>;

export const productSchema = productDraftSchema.merge(reservationSchema).strict();

export type Product = z.infer<typeof productSchema>;

export const updateProductReservationsSchema = z.object({
  reservations: z.array(
    z
      .object({
        price: priceSchema.optional(),
      })
      .merge(reservationStoreProductUpdateSchema),
  ),
});
