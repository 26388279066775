// Automatically generated with 20250205125702_generate_phonecodes.ts
export const phoneCodes = [
  { phoneCode: "+93", countryIso2: "AF" },
  { phoneCode: "+358-18", countryIso2: "AX" },
  { phoneCode: "+355", countryIso2: "AL" },
  { phoneCode: "+213", countryIso2: "DZ" },
  { phoneCode: "+1-684", countryIso2: "AS" },
  { phoneCode: "+376", countryIso2: "AD" },
  { phoneCode: "+244", countryIso2: "AO" },
  { phoneCode: "+1-264", countryIso2: "AI" },
  { phoneCode: "+672", countryIso2: "AQ" },
  { phoneCode: "+1-268", countryIso2: "AG" },
  { phoneCode: "+54", countryIso2: "AR" },
  { phoneCode: "+374", countryIso2: "AM" },
  { phoneCode: "+297", countryIso2: "AW" },
  { phoneCode: "+61", countryIso2: "AU" },
  { phoneCode: "+43", countryIso2: "AT" },
  { phoneCode: "+994", countryIso2: "AZ" },
  { phoneCode: "+973", countryIso2: "BH" },
  { phoneCode: "+880", countryIso2: "BD" },
  { phoneCode: "+1-246", countryIso2: "BB" },
  { phoneCode: "+375", countryIso2: "BY" },
  { phoneCode: "+32", countryIso2: "BE" },
  { phoneCode: "+501", countryIso2: "BZ" },
  { phoneCode: "+229", countryIso2: "BJ" },
  { phoneCode: "+1-441", countryIso2: "BM" },
  { phoneCode: "+975", countryIso2: "BT" },
  { phoneCode: "+591", countryIso2: "BO" },
  { phoneCode: "+599", countryIso2: "BQ" },
  { phoneCode: "+387", countryIso2: "BA" },
  { phoneCode: "+267", countryIso2: "BW" },
  { phoneCode: "+0055", countryIso2: "BV" },
  { phoneCode: "+55", countryIso2: "BR" },
  { phoneCode: "+246", countryIso2: "IO" },
  { phoneCode: "+673", countryIso2: "BN" },
  { phoneCode: "+359", countryIso2: "BG" },
  { phoneCode: "+226", countryIso2: "BF" },
  { phoneCode: "+257", countryIso2: "BI" },
  { phoneCode: "+855", countryIso2: "KH" },
  { phoneCode: "+237", countryIso2: "CM" },
  { phoneCode: "+1", countryIso2: "CA" },
  { phoneCode: "+238", countryIso2: "CV" },
  { phoneCode: "+1-345", countryIso2: "KY" },
  { phoneCode: "+236", countryIso2: "CF" },
  { phoneCode: "+235", countryIso2: "TD" },
  { phoneCode: "+56", countryIso2: "CL" },
  { phoneCode: "+86", countryIso2: "CN" },
  { phoneCode: "+61", countryIso2: "CX" },
  { phoneCode: "+61", countryIso2: "CC" },
  { phoneCode: "+57", countryIso2: "CO" },
  { phoneCode: "+269", countryIso2: "KM" },
  { phoneCode: "+242", countryIso2: "CG" },
  { phoneCode: "+682", countryIso2: "CK" },
  { phoneCode: "+506", countryIso2: "CR" },
  { phoneCode: "+225", countryIso2: "CI" },
  { phoneCode: "+385", countryIso2: "HR" },
  { phoneCode: "+53", countryIso2: "CU" },
  { phoneCode: "+599", countryIso2: "CW" },
  { phoneCode: "+357", countryIso2: "CY" },
  { phoneCode: "+420", countryIso2: "CZ" },
  { phoneCode: "+243", countryIso2: "CD" },
  { phoneCode: "+45", countryIso2: "DK" },
  { phoneCode: "+253", countryIso2: "DJ" },
  { phoneCode: "+1-767", countryIso2: "DM" },
  { phoneCode: "+1-809", countryIso2: "DO" },
  { phoneCode: "+1-829", countryIso2: "DO" },
  { phoneCode: "+593", countryIso2: "EC" },
  { phoneCode: "+20", countryIso2: "EG" },
  { phoneCode: "+503", countryIso2: "SV" },
  { phoneCode: "+240", countryIso2: "GQ" },
  { phoneCode: "+291", countryIso2: "ER" },
  { phoneCode: "+372", countryIso2: "EE" },
  { phoneCode: "+268", countryIso2: "SZ" },
  { phoneCode: "+251", countryIso2: "ET" },
  { phoneCode: "+500", countryIso2: "FK" },
  { phoneCode: "+298", countryIso2: "FO" },
  { phoneCode: "+679", countryIso2: "FJ" },
  { phoneCode: "+358", countryIso2: "FI" },
  { phoneCode: "+33", countryIso2: "FR" },
  { phoneCode: "+594", countryIso2: "GF" },
  { phoneCode: "+689", countryIso2: "PF" },
  { phoneCode: "+262", countryIso2: "TF" },
  { phoneCode: "+241", countryIso2: "GA" },
  { phoneCode: "+220", countryIso2: "GM" },
  { phoneCode: "+995", countryIso2: "GE" },
  { phoneCode: "+49", countryIso2: "DE" },
  { phoneCode: "+233", countryIso2: "GH" },
  { phoneCode: "+350", countryIso2: "GI" },
  { phoneCode: "+30", countryIso2: "GR" },
  { phoneCode: "+299", countryIso2: "GL" },
  { phoneCode: "+1-473", countryIso2: "GD" },
  { phoneCode: "+590", countryIso2: "GP" },
  { phoneCode: "+1-671", countryIso2: "GU" },
  { phoneCode: "+502", countryIso2: "GT" },
  { phoneCode: "+44-1481", countryIso2: "GG" },
  { phoneCode: "+224", countryIso2: "GN" },
  { phoneCode: "+245", countryIso2: "GW" },
  { phoneCode: "+592", countryIso2: "GY" },
  { phoneCode: "+509", countryIso2: "HT" },
  { phoneCode: "+672", countryIso2: "HM" },
  { phoneCode: "+504", countryIso2: "HN" },
  { phoneCode: "+852", countryIso2: "HK" },
  { phoneCode: "+36", countryIso2: "HU" },
  { phoneCode: "+354", countryIso2: "IS" },
  { phoneCode: "+91", countryIso2: "IN" },
  { phoneCode: "+62", countryIso2: "ID" },
  { phoneCode: "+98", countryIso2: "IR" },
  { phoneCode: "+964", countryIso2: "IQ" },
  { phoneCode: "+353", countryIso2: "IE" },
  { phoneCode: "+972", countryIso2: "IL" },
  { phoneCode: "+39", countryIso2: "IT" },
  { phoneCode: "+1-876", countryIso2: "JM" },
  { phoneCode: "+81", countryIso2: "JP" },
  { phoneCode: "+44-1534", countryIso2: "JE" },
  { phoneCode: "+962", countryIso2: "JO" },
  { phoneCode: "+7", countryIso2: "KZ" },
  { phoneCode: "+254", countryIso2: "KE" },
  { phoneCode: "+686", countryIso2: "KI" },
  { phoneCode: "+383", countryIso2: "XK" },
  { phoneCode: "+965", countryIso2: "KW" },
  { phoneCode: "+996", countryIso2: "KG" },
  { phoneCode: "+856", countryIso2: "LA" },
  { phoneCode: "+371", countryIso2: "LV" },
  { phoneCode: "+961", countryIso2: "LB" },
  { phoneCode: "+266", countryIso2: "LS" },
  { phoneCode: "+231", countryIso2: "LR" },
  { phoneCode: "+218", countryIso2: "LY" },
  { phoneCode: "+423", countryIso2: "LI" },
  { phoneCode: "+370", countryIso2: "LT" },
  { phoneCode: "+352", countryIso2: "LU" },
  { phoneCode: "+853", countryIso2: "MO" },
  { phoneCode: "+261", countryIso2: "MG" },
  { phoneCode: "+265", countryIso2: "MW" },
  { phoneCode: "+60", countryIso2: "MY" },
  { phoneCode: "+960", countryIso2: "MV" },
  { phoneCode: "+223", countryIso2: "ML" },
  { phoneCode: "+356", countryIso2: "MT" },
  { phoneCode: "+44-1624", countryIso2: "IM" },
  { phoneCode: "+692", countryIso2: "MH" },
  { phoneCode: "+596", countryIso2: "MQ" },
  { phoneCode: "+222", countryIso2: "MR" },
  { phoneCode: "+230", countryIso2: "MU" },
  { phoneCode: "+262", countryIso2: "YT" },
  { phoneCode: "+52", countryIso2: "MX" },
  { phoneCode: "+691", countryIso2: "FM" },
  { phoneCode: "+373", countryIso2: "MD" },
  { phoneCode: "+377", countryIso2: "MC" },
  { phoneCode: "+976", countryIso2: "MN" },
  { phoneCode: "+382", countryIso2: "ME" },
  { phoneCode: "+1-664", countryIso2: "MS" },
  { phoneCode: "+212", countryIso2: "MA" },
  { phoneCode: "+258", countryIso2: "MZ" },
  { phoneCode: "+95", countryIso2: "MM" },
  { phoneCode: "+264", countryIso2: "NA" },
  { phoneCode: "+674", countryIso2: "NR" },
  { phoneCode: "+977", countryIso2: "NP" },
  { phoneCode: "+31", countryIso2: "NL" },
  { phoneCode: "+687", countryIso2: "NC" },
  { phoneCode: "+64", countryIso2: "NZ" },
  { phoneCode: "+505", countryIso2: "NI" },
  { phoneCode: "+227", countryIso2: "NE" },
  { phoneCode: "+234", countryIso2: "NG" },
  { phoneCode: "+683", countryIso2: "NU" },
  { phoneCode: "+672", countryIso2: "NF" },
  { phoneCode: "+850", countryIso2: "KP" },
  { phoneCode: "+389", countryIso2: "MK" },
  { phoneCode: "+1-670", countryIso2: "MP" },
  { phoneCode: "+47", countryIso2: "NO" },
  { phoneCode: "+968", countryIso2: "OM" },
  { phoneCode: "+92", countryIso2: "PK" },
  { phoneCode: "+680", countryIso2: "PW" },
  { phoneCode: "+970", countryIso2: "PS" },
  { phoneCode: "+507", countryIso2: "PA" },
  { phoneCode: "+675", countryIso2: "PG" },
  { phoneCode: "+595", countryIso2: "PY" },
  { phoneCode: "+51", countryIso2: "PE" },
  { phoneCode: "+63", countryIso2: "PH" },
  { phoneCode: "+870", countryIso2: "PN" },
  { phoneCode: "+48", countryIso2: "PL" },
  { phoneCode: "+351", countryIso2: "PT" },
  { phoneCode: "+1-787", countryIso2: "PR" },
  { phoneCode: "+1-939", countryIso2: "PR" },
  { phoneCode: "+974", countryIso2: "QA" },
  { phoneCode: "+262", countryIso2: "RE" },
  { phoneCode: "+40", countryIso2: "RO" },
  { phoneCode: "+7", countryIso2: "RU" },
  { phoneCode: "+250", countryIso2: "RW" },
  { phoneCode: "+290", countryIso2: "SH" },
  { phoneCode: "+1-869", countryIso2: "KN" },
  { phoneCode: "+1-758", countryIso2: "LC" },
  { phoneCode: "+508", countryIso2: "PM" },
  { phoneCode: "+1-784", countryIso2: "VC" },
  { phoneCode: "+590", countryIso2: "BL" },
  { phoneCode: "+590", countryIso2: "MF" },
  { phoneCode: "+685", countryIso2: "WS" },
  { phoneCode: "+378", countryIso2: "SM" },
  { phoneCode: "+239", countryIso2: "ST" },
  { phoneCode: "+966", countryIso2: "SA" },
  { phoneCode: "+221", countryIso2: "SN" },
  { phoneCode: "+381", countryIso2: "RS" },
  { phoneCode: "+248", countryIso2: "SC" },
  { phoneCode: "+232", countryIso2: "SL" },
  { phoneCode: "+65", countryIso2: "SG" },
  { phoneCode: "+1721", countryIso2: "SX" },
  { phoneCode: "+421", countryIso2: "SK" },
  { phoneCode: "+386", countryIso2: "SI" },
  { phoneCode: "+677", countryIso2: "SB" },
  { phoneCode: "+252", countryIso2: "SO" },
  { phoneCode: "+27", countryIso2: "ZA" },
  { phoneCode: "+500", countryIso2: "GS" },
  { phoneCode: "+82", countryIso2: "KR" },
  { phoneCode: "+211", countryIso2: "SS" },
  { phoneCode: "+34", countryIso2: "ES" },
  { phoneCode: "+94", countryIso2: "LK" },
  { phoneCode: "+249", countryIso2: "SD" },
  { phoneCode: "+597", countryIso2: "SR" },
  { phoneCode: "+47", countryIso2: "SJ" },
  { phoneCode: "+46", countryIso2: "SE" },
  { phoneCode: "+41", countryIso2: "CH" },
  { phoneCode: "+963", countryIso2: "SY" },
  { phoneCode: "+886", countryIso2: "TW" },
  { phoneCode: "+992", countryIso2: "TJ" },
  { phoneCode: "+255", countryIso2: "TZ" },
  { phoneCode: "+66", countryIso2: "TH" },
  { phoneCode: "+1-242", countryIso2: "BS" },
  { phoneCode: "+670", countryIso2: "TL" },
  { phoneCode: "+228", countryIso2: "TG" },
  { phoneCode: "+690", countryIso2: "TK" },
  { phoneCode: "+676", countryIso2: "TO" },
  { phoneCode: "+1-868", countryIso2: "TT" },
  { phoneCode: "+216", countryIso2: "TN" },
  { phoneCode: "+90", countryIso2: "TR" },
  { phoneCode: "+993", countryIso2: "TM" },
  { phoneCode: "+1-649", countryIso2: "TC" },
  { phoneCode: "+688", countryIso2: "TV" },
  { phoneCode: "+256", countryIso2: "UG" },
  { phoneCode: "+380", countryIso2: "UA" },
  { phoneCode: "+971", countryIso2: "AE" },
  { phoneCode: "+44", countryIso2: "GB" },
  { phoneCode: "+1", countryIso2: "US" },
  { phoneCode: "+1", countryIso2: "UM" },
  { phoneCode: "+598", countryIso2: "UY" },
  { phoneCode: "+998", countryIso2: "UZ" },
  { phoneCode: "+678", countryIso2: "VU" },
  { phoneCode: "+379", countryIso2: "VA" },
  { phoneCode: "+58", countryIso2: "VE" },
  { phoneCode: "+84", countryIso2: "VN" },
  { phoneCode: "+1-284", countryIso2: "VG" },
  { phoneCode: "+1-340", countryIso2: "VI" },
  { phoneCode: "+681", countryIso2: "WF" },
  { phoneCode: "+212", countryIso2: "EH" },
  { phoneCode: "+967", countryIso2: "YE" },
  { phoneCode: "+260", countryIso2: "ZM" },
  { phoneCode: "+263", countryIso2: "ZW" }
];