import { z } from '../zod-openapi';

export const zodNumberOrStringAsPositiveInteger = z
  .number()
  .positive()
  .multipleOf(1)
  .or(
    z
      .string()
      .regex(/^\d+$/)
      .transform((v) => Number(v)),
  );

export const requestListQuerySchema = z
  .object({
    page: zodNumberOrStringAsPositiveInteger,
    perPage: zodNumberOrStringAsPositiveInteger,
    fields: z.string().regex(/^(?:\w{1,20}(?:, ?|\s)){0,19}\w{1,20}$/),
    sortBy: z.string(), // The new table will do the sorting with -field or field. Take this into account in apis when doing sorting
    search: z.string().optional(),
    value: z.string().optional(),
    id: z.string().optional(),
  })
  .partial();

export type RequestListQuery = z.infer<typeof requestListQuerySchema>;

export const idParam = z.object({
  id: z.string().openapi({
    param: {
      name: 'id',
      in: 'path',
    },
    example: '123456789',
  }),
});

export type IdParam = z.infer<typeof idParam>;

export const localizedString = z.record(z.string(), z.string()).openapi({
  example: { en: 'Example text' },
  description: 'Localized string object with language codes as keys',
});
