import { z } from '../zod-openapi';
import { countryCodeSchema } from '../world';

export const contentAddressSchema = z.object({
  placeId: z.string(),
  formattedAddress: z.string(),
  countryIso2: countryCodeSchema.optional(),
  city: z.string(),
  postcode: z.string(),
  cityId: z.number().int().optional(),
  country: z.string(),
});

export type ContentAddress = z.infer<typeof contentAddressSchema>;
