import { requestListQuerySchema } from '../common/request';
import { lengthAwarePaginationSchema } from '../pagination';
import { z } from '../zod-openapi';

// Already created tag types on the db

export enum TagTypes {
  ORGANIZATION_TYPE = 'ORGANIZATION_TYPE',
  CUSTOMER_GROUP = 'CUSTOMER_GROUP',
  CONTACT_ROLE = 'CONTACT_ROLE',
  SERVICE_LEVEL = 'SERVICE_LEVEL',
  SALES_TARGET = 'SALES_TARGET',
  BILLING_CYCLE = 'BILLING_CYCLE',
  CUSTOMER_CLASS = 'CUSTOMER_CLASS',
  PRICE_GROUP = 'PRICE_GROUP',
  EINVOICE_INTERMEDIARY = 'EINVOICE_INTERMEDIARY',
  EVENT_GENRE = 'EVENT_GENRE',
  EVENT_GENRE_TYPE = 'EVENT_GENRE_TYPE',
}

export const TagTypeschema = z.nativeEnum(TagTypes);

export type TagType = z.infer<typeof TagTypeschema>;

// Schemas for interacting with the tag_types table:

// Schema for creating a new tag type
export const TagTypeCreateSchema = z.object({
  type: z.string(),
});
export type TagTypeCreate = z.infer<typeof TagTypeCreateSchema>;

// Schema for a tag type
export const TagTypeItemSchema = z.object({
  id: z.string(),
  type: z.string(),
});
export type TagTypeItem = z.infer<typeof TagTypeItemSchema>;

// Schema for the tag type id parameter
export const TagTypeParamsSchema = z.object({
  id: z.string(),
});
export type TagTypeParams = z.infer<typeof TagTypeParamsSchema>;

// Schema for the response when fetching multiple tag types
export const TagTypesResultSchema = z.object({
  tagTypes: z.array(TagTypeItemSchema),
});
export type TagTypesResult = z.infer<typeof TagTypesResultSchema>;

// Schema for the response when fetching a single tag type
export const TagTypeResultSchema = z.object({
  tagType: TagTypeItemSchema,
});
export type TagTypeResult = z.infer<typeof TagTypeResultSchema>;

// Schemas for interacting with the tag table:

// Schema for creating a new tag
export const TagCreateSchema = z.object({
  value: z.object({ fi: z.string(), en: z.string() }),
  description: z.object({ fi: z.string(), en: z.string() }).nullable(),
  tagTypeId: z.string(),
  details: z.null().or(z.record(z.any())),
  colorId: z.string().nullable(),
  parentTagId: z.string().nullable().optional(),
});
export type TagCreate = z.infer<typeof TagCreateSchema>;

// Schema for a tag
export const TagSchema = z.object({
  id: z.string(),
  value: z.object({ fi: z.string(), en: z.string() }),
  tagTypeId: z.string(),
  description: z.object({ fi: z.string(), en: z.string() }).nullable(),
  isActive: z.boolean(),
  details: z.null().or(z.record(z.any())),
  colorId: z.string().nullable(),
  createdAt: z.string().or(z.date()).optional(),
  updatedAt: z.string().or(z.date()).optional(),
  parentTagId: z.string().nullable(),
});
export type Tag = z.infer<typeof TagSchema>;

// Schema for the tag id parameter
export const TagParamsSchema = z.object({
  id: z.string(),
});
export type TagParams = z.infer<typeof TagParamsSchema>;

// Schema for the query parameters for fetching tags. Tags can be filtered by type.
export const TagsQuerySchema = requestListQuerySchema.extend({
  type: z.union([TagTypeschema, z.array(TagTypeschema)]).optional(),

  parentTagId: z
    .string()
    .regex(/^(\w+)(,\w+)*$/, 'Invalid parentTagId format. Must be comma-separated values or a single string.')
    .optional(),
});

export type TagsQuery = z.infer<typeof TagsQuerySchema>;

// Schema for the response when fetching multiple tags
export const TagsResultSchema = z.object({
  tags: z.array(TagSchema),
  pagination: lengthAwarePaginationSchema,
});
export type TagsResult = z.infer<typeof TagsResultSchema>;

// Schema for the response when fetching a single tag
export const TagResultSchema = z.object({
  tag: TagSchema,
});
export type TagResult = z.infer<typeof TagResultSchema>;

// Schemas for tag colors.
export const TagColorSchema = z.object({
  id: z.string(),
  hex: z.string(),
});
export type TagColor = z.infer<typeof TagColorSchema>;

export const TagColorsResultSchema = z.object({
  colors: z.array(TagColorSchema),
});
export type TagColorsResult = z.infer<typeof TagColorsResultSchema>;
