import { z } from '@hono/zod-openapi';
const EU_COUNTRIES = {
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  EE: 'Estonia',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GR: 'Greece',
  HU: 'Hungary',
  IE: 'Ireland',
  IT: 'Italy',
  LV: 'Latvia',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MT: 'Malta',
  NL: 'Netherlands',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ES: 'Spain',
  SE: 'Sweden',
};

export const IS_EU_COUNTRY = (countryCode: string): boolean => {
  return EU_COUNTRIES[countryCode as keyof typeof EU_COUNTRIES] !== undefined;
};

const SmartshipSchemaPayloadBody = z
  .object({
    sender: z
      .object({
        quickId: z.string().optional(),
        name: z.string().optional(),
        country: z.string().optional(),
        city: z.string().optional(),
      })
      .refine((schema) => {
        return schema.quickId || (schema.name && schema.city && schema.country);
      })
      .openapi({
        description:
          'Sender information. quickId maps to preconfigured sender from the shipment portal. quickId value 1 maps to predefined in the portal. Also possible to define sender bypassing the portal configured.',
        example: {
          quickId: '1',
        },
      }),
    receiver: z
      .object({
        name: z.string(),
        address1: z.string().optional(),
        address2: z.string().optional(),
        zipcode: z.string().optional(),
        city: z.string(),
        country: z.string(),
        phone: z.string().optional(),
        mobile: z.string().optional(),
        email: z.string().optional(),
      })
      .openapi({
        description: 'Receiver information',
        example: {
          name: 'Tiketti Oy',
          address1: 'Urho Kekkosen katu 4-6',
          address2: 'Urho Kekkosen katu 4-6',
          zipcode: '00100',
          city: 'Helsinki',
          country: 'FI',
          phone: '0401234567',
          email: 'john.doe@tiketti.fi',
        },
      }),
    orderNo: z.string().optional().openapi({
      description: 'Order id from current system',
      example: '123456',
    }),
    service: z
      .object({
        id: z.string(),
      })
      .refine((schema) => {
        return schema.id === 'PO5001' || schema.id === 'PO5003' || schema.id === 'PO2461';
      })
      .openapi({
        description:
          'Service id. PO5001 is for domestic letters, PO2461 for domestic packages and PO5003 for international shipments',
        example: {
          id: 'PO5001',
        },
      }),
    customsDeclaration: z
      .object({
        printSet: z
          .array(z.string())
          .optional()
          .openapi({
            description: 'Print set for customs declaration. Either CN22POSTI, CN23POSTI or both.',
            example: ['CN22POSTI'],
          }),
        invoiceType: z.string().optional().openapi({
          description: 'Invoice type',
          example: 'STANDARD',
        }),
        invoiceNo: z.string().optional().openapi({
          description: 'Invoice number',
          example: '2024-06-06',
        }),
        currencyCode: z.string().optional().openapi({
          description: 'Currency code',
          example: 'EUR',
        }),
        importExportType: z.string().optional().openapi({
          description: 'Import export type',
          example: 'PERMANENT',
        }),
        lines: z
          .array(
            z
              .object({
                statNo: z.string().refine((value) => value.length === 6 || value.length === 8 || value.length === 10),
                copies: z.string().refine((value) => !!/^-?\d+$/.test(value)),
                value: z.number(),
                contents: z.string().optional(),
                netWeight: z.string().refine((value) => !!/^-?\d+(\.\d+)?$/.test(value)),
                sourceCountryCode: z.string(),
              })
              .openapi({
                description:
                  'Customs declaration lines. Notice: if "value" is at or over posti set treshold ~300€ eur at 17/07/2024, CN23POSTI printSet is required.',
                example: {
                  statNo: '123456',
                  copies: '1',
                  value: 100,
                  contents: 'Books',
                  netWeight: '1.5',
                  sourceCountryCode: 'FI',
                },
              }),
          )
          .optional(),
      })
      .optional(),
    parcels: z
      .array(
        z.object({
          copies: z.string().refine((value) => !!/^-?\d+$/.test(value)),
          weight: z.string().refine((value) => !!/^-?\d+(\.\d+)?$/.test(value)),
          contents: z.string().optional(),
          valuePerParcel: z.boolean(),
        }),
      )
      .openapi({
        description: 'Parcels information',
        example: [
          {
            copies: '1',
            weight: '0.249',
            contents: 'tickets',
            valuePerParcel: true,
          },
        ],
      }),
  })
  .superRefine((data, ctx) => {
    const isInEu = IS_EU_COUNTRY(data.receiver.country);
    if (data.customsDeclaration && !isInEu) {
      const parcelValue = data.customsDeclaration?.lines?.[0]?.value;
      const customsDeclarations = data.customsDeclaration;
      const value = parcelValue || 0;

      // OUTSIDE EU
      // CN22 is required when package value below 300€
      // CN23 is required when package value exceeds 300€

      if (value >= 300) {
        if (!customsDeclarations.printSet?.includes('CN23POSTI')) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `CN23POSTI printSet is required for parcels over 300€`,
          });
        }
      } else {
        if (!customsDeclarations.printSet?.includes('CN22POSTI')) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `CN22POSTI printSet is required for parcels with value less than 300€`,
          });
        }
      }
    }
  });

export type SmartshipSchemaPayload = z.infer<typeof SmartshipSchemaPayloadBody>;

export const SmartshipCreateItemSchema = z.object({
  shipment: SmartshipSchemaPayloadBody,
  printConfig: z.record(z.string()).openapi({
    description: 'Print configuration',
    example: {
      target1Media: 'laser-a5',
      target2Media: 'laser-a4',
    },
  }),
});

export type SmartshipCreateItem = z.infer<typeof SmartshipCreateItemSchema>;

export const SmartshipCreateSchema = z.object({
  items: z.array(SmartshipCreateItemSchema),
});

export type SmartshipCreate = z.infer<typeof SmartshipCreateSchema>;
export const ShipmentsErrorSchema = z
  .object({
    type: z.literal('error'),
    field: z.string(),
    location: z.string(),
    messageCode: z.string(),
    message: z.string(),
  })
  .openapi({
    description: 'Error object received from smartship api call',
    example: {
      type: 'error',
      field: 'parcels',
      location: 'parcels[0]',
      messageCode: 'error.parcel.weight.invalid',
      message: 'Parcel weight is invalid',
    },
  });

const SmartshipCreateResponseItemSchema = z.object({
  orderNo: z.string().openapi({
    description: 'Order number',
    example: '11111111',
  }),
  parcels: z.array(z.string()).openapi({
    description: 'Posti created Parcel identifiers',
    example: ['LX899059160FI'],
  }),
  concatenatedPrint: z.string().optional().openapi({
    description: 'Concatenated pdf print in base64 format',
    example:
      'data:application/pdf;base64,JVBERi0xLjcKJYGBgYEKCjQgMCBvYmoKPDwKL0ZpbHRlciAvRmxhdGVEZWNvZGUKL0xlbmd0aCAxNDg1Cj...',
  }),
});
export const SmartshipCreateResponseSchema = z.object({
  labels: z.string().openapi({ description: 'File in Base64 format' }),
  failed: z.array(ShipmentsErrorSchema.optional()).openapi({ description: 'Prints that failed' }),
  printed: z.array(SmartshipCreateResponseItemSchema.optional()).openapi({ description: 'Prints that succeeded' }),
});

export type SmartshipCreateBody = z.infer<typeof SmartshipCreateSchema>;

const ParcelResponseSchema = z.object({
  parcelNo: z.string(),
  returnParcelNo: z.string().nullable(),
  reference: z.string().nullable(),
  partOrderNo: z.string().nullable(),
  portalId: z.string().nullable(),
});

const PrintResponseSchema = z.object({
  href: z.string(),
  id: z.string(),
  description: z.string(),
  data: z.string(),
  media: z.string(),
  type: z.string(),
});

const ShipmentResponseSchema = z.object({
  href: z.string(),
  id: z.string(),
  status: z.string(),
  shipmentNo: z.string().nullable(),
  orderNo: z.string(),
  route: z.string().nullable(),
  routingCode: z.string().nullable(),
  reference: z.string().nullable(),
  serviceId: z.string(),
  parcelCount: z.number(),
  sndName: z.string(),
  sndZipcode: z.string(),
  sndCity: z.string(),
  sndCountry: z.string(),
  sndQuickId: z.string(),
  rcvName: z.string(),
  rcvZipcode: z.string(),
  rcvCity: z.string(),
  rcvCountry: z.string(),
  rcvQuickId: z.string().nullable(),
  created: z.string(),
  changed: z.string(),
  shipDate: z.string(),
  returnShipment: z.boolean(),
  normalShipment: z.boolean(),
  consolidated: z.boolean(),
  deliveryCode: z.string().nullable(),
  profileGroup: z.string(),
  portalId: z.string().nullable(),
  parcels: z.array(ParcelResponseSchema),
  pdfs: z.array(z.string()),
  previousPdfs: z.string().nullable(),
  prints: z.array(PrintResponseSchema),
  previousPrints: z.string().nullable(),
  addons: z.array(z.any()),
  statuses: z.any().nullable(),
  printed: z.string().nullable(),
  concatenatedPrint: z.string().optional(),
});

export const SmartshipRequestResponseDataSchema = z.union([
  z.array(ShipmentResponseSchema),
  z.array(ShipmentsErrorSchema),
]);

export type SmartshipRequestResponseDataItem = z.infer<typeof ShipmentResponseSchema>;
export type SmartshipRequestResponseError = z.infer<typeof ShipmentsErrorSchema>;
export type SmartshipRequestResponseDataOrError = z.infer<typeof SmartshipRequestResponseDataSchema>;

export type SmartshipCreateResponseItem = z.infer<typeof SmartshipCreateResponseItemSchema>;

export enum SmartshipProvider {
  SMARTSHIP = 'smartship',
}

export type SmartshipCreateResponse = z.infer<typeof SmartshipCreateResponseSchema>;
