export enum TikettiEventStatusByNumber {
  EVENT_STATUS_FLAG_DELETED = 1,
  EVENT_STATUS_FLAG_PUBLIC = 2,
  EVENT_PARENT_CHILD_TYPE_CHILD = 3,
  EVENT_STATUS_FLAG_CANCELLED = 4,
  EVENT_STATUS_FLAG_SOLD_OUT = 8,
  EVENT_STATUS_FLAG_CANCELLED_AND_FOR_SALE = 16,
  EVENT_STATUS_FLAG_TEMPLATE = 32,
  EVENT_STATUS_FLAG_ORGANIZER_DONE = 64,
  EVENT_STATUS_FLAG_PREVIEW = 128,
  EVENT_STATUS_FLAG_RESCHEULING_IN_PROGRESS = 256,
}

export enum EventDeliveryMethodName {
  ENABLED = 'Enabled',
  RESERVE = 'Nouto (netti)',
  SMS = 'Mobiililippu',
  PDF = 'PDF',
  POST = 'Posti (netti)',
  KIVRA = 'Kivra',
  UNKNOWN = 'Unknown',
}

export enum EventDeliveryMethod {
  ENABLED = 1,
  RESERVE = 2,
  SMS = 4,
  PDF = 8,
  POST = 16,
  KIVRA = 32,
}
export enum EventPaymentMethod {
  ENABLED = 1,
  RESERVE = 2,
  EPAY = 4,
  LUOTTOKUNTA = 8,
  SPORTTIPASSI = 16, // ePassi
  EVERYDAY = 32,
  EMAIL = 64,
  SMARTUM = 128, // Smartum-verkkomaksu
  EDENRED = 256, // Edenred-verkkomaksu
  EAZYBREAK = 512, // Eazybreak-verkkomaksu
}

export enum EventPaymentMethodName {
  ENABLED = '-',
  RESERVE = 'Varaus',
  EPAY = 'Verkkopankit',
  LUOTTOKUNTA = 'Visa / Mastercard / Amex / MobilePay',
  SPORTTIPASSI = 'ePassi',
  EVERYDAY = 'Everyday-maksu',
  EMAIL = 'Sähköpostilasku',
  SMARTUM = 'Smartum-verkkomaksu',
  EDENRED = 'Edenred-verkkomaksu',
  EAZYBREAK = 'Eazybreak-verkkomaksu',
  UNKNOWN = 'Unknown',
}

export enum EventInstanceStatus {
  NotForSale = 'not_for_sale',
  ForSale = 'for_sale',
  PresaleOnly = 'presale_only',
}
