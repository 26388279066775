import { z } from '../zod-openapi';
import { reservationStateSchema } from './state';

export const reservationInventoryStatusEnum = {
  active: 'active',
  inactive: 'inactive',
};

export enum ReservationInventoryStatusEnum {
  Active = 'active',
  Inactive = 'inactive',
}

export const reservationSchema = z.object({
  id: z.string().optional(),
  validUntil: z.string().optional(),
  state: reservationStateSchema,
});

export const reservationStoreSchema = z.object({
  validUntil: z.string().optional(),
  reservationId: z.string(),
  ticketTypeId: z.string(),
  state: reservationStateSchema,
});

export const reservationStoreUpdateSchema = z.object({
  validUntil: z.string().optional(),
  id: z.string(),
  ticketTypeId: z.string(),
  state: reservationStateSchema,
});

export const reservationStoreProductSchema = reservationStoreSchema
  .omit({
    ticketTypeId: true,
  })
  .extend({
    productTypeId: z.string(),
  });

export const reservationStoreProductUpdateSchema = reservationStoreUpdateSchema
  .omit({
    ticketTypeId: true,
  })
  .extend({
    productTypeId: z.string(),
  });

export type ReservationStoreUpdate = z.infer<typeof reservationStoreUpdateSchema>;
export type ReservationStore = z.infer<typeof reservationStoreSchema>;
export type ReservationProductStore = z.infer<typeof reservationStoreProductSchema>;
export type ReservationProductStoreUpdate = z.infer<typeof reservationStoreProductUpdateSchema>;

export type Reservation = z.infer<typeof reservationSchema>;

export const ReservationInventoryDBItemSchema = z.object({
  id: z.string(),
  available: z.number(),
  reserved: z.number(),
  quota: z.number(),
  reserve_time_seconds: z.number(),
  inventory_status: z.enum(['active', 'inactive']),
  type_id: z.string(),
  type: z.enum(['ticket', 'product']),
});

export type ReservationInventoryDBItem = z.infer<typeof ReservationInventoryDBItemSchema>;
