import { cartIdSchema } from '../cart/id';
import { timestampSchema } from '../date';
import { getIdSchema, getIdValueSchema } from '../id';
import { orderIdSchema, orderIdValueSchema } from '../order/id';
import { z } from '../zod-openapi';
import {
  paytrailPaymentResultAndHeadersSchema,
  paytrailPaymentResultSchema,
  paytrailPaymentSchema,
  paytrailProvidersSchema,
} from './paytrail';

export * from './paytrail';

export enum OrderPaymentStatus {
  Underpaid = 'underpaid',
  Overpaid = 'overpaid',
  ExactlyPaid = 'exactlyPaid',
  Invalid = 'invalid',
}

export type PaymentCheckResult = {
  success: boolean;
  orderPaymentStatus: OrderPaymentStatus;
  difference: number;
};

const paymentIdSchema = getIdSchema('paymentId', 8);
const paymentIdValueSchema = paymentIdSchema.shape.paymentId;

export const paymentIntentInfoSchema = z
  .object({
    orderId: orderIdValueSchema,
    paymentId: paymentIdValueSchema,
    providers: paytrailProvidersSchema,
  })
  .strict();

export type PaymentIntentInfo = z.infer<typeof paymentIntentInfoSchema>;

export const paymentStateSchema = z.enum(['creating', 'created', 'paying', 'paid', 'cancelled']);

export const paymentState = paymentStateSchema.enum;

export const paytrailPaymentStateMap: Record<string, keyof typeof paymentState> = {
  new: paymentState.created,
  ok: paymentState.paid,
  pending: paymentState.paying,
  delayed: paymentState.paying,
  fail: paymentState.cancelled,
};

export const paymentProvider = z.enum(['paytrail']);

export type PaymentProvider = z.infer<typeof paymentProvider>;

export const paymentSchema = z
  .object({
    id: getIdValueSchema(8),
    orderId: getIdValueSchema(8),
    created: timestampSchema,
    state: paymentStateSchema,
    amount: z.number().int().openapi({ description: 'Total payable in euro cents including taxes', example: 1255 }),
    gateway: z.literal('paytrail'),
    gatewayPaymentInfo: paytrailPaymentSchema.partial(),
    selectedProviderId: z.string().or(z.null()).optional().openapi({
      description: 'Once selected, id of a provider for this payment',
      example: 'osuuspankki',
    }),
    results: z.array(
      paytrailPaymentResultSchema.merge(
        z.object({
          created: z.coerce.date().openapi({
            description: 'date in string format that we coerce to date',
            example: '2024-10-15T11:35:08.680Z',
          }),
        }),
      ),
    ),
  })
  .strict();

export const paymentsSchema = z.object({ payments: z.array(paymentSchema) });

export type Payment = z.infer<typeof paymentSchema>;

export const paymentParamsSchema = orderIdSchema.merge(paymentIdSchema);

export type PaymentParams = z.infer<typeof paymentParamsSchema>;

export const paymentResultSchema = paymentParamsSchema.merge(paytrailPaymentResultAndHeadersSchema).strict();

export type PaymentResult = z.infer<typeof paymentResultSchema>;

export const cartPaymentParamsSchema = cartIdSchema.merge(paymentParamsSchema).strict();
export type CartPaymentParams = z.infer<typeof cartPaymentParamsSchema>;
