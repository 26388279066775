// generated from countries-and-timezones module by /home/kato/tiketti/tiketti-api/scripts/world-import/generate-countries-and-timezones-ts.js on 2024-03-06
// Manual changes to this file may be overwritten; see the script on how to add entries.

// All names of timezones known by countries-and-timezones on 2024-03-06
export const timezoneNames = [
  "Africa/Abidjan",
  "Africa/Algiers",
  "Africa/Bissau",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/El_Aaiun",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Khartoum",
  "Africa/Lagos",
  "Africa/Maputo",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Sao_Tome",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Asuncion",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Cayenne",
  "America/Chicago",
  "America/Chihuahua",
  "America/Ciudad_Juarez",
  "America/Costa_Rica",
  "America/Cuiaba",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Fort_Nelson",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/New_York",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Nuuk",
  "America/Ojinaga",
  "America/Panama",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Sitka",
  "America/St_Johns",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Tijuana",
  "America/Toronto",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Troll",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Colombo",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kathmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuching",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qostanay",
  "Asia/Qyzylorda",
  "Asia/Riyadh",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ulaanbaatar",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faroe",
  "Atlantic/Madeira",
  "Atlantic/South_Georgia",
  "Atlantic/Stanley",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/Perth",
  "Australia/Sydney",
  "CET",
  "CST6CDT",
  "EET",
  "EST",
  "EST5EDT",
  "Etc/GMT",
  "Etc/GMT+1",
  "Etc/GMT+10",
  "Etc/GMT+11",
  "Etc/GMT+12",
  "Etc/GMT+2",
  "Etc/GMT+3",
  "Etc/GMT+4",
  "Etc/GMT+5",
  "Etc/GMT+6",
  "Etc/GMT+7",
  "Etc/GMT+8",
  "Etc/GMT+9",
  "Etc/GMT-1",
  "Etc/GMT-10",
  "Etc/GMT-11",
  "Etc/GMT-12",
  "Etc/GMT-13",
  "Etc/GMT-14",
  "Etc/GMT-2",
  "Etc/GMT-3",
  "Etc/GMT-4",
  "Etc/GMT-5",
  "Etc/GMT-6",
  "Etc/GMT-7",
  "Etc/GMT-8",
  "Etc/GMT-9",
  "Etc/UTC",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Chisinau",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Helsinki",
  "Europe/Istanbul",
  "Europe/Kaliningrad",
  "Europe/Kirov",
  "Europe/Kyiv",
  "Europe/Lisbon",
  "Europe/London",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Minsk",
  "Europe/Moscow",
  "Europe/Paris",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Sofia",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Ulyanovsk",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zurich",
  "Factory",
  "HST",
  "Indian/Chagos",
  "Indian/Maldives",
  "Indian/Mauritius",
  "MET",
  "MST",
  "MST7MDT",
  "PST8PDT",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Kanton",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Marquesas",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "WET",
  "Europe/Mariehamn",
  "Africa/Luanda",
  "America/Anguilla",
  "Antarctica/DumontDUrville",
  "Antarctica/McMurdo",
  "Antarctica/Syowa",
  "Antarctica/Vostok",
  "America/Antigua",
  "America/Aruba",
  "Australia/Currie",
  "Asia/Bahrain",
  "Africa/Porto-Novo",
  "Europe/Sarajevo",
  "Africa/Gaborone",
  "Europe/Oslo",
  "Asia/Brunei",
  "Africa/Ouagadougou",
  "Africa/Bujumbura",
  "Asia/Phnom_Penh",
  "Africa/Douala",
  "America/Atikokan",
  "America/Blanc-Sablon",
  "America/Creston",
  "America/Nipigon",
  "America/Pangnirtung",
  "America/Rainy_River",
  "America/Thunder_Bay",
  "America/Yellowknife",
  "America/Cayman",
  "Africa/Bangui",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Africa/Brazzaville",
  "Europe/Zagreb",
  "America/Curacao",
  "Africa/Kinshasa",
  "Africa/Lubumbashi",
  "Europe/Copenhagen",
  "Africa/Djibouti",
  "America/Dominica",
  "Africa/Malabo",
  "Africa/Asmara",
  "Africa/Mbabane",
  "Africa/Addis_Ababa",
  "Indian/Kerguelen",
  "Africa/Libreville",
  "Africa/Banjul",
  "Europe/Busingen",
  "Africa/Accra",
  "America/Grenada",
  "America/Guadeloupe",
  "Europe/Guernsey",
  "Africa/Conakry",
  "Atlantic/Reykjavik",
  "Europe/Jersey",
  "Pacific/Enderbury",
  "Asia/Kuwait",
  "Asia/Vientiane",
  "Africa/Maseru",
  "Europe/Vaduz",
  "Europe/Luxembourg",
  "Indian/Antananarivo",
  "Africa/Blantyre",
  "Asia/Kuala_Lumpur",
  "Africa/Bamako",
  "Europe/Isle_of_Man",
  "Pacific/Majuro",
  "Africa/Nouakchott",
  "Indian/Mayotte",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Europe/Monaco",
  "Europe/Podgorica",
  "America/Montserrat",
  "Europe/Amsterdam",
  "Africa/Niamey",
  "Europe/Skopje",
  "Pacific/Saipan",
  "Asia/Muscat",
  "Indian/Reunion",
  "Africa/Kigali",
  "Atlantic/St_Helena",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Vincent",
  "America/St_Barthelemy",
  "America/Marigot",
  "Europe/San_Marino",
  "Africa/Dakar",
  "Indian/Mahe",
  "Africa/Freetown",
  "Europe/Bratislava",
  "Europe/Ljubljana",
  "Africa/Mogadishu",
  "Arctic/Longyearbyen",
  "Europe/Stockholm",
  "Africa/Dar_es_Salaam",
  "America/Nassau",
  "Africa/Lome",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Africa/Kampala",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Midway",
  "Pacific/Wake",
  "Europe/Vatican",
  "America/Tortola",
  "America/St_Thomas",
  "Pacific/Wallis",
  "Asia/Aden",
  "Africa/Lusaka",
  "Africa/Harare"
] as const;

// Information about all timezones known by countries-and-timezones on 2024-03-06
export const timezoneInfo = {
  "Africa/Abidjan": {
    "name": "Africa/Abidjan",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "+00:00"
  },
  "Africa/Algiers": {
    "name": "Africa/Algiers",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "+01:00"
  },
  "Africa/Bissau": {
    "name": "Africa/Bissau",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "+00:00"
  },
  "Africa/Cairo": {
    "name": "Africa/Cairo",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Africa/Casablanca": {
    "name": "Africa/Casablanca",
    "windowsName": "Western European Summer Time",
    "abbreviation": "WEST",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "+00:00"
  },
  "Africa/Ceuta": {
    "name": "Africa/Ceuta",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Africa/El_Aaiun": {
    "name": "Africa/El_Aaiun",
    "windowsName": "Western European Summer Time",
    "abbreviation": "WEST",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "+00:00"
  },
  "Africa/Johannesburg": {
    "name": "Africa/Johannesburg",
    "windowsName": "South African Standard Time",
    "abbreviation": "SAST",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Africa/Juba": {
    "name": "Africa/Juba",
    "windowsName": "East Africa Time",
    "abbreviation": "EAT",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Africa/Khartoum": {
    "name": "Africa/Khartoum",
    "windowsName": "Eastern African Time",
    "abbreviation": "EAT",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Africa/Lagos": {
    "name": "Africa/Lagos",
    "windowsName": "West Africa Time",
    "abbreviation": "WAT",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "+01:00"
  },
  "Africa/Maputo": {
    "name": "Africa/Maputo",
    "windowsName": "Central Africa Time",
    "abbreviation": "CAT",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Africa/Monrovia": {
    "name": "Africa/Monrovia",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "+00:00"
  },
  "Africa/Nairobi": {
    "name": "Africa/Nairobi",
    "windowsName": "East Africa Time",
    "abbreviation": "EAT",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Africa/Ndjamena": {
    "name": "Africa/Ndjamena",
    "windowsName": "West Africa Time",
    "abbreviation": "WAT",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "+01:00"
  },
  "Africa/Sao_Tome": {
    "name": "Africa/Sao_Tome",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "+00:00"
  },
  "Africa/Tripoli": {
    "name": "Africa/Tripoli",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Africa/Tunis": {
    "name": "Africa/Tunis",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "+01:00"
  },
  "Africa/Windhoek": {
    "name": "Africa/Windhoek",
    "windowsName": "West Africa Summer Time",
    "abbreviation": "WAST",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "America/Adak": {
    "name": "America/Adak",
    "windowsName": "Hawaii–Aleutian Standard Time",
    "abbreviation": "HST",
    "utcOffsetSeconds": -36000,
    "utcOffsetText": "-10:00",
    "dstOffsetSeconds": -32400,
    "dstOffsetText": "-09:00"
  },
  "America/Anchorage": {
    "name": "America/Anchorage",
    "windowsName": "Alaska Standard Time",
    "abbreviation": "AKST",
    "utcOffsetSeconds": -32400,
    "utcOffsetText": "-09:00",
    "dstOffsetSeconds": -28800,
    "dstOffsetText": "-08:00"
  },
  "America/Araguaina": {
    "name": "America/Araguaina",
    "windowsName": "Brasília Time",
    "abbreviation": "BRT",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Argentina/Buenos_Aires": {
    "name": "America/Argentina/Buenos_Aires",
    "windowsName": "Argentina Time",
    "abbreviation": "ART",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Argentina/Catamarca": {
    "name": "America/Argentina/Catamarca",
    "windowsName": "Argentina Time",
    "abbreviation": "ART",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Argentina/Cordoba": {
    "name": "America/Argentina/Cordoba",
    "windowsName": "Argentina Time",
    "abbreviation": "ART",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Argentina/Jujuy": {
    "name": "America/Argentina/Jujuy",
    "windowsName": "Argentina Time",
    "abbreviation": "ART",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Argentina/La_Rioja": {
    "name": "America/Argentina/La_Rioja",
    "windowsName": "Argentina Time",
    "abbreviation": "ART",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Argentina/Mendoza": {
    "name": "America/Argentina/Mendoza",
    "windowsName": "Argentina Time",
    "abbreviation": "ART",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Argentina/Rio_Gallegos": {
    "name": "America/Argentina/Rio_Gallegos",
    "windowsName": "Argentina Time",
    "abbreviation": "ART",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Argentina/Salta": {
    "name": "America/Argentina/Salta",
    "windowsName": "Argentina Time",
    "abbreviation": "ART",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Argentina/San_Juan": {
    "name": "America/Argentina/San_Juan",
    "windowsName": "Argentina Time",
    "abbreviation": "ART",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Argentina/San_Luis": {
    "name": "America/Argentina/San_Luis",
    "windowsName": "Argentina Time",
    "abbreviation": "ART",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Argentina/Tucuman": {
    "name": "America/Argentina/Tucuman",
    "windowsName": "Argentina Time",
    "abbreviation": "ART",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Argentina/Ushuaia": {
    "name": "America/Argentina/Ushuaia",
    "windowsName": "Argentina Time",
    "abbreviation": "ART",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Asuncion": {
    "name": "America/Asuncion",
    "windowsName": "Paraguay Summer Time",
    "abbreviation": "PYST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Bahia": {
    "name": "America/Bahia",
    "windowsName": "Brasília Time",
    "abbreviation": "BRT",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Bahia_Banderas": {
    "name": "America/Bahia_Banderas",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Barbados": {
    "name": "America/Barbados",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Belem": {
    "name": "America/Belem",
    "windowsName": "Brasília Time",
    "abbreviation": "BRT",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Belize": {
    "name": "America/Belize",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Boa_Vista": {
    "name": "America/Boa_Vista",
    "windowsName": "Amazon Time (Brazil)[3",
    "abbreviation": "AMT",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Bogota": {
    "name": "America/Bogota",
    "windowsName": "Colombia Time",
    "abbreviation": "COT",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Boise": {
    "name": "America/Boise",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Cambridge_Bay": {
    "name": "America/Cambridge_Bay",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Campo_Grande": {
    "name": "America/Campo_Grande",
    "windowsName": "Amazon Time (Brazil)[3",
    "abbreviation": "AMT",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Cancun": {
    "name": "America/Cancun",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Caracas": {
    "name": "America/Caracas",
    "windowsName": "Venezuelan Standard Time",
    "abbreviation": "VET",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Cayenne": {
    "name": "America/Cayenne",
    "windowsName": "French Guiana Time",
    "abbreviation": "GFT",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Chicago": {
    "name": "America/Chicago",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Chihuahua": {
    "name": "America/Chihuahua",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Ciudad_Juarez": {
    "name": "America/Ciudad_Juarez",
    "windowsName": "America/Ciudad_Juarez",
    "abbreviation": "",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Costa_Rica": {
    "name": "America/Costa_Rica",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Cuiaba": {
    "name": "America/Cuiaba",
    "windowsName": "Brasilia Time",
    "abbreviation": "BRT",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Danmarkshavn": {
    "name": "America/Danmarkshavn",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "+00:00"
  },
  "America/Dawson": {
    "name": "America/Dawson",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "America/Dawson_Creek": {
    "name": "America/Dawson_Creek",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "America/Denver": {
    "name": "America/Denver",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Detroit": {
    "name": "America/Detroit",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Edmonton": {
    "name": "America/Edmonton",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Eirunepe": {
    "name": "America/Eirunepe",
    "windowsName": "Acre Time",
    "abbreviation": "ACT",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/El_Salvador": {
    "name": "America/El_Salvador",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Fort_Nelson": {
    "name": "America/Fort_Nelson",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "America/Fortaleza": {
    "name": "America/Fortaleza",
    "windowsName": "Brasília Time",
    "abbreviation": "BRT",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Glace_Bay": {
    "name": "America/Glace_Bay",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Goose_Bay": {
    "name": "America/Goose_Bay",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Grand_Turk": {
    "name": "America/Grand_Turk",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Guatemala": {
    "name": "America/Guatemala",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Guayaquil": {
    "name": "America/Guayaquil",
    "windowsName": "Ecuador Time",
    "abbreviation": "ECT",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Guyana": {
    "name": "America/Guyana",
    "windowsName": "Guyana Time",
    "abbreviation": "GYT",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Halifax": {
    "name": "America/Halifax",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Havana": {
    "name": "America/Havana",
    "windowsName": "Cuba Standard Time",
    "abbreviation": "CST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Hermosillo": {
    "name": "America/Hermosillo",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "America/Indiana/Indianapolis": {
    "name": "America/Indiana/Indianapolis",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Indiana/Knox": {
    "name": "America/Indiana/Knox",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Indiana/Marengo": {
    "name": "America/Indiana/Marengo",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Indiana/Petersburg": {
    "name": "America/Indiana/Petersburg",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Indiana/Tell_City": {
    "name": "America/Indiana/Tell_City",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Indiana/Vevay": {
    "name": "America/Indiana/Vevay",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Indiana/Vincennes": {
    "name": "America/Indiana/Vincennes",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Indiana/Winamac": {
    "name": "America/Indiana/Winamac",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Inuvik": {
    "name": "America/Inuvik",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Iqaluit": {
    "name": "America/Iqaluit",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Jamaica": {
    "name": "America/Jamaica",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Juneau": {
    "name": "America/Juneau",
    "windowsName": "Alaska Standard Time",
    "abbreviation": "AKST",
    "utcOffsetSeconds": -32400,
    "utcOffsetText": "-09:00",
    "dstOffsetSeconds": -28800,
    "dstOffsetText": "-08:00"
  },
  "America/Kentucky/Louisville": {
    "name": "America/Kentucky/Louisville",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Kentucky/Monticello": {
    "name": "America/Kentucky/Monticello",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/La_Paz": {
    "name": "America/La_Paz",
    "windowsName": "Bolivia Time",
    "abbreviation": "BOT",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Lima": {
    "name": "America/Lima",
    "windowsName": "Peru Time",
    "abbreviation": "PET",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Los_Angeles": {
    "name": "America/Los_Angeles",
    "windowsName": "Pacific Standard Time (North America)",
    "abbreviation": "PST",
    "utcOffsetSeconds": -28800,
    "utcOffsetText": "-08:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "America/Maceio": {
    "name": "America/Maceio",
    "windowsName": "Brasília Time",
    "abbreviation": "BRT",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Managua": {
    "name": "America/Managua",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Manaus": {
    "name": "America/Manaus",
    "windowsName": "Amazon Time (Brazil)",
    "abbreviation": "AMT",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Martinique": {
    "name": "America/Martinique",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Matamoros": {
    "name": "America/Matamoros",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Mazatlan": {
    "name": "America/Mazatlan",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "America/Menominee": {
    "name": "America/Menominee",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Merida": {
    "name": "America/Merida",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Metlakatla": {
    "name": "America/Metlakatla",
    "windowsName": "Alaska Standard Time",
    "abbreviation": "AKST",
    "utcOffsetSeconds": -32400,
    "utcOffsetText": "-09:00",
    "dstOffsetSeconds": -28800,
    "dstOffsetText": "-08:00"
  },
  "America/Mexico_City": {
    "name": "America/Mexico_City",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Miquelon": {
    "name": "America/Miquelon",
    "windowsName": "Pierre & Miquelon Daylight Time",
    "abbreviation": "PMDT",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -7200,
    "dstOffsetText": "-02:00"
  },
  "America/Moncton": {
    "name": "America/Moncton",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Monterrey": {
    "name": "America/Monterrey",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Montevideo": {
    "name": "America/Montevideo",
    "windowsName": "Uruguay Standard Time",
    "abbreviation": "UYT",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/New_York": {
    "name": "America/New_York",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Nome": {
    "name": "America/Nome",
    "windowsName": "Alaska Standard Time",
    "abbreviation": "AKST",
    "utcOffsetSeconds": -32400,
    "utcOffsetText": "-09:00",
    "dstOffsetSeconds": -28800,
    "dstOffsetText": "-08:00"
  },
  "America/Noronha": {
    "name": "America/Noronha",
    "windowsName": "Fernando de Noronha Time",
    "abbreviation": "FNT",
    "utcOffsetSeconds": -7200,
    "utcOffsetText": "-02:00",
    "dstOffsetSeconds": -7200,
    "dstOffsetText": "-02:00"
  },
  "America/North_Dakota/Beulah": {
    "name": "America/North_Dakota/Beulah",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/North_Dakota/Center": {
    "name": "America/North_Dakota/Center",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/North_Dakota/New_Salem": {
    "name": "America/North_Dakota/New_Salem",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Nuuk": {
    "name": "America/Nuuk",
    "windowsName": "West Greenland Time",
    "abbreviation": "WGT",
    "utcOffsetSeconds": -7200,
    "utcOffsetText": "-02:00",
    "dstOffsetSeconds": -3600,
    "dstOffsetText": "-01:00"
  },
  "America/Ojinaga": {
    "name": "America/Ojinaga",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Panama": {
    "name": "America/Panama",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Paramaribo": {
    "name": "America/Paramaribo",
    "windowsName": "Suriname Time",
    "abbreviation": "SRT",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Phoenix": {
    "name": "America/Phoenix",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "America/Port-au-Prince": {
    "name": "America/Port-au-Prince",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Porto_Velho": {
    "name": "America/Porto_Velho",
    "windowsName": "Amazon Time (Brazil)[3",
    "abbreviation": "AMT",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Puerto_Rico": {
    "name": "America/Puerto_Rico",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Punta_Arenas": {
    "name": "America/Punta_Arenas",
    "windowsName": "Chile Summer Time",
    "abbreviation": "CLST",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Rankin_Inlet": {
    "name": "America/Rankin_Inlet",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Recife": {
    "name": "America/Recife",
    "windowsName": "Brasília Time",
    "abbreviation": "BRT",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Regina": {
    "name": "America/Regina",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Resolute": {
    "name": "America/Resolute",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Rio_Branco": {
    "name": "America/Rio_Branco",
    "windowsName": "Acre Time",
    "abbreviation": "ACT",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Santarem": {
    "name": "America/Santarem",
    "windowsName": "Brasília Time",
    "abbreviation": "BRT",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Santiago": {
    "name": "America/Santiago",
    "windowsName": "Chile Summer Time",
    "abbreviation": "CLST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Santo_Domingo": {
    "name": "America/Santo_Domingo",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Sao_Paulo": {
    "name": "America/Sao_Paulo",
    "windowsName": "Brasília Time",
    "abbreviation": "BRT",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Scoresbysund": {
    "name": "America/Scoresbysund",
    "windowsName": "Eastern Greenland Time",
    "abbreviation": "EGT",
    "utcOffsetSeconds": -3600,
    "utcOffsetText": "-01:00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "+00:00"
  },
  "America/Sitka": {
    "name": "America/Sitka",
    "windowsName": "Alaska Standard Time",
    "abbreviation": "AKST",
    "utcOffsetSeconds": -32400,
    "utcOffsetText": "-09:00",
    "dstOffsetSeconds": -28800,
    "dstOffsetText": "-08:00"
  },
  "America/St_Johns": {
    "name": "America/St_Johns",
    "windowsName": "Newfoundland Standard Time",
    "abbreviation": "NST",
    "utcOffsetSeconds": -12600,
    "utcOffsetText": "-03:30",
    "dstOffsetSeconds": -9000,
    "dstOffsetText": "-02:30"
  },
  "America/Swift_Current": {
    "name": "America/Swift_Current",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Tegucigalpa": {
    "name": "America/Tegucigalpa",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "America/Thule": {
    "name": "America/Thule",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "America/Tijuana": {
    "name": "America/Tijuana",
    "windowsName": "Pacific Standard Time (North America)",
    "abbreviation": "PST",
    "utcOffsetSeconds": -28800,
    "utcOffsetText": "-08:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "America/Toronto": {
    "name": "America/Toronto",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "America/Vancouver": {
    "name": "America/Vancouver",
    "windowsName": "Pacific Standard Time (North America)",
    "abbreviation": "PST",
    "utcOffsetSeconds": -28800,
    "utcOffsetText": "-08:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "America/Whitehorse": {
    "name": "America/Whitehorse",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "America/Winnipeg": {
    "name": "America/Winnipeg",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Yakutat": {
    "name": "America/Yakutat",
    "windowsName": "Alaska Standard Time",
    "abbreviation": "AKST",
    "utcOffsetSeconds": -32400,
    "utcOffsetText": "-09:00",
    "dstOffsetSeconds": -28800,
    "dstOffsetText": "-08:00"
  },
  "Antarctica/Casey": {
    "name": "Antarctica/Casey",
    "windowsName": "Australian Western Standard Time",
    "abbreviation": "AWST",
    "utcOffsetSeconds": 39600,
    "utcOffsetText": "+11:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "Antarctica/Davis": {
    "name": "Antarctica/Davis",
    "windowsName": "Davis Time",
    "abbreviation": "DAVT",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "+07:00"
  },
  "Antarctica/Macquarie": {
    "name": "Antarctica/Macquarie",
    "windowsName": "Macquarie Island Station Time",
    "abbreviation": "MIST",
    "utcOffsetSeconds": 36000,
    "utcOffsetText": "+10:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "Antarctica/Mawson": {
    "name": "Antarctica/Mawson",
    "windowsName": "Mawson Station Time",
    "abbreviation": "MAWT",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "+05:00"
  },
  "Antarctica/Palmer": {
    "name": "Antarctica/Palmer",
    "windowsName": "Chile Summer Time",
    "abbreviation": "CLST",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "Antarctica/Rothera": {
    "name": "Antarctica/Rothera",
    "windowsName": "Rothera Research Station Time",
    "abbreviation": "ROTT",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "Antarctica/Troll": {
    "name": "Antarctica/Troll",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Asia/Almaty": {
    "name": "Asia/Almaty",
    "windowsName": "Alma-Ata Time[1",
    "abbreviation": "ALMT",
    "utcOffsetSeconds": 21600,
    "utcOffsetText": "+06:00",
    "dstOffsetSeconds": 21600,
    "dstOffsetText": "+06:00"
  },
  "Asia/Amman": {
    "name": "Asia/Amman",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Asia/Anadyr": {
    "name": "Asia/Anadyr",
    "windowsName": "Anadyr Time[4",
    "abbreviation": "ANAT",
    "utcOffsetSeconds": 43200,
    "utcOffsetText": "+12:00",
    "dstOffsetSeconds": 43200,
    "dstOffsetText": "+12:00"
  },
  "Asia/Aqtau": {
    "name": "Asia/Aqtau",
    "windowsName": "Aqtobe Time",
    "abbreviation": "AQTT",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "+05:00"
  },
  "Asia/Aqtobe": {
    "name": "Asia/Aqtobe",
    "windowsName": "Aqtobe Time",
    "abbreviation": "AQTT",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "+05:00"
  },
  "Asia/Ashgabat": {
    "name": "Asia/Ashgabat",
    "windowsName": "Turkmenistan Time",
    "abbreviation": "TMT",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "+05:00"
  },
  "Asia/Atyrau": {
    "name": "Asia/Atyrau",
    "windowsName": "Moscow Daylight Time+1",
    "abbreviation": "MSD+1",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "+05:00"
  },
  "Asia/Baghdad": {
    "name": "Asia/Baghdad",
    "windowsName": "Arabia Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Asia/Baku": {
    "name": "Asia/Baku",
    "windowsName": "Azerbaijan Time",
    "abbreviation": "AZT",
    "utcOffsetSeconds": 14400,
    "utcOffsetText": "+04:00",
    "dstOffsetSeconds": 14400,
    "dstOffsetText": "+04:00"
  },
  "Asia/Bangkok": {
    "name": "Asia/Bangkok",
    "windowsName": "Indochina Time",
    "abbreviation": "ICT",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "+07:00"
  },
  "Asia/Barnaul": {
    "name": "Asia/Barnaul",
    "windowsName": "Krasnoyarsk Time",
    "abbreviation": "KRAT",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "+07:00"
  },
  "Asia/Beirut": {
    "name": "Asia/Beirut",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Asia/Bishkek": {
    "name": "Asia/Bishkek",
    "windowsName": "Kyrgyzstan Time",
    "abbreviation": "KGT",
    "utcOffsetSeconds": 21600,
    "utcOffsetText": "+06:00",
    "dstOffsetSeconds": 21600,
    "dstOffsetText": "+06:00"
  },
  "Asia/Chita": {
    "name": "Asia/Chita",
    "windowsName": "Yakutsk Time",
    "abbreviation": "YAKT",
    "utcOffsetSeconds": 32400,
    "utcOffsetText": "+09:00",
    "dstOffsetSeconds": 32400,
    "dstOffsetText": "+09:00"
  },
  "Asia/Choibalsan": {
    "name": "Asia/Choibalsan",
    "windowsName": "Choibalsan Standard Time",
    "abbreviation": "CHOT",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "+08:00"
  },
  "Asia/Colombo": {
    "name": "Asia/Colombo",
    "windowsName": "Indian Standard Time",
    "abbreviation": "IST",
    "utcOffsetSeconds": 19800,
    "utcOffsetText": "+05:30",
    "dstOffsetSeconds": 19800,
    "dstOffsetText": "+05:30"
  },
  "Asia/Damascus": {
    "name": "Asia/Damascus",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Asia/Dhaka": {
    "name": "Asia/Dhaka",
    "windowsName": "Bangladesh Standard Time",
    "abbreviation": "BDT",
    "utcOffsetSeconds": 21600,
    "utcOffsetText": "+06:00",
    "dstOffsetSeconds": 21600,
    "dstOffsetText": "+06:00"
  },
  "Asia/Dili": {
    "name": "Asia/Dili",
    "windowsName": "Timor Leste Time",
    "abbreviation": "TLT",
    "utcOffsetSeconds": 32400,
    "utcOffsetText": "+09:00",
    "dstOffsetSeconds": 32400,
    "dstOffsetText": "+09:00"
  },
  "Asia/Dubai": {
    "name": "Asia/Dubai",
    "windowsName": "Gulf Standard Time",
    "abbreviation": "GST",
    "utcOffsetSeconds": 14400,
    "utcOffsetText": "+04:00",
    "dstOffsetSeconds": 14400,
    "dstOffsetText": "+04:00"
  },
  "Asia/Dushanbe": {
    "name": "Asia/Dushanbe",
    "windowsName": "Tajikistan Time",
    "abbreviation": "TJT",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "+05:00"
  },
  "Asia/Famagusta": {
    "name": "Asia/Famagusta",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Asia/Gaza": {
    "name": "Asia/Gaza",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Asia/Hebron": {
    "name": "Asia/Hebron",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Asia/Ho_Chi_Minh": {
    "name": "Asia/Ho_Chi_Minh",
    "windowsName": "Indochina Time",
    "abbreviation": "ICT",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "+07:00"
  },
  "Asia/Hong_Kong": {
    "name": "Asia/Hong_Kong",
    "windowsName": "Hong Kong Time",
    "abbreviation": "HKT",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "+08:00"
  },
  "Asia/Hovd": {
    "name": "Asia/Hovd",
    "windowsName": "Hovd Time",
    "abbreviation": "HOVT",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "+07:00"
  },
  "Asia/Irkutsk": {
    "name": "Asia/Irkutsk",
    "windowsName": "Irkutsk Time",
    "abbreviation": "IRKT",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "+08:00"
  },
  "Asia/Jakarta": {
    "name": "Asia/Jakarta",
    "windowsName": "Western Indonesian Time",
    "abbreviation": "WIB",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "+07:00"
  },
  "Asia/Jayapura": {
    "name": "Asia/Jayapura",
    "windowsName": "Eastern Indonesian Time",
    "abbreviation": "WIT",
    "utcOffsetSeconds": 32400,
    "utcOffsetText": "+09:00",
    "dstOffsetSeconds": 32400,
    "dstOffsetText": "+09:00"
  },
  "Asia/Jerusalem": {
    "name": "Asia/Jerusalem",
    "windowsName": "Israel Standard Time",
    "abbreviation": "IST",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Asia/Kabul": {
    "name": "Asia/Kabul",
    "windowsName": "Afghanistan Time",
    "abbreviation": "AFT",
    "utcOffsetSeconds": 16200,
    "utcOffsetText": "+04:30",
    "dstOffsetSeconds": 16200,
    "dstOffsetText": "+04:30"
  },
  "Asia/Kamchatka": {
    "name": "Asia/Kamchatka",
    "windowsName": "Kamchatka Time",
    "abbreviation": "PETT",
    "utcOffsetSeconds": 43200,
    "utcOffsetText": "+12:00",
    "dstOffsetSeconds": 43200,
    "dstOffsetText": "+12:00"
  },
  "Asia/Karachi": {
    "name": "Asia/Karachi",
    "windowsName": "Pakistan Standard Time",
    "abbreviation": "PKT",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "+05:00"
  },
  "Asia/Kathmandu": {
    "name": "Asia/Kathmandu",
    "windowsName": "Nepal Time",
    "abbreviation": "NPT",
    "utcOffsetSeconds": 20700,
    "utcOffsetText": "+05:45",
    "dstOffsetSeconds": 20700,
    "dstOffsetText": "+05:45"
  },
  "Asia/Khandyga": {
    "name": "Asia/Khandyga",
    "windowsName": "Yakutsk Time",
    "abbreviation": "YAKT",
    "utcOffsetSeconds": 32400,
    "utcOffsetText": "+09:00",
    "dstOffsetSeconds": 32400,
    "dstOffsetText": "+09:00"
  },
  "Asia/Kolkata": {
    "name": "Asia/Kolkata",
    "windowsName": "Indian Standard Time",
    "abbreviation": "IST",
    "utcOffsetSeconds": 19800,
    "utcOffsetText": "+05:30",
    "dstOffsetSeconds": 19800,
    "dstOffsetText": "+05:30"
  },
  "Asia/Krasnoyarsk": {
    "name": "Asia/Krasnoyarsk",
    "windowsName": "Krasnoyarsk Time",
    "abbreviation": "KRAT",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "+07:00"
  },
  "Asia/Kuching": {
    "name": "Asia/Kuching",
    "windowsName": "Malaysia Time",
    "abbreviation": "MYT",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "+08:00"
  },
  "Asia/Macau": {
    "name": "Asia/Macau",
    "windowsName": "China Standard Time",
    "abbreviation": "CST",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "+08:00"
  },
  "Asia/Magadan": {
    "name": "Asia/Magadan",
    "windowsName": "Magadan Time",
    "abbreviation": "MAGT",
    "utcOffsetSeconds": 39600,
    "utcOffsetText": "+11:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "Asia/Makassar": {
    "name": "Asia/Makassar",
    "windowsName": "Central Indonesia Time",
    "abbreviation": "WITA",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "+08:00"
  },
  "Asia/Manila": {
    "name": "Asia/Manila",
    "windowsName": "Philippine Time",
    "abbreviation": "PHT",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "+08:00"
  },
  "Asia/Nicosia": {
    "name": "Asia/Nicosia",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Asia/Novokuznetsk": {
    "name": "Asia/Novokuznetsk",
    "windowsName": "Krasnoyarsk Time",
    "abbreviation": "KRAT",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "+07:00"
  },
  "Asia/Novosibirsk": {
    "name": "Asia/Novosibirsk",
    "windowsName": "Novosibirsk Time",
    "abbreviation": "NOVT",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "+07:00"
  },
  "Asia/Omsk": {
    "name": "Asia/Omsk",
    "windowsName": "Omsk Time",
    "abbreviation": "OMST",
    "utcOffsetSeconds": 21600,
    "utcOffsetText": "+06:00",
    "dstOffsetSeconds": 21600,
    "dstOffsetText": "+06:00"
  },
  "Asia/Oral": {
    "name": "Asia/Oral",
    "windowsName": "Oral Time",
    "abbreviation": "ORAT",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "+05:00"
  },
  "Asia/Pontianak": {
    "name": "Asia/Pontianak",
    "windowsName": "Western Indonesian Time",
    "abbreviation": "WIB",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "+07:00"
  },
  "Asia/Pyongyang": {
    "name": "Asia/Pyongyang",
    "windowsName": "Korea Standard Time",
    "abbreviation": "KST",
    "utcOffsetSeconds": 32400,
    "utcOffsetText": "+09:00",
    "dstOffsetSeconds": 32400,
    "dstOffsetText": "+09:00"
  },
  "Asia/Qatar": {
    "name": "Asia/Qatar",
    "windowsName": "Arabia Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Asia/Qostanay": {
    "name": "Asia/Qostanay",
    "windowsName": "Qyzylorda Summer Time",
    "abbreviation": "QYZST",
    "utcOffsetSeconds": 21600,
    "utcOffsetText": "+06:00",
    "dstOffsetSeconds": 21600,
    "dstOffsetText": "+06:00"
  },
  "Asia/Qyzylorda": {
    "name": "Asia/Qyzylorda",
    "windowsName": "Qyzylorda Summer Time",
    "abbreviation": "QYZT",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "+05:00"
  },
  "Asia/Riyadh": {
    "name": "Asia/Riyadh",
    "windowsName": "Arabia Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Asia/Sakhalin": {
    "name": "Asia/Sakhalin",
    "windowsName": "Sakhalin Island Time",
    "abbreviation": "SAKT",
    "utcOffsetSeconds": 39600,
    "utcOffsetText": "+11:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "Asia/Samarkand": {
    "name": "Asia/Samarkand",
    "windowsName": "Uzbekistan Time",
    "abbreviation": "UZT",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "+05:00"
  },
  "Asia/Seoul": {
    "name": "Asia/Seoul",
    "windowsName": "Korea Standard Time",
    "abbreviation": "KST",
    "utcOffsetSeconds": 32400,
    "utcOffsetText": "+09:00",
    "dstOffsetSeconds": 32400,
    "dstOffsetText": "+09:00"
  },
  "Asia/Shanghai": {
    "name": "Asia/Shanghai",
    "windowsName": "China Standard Time",
    "abbreviation": "CST",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "+08:00"
  },
  "Asia/Singapore": {
    "name": "Asia/Singapore",
    "windowsName": "Singapore Time",
    "abbreviation": "SGT",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "+08:00"
  },
  "Asia/Srednekolymsk": {
    "name": "Asia/Srednekolymsk",
    "windowsName": "Srednekolymsk Time",
    "abbreviation": "SRET",
    "utcOffsetSeconds": 39600,
    "utcOffsetText": "+11:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "Asia/Taipei": {
    "name": "Asia/Taipei",
    "windowsName": "China Standard Time",
    "abbreviation": "CST",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "+08:00"
  },
  "Asia/Tashkent": {
    "name": "Asia/Tashkent",
    "windowsName": "Uzbekistan Time",
    "abbreviation": "UZT",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "+05:00"
  },
  "Asia/Tbilisi": {
    "name": "Asia/Tbilisi",
    "windowsName": "Georgia Standard Time",
    "abbreviation": "GET",
    "utcOffsetSeconds": 14400,
    "utcOffsetText": "+04:00",
    "dstOffsetSeconds": 14400,
    "dstOffsetText": "+04:00"
  },
  "Asia/Tehran": {
    "name": "Asia/Tehran",
    "windowsName": "Iran Daylight Time",
    "abbreviation": "IRDT",
    "utcOffsetSeconds": 12600,
    "utcOffsetText": "+03:30",
    "dstOffsetSeconds": 12600,
    "dstOffsetText": "+03:30"
  },
  "Asia/Thimphu": {
    "name": "Asia/Thimphu",
    "windowsName": "Bhutan Time",
    "abbreviation": "BTT",
    "utcOffsetSeconds": 21600,
    "utcOffsetText": "+06:00",
    "dstOffsetSeconds": 21600,
    "dstOffsetText": "+06:00"
  },
  "Asia/Tokyo": {
    "name": "Asia/Tokyo",
    "windowsName": "Japan Standard Time",
    "abbreviation": "JST",
    "utcOffsetSeconds": 32400,
    "utcOffsetText": "+09:00",
    "dstOffsetSeconds": 32400,
    "dstOffsetText": "+09:00"
  },
  "Asia/Tomsk": {
    "name": "Asia/Tomsk",
    "windowsName": "Moscow Daylight Time+3",
    "abbreviation": "MSD+3",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "+07:00"
  },
  "Asia/Ulaanbaatar": {
    "name": "Asia/Ulaanbaatar",
    "windowsName": "Ulaanbaatar Standard Time",
    "abbreviation": "ULAT",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "+08:00"
  },
  "Asia/Urumqi": {
    "name": "Asia/Urumqi",
    "windowsName": "China Standard Time",
    "abbreviation": "XJT",
    "utcOffsetSeconds": 21600,
    "utcOffsetText": "+06:00",
    "dstOffsetSeconds": 21600,
    "dstOffsetText": "+06:00"
  },
  "Asia/Ust-Nera": {
    "name": "Asia/Ust-Nera",
    "windowsName": "Vladivostok Time",
    "abbreviation": "VLAT",
    "utcOffsetSeconds": 36000,
    "utcOffsetText": "+10:00",
    "dstOffsetSeconds": 36000,
    "dstOffsetText": "+10:00"
  },
  "Asia/Vladivostok": {
    "name": "Asia/Vladivostok",
    "windowsName": "Vladivostok Time",
    "abbreviation": "VLAT",
    "utcOffsetSeconds": 36000,
    "utcOffsetText": "+10:00",
    "dstOffsetSeconds": 36000,
    "dstOffsetText": "+10:00"
  },
  "Asia/Yakutsk": {
    "name": "Asia/Yakutsk",
    "windowsName": "Yakutsk Time",
    "abbreviation": "YAKT",
    "utcOffsetSeconds": 32400,
    "utcOffsetText": "+09:00",
    "dstOffsetSeconds": 32400,
    "dstOffsetText": "+09:00"
  },
  "Asia/Yangon": {
    "name": "Asia/Yangon",
    "windowsName": "Myanmar Standard Time",
    "abbreviation": "MMT",
    "utcOffsetSeconds": 23400,
    "utcOffsetText": "+06:30",
    "dstOffsetSeconds": 23400,
    "dstOffsetText": "+06:30"
  },
  "Asia/Yekaterinburg": {
    "name": "Asia/Yekaterinburg",
    "windowsName": "Yekaterinburg Time",
    "abbreviation": "YEKT",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "+05:00"
  },
  "Asia/Yerevan": {
    "name": "Asia/Yerevan",
    "windowsName": "Armenia Time",
    "abbreviation": "AMT",
    "utcOffsetSeconds": 14400,
    "utcOffsetText": "+04:00",
    "dstOffsetSeconds": 14400,
    "dstOffsetText": "+04:00"
  },
  "Atlantic/Azores": {
    "name": "Atlantic/Azores",
    "windowsName": "Azores Standard Time",
    "abbreviation": "AZOT",
    "utcOffsetSeconds": -3600,
    "utcOffsetText": "-01:00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "+00:00"
  },
  "Atlantic/Bermuda": {
    "name": "Atlantic/Bermuda",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "Atlantic/Canary": {
    "name": "Atlantic/Canary",
    "windowsName": "Western European Time",
    "abbreviation": "WET",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "+01:00"
  },
  "Atlantic/Cape_Verde": {
    "name": "Atlantic/Cape_Verde",
    "windowsName": "Cape Verde Time",
    "abbreviation": "CVT",
    "utcOffsetSeconds": -3600,
    "utcOffsetText": "-01:00",
    "dstOffsetSeconds": -3600,
    "dstOffsetText": "-01:00"
  },
  "Atlantic/Faroe": {
    "name": "Atlantic/Faroe",
    "windowsName": "Western European Time",
    "abbreviation": "WET",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "+01:00"
  },
  "Atlantic/Madeira": {
    "name": "Atlantic/Madeira",
    "windowsName": "Western European Time",
    "abbreviation": "WET",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "+01:00"
  },
  "Atlantic/South_Georgia": {
    "name": "Atlantic/South_Georgia",
    "windowsName": "South Georgia and the South Sandwich Islands Time",
    "abbreviation": "GST",
    "utcOffsetSeconds": -7200,
    "utcOffsetText": "-02:00",
    "dstOffsetSeconds": -7200,
    "dstOffsetText": "-02:00"
  },
  "Atlantic/Stanley": {
    "name": "Atlantic/Stanley",
    "windowsName": "Falkland Islands Summer Time",
    "abbreviation": "FKST",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "Australia/Adelaide": {
    "name": "Australia/Adelaide",
    "windowsName": "Australian Central Daylight Saving Time",
    "abbreviation": "ACDT",
    "utcOffsetSeconds": 34200,
    "utcOffsetText": "+09:30",
    "dstOffsetSeconds": 37800,
    "dstOffsetText": "+10:30"
  },
  "Australia/Brisbane": {
    "name": "Australia/Brisbane",
    "windowsName": "Australian Eastern Standard Time",
    "abbreviation": "AEST",
    "utcOffsetSeconds": 36000,
    "utcOffsetText": "+10:00",
    "dstOffsetSeconds": 36000,
    "dstOffsetText": "+10:00"
  },
  "Australia/Broken_Hill": {
    "name": "Australia/Broken_Hill",
    "windowsName": "Australian Central Daylight Saving Time",
    "abbreviation": "ACDT",
    "utcOffsetSeconds": 34200,
    "utcOffsetText": "+09:30",
    "dstOffsetSeconds": 37800,
    "dstOffsetText": "+10:30"
  },
  "Australia/Darwin": {
    "name": "Australia/Darwin",
    "windowsName": "Australian Central Standard Time",
    "abbreviation": "ACST",
    "utcOffsetSeconds": 34200,
    "utcOffsetText": "+09:30",
    "dstOffsetSeconds": 34200,
    "dstOffsetText": "+09:30"
  },
  "Australia/Eucla": {
    "name": "Australia/Eucla",
    "windowsName": "Australian Central Western Standard Time (Unofficial)",
    "abbreviation": "ACWST",
    "utcOffsetSeconds": 31500,
    "utcOffsetText": "+08:45",
    "dstOffsetSeconds": 31500,
    "dstOffsetText": "+08:45"
  },
  "Australia/Hobart": {
    "name": "Australia/Hobart",
    "windowsName": "Australian Eastern Daylight Saving Time",
    "abbreviation": "AEDT",
    "utcOffsetSeconds": 36000,
    "utcOffsetText": "+10:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "Australia/Lindeman": {
    "name": "Australia/Lindeman",
    "windowsName": "Australian Eastern Standard Time",
    "abbreviation": "AEST",
    "utcOffsetSeconds": 36000,
    "utcOffsetText": "+10:00",
    "dstOffsetSeconds": 36000,
    "dstOffsetText": "+10:00"
  },
  "Australia/Lord_Howe": {
    "name": "Australia/Lord_Howe",
    "windowsName": "Lord Howe Summer Time",
    "abbreviation": "LHST",
    "utcOffsetSeconds": 37800,
    "utcOffsetText": "+10:30",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "Australia/Melbourne": {
    "name": "Australia/Melbourne",
    "windowsName": "Australian Eastern Daylight Saving Time",
    "abbreviation": "AEDT",
    "utcOffsetSeconds": 36000,
    "utcOffsetText": "+10:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "Australia/Perth": {
    "name": "Australia/Perth",
    "windowsName": "Australian Western Standard Time",
    "abbreviation": "AWST",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "+08:00"
  },
  "Australia/Sydney": {
    "name": "Australia/Sydney",
    "windowsName": "Australian Eastern Daylight Saving Time",
    "abbreviation": "AEDT",
    "utcOffsetSeconds": 36000,
    "utcOffsetText": "+10:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "CET": {
    "name": "CET",
    "windowsName": "CET",
    "abbreviation": "",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "CST6CDT": {
    "name": "CST6CDT",
    "windowsName": "CST6CDT",
    "abbreviation": "",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "EET": {
    "name": "EET",
    "windowsName": "EET",
    "abbreviation": "",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "EST": {
    "name": "EST",
    "windowsName": "EST",
    "abbreviation": "",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "EST5EDT": {
    "name": "EST5EDT",
    "windowsName": "EST5EDT",
    "abbreviation": "",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "Etc/GMT": {
    "name": "Etc/GMT",
    "windowsName": "Etc/GMT",
    "abbreviation": "",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "+00:00"
  },
  "Etc/GMT+1": {
    "name": "Etc/GMT+1",
    "windowsName": "Etc/GMT+1",
    "abbreviation": "",
    "utcOffsetSeconds": -3600,
    "utcOffsetText": "-01:00",
    "dstOffsetSeconds": -3600,
    "dstOffsetText": "-01:00"
  },
  "Etc/GMT+10": {
    "name": "Etc/GMT+10",
    "windowsName": "Etc/GMT+10",
    "abbreviation": "",
    "utcOffsetSeconds": -36000,
    "utcOffsetText": "-10:00",
    "dstOffsetSeconds": -36000,
    "dstOffsetText": "-10:00"
  },
  "Etc/GMT+11": {
    "name": "Etc/GMT+11",
    "windowsName": "Etc/GMT+11",
    "abbreviation": "",
    "utcOffsetSeconds": -39600,
    "utcOffsetText": "-11:00",
    "dstOffsetSeconds": -39600,
    "dstOffsetText": "-11:00"
  },
  "Etc/GMT+12": {
    "name": "Etc/GMT+12",
    "windowsName": "Etc/GMT+12",
    "abbreviation": "",
    "utcOffsetSeconds": -43200,
    "utcOffsetText": "-12:00",
    "dstOffsetSeconds": -43200,
    "dstOffsetText": "-12:00"
  },
  "Etc/GMT+2": {
    "name": "Etc/GMT+2",
    "windowsName": "Etc/GMT+2",
    "abbreviation": "",
    "utcOffsetSeconds": -7200,
    "utcOffsetText": "-02:00",
    "dstOffsetSeconds": -7200,
    "dstOffsetText": "-02:00"
  },
  "Etc/GMT+3": {
    "name": "Etc/GMT+3",
    "windowsName": "Etc/GMT+3",
    "abbreviation": "",
    "utcOffsetSeconds": -10800,
    "utcOffsetText": "-03:00",
    "dstOffsetSeconds": -10800,
    "dstOffsetText": "-03:00"
  },
  "Etc/GMT+4": {
    "name": "Etc/GMT+4",
    "windowsName": "Etc/GMT+4",
    "abbreviation": "",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "-04:00"
  },
  "Etc/GMT+5": {
    "name": "Etc/GMT+5",
    "windowsName": "Etc/GMT+5",
    "abbreviation": "",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "Etc/GMT+6": {
    "name": "Etc/GMT+6",
    "windowsName": "Etc/GMT+6",
    "abbreviation": "",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "Etc/GMT+7": {
    "name": "Etc/GMT+7",
    "windowsName": "Etc/GMT+7",
    "abbreviation": "",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "Etc/GMT+8": {
    "name": "Etc/GMT+8",
    "windowsName": "Etc/GMT+8",
    "abbreviation": "",
    "utcOffsetSeconds": -28800,
    "utcOffsetText": "-08:00",
    "dstOffsetSeconds": -28800,
    "dstOffsetText": "-08:00"
  },
  "Etc/GMT+9": {
    "name": "Etc/GMT+9",
    "windowsName": "Etc/GMT+9",
    "abbreviation": "",
    "utcOffsetSeconds": -32400,
    "utcOffsetText": "-09:00",
    "dstOffsetSeconds": -32400,
    "dstOffsetText": "-09:00"
  },
  "Etc/GMT-1": {
    "name": "Etc/GMT-1",
    "windowsName": "Etc/GMT-1",
    "abbreviation": "",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "+01:00"
  },
  "Etc/GMT-10": {
    "name": "Etc/GMT-10",
    "windowsName": "Etc/GMT-10",
    "abbreviation": "",
    "utcOffsetSeconds": 36000,
    "utcOffsetText": "+10:00",
    "dstOffsetSeconds": 36000,
    "dstOffsetText": "+10:00"
  },
  "Etc/GMT-11": {
    "name": "Etc/GMT-11",
    "windowsName": "Etc/GMT-11",
    "abbreviation": "",
    "utcOffsetSeconds": 39600,
    "utcOffsetText": "+11:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "Etc/GMT-12": {
    "name": "Etc/GMT-12",
    "windowsName": "Etc/GMT-12",
    "abbreviation": "",
    "utcOffsetSeconds": 43200,
    "utcOffsetText": "+12:00",
    "dstOffsetSeconds": 43200,
    "dstOffsetText": "+12:00"
  },
  "Etc/GMT-13": {
    "name": "Etc/GMT-13",
    "windowsName": "Etc/GMT-13",
    "abbreviation": "",
    "utcOffsetSeconds": 46800,
    "utcOffsetText": "+13:00",
    "dstOffsetSeconds": 46800,
    "dstOffsetText": "+13:00"
  },
  "Etc/GMT-14": {
    "name": "Etc/GMT-14",
    "windowsName": "Etc/GMT-14",
    "abbreviation": "",
    "utcOffsetSeconds": 50400,
    "utcOffsetText": "+14:00",
    "dstOffsetSeconds": 50400,
    "dstOffsetText": "+14:00"
  },
  "Etc/GMT-2": {
    "name": "Etc/GMT-2",
    "windowsName": "Etc/GMT-2",
    "abbreviation": "",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Etc/GMT-3": {
    "name": "Etc/GMT-3",
    "windowsName": "Etc/GMT-3",
    "abbreviation": "",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Etc/GMT-4": {
    "name": "Etc/GMT-4",
    "windowsName": "Etc/GMT-4",
    "abbreviation": "",
    "utcOffsetSeconds": 14400,
    "utcOffsetText": "+04:00",
    "dstOffsetSeconds": 14400,
    "dstOffsetText": "+04:00"
  },
  "Etc/GMT-5": {
    "name": "Etc/GMT-5",
    "windowsName": "Etc/GMT-5",
    "abbreviation": "",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "+05:00"
  },
  "Etc/GMT-6": {
    "name": "Etc/GMT-6",
    "windowsName": "Etc/GMT-6",
    "abbreviation": "",
    "utcOffsetSeconds": 21600,
    "utcOffsetText": "+06:00",
    "dstOffsetSeconds": 21600,
    "dstOffsetText": "+06:00"
  },
  "Etc/GMT-7": {
    "name": "Etc/GMT-7",
    "windowsName": "Etc/GMT-7",
    "abbreviation": "",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "+07:00"
  },
  "Etc/GMT-8": {
    "name": "Etc/GMT-8",
    "windowsName": "Etc/GMT-8",
    "abbreviation": "",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "+08:00"
  },
  "Etc/GMT-9": {
    "name": "Etc/GMT-9",
    "windowsName": "Etc/GMT-9",
    "abbreviation": "",
    "utcOffsetSeconds": 32400,
    "utcOffsetText": "+09:00",
    "dstOffsetSeconds": 32400,
    "dstOffsetText": "+09:00"
  },
  "Etc/UTC": {
    "name": "Etc/UTC",
    "windowsName": "Etc/UTC",
    "abbreviation": "",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "+00:00"
  },
  "Europe/Andorra": {
    "name": "Europe/Andorra",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Astrakhan": {
    "name": "Europe/Astrakhan",
    "windowsName": "Samara Time",
    "abbreviation": "SAMT",
    "utcOffsetSeconds": 14400,
    "utcOffsetText": "+04:00",
    "dstOffsetSeconds": 14400,
    "dstOffsetText": "+04:00"
  },
  "Europe/Athens": {
    "name": "Europe/Athens",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Belgrade": {
    "name": "Europe/Belgrade",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Berlin": {
    "name": "Europe/Berlin",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Brussels": {
    "name": "Europe/Brussels",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Bucharest": {
    "name": "Europe/Bucharest",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Budapest": {
    "name": "Europe/Budapest",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Chisinau": {
    "name": "Europe/Chisinau",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Dublin": {
    "name": "Europe/Dublin",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "+00:00"
  },
  "Europe/Gibraltar": {
    "name": "Europe/Gibraltar",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Helsinki": {
    "name": "Europe/Helsinki",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Istanbul": {
    "name": "Europe/Istanbul",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Kaliningrad": {
    "name": "Europe/Kaliningrad",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Kirov": {
    "name": "Europe/Kirov",
    "windowsName": "Moscow Time",
    "abbreviation": "MSK",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Kyiv": {
    "name": "Europe/Kyiv",
    "windowsName": "Europe/Kyiv",
    "abbreviation": "",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Lisbon": {
    "name": "Europe/Lisbon",
    "windowsName": "Western European Time",
    "abbreviation": "WET",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "+01:00"
  },
  "Europe/London": {
    "name": "Europe/London",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "+01:00"
  },
  "Europe/Madrid": {
    "name": "Europe/Madrid",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Malta": {
    "name": "Europe/Malta",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Minsk": {
    "name": "Europe/Minsk",
    "windowsName": "Moscow Time",
    "abbreviation": "MSK",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Moscow": {
    "name": "Europe/Moscow",
    "windowsName": "Moscow Time",
    "abbreviation": "MSK",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Paris": {
    "name": "Europe/Paris",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Prague": {
    "name": "Europe/Prague",
    "windowsName": "Europe/Prague",
    "abbreviation": "",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Riga": {
    "name": "Europe/Riga",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Rome": {
    "name": "Europe/Rome",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Samara": {
    "name": "Europe/Samara",
    "windowsName": "Samara Time",
    "abbreviation": "SAMT",
    "utcOffsetSeconds": 14400,
    "utcOffsetText": "+04:00",
    "dstOffsetSeconds": 14400,
    "dstOffsetText": "+04:00"
  },
  "Europe/Saratov": {
    "name": "Europe/Saratov",
    "windowsName": "Moscow Daylight Time+4",
    "abbreviation": "MSD",
    "utcOffsetSeconds": 14400,
    "utcOffsetText": "+04:00",
    "dstOffsetSeconds": 14400,
    "dstOffsetText": "+04:00"
  },
  "Europe/Simferopol": {
    "name": "Europe/Simferopol",
    "windowsName": "Moscow Time",
    "abbreviation": "MSK",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Sofia": {
    "name": "Europe/Sofia",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Tallinn": {
    "name": "Europe/Tallinn",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Tirane": {
    "name": "Europe/Tirane",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Ulyanovsk": {
    "name": "Europe/Ulyanovsk",
    "windowsName": "Samara Time",
    "abbreviation": "SAMT",
    "utcOffsetSeconds": 14400,
    "utcOffsetText": "+04:00",
    "dstOffsetSeconds": 14400,
    "dstOffsetText": "+04:00"
  },
  "Europe/Vienna": {
    "name": "Europe/Vienna",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Vilnius": {
    "name": "Europe/Vilnius",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Volgograd": {
    "name": "Europe/Volgograd",
    "windowsName": "Moscow Standard Time",
    "abbreviation": "MSK",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Warsaw": {
    "name": "Europe/Warsaw",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Zurich": {
    "name": "Europe/Zurich",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Factory": {
    "name": "Factory",
    "windowsName": "Factory",
    "abbreviation": "",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "+00:00"
  },
  "HST": {
    "name": "HST",
    "windowsName": "HST",
    "abbreviation": "",
    "utcOffsetSeconds": -36000,
    "utcOffsetText": "-10:00",
    "dstOffsetSeconds": -36000,
    "dstOffsetText": "-10:00"
  },
  "Indian/Chagos": {
    "name": "Indian/Chagos",
    "windowsName": "Indian Ocean Time",
    "abbreviation": "IOT",
    "utcOffsetSeconds": 21600,
    "utcOffsetText": "+06:00",
    "dstOffsetSeconds": 21600,
    "dstOffsetText": "+06:00"
  },
  "Indian/Maldives": {
    "name": "Indian/Maldives",
    "windowsName": "Maldives Time",
    "abbreviation": "MVT",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "+05:00"
  },
  "Indian/Mauritius": {
    "name": "Indian/Mauritius",
    "windowsName": "Mauritius Time",
    "abbreviation": "MUT",
    "utcOffsetSeconds": 14400,
    "utcOffsetText": "+04:00",
    "dstOffsetSeconds": 14400,
    "dstOffsetText": "+04:00"
  },
  "MET": {
    "name": "MET",
    "windowsName": "MET",
    "abbreviation": "",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "MST": {
    "name": "MST",
    "windowsName": "MST",
    "abbreviation": "",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "MST7MDT": {
    "name": "MST7MDT",
    "windowsName": "MST7MDT",
    "abbreviation": "",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "PST8PDT": {
    "name": "PST8PDT",
    "windowsName": "PST8PDT",
    "abbreviation": "",
    "utcOffsetSeconds": -28800,
    "utcOffsetText": "-08:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "Pacific/Apia": {
    "name": "Pacific/Apia",
    "windowsName": "West Samoa Time",
    "abbreviation": "WST",
    "utcOffsetSeconds": 46800,
    "utcOffsetText": "+13:00",
    "dstOffsetSeconds": 46800,
    "dstOffsetText": "+13:00"
  },
  "Pacific/Auckland": {
    "name": "Pacific/Auckland",
    "windowsName": "New Zealand Daylight Time",
    "abbreviation": "NZDT",
    "utcOffsetSeconds": 43200,
    "utcOffsetText": "+12:00",
    "dstOffsetSeconds": 46800,
    "dstOffsetText": "+13:00"
  },
  "Pacific/Bougainville": {
    "name": "Pacific/Bougainville",
    "windowsName": "Bougainville Standard Time[6",
    "abbreviation": "BST",
    "utcOffsetSeconds": 39600,
    "utcOffsetText": "+11:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "Pacific/Chatham": {
    "name": "Pacific/Chatham",
    "windowsName": "Chatham Standard Time",
    "abbreviation": "CHAST",
    "utcOffsetSeconds": 45900,
    "utcOffsetText": "+12:45",
    "dstOffsetSeconds": 49500,
    "dstOffsetText": "+13:45"
  },
  "Pacific/Easter": {
    "name": "Pacific/Easter",
    "windowsName": "Easter Island Summer Time",
    "abbreviation": "EASST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "Pacific/Efate": {
    "name": "Pacific/Efate",
    "windowsName": "Vanuatu Time",
    "abbreviation": "VUT",
    "utcOffsetSeconds": 39600,
    "utcOffsetText": "+11:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "Pacific/Fakaofo": {
    "name": "Pacific/Fakaofo",
    "windowsName": "Tokelau Time",
    "abbreviation": "TKT",
    "utcOffsetSeconds": 46800,
    "utcOffsetText": "+13:00",
    "dstOffsetSeconds": 46800,
    "dstOffsetText": "+13:00"
  },
  "Pacific/Fiji": {
    "name": "Pacific/Fiji",
    "windowsName": "Fiji Time",
    "abbreviation": "FJT",
    "utcOffsetSeconds": 43200,
    "utcOffsetText": "+12:00",
    "dstOffsetSeconds": 43200,
    "dstOffsetText": "+12:00"
  },
  "Pacific/Galapagos": {
    "name": "Pacific/Galapagos",
    "windowsName": "Galápagos Time",
    "abbreviation": "GALT",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "-06:00"
  },
  "Pacific/Gambier": {
    "name": "Pacific/Gambier",
    "windowsName": "Gambier Islands Time",
    "abbreviation": "GAMT",
    "utcOffsetSeconds": -32400,
    "utcOffsetText": "-09:00",
    "dstOffsetSeconds": -32400,
    "dstOffsetText": "-09:00"
  },
  "Pacific/Guadalcanal": {
    "name": "Pacific/Guadalcanal",
    "windowsName": "Solomon Islands Time",
    "abbreviation": "SBT",
    "utcOffsetSeconds": 39600,
    "utcOffsetText": "+11:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "Pacific/Guam": {
    "name": "Pacific/Guam",
    "windowsName": "Chamorro Standard Time",
    "abbreviation": "CHST",
    "utcOffsetSeconds": 36000,
    "utcOffsetText": "+10:00",
    "dstOffsetSeconds": 36000,
    "dstOffsetText": "+10:00"
  },
  "Pacific/Honolulu": {
    "name": "Pacific/Honolulu",
    "windowsName": "Hawaii–Aleutian Standard Time",
    "abbreviation": "HST",
    "utcOffsetSeconds": -36000,
    "utcOffsetText": "-10:00",
    "dstOffsetSeconds": -36000,
    "dstOffsetText": "-10:00"
  },
  "Pacific/Kanton": {
    "name": "Pacific/Kanton",
    "windowsName": "Pacific/Kanton",
    "abbreviation": "",
    "utcOffsetSeconds": 46800,
    "utcOffsetText": "+13:00",
    "dstOffsetSeconds": 46800,
    "dstOffsetText": "+13:00"
  },
  "Pacific/Kiritimati": {
    "name": "Pacific/Kiritimati",
    "windowsName": "Line Islands Time",
    "abbreviation": "LINT",
    "utcOffsetSeconds": 50400,
    "utcOffsetText": "+14:00",
    "dstOffsetSeconds": 50400,
    "dstOffsetText": "+14:00"
  },
  "Pacific/Kosrae": {
    "name": "Pacific/Kosrae",
    "windowsName": "Kosrae Time",
    "abbreviation": "KOST",
    "utcOffsetSeconds": 39600,
    "utcOffsetText": "+11:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "Pacific/Kwajalein": {
    "name": "Pacific/Kwajalein",
    "windowsName": "Marshall Islands Time",
    "abbreviation": "MHT",
    "utcOffsetSeconds": 43200,
    "utcOffsetText": "+12:00",
    "dstOffsetSeconds": 43200,
    "dstOffsetText": "+12:00"
  },
  "Pacific/Marquesas": {
    "name": "Pacific/Marquesas",
    "windowsName": "Marquesas Islands Time",
    "abbreviation": "MART",
    "utcOffsetSeconds": -34200,
    "utcOffsetText": "-09:30",
    "dstOffsetSeconds": -34200,
    "dstOffsetText": "-09:30"
  },
  "Pacific/Nauru": {
    "name": "Pacific/Nauru",
    "windowsName": "Nauru Time",
    "abbreviation": "NRT",
    "utcOffsetSeconds": 43200,
    "utcOffsetText": "+12:00",
    "dstOffsetSeconds": 43200,
    "dstOffsetText": "+12:00"
  },
  "Pacific/Niue": {
    "name": "Pacific/Niue",
    "windowsName": "Niue Time",
    "abbreviation": "NUT",
    "utcOffsetSeconds": -39600,
    "utcOffsetText": "-11:00",
    "dstOffsetSeconds": -39600,
    "dstOffsetText": "-11:00"
  },
  "Pacific/Norfolk": {
    "name": "Pacific/Norfolk",
    "windowsName": "Norfolk Time",
    "abbreviation": "NFT",
    "utcOffsetSeconds": 39600,
    "utcOffsetText": "+11:00",
    "dstOffsetSeconds": 43200,
    "dstOffsetText": "+12:00"
  },
  "Pacific/Noumea": {
    "name": "Pacific/Noumea",
    "windowsName": "New Caledonia Time",
    "abbreviation": "NCT",
    "utcOffsetSeconds": 39600,
    "utcOffsetText": "+11:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "+11:00"
  },
  "Pacific/Pago_Pago": {
    "name": "Pacific/Pago_Pago",
    "windowsName": "Samoa Standard Time",
    "abbreviation": "SST",
    "utcOffsetSeconds": -39600,
    "utcOffsetText": "-11:00",
    "dstOffsetSeconds": -39600,
    "dstOffsetText": "-11:00"
  },
  "Pacific/Palau": {
    "name": "Pacific/Palau",
    "windowsName": "Palau Time",
    "abbreviation": "PWT",
    "utcOffsetSeconds": 32400,
    "utcOffsetText": "+09:00",
    "dstOffsetSeconds": 32400,
    "dstOffsetText": "+09:00"
  },
  "Pacific/Pitcairn": {
    "name": "Pacific/Pitcairn",
    "windowsName": "Pacific Standard Time (North America)",
    "abbreviation": "PST",
    "utcOffsetSeconds": -28800,
    "utcOffsetText": "-08:00",
    "dstOffsetSeconds": -28800,
    "dstOffsetText": "-08:00"
  },
  "Pacific/Port_Moresby": {
    "name": "Pacific/Port_Moresby",
    "windowsName": "Papua New Guinea Time",
    "abbreviation": "PGT",
    "utcOffsetSeconds": 36000,
    "utcOffsetText": "+10:00",
    "dstOffsetSeconds": 36000,
    "dstOffsetText": "+10:00"
  },
  "Pacific/Rarotonga": {
    "name": "Pacific/Rarotonga",
    "windowsName": "Cook Island Time",
    "abbreviation": "CKT",
    "utcOffsetSeconds": -36000,
    "utcOffsetText": "-10:00",
    "dstOffsetSeconds": -36000,
    "dstOffsetText": "-10:00"
  },
  "Pacific/Tahiti": {
    "name": "Pacific/Tahiti",
    "windowsName": "Tahiti Time",
    "abbreviation": "TAHT",
    "utcOffsetSeconds": -36000,
    "utcOffsetText": "-10:00",
    "dstOffsetSeconds": -36000,
    "dstOffsetText": "-10:00"
  },
  "Pacific/Tarawa": {
    "name": "Pacific/Tarawa",
    "windowsName": "Gilbert Island Time",
    "abbreviation": "GILT",
    "utcOffsetSeconds": 43200,
    "utcOffsetText": "+12:00",
    "dstOffsetSeconds": 43200,
    "dstOffsetText": "+12:00"
  },
  "Pacific/Tongatapu": {
    "name": "Pacific/Tongatapu",
    "windowsName": "Tonga Time",
    "abbreviation": "TOT",
    "utcOffsetSeconds": 46800,
    "utcOffsetText": "+13:00",
    "dstOffsetSeconds": 46800,
    "dstOffsetText": "+13:00"
  },
  "WET": {
    "name": "WET",
    "windowsName": "WET",
    "abbreviation": "",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "+00:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "+01:00"
  },
  "Europe/Mariehamn": {
    "name": "Europe/Mariehamn",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Africa/Luanda": {
    "name": "Africa/Luanda",
    "windowsName": "West Africa Time",
    "abbreviation": "WAT",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "UTC+01:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "UTC+01:00"
  },
  "America/Anguilla": {
    "name": "America/Anguilla",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "Antarctica/DumontDUrville": {
    "name": "Antarctica/DumontDUrville",
    "windowsName": "Dumont d'Urville Time",
    "abbreviation": "DDUT",
    "utcOffsetSeconds": 36000,
    "utcOffsetText": "UTC+10:00",
    "dstOffsetSeconds": 36000,
    "dstOffsetText": "UTC+10:00"
  },
  "Antarctica/McMurdo": {
    "name": "Antarctica/McMurdo",
    "windowsName": "New Zealand Daylight Time",
    "abbreviation": "NZDT",
    "utcOffsetSeconds": 46800,
    "utcOffsetText": "UTC+13:00",
    "dstOffsetSeconds": 46800,
    "dstOffsetText": "UTC+13:00"
  },
  "Antarctica/Syowa": {
    "name": "Antarctica/Syowa",
    "windowsName": "Showa Station Time",
    "abbreviation": "SYOT",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "UTC+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "UTC+03:00"
  },
  "Antarctica/Vostok": {
    "name": "Antarctica/Vostok",
    "windowsName": "Vostok Station Time",
    "abbreviation": "VOST",
    "utcOffsetSeconds": 21600,
    "utcOffsetText": "UTC+06:00",
    "dstOffsetSeconds": 21600,
    "dstOffsetText": "UTC+06:00"
  },
  "America/Antigua": {
    "name": "America/Antigua",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "America/Aruba": {
    "name": "America/Aruba",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "Australia/Currie": {
    "name": "Australia/Currie",
    "windowsName": "Australian Eastern Daylight Saving Time",
    "abbreviation": "AEDT",
    "utcOffsetSeconds": 39600,
    "utcOffsetText": "UTC+11:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "UTC+11:00"
  },
  "Asia/Bahrain": {
    "name": "Asia/Bahrain",
    "windowsName": "Arabia Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "UTC+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "UTC+03:00"
  },
  "Africa/Porto-Novo": {
    "name": "Africa/Porto-Novo",
    "windowsName": "West Africa Time",
    "abbreviation": "WAT",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "UTC+01:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "UTC+01:00"
  },
  "Europe/Sarajevo": {
    "name": "Europe/Sarajevo",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Africa/Gaborone": {
    "name": "Africa/Gaborone",
    "windowsName": "Central Africa Time",
    "abbreviation": "CAT",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "UTC+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "UTC+02:00"
  },
  "Europe/Oslo": {
    "name": "Europe/Oslo",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Asia/Brunei": {
    "name": "Asia/Brunei",
    "windowsName": "Brunei Darussalam Time",
    "abbreviation": "BNT",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "UTC+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "UTC+08:00"
  },
  "Africa/Ouagadougou": {
    "name": "Africa/Ouagadougou",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "UTC±00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "UTC±00"
  },
  "Africa/Bujumbura": {
    "name": "Africa/Bujumbura",
    "windowsName": "Central Africa Time",
    "abbreviation": "CAT",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "UTC+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "UTC+02:00"
  },
  "Asia/Phnom_Penh": {
    "name": "Asia/Phnom_Penh",
    "windowsName": "Indochina Time",
    "abbreviation": "ICT",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "UTC+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "UTC+07:00"
  },
  "Africa/Douala": {
    "name": "Africa/Douala",
    "windowsName": "West Africa Time",
    "abbreviation": "WAT",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "UTC+01:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "UTC+01:00"
  },
  "America/Atikokan": {
    "name": "America/Atikokan",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Blanc-Sablon": {
    "name": "America/Blanc-Sablon",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "America/Creston": {
    "name": "America/Creston",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "America/Nipigon": {
    "name": "America/Nipigon",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Pangnirtung": {
    "name": "America/Pangnirtung",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Rainy_River": {
    "name": "America/Rainy_River",
    "windowsName": "Central Standard Time (North America)",
    "abbreviation": "CST",
    "utcOffsetSeconds": -21600,
    "utcOffsetText": "UTC-06:00",
    "dstOffsetSeconds": -21600,
    "dstOffsetText": "UTC-06:00"
  },
  "America/Thunder_Bay": {
    "name": "America/Thunder_Bay",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "America/Yellowknife": {
    "name": "America/Yellowknife",
    "windowsName": "Mountain Standard Time (North America)",
    "abbreviation": "MST",
    "utcOffsetSeconds": -25200,
    "utcOffsetText": "-07:00",
    "dstOffsetSeconds": -25200,
    "dstOffsetText": "-07:00"
  },
  "America/Cayman": {
    "name": "America/Cayman",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "Africa/Bangui": {
    "name": "Africa/Bangui",
    "windowsName": "West Africa Time",
    "abbreviation": "WAT",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "UTC+01:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "UTC+01:00"
  },
  "Indian/Christmas": {
    "name": "Indian/Christmas",
    "windowsName": "Christmas Island Time",
    "abbreviation": "CXT",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "UTC+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "UTC+07:00"
  },
  "Indian/Cocos": {
    "name": "Indian/Cocos",
    "windowsName": "Cocos Islands Time",
    "abbreviation": "CCT",
    "utcOffsetSeconds": 23400,
    "utcOffsetText": "UTC+06:30",
    "dstOffsetSeconds": 23400,
    "dstOffsetText": "UTC+06:30"
  },
  "Indian/Comoro": {
    "name": "Indian/Comoro",
    "windowsName": "East Africa Time",
    "abbreviation": "EAT",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "UTC+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "UTC+03:00"
  },
  "Africa/Brazzaville": {
    "name": "Africa/Brazzaville",
    "windowsName": "West Africa Time",
    "abbreviation": "WAT",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "UTC+01:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "UTC+01:00"
  },
  "Europe/Zagreb": {
    "name": "Europe/Zagreb",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "America/Curacao": {
    "name": "America/Curacao",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "Africa/Kinshasa": {
    "name": "Africa/Kinshasa",
    "windowsName": "West Africa Time",
    "abbreviation": "WAT",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "UTC+01:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "UTC+01:00"
  },
  "Africa/Lubumbashi": {
    "name": "Africa/Lubumbashi",
    "windowsName": "Central Africa Time",
    "abbreviation": "CAT",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "UTC+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "UTC+02:00"
  },
  "Europe/Copenhagen": {
    "name": "Europe/Copenhagen",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Africa/Djibouti": {
    "name": "Africa/Djibouti",
    "windowsName": "East Africa Time",
    "abbreviation": "EAT",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "UTC+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "UTC+03:00"
  },
  "America/Dominica": {
    "name": "America/Dominica",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "Africa/Malabo": {
    "name": "Africa/Malabo",
    "windowsName": "West Africa Time",
    "abbreviation": "WAT",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "UTC+01:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "UTC+01:00"
  },
  "Africa/Asmara": {
    "name": "Africa/Asmara",
    "windowsName": "East Africa Time",
    "abbreviation": "EAT",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "UTC+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "UTC+03:00"
  },
  "Africa/Mbabane": {
    "name": "Africa/Mbabane",
    "windowsName": "South African Standard Time",
    "abbreviation": "SAST",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "UTC+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "UTC+02:00"
  },
  "Africa/Addis_Ababa": {
    "name": "Africa/Addis_Ababa",
    "windowsName": "East Africa Time",
    "abbreviation": "EAT",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "UTC+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "UTC+03:00"
  },
  "Indian/Kerguelen": {
    "name": "Indian/Kerguelen",
    "windowsName": "French Southern and Antarctic Time",
    "abbreviation": "TFT",
    "utcOffsetSeconds": 18000,
    "utcOffsetText": "UTC+05:00",
    "dstOffsetSeconds": 18000,
    "dstOffsetText": "UTC+05:00"
  },
  "Africa/Libreville": {
    "name": "Africa/Libreville",
    "windowsName": "West Africa Time",
    "abbreviation": "WAT",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "UTC+01:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "UTC+01:00"
  },
  "Africa/Banjul": {
    "name": "Africa/Banjul",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "UTC±00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "UTC±00"
  },
  "Europe/Busingen": {
    "name": "Europe/Busingen",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Africa/Accra": {
    "name": "Africa/Accra",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "UTC±00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "UTC±00"
  },
  "America/Grenada": {
    "name": "America/Grenada",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "America/Guadeloupe": {
    "name": "America/Guadeloupe",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "Europe/Guernsey": {
    "name": "Europe/Guernsey",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "UTC±00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "UTC±00"
  },
  "Africa/Conakry": {
    "name": "Africa/Conakry",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "UTC±00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "UTC±00"
  },
  "Atlantic/Reykjavik": {
    "name": "Atlantic/Reykjavik",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "UTC±00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "UTC±00"
  },
  "Europe/Jersey": {
    "name": "Europe/Jersey",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "UTC±00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "UTC±00"
  },
  "Pacific/Enderbury": {
    "name": "Pacific/Enderbury",
    "windowsName": "Phoenix Island Time",
    "abbreviation": "PHOT",
    "utcOffsetSeconds": 46800,
    "utcOffsetText": "UTC+13:00",
    "dstOffsetSeconds": 46800,
    "dstOffsetText": "UTC+13:00"
  },
  "Asia/Kuwait": {
    "name": "Asia/Kuwait",
    "windowsName": "Arabia Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "UTC+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "UTC+03:00"
  },
  "Asia/Vientiane": {
    "name": "Asia/Vientiane",
    "windowsName": "Indochina Time",
    "abbreviation": "ICT",
    "utcOffsetSeconds": 25200,
    "utcOffsetText": "UTC+07:00",
    "dstOffsetSeconds": 25200,
    "dstOffsetText": "UTC+07:00"
  },
  "Africa/Maseru": {
    "name": "Africa/Maseru",
    "windowsName": "South African Standard Time",
    "abbreviation": "SAST",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "UTC+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "UTC+02:00"
  },
  "Europe/Vaduz": {
    "name": "Europe/Vaduz",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Luxembourg": {
    "name": "Europe/Luxembourg",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Indian/Antananarivo": {
    "name": "Indian/Antananarivo",
    "windowsName": "East Africa Time",
    "abbreviation": "EAT",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "UTC+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "UTC+03:00"
  },
  "Africa/Blantyre": {
    "name": "Africa/Blantyre",
    "windowsName": "Central Africa Time",
    "abbreviation": "CAT",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "UTC+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "UTC+02:00"
  },
  "Asia/Kuala_Lumpur": {
    "name": "Asia/Kuala_Lumpur",
    "windowsName": "Malaysia Time",
    "abbreviation": "MYT",
    "utcOffsetSeconds": 28800,
    "utcOffsetText": "UTC+08:00",
    "dstOffsetSeconds": 28800,
    "dstOffsetText": "UTC+08:00"
  },
  "Africa/Bamako": {
    "name": "Africa/Bamako",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "UTC±00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "UTC±00"
  },
  "Europe/Isle_of_Man": {
    "name": "Europe/Isle_of_Man",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "UTC±00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "UTC±00"
  },
  "Pacific/Majuro": {
    "name": "Pacific/Majuro",
    "windowsName": "Marshall Islands Time",
    "abbreviation": "MHT",
    "utcOffsetSeconds": 43200,
    "utcOffsetText": "UTC+12:00",
    "dstOffsetSeconds": 43200,
    "dstOffsetText": "UTC+12:00"
  },
  "Africa/Nouakchott": {
    "name": "Africa/Nouakchott",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "UTC±00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "UTC±00"
  },
  "Indian/Mayotte": {
    "name": "Indian/Mayotte",
    "windowsName": "East Africa Time",
    "abbreviation": "EAT",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "UTC+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "UTC+03:00"
  },
  "Pacific/Chuuk": {
    "name": "Pacific/Chuuk",
    "windowsName": "Chuuk Time",
    "abbreviation": "CHUT",
    "utcOffsetSeconds": 36000,
    "utcOffsetText": "UTC+10:00",
    "dstOffsetSeconds": 36000,
    "dstOffsetText": "UTC+10:00"
  },
  "Pacific/Pohnpei": {
    "name": "Pacific/Pohnpei",
    "windowsName": "Pohnpei Standard Time",
    "abbreviation": "PONT",
    "utcOffsetSeconds": 39600,
    "utcOffsetText": "UTC+11:00",
    "dstOffsetSeconds": 39600,
    "dstOffsetText": "UTC+11:00"
  },
  "Europe/Monaco": {
    "name": "Europe/Monaco",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Podgorica": {
    "name": "Europe/Podgorica",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "America/Montserrat": {
    "name": "America/Montserrat",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "Europe/Amsterdam": {
    "name": "Europe/Amsterdam",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Africa/Niamey": {
    "name": "Africa/Niamey",
    "windowsName": "West Africa Time",
    "abbreviation": "WAT",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "UTC+01:00",
    "dstOffsetSeconds": 3600,
    "dstOffsetText": "UTC+01:00"
  },
  "Europe/Skopje": {
    "name": "Europe/Skopje",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Pacific/Saipan": {
    "name": "Pacific/Saipan",
    "windowsName": "Chamorro Standard Time",
    "abbreviation": "ChST",
    "utcOffsetSeconds": 36000,
    "utcOffsetText": "UTC+10:00",
    "dstOffsetSeconds": 36000,
    "dstOffsetText": "UTC+10:00"
  },
  "Asia/Muscat": {
    "name": "Asia/Muscat",
    "windowsName": "Gulf Standard Time",
    "abbreviation": "GST",
    "utcOffsetSeconds": 14400,
    "utcOffsetText": "UTC+04:00",
    "dstOffsetSeconds": 14400,
    "dstOffsetText": "UTC+04:00"
  },
  "Indian/Reunion": {
    "name": "Indian/Reunion",
    "windowsName": "Réunion Time",
    "abbreviation": "RET",
    "utcOffsetSeconds": 14400,
    "utcOffsetText": "UTC+04:00",
    "dstOffsetSeconds": 14400,
    "dstOffsetText": "UTC+04:00"
  },
  "Africa/Kigali": {
    "name": "Africa/Kigali",
    "windowsName": "Central Africa Time",
    "abbreviation": "CAT",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "UTC+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "UTC+02:00"
  },
  "Atlantic/St_Helena": {
    "name": "Atlantic/St_Helena",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "UTC±00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "UTC±00"
  },
  "America/St_Kitts": {
    "name": "America/St_Kitts",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "America/St_Lucia": {
    "name": "America/St_Lucia",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "America/St_Vincent": {
    "name": "America/St_Vincent",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "America/St_Barthelemy": {
    "name": "America/St_Barthelemy",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "America/Marigot": {
    "name": "America/Marigot",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "Europe/San_Marino": {
    "name": "Europe/San_Marino",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Africa/Dakar": {
    "name": "Africa/Dakar",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "UTC±00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "UTC±00"
  },
  "Indian/Mahe": {
    "name": "Indian/Mahe",
    "windowsName": "Seychelles Time",
    "abbreviation": "SCT",
    "utcOffsetSeconds": 14400,
    "utcOffsetText": "UTC+04:00",
    "dstOffsetSeconds": 14400,
    "dstOffsetText": "UTC+04:00"
  },
  "Africa/Freetown": {
    "name": "Africa/Freetown",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "UTC±00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "UTC±00"
  },
  "Europe/Bratislava": {
    "name": "Europe/Bratislava",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Ljubljana": {
    "name": "Europe/Ljubljana",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Africa/Mogadishu": {
    "name": "Africa/Mogadishu",
    "windowsName": "East Africa Time",
    "abbreviation": "EAT",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "UTC+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "UTC+03:00"
  },
  "Arctic/Longyearbyen": {
    "name": "Arctic/Longyearbyen",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Europe/Stockholm": {
    "name": "Europe/Stockholm",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "Africa/Dar_es_Salaam": {
    "name": "Africa/Dar_es_Salaam",
    "windowsName": "East Africa Time",
    "abbreviation": "EAT",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "UTC+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "UTC+03:00"
  },
  "America/Nassau": {
    "name": "America/Nassau",
    "windowsName": "Eastern Standard Time (North America)",
    "abbreviation": "EST",
    "utcOffsetSeconds": -18000,
    "utcOffsetText": "-05:00",
    "dstOffsetSeconds": -18000,
    "dstOffsetText": "-05:00"
  },
  "Africa/Lome": {
    "name": "Africa/Lome",
    "windowsName": "Greenwich Mean Time",
    "abbreviation": "GMT",
    "utcOffsetSeconds": 0,
    "utcOffsetText": "UTC±00",
    "dstOffsetSeconds": 0,
    "dstOffsetText": "UTC±00"
  },
  "America/Port_of_Spain": {
    "name": "America/Port_of_Spain",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "Pacific/Funafuti": {
    "name": "Pacific/Funafuti",
    "windowsName": "Tuvalu Time",
    "abbreviation": "TVT",
    "utcOffsetSeconds": 43200,
    "utcOffsetText": "UTC+12:00",
    "dstOffsetSeconds": 43200,
    "dstOffsetText": "UTC+12:00"
  },
  "Africa/Kampala": {
    "name": "Africa/Kampala",
    "windowsName": "East Africa Time",
    "abbreviation": "EAT",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "UTC+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "UTC+03:00"
  },
  "Europe/Kiev": {
    "name": "Europe/Kiev",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Uzhgorod": {
    "name": "Europe/Uzhgorod",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Europe/Zaporozhye": {
    "name": "Europe/Zaporozhye",
    "windowsName": "Eastern European Time",
    "abbreviation": "EET",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "+02:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "+03:00"
  },
  "Pacific/Midway": {
    "name": "Pacific/Midway",
    "windowsName": "Samoa Standard Time",
    "abbreviation": "SST",
    "utcOffsetSeconds": -39600,
    "utcOffsetText": "UTC-11:00",
    "dstOffsetSeconds": -39600,
    "dstOffsetText": "UTC-11:00"
  },
  "Pacific/Wake": {
    "name": "Pacific/Wake",
    "windowsName": "Wake Island Time",
    "abbreviation": "WAKT",
    "utcOffsetSeconds": 43200,
    "utcOffsetText": "UTC+12:00",
    "dstOffsetSeconds": 43200,
    "dstOffsetText": "UTC+12:00"
  },
  "Europe/Vatican": {
    "name": "Europe/Vatican",
    "windowsName": "Central European Time",
    "abbreviation": "CET",
    "utcOffsetSeconds": 3600,
    "utcOffsetText": "+01:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "+02:00"
  },
  "America/Tortola": {
    "name": "America/Tortola",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "America/St_Thomas": {
    "name": "America/St_Thomas",
    "windowsName": "Atlantic Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": -14400,
    "utcOffsetText": "UTC-04:00",
    "dstOffsetSeconds": -14400,
    "dstOffsetText": "UTC-04:00"
  },
  "Pacific/Wallis": {
    "name": "Pacific/Wallis",
    "windowsName": "Wallis & Futuna Time",
    "abbreviation": "WFT",
    "utcOffsetSeconds": 43200,
    "utcOffsetText": "UTC+12:00",
    "dstOffsetSeconds": 43200,
    "dstOffsetText": "UTC+12:00"
  },
  "Asia/Aden": {
    "name": "Asia/Aden",
    "windowsName": "Arabia Standard Time",
    "abbreviation": "AST",
    "utcOffsetSeconds": 10800,
    "utcOffsetText": "UTC+03:00",
    "dstOffsetSeconds": 10800,
    "dstOffsetText": "UTC+03:00"
  },
  "Africa/Lusaka": {
    "name": "Africa/Lusaka",
    "windowsName": "Central Africa Time",
    "abbreviation": "CAT",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "UTC+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "UTC+02:00"
  },
  "Africa/Harare": {
    "name": "Africa/Harare",
    "windowsName": "Central Africa Time",
    "abbreviation": "CAT",
    "utcOffsetSeconds": 7200,
    "utcOffsetText": "UTC+02:00",
    "dstOffsetSeconds": 7200,
    "dstOffsetText": "UTC+02:00"
  }
} as const;
