export enum BlockType {
  ACCORDION = 'accordion',
  BODY_TEXT = 'body_text',
  CAROUSEL = 'carousel',
  CONTENT_GRID = 'content_grid',
  COUNTDOWN = 'countdown',
  DIVIDER = 'divider',
  FORM = 'form',
  HEADER_IMAGE = 'header_image',
  HEADING = 'heading',
  IMAGE = 'image',
  IMAGE_GALLERY = 'image_gallery',
  LINKS = 'links',
  LIST_BLOCK = 'list_block',
  SOCIAL_MEDIA = 'social_media',
  VIDEO = 'video',
  TABS = 'tabs',
  LOCATION = 'location',
  COLUMNS = 'columns',
  ACCESSIBILITY = 'accessibility',
  PURCHASE_BLOCK = 'purchase_block',
}

export type PartialBlockType =
  | typeof BlockType.ACCORDION
  | typeof BlockType.CAROUSEL
  | typeof BlockType.COLUMNS
  | typeof BlockType.TABS;
