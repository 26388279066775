import { z } from '../zod-openapi';

export const basePaginationSchema = z
  .object({
    currentPage: z.number().int(),
    from: z.number().int(),
    perPage: z.number().int(),
    to: z.number().int(),
  })
  .openapi({ description: '`IBasePagination` from Knex pagination.' });

export const lengthAwarePaginationSchema = basePaginationSchema
  .merge(
    z.object({
      lastPage: z.number().int().nullable(),
      nextPage: z.number().int().nullable(),
      prevPage: z.number().int().nullable(),
      total: z.number().int(),
    }),
  )
  .openapi({ description: '`ILengthAwarePagination` from Knex pagination.' });

export type IBasePagination = z.infer<typeof basePaginationSchema>;
export type ILengthAwarePagination = z.infer<typeof lengthAwarePaginationSchema>;
