import { localizedString } from '../../common/request';
import { z } from '../../zod-openapi';

//ASSET PROPOSAL
export const AssetProposalSchema = z.object({
  filename: z.string(),
  filetype: z.string(),
  predata: z.string(),
  assetCollectionId: z.string(),
});
export type AssetProposal = z.infer<typeof AssetProposalSchema>;

//ASSET PROPOSAL RESPONSE
export const AssetProposalResponseSchema = z.object({
  filename: z.string(),
  uploadUrl: z.string(),
  expires: z.string(),
});
export type AssetProposalResponse = z.infer<typeof AssetProposalResponseSchema>;

//ASSET METADATA
export const AssetMetadataSchema = z.object({
  id: z.string(),
  name: z.string().min(3, 'validate:min_length'), // The original filename
  path: z.string().min(3, 'validate:min_length'), // The actual filename in db
  assetCollectionId: z.string(),
  userId: z.string().nullable().optional(),
  tenantId: z.string().nullable().optional(),
  organizationId: z.string().nullable().optional(),
  createdAt: z.date().nullable().optional(),
  updatedAt: z.date().nullable().optional(),
});
export type AssetMetadata = z.infer<typeof AssetMetadataSchema>;

export enum AssetStorageType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

//ASSET METADATA RESPONSE
export const AssetMetadataResponseSchema = z.object({
  assetMetadata: AssetMetadataSchema,
  type: z.nativeEnum(AssetStorageType),
  url: z.string(),
});
export type AssetMetadataResponse = z.infer<typeof AssetMetadataResponseSchema>;

//ASSET COLLECTION RESPONSE
export const AssetCollectionItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  url: z.string(),
  type: z.nativeEnum(AssetStorageType),
});

export const AssetCollectionResponseSchema = z.object({
  id: z.string(),
  name: localizedString,
  assets: z.array(AssetCollectionItemSchema),
});

export type AssetCollectionResponse = z.infer<typeof AssetCollectionResponseSchema>;

export const AssetCollectionsResponseSchema = z.object({
  collections: z.array(
    z.object({
      id: z.string(),
      name: localizedString,
    }),
  ),
});

export type AssetCollectionsResponse = z.infer<typeof AssetCollectionsResponseSchema>;
