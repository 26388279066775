import { z } from '../zod-openapi';
import { timezoneListSchema } from './timezone';
import { localizationSchema } from './language';
import { currencyCodeSchema } from './currency';
import { countryCodes } from './generated/countries';

export * from './generated/countries';

const bigLetters = /^[A-Z]*$/;
const smallLetters = /^[a-z]*$/;
const integer = /^[0-9]+$/;

export const countryBaseSchema = z.object({
  iso2: z.string().length(2).regex(bigLetters),
  iso3: z.string().length(3).regex(bigLetters),
  numericCode: z.string().length(3).regex(integer),
  tld: z.string().length(2).regex(smallLetters),
});

export const countrySchema = countryBaseSchema.merge(
  z.object({
    names: localizationSchema,
    timezones: timezoneListSchema,
    currency: currencyCodeSchema,
  }),
);

export type Country = z.infer<typeof countrySchema>;

export const countryCodeSchema = z.enum(countryCodes).openapi('countryCodeIso2', {
  description: 'ISO-2 format Country Code',
  example: 'FI',
});

export type CountryCode = z.infer<typeof countryCodeSchema>;
