import { z } from '@hono/zod-openapi';
import { ApiEndpointDeclaration, apiKeyHeaderSchema, apiResponseSucceededWithDataSchema, httpMethods } from '../';
import { SmartshipCreateResponseSchema, SmartshipCreateSchema } from './schemas';
const version = '/v1';
const SHIPMENT_API_PREFIX = `${version}/shipments`;

const SetSmartshipPrintRoute: ApiEndpointDeclaration = {
  description: 'Set smartship print by provider',
  method: httpMethods.post,
  path: `${SHIPMENT_API_PREFIX}/print/{provider}`,
  request: {
    headers: apiKeyHeaderSchema,
    params: z.object({
      provider: z.string().openapi({
        param: {
          name: 'provider',
          in: 'path',
        },
        example: 'smartship',
      }),
    }),
    body: {
      required: true,
      description: 'Print data',
      content: {
        'application/json': {
          schema: SmartshipCreateSchema.openapi({
            description:
              'Smartship label print data. Payload documented at https://api.posti.fi/resources/Posti_Smarthip_API_description_fi.pdf',
          }),
        },
      },
    },
  },
  responses: {
    201: {
      content: {
        'application/json': {
          schema: apiResponseSucceededWithDataSchema(z.object({ print: SmartshipCreateResponseSchema })),
        },
      },
      description: 'Print set',
    },
  },
};

export const shipmentApi = {
  SetSmartshipPrintRoute,
};
