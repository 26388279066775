import { z } from '../zod-openapi';

export const conditionalPermissionSchema = z
  .object({
    id: z.string(),
    conditions: z.array(z.string()),
    organizationId: z.string(),
    roleId: z.string(),
  })
  .partial()
  .strict();

// Passed around in JWT
export interface ConditionalPermission {
  conditions?: string[];
  organizationId?: string;
  roleId?: string;
}

export const actorSessionSchema = z.object({
  actorType: z.enum(['user', 'apiKey']),
  actorId: z.string(),
  tenantId: z.string(),
  organizationId: z.string().optional(),
  rights: z.record(z.string(), z.array(conditionalPermissionSchema)),
});

export type Session = ({ userId: string } | { actorType: 'user' | 'apiKey'; actorId: string }) & {
  tenantId: string;
  organizationId?: string;
  rights: Record<string, ConditionalPermission[]>;
};

export type SessionWithConditionalPermissions = Session;

export interface PermissionConditionCheckResultOk {
  ok: true;
  basis: string;
}

export interface PermissionConditionCheckResultFailed {
  ok: false;
  cause: string;
}

export type PermissionConditionCheckResult = PermissionConditionCheckResultOk | PermissionConditionCheckResultFailed;

export type PermissionConditionCheckerParameters<CTX extends Record<string, unknown> = never> = {
  session: Session;
  permission: Permission;
  context: CTX;
};

export type PermissionConditionChecker<CTX extends Record<string, unknown> = never> = (
  args: PermissionConditionCheckerParameters<CTX>,
) => PermissionConditionCheckResult | Promise<PermissionConditionCheckResult>;

export interface PermissionCondition<N extends string = string> {
  codeName: N;
  check: PermissionConditionChecker;
}

export interface Permission {
  path: string;
  description: string;
  conditions?: PermissionCondition[];
}

export interface PermissionPathBranch {
  [branchName: string]: PermissionPathBranch | Permission;
}

export type Permissions = PermissionPathBranch;
