import { z } from '../zod-openapi';

const acceptLanguageSchema = z.object({
  'accept-language': z
    .string()
    .regex(/^([\w-]+(;q=[\d.]+)?,\s*)*([\w-]+(;q=[\d.]+)?)$/)
    .openapi({ example: 'sv-fi, sv;q=0.9, en-us, en' }),
});

const apiKeyHeaderSchema = z.object({
  'x-api-key': z
    .string()
    .regex(/^.{20,9999}$/, 'API key should be a string of sufficient length')
    .openapi({
      description: 'A secret used to access an API endpoint programmatically',
    }),
});

export const httpHeaderSchemas = {
  acceptLanguageSchema,
  apiKeyHeaderSchema,
};
