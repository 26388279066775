import { z } from '@hono/zod-openapi';
import { paginateResponseSchema, postalAddressWithIdsSchema } from '../';
import { AccessibilityDescription, accessibilitySchema, AccessibilityStatus } from '../accessibility';
import { IdParam, localizedString, requestListQuerySchema } from '../common/request';
import { getIdAndValueSchema, getIdValueSchema } from '../id';
import { VenueState } from '../venue';

export const [eventIdSchema, eventIdValueSchema] = getIdAndValueSchema('eventId', 8);
export const [idSchema, idValueSchema] = getIdAndValueSchema('id', 8);
export const [ticketClassificationIdSchema, ticketClassificationIdValueSchema] = getIdAndValueSchema(
  'ticketClassificationId',
  8,
);

export const eventIdInLegacySystem = z.number().openapi({
  description: 'Event ids are ORDERED NUMBERS in legacy system',
  example: 1,
});

const eventIdInLegacySystemAsAString = z.string().openapi({
  description: 'String format of the legacy event id',
  example: '1',
});

export const EVENT_CACHE_KEYS = {
  event: (id: string) => `event:${id}`,
  quota: (id: string) => `quota:${id}`,
};

export const eventsListRequestSchema = requestListQuerySchema;

export const EventInstanceShowtimeVenueSchema = z.object({
  id: z.string(),
  name: z.string(),
  oldId: z.number().nullable(),
  address: postalAddressWithIdsSchema,
});

export enum EventState {
  DRAFT = 'DRAFT',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  ACTIVE = 'ACTIVE',
  TEMPLATE = 'TEMPLATE',
}

export enum EventShowtimeState {
  DRAFT = 'DRAFT',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  ACTIVE = 'ACTIVE',
  TEMPLATE = 'TEMPLATE',
}

export enum EventAgeLimitType {
  NO_AGELIMIT = 'NO_AGELIMIT',
  RECOMMENDED_AGELIMIT = 'RECOMMENDED_AGELIMIT',
  AGELIMIT = 'AGELIMIT',
}

export enum EventCapacityType {
  SECTION = 'SECTION',
}

export enum EventTicketPriceType {
  FACE_VALUE = 'FACE_VALUE',
  DOOR_VALUE = 'DOOR_VALUE',
  SECONDHAND_VALUE = 'SECONDHAND_VALUE',
}

export enum EventTicketTypeClass {
  BASIC = 'BASIC',
  OTHER = 'OTHER',
}

export const venueBaseSchema = z.object({
  name: localizedString,
  address: postalAddressWithIdsSchema,
  accessibility: accessibilitySchema.optional().nullable(),
  status: z.nativeEnum(VenueState).default(VenueState.PUBLISHED).optional().openapi({ example: VenueState.DRAFT }),
});

const ticketCapacitySchema = z.object({
  id: z.string(),
  quota: z.number(),
  available: z.number(),
});

const ticketTypePriceSchema = idSchema.merge(
  z.object({
    priceType: z.nativeEnum(EventTicketPriceType).openapi({
      example: EventTicketPriceType.FACE_VALUE,
    }),
    price: z.number(),
  }),
);

const ticketCapacityDraftSchema = ticketCapacitySchema.partial();

const eventTicketTypeSchema = idSchema.merge(
  z.object({
    oldId: eventIdInLegacySystem.nullable(),
    name: localizedString.nullable(),
    additionalInfo: localizedString.nullable(),
    class: z.nativeEnum(EventTicketTypeClass).openapi({ example: EventTicketTypeClass.BASIC }),
    discountGroups: z.object({
      student: z.boolean().default(false),
      pensioner: z.boolean().default(false),
      conscript: z.boolean().default(false),
      unemployed: z.boolean().default(false),
    }),
    bundleMinimumTickets: z.number().default(1),
    ticketPrices: z.array(ticketTypePriceSchema),
    childrenTicketAgeRangeFrom: z.number().nullable(),
    childrenTicketAgeRangeTo: z.number().nullable(),
    ordinal: z.number().optional(),
  }),
);

export const eventTicketTypeDraftSchema = eventTicketTypeSchema
  .omit({
    ticketPrices: true,
  })
  .extend({
    ticketPrices: z.array(ticketTypePriceSchema.omit({ id: true }).partial()),
  })
  .partial();

const ticketClassificationSchema = z.object({
  id: z.string(),
  oldId: eventIdInLegacySystem.nullable(),
  capacityType: z
    .nativeEnum(EventCapacityType)
    .openapi({ example: EventCapacityType.SECTION, description: 'Ticket classification capacity type' }),
  name: localizedString.nullable(),
  ticketCapacity: ticketCapacitySchema,
  ticketTypes: z.array(eventTicketTypeSchema),
  ordinal: z.number().optional(),
});
export type TicketClassification = z.infer<typeof ticketClassificationSchema>;

export const ticketClassificationDraftSchema = ticketClassificationSchema
  .omit({ ticketTypes: true, ticketCapacity: true, id: true })
  .extend({
    id: idValueSchema.optional(),
    ticketTypes: z.array(eventTicketTypeDraftSchema),
    ticketCapacity: ticketCapacityDraftSchema,
  })
  .partial();

export type TicketClassificationDraft = z.infer<typeof ticketClassificationDraftSchema>;

export const eventShowtimeSalesSchema = z.object({
  salesFrom: z
    .string()
    .datetime()
    .nullable()
    .openapi({ example: '2024-12-01T22:00:00+00:00', description: 'Sales start' }),
  salesTo: z.string().datetime().openapi({ example: '2024-12-01T22:00:00+00:00', description: 'Sales end' }),
  publicationFrom: z
    .string()
    .datetime()
    .nullable()
    .openapi({ example: '2024-12-01T22:00:00+00:00', description: 'Publication start' }),
  publicationTo: z
    .string()
    .datetime()
    .nullable()
    .openapi({ example: '2024-12-01T22:00:00+00:00', description: 'Publication end' }),
});

// Access constraint schema
const accessConstraintSchema = idSchema
  .merge(
    z.object({
      ageLimitInfo: localizedString
        .nullable()
        .openapi({ example: { en: 'K18' }, description: 'Age limit information' }),
      minimumAttendeeAgeYears: z.number().openapi({ example: 18, description: 'Minimum age requirement in years' }),
      ageLimitType: z.nativeEnum(EventAgeLimitType).openapi({
        example: EventAgeLimitType.NO_AGELIMIT,
        description: ' NO_AGELIMIT,RECOMMENDED_AGELIMIT,AGELIMIT',
      }),
    }),
  )
  .openapi({ description: 'Event access constraints' });

export const accessConstraintDraftSchema = accessConstraintSchema.partial();

export const eventShowtimeSchema = idSchema.merge(
  z.object({
    state: z
      .nativeEnum(EventShowtimeState)
      .openapi({ example: EventShowtimeState.DRAFT, description: 'Showtime state' }),
    title: localizedString
      .openapi({ example: { en: 'Tavastia concert' }, description: 'Showtime title' })
      .optional()
      .nullable(),
    sales: eventShowtimeSalesSchema.nullable(),
    accessConstraint: accessConstraintSchema.nullable(),
    venue: idSchema.merge(venueBaseSchema),
    oldId: eventIdInLegacySystem.nullable(),
    endsAt: z.string().datetime().openapi({ example: '2024-12-01T22:00:00+00:00', description: 'Event end time' }),
    accessControlBeginsAt: z
      .string()
      .datetime()
      .nullable()
      .openapi({ example: '2024-12-01T22:00:00+00:00', description: 'Access control begins at' }),
    accessControlEndsAt: z.string().datetime().nullable().openapi({
      example: '2024-12-01T22:00:00+00:00',
      description: 'Access control ends at. Can be null. Must be over accessControlBeginsAt',
    }),
    // beginsAt: z.string().datetime().openapi({ example: '2024-12-01T19:00:00+00:00', description: 'Event start time' }),
    beginsAt: z.string().datetime().openapi({
      example: '2024-12-01T19:00:00+00:00',
      description: 'Event start time',
    }),
    contentBeginsAt: z.string().datetime().nullable().openapi({
      example: '2024-12-01T19:00:00+00:00',
      description: 'Event showtime content start time',
    }),
    contentEndsAt: z.string().datetime().nullable().openapi({
      example: '2024-12-01T19:00:00+00:00',
      description: 'Event showtime content end time',
    }),

    entryOpensAt: z
      .string()
      .datetime()
      .openapi({ example: '2024-12-01T17:00:00+00:00', description: 'Entry opening time' }),
    entryClosesAt: z
      .string()
      .datetime()
      .nullable()
      .openapi({ example: '2024-12-01T21:00:00+00:00', description: 'Entry closing time. Can be null' }),
    eventScheduleInfo: localizedString.nullable().openapi({
      example: { en: '3h' },
      description: 'Event duration information or null',
    }),
    ticketClassification: z.array(ticketClassificationSchema),
  }),
);

export const eventShowtimeDraftSchema = eventShowtimeSchema
  .omit({ ticketClassification: true, venue: true, accessConstraint: true })
  .extend({
    venue: idSchema,
    ticketClassification: z.array(ticketClassificationDraftSchema),
    accessConstraint: accessConstraintDraftSchema.nullable(),
  })
  .partial();

// Organization and tenant schemas
const organizationSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const tenantSchema = z.object({
  id: z.string(),
  name: z.string(),
});

const eventExtraInfoSchema = z.object({
  needPrintedTickets: z.boolean(),
  accessControlDevicesRentalEnabled: z.boolean(),
  accessControlDevicesRentalAmount: z.number().nullable(),
  accessControlDevicesRentalBeginsAt: z.string().datetime().nullable(),
  accessControlDevicesRentalEndsAt: z.string().datetime().nullable(),
  additionalInfo: localizedString.nullable(),
  submittedByUserId: getIdValueSchema(8, 'submittedByUserId'),
  accessControlAdditionalInfo: z.string().nullable(),
});

export const eventExtraInfoDraftSchema = eventExtraInfoSchema
  .omit({
    submittedByUserId: true,
  })
  .partial()
  .extend({ submittedByUserId: getIdValueSchema(8, 'submittedByUserId') });

export const eventTagSchema = idSchema.merge(
  eventIdSchema.merge(
    z.object({
      tagId: getIdValueSchema(8, 'tagId'),
    }),
  ),
);
export type EventTag = z.infer<typeof eventTagSchema>;
export const eventTagDraftSchema = eventTagSchema
  .omit({
    tagId: true,
  })
  .partial()
  .extend({ tagId: getIdValueSchema(8, 'tagId') });

export const eventInstanceSchema = idSchema
  .merge(
    z.object({
      name: localizedString.openapi({ example: { en: 'Tavastia concert' }, description: 'Event name' }),
      state: z.nativeEnum(EventState).openapi({ example: EventState.DRAFT, description: 'Event state' }),
      createdAt: z
        .string()
        .openapi({ example: '2024-12-01T22:00:00.000000+00:00', description: 'Event creation time' }),
      updatedAt: z
        .string()
        .openapi({ example: '2024-12-01T22:00:00.000000+00:00', description: 'Event last update time' }),
      imageAsset: z
        .object({
          id: z.string().nullable(),
          name: z.string().nullable(),
          url: z.string().nullable(),
        })
        .nullable()
        .openapi({
          description: 'Image asset id and url',
        }),
      socialContact: z
        .array(
          z
            .object({
              url: z.string(),
              type: z.string(),
            })
            .openapi({
              example: { url: 'facebook url', type: 'facebook' },
              description: 'Object with keys structure',
            }),
        )
        .nullable(),
      accessibility: accessibilitySchema.nullable(),
      tenant: tenantSchema.nullable(),
      oldId: eventIdInLegacySystem.nullable(),
      shortName: localizedString.nullable(),
      description: localizedString.nullable(),
      tags: z.array(eventTagSchema),
      additionalInfo: localizedString.nullable(),
      organization: organizationSchema.nullable(),
      eventShowtimes: z.array(eventShowtimeSchema).openapi({ description: 'List of event showtimes' }),
      accessConstraint: accessConstraintSchema.nullable(),
      eventExtraInfo: eventExtraInfoSchema,
    }),
  )
  .strict()
  .openapi({
    example: {
      id: '12345678',
      name: { en: 'Tavastia concert' },
      createdAt: '2024-12-01T22:00:00.000000+00:00',
      updatedAt: '2024-12-01T22:00:00.000000+00:00',
      state: EventState.DRAFT,
      oldId: 123,
      imageAsset: {
        id: '12345678',
        name: 'image.jpg',
        url: 'http://example.com/image.jpg',
      },
      accessibility: [
        {
          description: AccessibilityDescription.STEP_FREE_ACCESS,
          status: AccessibilityStatus.AVAILABLE,
          additionalInfo: {
            en: 'Additional info',
          },
        },
      ],
      socialContact: [
        {
          url: 'https://facebook.com',
          type: 'facebook',
        },
      ],
      tags: [
        {
          id: '12345678',
          tagId: '12345678',
          eventId: '12345678',
        },
      ],
      additionalInfo: {
        en: 'Theres a lot of parking space at the event!',
      },
      description: {
        en: 'Event description',
      },
      shortName: {
        en: 'Concert',
      },
      tenant: { id: '12345678', name: 'tiketti' },
      organization: { id: '12345678', name: 'Test org single' },
      eventExtraInfo: {
        needPrintedTickets: true,
        additionalInfo: null,
        accessControlDevicesRentalEnabled: true,
        accessControlDevicesRentalAmount: 3,
        accessControlDevicesRentalBeginsAt: '2024-12-01T18:00:00+00:00',
        accessControlDevicesRentalEndsAt: '2024-12-01T18:00:00+00:00',
        submittedByUserId: '12345678',
        accessControlAdditionalInfo: 'Additional info for the access control',
      },
      eventShowtimes: [
        {
          id: '12345678',
          accessConstraint: {
            id: '12345678',
            ageLimitInfo: { en: 'K18' },
            minimumAttendeeAgeYears: 18,
            ageLimitType: EventAgeLimitType.NO_AGELIMIT,
          },
          state: EventShowtimeState.DRAFT,
          sales: {
            salesFrom: '2024-12-01T18:00:00+00:00',
            salesTo: '2024-12-01T18:00:00+00:00',
            publicationFrom: '2024-12-01T18:00:00+00:00',
            publicationTo: '2024-12-01T18:00:00+00:00',
          },
          oldId: 456,
          title: { en: 'Tavastia concert' },
          venue: {
            id: '12345678',
            name: {
              en: 'Tavastia',
            },
            address: {
              street: 'Test Street 1',
              postcode: '00100',
              country: 'FI',
              city: 'Test City',
              cityId: 12354215,
              countryIso2: 'FI',
            },
          },
          accessControlBeginsAt: '2024-12-01T18:00:00+00:00',
          accessControlEndsAt: '2024-12-01T19:00:00+00:00',
          endsAt: '2024-12-01T22:00:00+00:00',
          contentEndsAt: '2024-12-01T22:00:00+00:00',
          beginsAt: '2024-12-01T19:00:00+00:00',
          contentBeginsAt: '2024-12-01T19:00:00+00:00',
          entryOpensAt: '2024-12-01T17:00:00+00:00',
          entryClosesAt: null,
          eventScheduleInfo: { en: '3h' },
          ticketClassification: [
            {
              id: '12345678',
              oldId: 123,
              ordinal: 0,
              capacityType: EventCapacityType.SECTION,
              name: {
                en: 'name',
              },
              ticketCapacity: {
                id: '12345678',
                quota: 5,
                available: 3,
              },
              ticketTypes: [
                {
                  id: '12345678',
                  oldId: 123,
                  name: {
                    en: 'VIP ticket!',
                  },
                  class: EventTicketTypeClass.BASIC,
                  additionalInfo: {
                    en: 'Additional info for the VIP ticket!',
                  },
                  ticketPrices: [
                    {
                      id: '12345657',
                      priceType: EventTicketPriceType.DOOR_VALUE,
                      price: 500,
                    },
                  ],
                  discountGroups: {
                    student: true,
                    pensioner: true,
                    conscript: true,
                    unemployed: true,
                  },
                  bundleMinimumTickets: 1,
                  childrenTicketAgeRangeFrom: 0,
                  childrenTicketAgeRangeTo: 12,
                  ordinal: 0,
                },
              ],
            },
          ],
        },
      ],
      accessConstraint: {
        id: '12345678',
        ageLimitInfo: { en: 'K18' },
        minimumAttendeeAgeYears: 18,
        ageLimitType: EventAgeLimitType.NO_AGELIMIT,
      },
    },
  });

export type EventInstance = z.infer<typeof eventInstanceSchema>;
export type StoreEvent = z.infer<typeof eventInstanceSchema>;
export enum EventInventoryStatus {
  active = 'active',
  inactive = 'inactive',
}
export const eventInventoryStatusSchema = z.nativeEnum(EventInventoryStatus);

export enum EventInventoryType {
  event = 'event',
  section = 'section',
  ticket = 'ticket',
  product = 'product',
}

export const eventInventoryTypeSchema = z.nativeEnum(EventInventoryType);

export const eventQuotaLocationSchema = z.object({
  eventId: z.string(),
  sectionId: z.string().nullable(),
  categoryId: z.string().nullable(),
  priceGroupId: z.string().nullable(),
});
export type EventQuotaLocation = z.infer<typeof eventQuotaLocationSchema>;

export const eventInventoryItemSchema = z.object({
  id: z.string(),
  quota: z.number(),
  quotaLocation: eventQuotaLocationSchema,
  reserved: z.number(),
  available: z.number(),
  reserveTimeSeconds: z.number(),
  inventoryStatus: eventInventoryStatusSchema,
  type: eventInventoryTypeSchema,
});
export type EventInventoryItem = z.infer<typeof eventInventoryItemSchema>;
export const eventInventoryItemDraft = eventInventoryItemSchema.partial();
export type EventInventoryItemDraft = z.infer<typeof eventInventoryItemDraft>;
export const EventInventoryQuotaQuery = z
  .object({
    sectionId: z.string(),
    priceGroupId: z.string(),
    categoryId: z.string(),
  })
  .partial();

/**
 * Event endpoints
 */

export interface EventFilter {
  type: 'include' | 'exclude';
  value?: string;
  language?: string;
}

export interface EventShowtimeFilter {
  showtimeFrom?: string;
  showtimeTo?: string;
}

const eventContentFilterQuery = z.object({
  filter: z.string().or(z.array(z.string())).optional(),
  showtimeSort: z.string().optional(),
  showtimeFrom: z.string().optional(),
  showtimeTo: z.string().optional(),
});

export type EventContentModifierQuery = z.infer<typeof eventContentFilterQuery>;
export const EventContentModifierQuerySchema = eventContentFilterQuery;

/**
 * getEvents
 */

export const EventsQuerySchema = requestListQuerySchema.merge(eventContentFilterQuery);
export type EventsQuery = z.infer<typeof EventsQuerySchema>;

/**
 * We need to optionalize the eventContentExcludeQuery fields
 * as they can excluded from result set
 * "venue" | "organization" | "category" | "artists" | "tickettype"
 */
export const EventsListResponseSchema = z
  .object({
    pagination: paginateResponseSchema,
  })
  .extend({
    items: z.array(
      eventInstanceSchema.omit({ eventShowtimes: true }).extend({
        eventShowtimes: z.array(
          eventShowtimeSchema.omit({ venue: true }).extend({
            venue: idSchema.merge(venueBaseSchema).optional(),
          }),
        ),
      }),
    ),
  });

export type EventsListResponse = z.infer<typeof EventsListResponseSchema>;
export interface EventsListRequestQuery extends EventsQuery {}

/**
 * getEvent
 */

export const EventsResponseSchema = eventInstanceSchema;

export type EventResponse = z.infer<typeof EventsResponseSchema>;
export type EventsRequestParam = IdParam;

/**
 * resetEvent
 */
const EventQuotaOutputSchema = z.object({
  quota: z.number().openapi({
    example: 10,
  }),
  quotaLocation: z.object({
    eventId: eventIdInLegacySystemAsAString,
    sectionId: z.string().nullable().openapi({
      example: '2088',
    }),
    categoryId: z.string().nullable().openapi({
      example: '1',
    }),
    priceGroupId: z.string().nullable().openapi({
      example: '2372',
    }),
  }),
  type: z.nativeEnum(EventInventoryType).openapi({
    example: EventInventoryType.event,
  }),
});
export const ResetEventQuotaOutputSchema = idSchema.merge(
  z.object({
    legacyEventId: eventIdInLegacySystemAsAString,
    items: z.array(EventQuotaOutputSchema.optional()),
  }),
);

/**
 * setEventQuota
 */

export const SetEventQuotaOutputSchema = idSchema.merge(
  z.object({
    legacyEventId: eventIdInLegacySystemAsAString,
    items: z.array(EventQuotaOutputSchema.optional()),
  }),
);

/**
 * createEvents
 */

export const eventInstanceDraftSchema = eventInstanceSchema
  .omit({
    id: true,
    tenant: true,
    organization: true,
    eventShowtimes: true,
    accessConstraint: true,
    eventExtraInfo: true,
    imageAsset: true,
    tags: true,
  })
  .extend({
    eventShowtimes: z.array(eventShowtimeDraftSchema),
    eventExtraInfo: eventExtraInfoDraftSchema,
    accessConstraint: accessConstraintDraftSchema,
    organizationId: z.string().nullable().optional(),
    tenantId: z.string().nullable().optional(),
    imageAssetId: z.string().optional().nullable(),
    tags: z.array(eventTagDraftSchema.optional()),
    taskToken: z.string().optional(),
    imageAsset: z
      .object({
        id: z.string().nullable(),
        name: z.string().nullable(),
        url: z.string().nullable(),
      })
      .nullable()
      .optional(),
  })
  .partial()
  .openapi({
    example: {
      name: {
        en: 'Tavastia concert',
      },
      state: EventState.DRAFT,
      shortName: {
        en: 'Concert',
      },
      description: {
        en: 'Description',
      },
      createdAt: '2024-12-01T22:00:00.000000+00:00',
      updatedAt: '2024-12-01T22:00:00.000000+00:00',
      tags: [],
      imageAssetId: null,
      imageAsset: {
        id: '12345678',
        name: 'image.jpg',
        url: 'https://example.com/image.jpg',
      },
      oldId: null,
      eventExtraInfo: {
        additionalInfo: {
          en: 'Hello',
        },
        needPrintedTickets: true,
        accessControlDevicesRentalEnabled: true,
        accessControlDevicesRentalAmount: 1,
        accessControlDevicesRentalBeginsAt: '2024-12-01T22:00:00+00:00',
        accessControlDevicesRentalEndsAt: '2024-12-01T22:00:00+00:00',
        submittedByUserId: '12345678',
      },
      tenantId: '12345678',
      organizationId: '12345678',
      accessConstraint: {
        ageLimitInfo: {
          en: 'K18',
        },
        minimumAttendeeAgeYears: 18,
      },
      eventShowtimes: [
        {
          state: EventShowtimeState.DRAFT,
          oldId: null,
          title: {
            en: 'Tavastia concert',
          },
          venue: {
            id: '12345678',
          },
          endsAt: '2024-12-01T22:00:00Z',
          beginsAt: '2024-12-01T19:00:00Z',
          accessControlBeginsAt: '2024-12-01T22:00:00+00:00',
          accessControlEndsAt: null,
          entryOpensAt: '2024-12-01T17:00:00Z',
          entryClosesAt: null,
          contentBeginsAt: null,
          contentEndsAt: null,
          eventScheduleInfo: {
            en: '4h',
          },
          ticketClassification: [
            {
              oldId: null,
              capacityType: EventCapacityType.SECTION,
              name: {
                en: 'Name of the ticket classification!',
              },
              ordinal: 0,
              ticketCapacity: {
                id: '12345678',
                quota: 5,
                available: 3,
              },
              ticketTypes: [
                {
                  oldId: null,
                  additionalInfo: {
                    en: 'Additional info :)',
                  },
                  name: {
                    en: 'Ticket type name!',
                  },

                  discountGroups: {
                    student: false,
                    pensioner: true,
                    conscript: true,
                    unemployed: true,
                  },
                  bundleMinimumTickets: 1,
                  ticketPrices: [
                    {
                      priceType: EventTicketPriceType.FACE_VALUE,
                      price: 500,
                    },
                  ],
                  ordinal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
  });

export const eventInstanceCreateSchema = eventInstanceSchema
  .omit({
    id: true,
    tenant: true,
    organization: true,
    eventShowtimes: true,
    accessConstraint: true,
    eventExtraInfo: true,
    imageAsset: true,
    tags: true,
    createdAt: true,
    updatedAt: true,
  })
  .extend({
    eventShowtimes: z.array(eventShowtimeDraftSchema.omit({ id: true })),
    eventExtraInfo: eventExtraInfoDraftSchema,
    accessConstraint: accessConstraintDraftSchema,
    organizationId: z.string().nullable().optional(),
    tenantId: z.string().nullable().optional(),
    imageAssetId: z.string().optional().nullable(),
    tags: z.array(eventTagDraftSchema.optional()),
    taskToken: z.string().optional(),
  })
  .partial()
  .openapi({
    example: {
      name: {
        en: 'Tavastia concert',
      },
      state: EventState.DRAFT,
      shortName: {
        en: 'Concert',
      },
      description: {
        en: 'Description',
      },
      tags: [],
      imageAssetId: null,
      oldId: null,
      eventExtraInfo: {
        additionalInfo: {
          en: 'Hello',
        },
        needPrintedTickets: true,
        accessControlDevicesRentalEnabled: true,
        accessControlDevicesRentalAmount: 1,
        accessControlDevicesRentalBeginsAt: '2024-12-01T22:00:00+00:00',
        accessControlDevicesRentalEndsAt: '2024-12-01T22:00:00+00:00',
        submittedByUserId: '12345678',
      },
      tenantId: '12345678',
      organizationId: '12345678',
      accessConstraint: {
        ageLimitInfo: {
          en: 'K18',
        },
        minimumAttendeeAgeYears: 18,
      },
      eventShowtimes: [
        {
          state: EventShowtimeState.DRAFT,
          oldId: null,
          title: {
            en: 'Tavastia concert',
          },
          venue: {
            id: '12345678',
          },
          endsAt: '2024-12-01T22:00:00Z',
          beginsAt: '2024-12-01T19:00:00Z',
          accessControlBeginsAt: '2024-12-01T22:00:00+00:00',
          accessControlEndsAt: null,
          entryOpensAt: '2024-12-01T17:00:00Z',
          entryClosesAt: null,
          eventScheduleInfo: {
            en: '4h',
          },
          ticketClassification: [
            {
              oldId: null,
              capacityType: EventCapacityType.SECTION,
              name: {
                en: 'Name of the ticket classification!',
              },
              ordinal: 0,
              ticketCapacity: {
                id: '12345678',
                quota: 5,
                available: 3,
              },
              ticketTypes: [
                {
                  oldId: null,
                  additionalInfo: {
                    en: 'Additional info :)',
                  },
                  name: {
                    en: 'Ticket type name!',
                  },

                  discountGroups: {
                    student: false,
                    pensioner: true,
                    conscript: true,
                    unemployed: true,
                  },
                  bundleMinimumTickets: 1,
                  ticketPrices: [
                    {
                      priceType: EventTicketPriceType.FACE_VALUE,
                      price: 500,
                    },
                  ],
                  ordinal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
  });

export type EventInstanceDraft = z.infer<typeof eventInstanceDraftSchema>;

export type CreateEventsInput = z.infer<typeof CreateEventsInputSchema>;
export const CreateEventsInputSchema = z.object({
  items: z.array(eventInstanceCreateSchema),
});

export type CreateEventsOutput = z.infer<typeof CreateEventsOutputSchema>;
export const CreateEventsOutputSchema = z.object({
  items: z.array(eventInstanceSchema),
});

export const UpdateEventsInputSchema = eventInstanceDraftSchema.partial();
export type UpdateEventsInput = z.infer<typeof UpdateEventsInputSchema>;

export const UpdateEventsOutputSchema = eventInstanceSchema;
export type UpdateEventsOutput = z.infer<typeof UpdateEventsOutputSchema>;

export type EventShowtime = NonNullable<EventInstance['eventShowtimes']>[number];
export type EventShowtimeClassification = NonNullable<EventShowtime['ticketClassification']>[number];
export type EventShowtimeClassificationType = NonNullable<EventShowtimeClassification['ticketTypes']>[number];
export type EventShowtimeClassificationTypePrice = NonNullable<EventShowtimeClassificationType['ticketPrices']>[number];

export type EventShowtimeDraft = NonNullable<EventInstanceDraft['eventShowtimes']>[number];
export type EventShowtimeClassificationDraft = NonNullable<EventShowtimeDraft['ticketClassification']>[number];
export type EventShowtimeClassificationTypeDraft = NonNullable<EventShowtimeClassificationDraft['ticketTypes']>[number];
export type EventShowtimeClassificationTypePriceDraft = NonNullable<
  EventShowtimeClassificationTypeDraft['ticketPrices']
>[number];

export const PublishEventInputSchema = z.object({
  recipientList: z
    .array(
      z.object({
        name: z.string(),
        email: z.string(),
      }),
    )
    .optional(),
});
export type PublishEventInput = z.infer<typeof PublishEventInputSchema>;
export const PublishEventsOutputSchema = eventInstanceSchema;
export type PublishEventsOutput = z.infer<typeof PublishEventsOutputSchema>;

export const ConfirmEventsOutputSchema = eventInstanceSchema;
export type ConfirmEventOutput = z.infer<typeof PublishEventsOutputSchema>;

export const UnpublishEventsOutputSchema = eventInstanceSchema;
export type UnpublishEventOutput = z.infer<typeof UnpublishEventsOutputSchema>;

/**
 * saveSeatMapInventoryFromChartKey
 */

export const SaveSeatMapInventoryFromChartKeyInputSchema = z.object({
  chartKey: z.string(),
});
export type SaveSeatMapInventoryFromChartKeyInput = z.infer<typeof SaveSeatMapInventoryFromChartKeyInputSchema>;
export const SaveSeatMapInventoryFromChartKeyOutputSchema = eventIdSchema.merge(
  z.object({
    items: z.array(EventQuotaOutputSchema.optional()),
  }),
);

export type SaveSeatMapInventoryFromChartKeyOutput = z.infer<typeof SaveSeatMapInventoryFromChartKeyOutputSchema>;
