// generated by /home/kato/tiketti/tiketti-api/scripts/world-import/generate-countries-and-timezones-ts.js on 2024-03-06
// Manual changes to this file may be overwritten; see the script on how to add entries.

// All country codes known by countries-and-timezones on 2024-03-06
export const countryCodes = [
  "AD",
  "AE",
  "AF",
  "AG",
  "AI",
  "AL",
  "AM",
  "AO",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AW",
  "AX",
  "AZ",
  "BA",
  "BB",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BL",
  "BM",
  "BN",
  "BO",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BW",
  "BY",
  "BZ",
  "CA",
  "CC",
  "CD",
  "CF",
  "CG",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CU",
  "CV",
  "CW",
  "CX",
  "CY",
  "CZ",
  "DE",
  "DJ",
  "DK",
  "DM",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "EH",
  "ER",
  "ES",
  "ET",
  "FI",
  "FJ",
  "FK",
  "FM",
  "FO",
  "FR",
  "GA",
  "GB",
  "GD",
  "GE",
  "GF",
  "GG",
  "GH",
  "GI",
  "GL",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GR",
  "GS",
  "GT",
  "GU",
  "GW",
  "GY",
  "HK",
  "HN",
  "HR",
  "HT",
  "HU",
  "ID",
  "IE",
  "IL",
  "IM",
  "IN",
  "IO",
  "IQ",
  "IR",
  "IS",
  "IT",
  "JE",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KI",
  "KM",
  "KN",
  "KP",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LC",
  "LI",
  "LK",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "LY",
  "MA",
  "MC",
  "MD",
  "ME",
  "MF",
  "MG",
  "MH",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MP",
  "MQ",
  "MR",
  "MS",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NC",
  "NE",
  "NF",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NR",
  "NU",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PF",
  "PG",
  "PH",
  "PK",
  "PL",
  "PM",
  "PN",
  "PR",
  "PS",
  "PT",
  "PW",
  "PY",
  "QA",
  "RE",
  "RO",
  "RS",
  "RU",
  "RW",
  "SA",
  "SB",
  "SC",
  "SD",
  "SE",
  "SG",
  "SH",
  "SI",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SO",
  "SR",
  "SS",
  "ST",
  "SV",
  "SX",
  "SY",
  "SZ",
  "TC",
  "TD",
  "TF",
  "TG",
  "TH",
  "TJ",
  "TK",
  "TL",
  "TM",
  "TN",
  "TO",
  "TR",
  "TT",
  "TV",
  "TW",
  "TZ",
  "UA",
  "UG",
  "UM",
  "US",
  "UY",
  "UZ",
  "VA",
  "VC",
  "VE",
  "VG",
  "VI",
  "VN",
  "VU",
  "WF",
  "WS",
  "YE",
  "YT",
  "ZA",
  "ZM",
  "ZW",
  "BV",
  "HM",
  "XK"
] as const;

// English names of all countries known by countries-and-timezones on 2024-03-06
export const countryNames = {
  "AD": "Andorra",
  "AE": "United Arab Emirates",
  "AF": "Afghanistan",
  "AG": "Antigua and Barbuda",
  "AI": "Anguilla",
  "AL": "Albania",
  "AM": "Armenia",
  "AO": "Angola",
  "AQ": "Antarctica",
  "AR": "Argentina",
  "AS": "American Samoa",
  "AT": "Austria",
  "AU": "Australia",
  "AW": "Aruba",
  "AX": "Åland Islands",
  "AZ": "Azerbaijan",
  "BA": "Bosnia and Herzegovina",
  "BB": "Barbados",
  "BD": "Bangladesh",
  "BE": "Belgium",
  "BF": "Burkina Faso",
  "BG": "Bulgaria",
  "BH": "Bahrain",
  "BI": "Burundi",
  "BJ": "Benin",
  "BL": "Saint Barthélemy",
  "BM": "Bermuda",
  "BN": "Brunei",
  "BO": "Bolivia",
  "BQ": "Caribbean Netherlands",
  "BR": "Brazil",
  "BS": "Bahamas",
  "BT": "Bhutan",
  "BW": "Botswana",
  "BY": "Belarus",
  "BZ": "Belize",
  "CA": "Canada",
  "CC": "Cocos Islands",
  "CD": "Democratic Republic of the Congo",
  "CF": "Central African Republic",
  "CG": "Republic of the Congo",
  "CH": "Switzerland",
  "CI": "Ivory Coast",
  "CK": "Cook Islands",
  "CL": "Chile",
  "CM": "Cameroon",
  "CN": "China",
  "CO": "Colombia",
  "CR": "Costa Rica",
  "CU": "Cuba",
  "CV": "Cabo Verde",
  "CW": "Curaçao",
  "CX": "Christmas Island",
  "CY": "Cyprus",
  "CZ": "Czechia",
  "DE": "Germany",
  "DJ": "Djibouti",
  "DK": "Denmark",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "DZ": "Algeria",
  "EC": "Ecuador",
  "EE": "Estonia",
  "EG": "Egypt",
  "EH": "Western Sahara",
  "ER": "Eritrea",
  "ES": "Spain",
  "ET": "Ethiopia",
  "FI": "Finland",
  "FJ": "Fiji",
  "FK": "Falkland Islands",
  "FM": "Micronesia",
  "FO": "Faroe Islands",
  "FR": "France",
  "GA": "Gabon",
  "GB": "United Kingdom",
  "GD": "Grenada",
  "GE": "Georgia",
  "GF": "French Guiana",
  "GG": "Guernsey",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GL": "Greenland",
  "GM": "Gambia",
  "GN": "Guinea",
  "GP": "Guadeloupe",
  "GQ": "Equatorial Guinea",
  "GR": "Greece",
  "GS": "South Georgia and the South Sandwich Islands",
  "GT": "Guatemala",
  "GU": "Guam",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HK": "Hong Kong",
  "HN": "Honduras",
  "HR": "Croatia",
  "HT": "Haiti",
  "HU": "Hungary",
  "ID": "Indonesia",
  "IE": "Ireland",
  "IL": "Israel",
  "IM": "Isle of Man",
  "IN": "India",
  "IO": "British Indian Ocean Territory",
  "IQ": "Iraq",
  "IR": "Iran",
  "IS": "Iceland",
  "IT": "Italy",
  "JE": "Jersey",
  "JM": "Jamaica",
  "JO": "Jordan",
  "JP": "Japan",
  "KE": "Kenya",
  "KG": "Kyrgyzstan",
  "KH": "Cambodia",
  "KI": "Kiribati",
  "KM": "Comoros",
  "KN": "Saint Kitts and Nevis",
  "KP": "North Korea",
  "KR": "South Korea",
  "KW": "Kuwait",
  "KY": "Cayman Islands",
  "KZ": "Kazakhstan",
  "LA": "Laos",
  "LB": "Lebanon",
  "LC": "Saint Lucia",
  "LI": "Liechtenstein",
  "LK": "Sri Lanka",
  "LR": "Liberia",
  "LS": "Lesotho",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "LV": "Latvia",
  "LY": "Libya",
  "MA": "Morocco",
  "MC": "Monaco",
  "MD": "Moldova",
  "ME": "Montenegro",
  "MF": "Saint Martin",
  "MG": "Madagascar",
  "MH": "Marshall Islands",
  "MK": "North Macedonia",
  "ML": "Mali",
  "MM": "Myanmar",
  "MN": "Mongolia",
  "MO": "Macao",
  "MP": "Northern Mariana Islands",
  "MQ": "Martinique",
  "MR": "Mauritania",
  "MS": "Montserrat",
  "MT": "Malta",
  "MU": "Mauritius",
  "MV": "Maldives",
  "MW": "Malawi",
  "MX": "Mexico",
  "MY": "Malaysia",
  "MZ": "Mozambique",
  "NA": "Namibia",
  "NC": "New Caledonia",
  "NE": "Niger",
  "NF": "Norfolk Island",
  "NG": "Nigeria",
  "NI": "Nicaragua",
  "NL": "Netherlands",
  "NO": "Norway",
  "NP": "Nepal",
  "NR": "Nauru",
  "NU": "Niue",
  "NZ": "New Zealand",
  "OM": "Oman",
  "PA": "Panama",
  "PE": "Peru",
  "PF": "French Polynesia",
  "PG": "Papua New Guinea",
  "PH": "Philippines",
  "PK": "Pakistan",
  "PL": "Poland",
  "PM": "Saint Pierre and Miquelon",
  "PN": "Pitcairn",
  "PR": "Puerto Rico",
  "PS": "Palestine",
  "PT": "Portugal",
  "PW": "Palau",
  "PY": "Paraguay",
  "QA": "Qatar",
  "RE": "Réunion",
  "RO": "Romania",
  "RS": "Serbia",
  "RU": "Russia",
  "RW": "Rwanda",
  "SA": "Saudi Arabia",
  "SB": "Solomon Islands",
  "SC": "Seychelles",
  "SD": "Sudan",
  "SE": "Sweden",
  "SG": "Singapore",
  "SH": "Saint Helena, Ascension and Tristan da Cunha",
  "SI": "Slovenia",
  "SJ": "Svalbard and Jan Mayen",
  "SK": "Slovakia",
  "SL": "Sierra Leone",
  "SM": "San Marino",
  "SN": "Senegal",
  "SO": "Somalia",
  "SR": "Suriname",
  "SS": "South Sudan",
  "ST": "Sao Tome and Principe",
  "SV": "El Salvador",
  "SX": "Sint Maarten",
  "SY": "Syria",
  "SZ": "Eswatini",
  "TC": "Turks and Caicos Islands",
  "TD": "Chad",
  "TF": "French Southern Territories",
  "TG": "Togo",
  "TH": "Thailand",
  "TJ": "Tajikistan",
  "TK": "Tokelau",
  "TL": "Timor-Leste",
  "TM": "Turkmenistan",
  "TN": "Tunisia",
  "TO": "Tonga",
  "TR": "Türkiye",
  "TT": "Trinidad and Tobago",
  "TV": "Tuvalu",
  "TW": "Taiwan",
  "TZ": "Tanzania",
  "UA": "Ukraine",
  "UG": "Uganda",
  "UM": "United States Minor Outlying Islands",
  "US": "United States of America",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VA": "Holy See",
  "VC": "Saint Vincent and the Grenadines",
  "VE": "Venezuela",
  "VG": "Virgin Islands (UK)",
  "VI": "Virgin Islands (US)",
  "VN": "Vietnam",
  "VU": "Vanuatu",
  "WF": "Wallis and Futuna",
  "WS": "Samoa",
  "YE": "Yemen",
  "YT": "Mayotte",
  "ZA": "South Africa",
  "ZM": "Zambia",
  "ZW": "Zimbabwe",
  "BV": "Bouvet Island",
  "HM": "Heard Island and McDonald Islands",
  "XK": "Kosovo"
} as const;

// All timezones of countries known by countries-and-timezones on 2024-03-06
export const countryTimezones = {
  "AD": [
    "Europe/Andorra"
  ],
  "AE": [
    "Asia/Dubai"
  ],
  "AF": [
    "Asia/Kabul"
  ],
  "AG": [
    "America/Puerto_Rico"
  ],
  "AI": [
    "America/Puerto_Rico"
  ],
  "AL": [
    "Europe/Tirane"
  ],
  "AM": [
    "Asia/Yerevan"
  ],
  "AO": [
    "Africa/Lagos"
  ],
  "AQ": [
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/Mawson",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Troll",
    "Asia/Riyadh",
    "Asia/Urumqi",
    "Pacific/Auckland",
    "Pacific/Port_Moresby"
  ],
  "AR": [
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Catamarca",
    "America/Argentina/Cordoba",
    "America/Argentina/Jujuy",
    "America/Argentina/La_Rioja",
    "America/Argentina/Mendoza",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Salta",
    "America/Argentina/San_Juan",
    "America/Argentina/San_Luis",
    "America/Argentina/Tucuman",
    "America/Argentina/Ushuaia"
  ],
  "AS": [
    "Pacific/Pago_Pago"
  ],
  "AT": [
    "Europe/Vienna"
  ],
  "AU": [
    "Antarctica/Macquarie",
    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Broken_Hill",
    "Australia/Darwin",
    "Australia/Eucla",
    "Australia/Hobart",
    "Australia/Lindeman",
    "Australia/Lord_Howe",
    "Australia/Melbourne",
    "Australia/Perth",
    "Australia/Sydney"
  ],
  "AW": [
    "America/Puerto_Rico"
  ],
  "AX": [
    "Europe/Helsinki"
  ],
  "AZ": [
    "Asia/Baku"
  ],
  "BA": [
    "Europe/Belgrade"
  ],
  "BB": [
    "America/Barbados"
  ],
  "BD": [
    "Asia/Dhaka"
  ],
  "BE": [
    "Europe/Brussels"
  ],
  "BF": [
    "Africa/Abidjan"
  ],
  "BG": [
    "Europe/Sofia"
  ],
  "BH": [
    "Asia/Qatar"
  ],
  "BI": [
    "Africa/Maputo"
  ],
  "BJ": [
    "Africa/Lagos"
  ],
  "BL": [
    "America/Puerto_Rico"
  ],
  "BM": [
    "Atlantic/Bermuda"
  ],
  "BN": [
    "Asia/Kuching"
  ],
  "BO": [
    "America/La_Paz"
  ],
  "BQ": [
    "America/Puerto_Rico"
  ],
  "BR": [
    "America/Araguaina",
    "America/Bahia",
    "America/Belem",
    "America/Boa_Vista",
    "America/Campo_Grande",
    "America/Cuiaba",
    "America/Eirunepe",
    "America/Fortaleza",
    "America/Maceio",
    "America/Manaus",
    "America/Noronha",
    "America/Porto_Velho",
    "America/Recife",
    "America/Rio_Branco",
    "America/Santarem",
    "America/Sao_Paulo"
  ],
  "BS": [
    "America/Toronto"
  ],
  "BT": [
    "Asia/Thimphu"
  ],
  "BW": [
    "Africa/Maputo"
  ],
  "BY": [
    "Europe/Minsk"
  ],
  "BZ": [
    "America/Belize"
  ],
  "CA": [
    "America/Cambridge_Bay",
    "America/Dawson",
    "America/Dawson_Creek",
    "America/Edmonton",
    "America/Fort_Nelson",
    "America/Glace_Bay",
    "America/Goose_Bay",
    "America/Halifax",
    "America/Inuvik",
    "America/Iqaluit",
    "America/Moncton",
    "America/Panama",
    "America/Phoenix",
    "America/Puerto_Rico",
    "America/Rankin_Inlet",
    "America/Regina",
    "America/Resolute",
    "America/St_Johns",
    "America/Swift_Current",
    "America/Toronto",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Winnipeg"
  ],
  "CC": [
    "Asia/Yangon"
  ],
  "CD": [
    "Africa/Lagos",
    "Africa/Maputo"
  ],
  "CF": [
    "Africa/Lagos"
  ],
  "CG": [
    "Africa/Lagos"
  ],
  "CH": [
    "Europe/Zurich"
  ],
  "CI": [
    "Africa/Abidjan"
  ],
  "CK": [
    "Pacific/Rarotonga"
  ],
  "CL": [
    "America/Punta_Arenas",
    "America/Santiago",
    "Pacific/Easter"
  ],
  "CM": [
    "Africa/Lagos"
  ],
  "CN": [
    "Asia/Shanghai",
    "Asia/Urumqi"
  ],
  "CO": [
    "America/Bogota"
  ],
  "CR": [
    "America/Costa_Rica"
  ],
  "CU": [
    "America/Havana"
  ],
  "CV": [
    "Atlantic/Cape_Verde"
  ],
  "CW": [
    "America/Puerto_Rico"
  ],
  "CX": [
    "Asia/Bangkok"
  ],
  "CY": [
    "Asia/Famagusta",
    "Asia/Nicosia"
  ],
  "CZ": [
    "Europe/Prague"
  ],
  "DE": [
    "Europe/Berlin",
    "Europe/Zurich"
  ],
  "DJ": [
    "Africa/Nairobi"
  ],
  "DK": [
    "Europe/Berlin"
  ],
  "DM": [
    "America/Puerto_Rico"
  ],
  "DO": [
    "America/Santo_Domingo"
  ],
  "DZ": [
    "Africa/Algiers"
  ],
  "EC": [
    "America/Guayaquil",
    "Pacific/Galapagos"
  ],
  "EE": [
    "Europe/Tallinn"
  ],
  "EG": [
    "Africa/Cairo"
  ],
  "EH": [
    "Africa/El_Aaiun"
  ],
  "ER": [
    "Africa/Nairobi"
  ],
  "ES": [
    "Africa/Ceuta",
    "Atlantic/Canary",
    "Europe/Madrid"
  ],
  "ET": [
    "Africa/Nairobi"
  ],
  "FI": [
    "Europe/Helsinki"
  ],
  "FJ": [
    "Pacific/Fiji"
  ],
  "FK": [
    "Atlantic/Stanley"
  ],
  "FM": [
    "Pacific/Guadalcanal",
    "Pacific/Kosrae",
    "Pacific/Port_Moresby"
  ],
  "FO": [
    "Atlantic/Faroe"
  ],
  "FR": [
    "Europe/Paris"
  ],
  "GA": [
    "Africa/Lagos"
  ],
  "GB": [
    "Europe/London"
  ],
  "GD": [
    "America/Puerto_Rico"
  ],
  "GE": [
    "Asia/Tbilisi"
  ],
  "GF": [
    "America/Cayenne"
  ],
  "GG": [
    "Europe/London"
  ],
  "GH": [
    "Africa/Abidjan"
  ],
  "GI": [
    "Europe/Gibraltar"
  ],
  "GL": [
    "America/Danmarkshavn",
    "America/Nuuk",
    "America/Scoresbysund",
    "America/Thule"
  ],
  "GM": [
    "Africa/Abidjan"
  ],
  "GN": [
    "Africa/Abidjan"
  ],
  "GP": [
    "America/Puerto_Rico"
  ],
  "GQ": [
    "Africa/Lagos"
  ],
  "GR": [
    "Europe/Athens"
  ],
  "GS": [
    "Atlantic/South_Georgia"
  ],
  "GT": [
    "America/Guatemala"
  ],
  "GU": [
    "Pacific/Guam"
  ],
  "GW": [
    "Africa/Bissau"
  ],
  "GY": [
    "America/Guyana"
  ],
  "HK": [
    "Asia/Hong_Kong"
  ],
  "HN": [
    "America/Tegucigalpa"
  ],
  "HR": [
    "Europe/Belgrade"
  ],
  "HT": [
    "America/Port-au-Prince"
  ],
  "HU": [
    "Europe/Budapest"
  ],
  "ID": [
    "Asia/Jakarta",
    "Asia/Jayapura",
    "Asia/Makassar",
    "Asia/Pontianak"
  ],
  "IE": [
    "Europe/Dublin"
  ],
  "IL": [
    "Asia/Jerusalem"
  ],
  "IM": [
    "Europe/London"
  ],
  "IN": [
    "Asia/Kolkata"
  ],
  "IO": [
    "Indian/Chagos"
  ],
  "IQ": [
    "Asia/Baghdad"
  ],
  "IR": [
    "Asia/Tehran"
  ],
  "IS": [
    "Africa/Abidjan"
  ],
  "IT": [
    "Europe/Rome"
  ],
  "JE": [
    "Europe/London"
  ],
  "JM": [
    "America/Jamaica"
  ],
  "JO": [
    "Asia/Amman"
  ],
  "JP": [
    "Asia/Tokyo"
  ],
  "KE": [
    "Africa/Nairobi"
  ],
  "KG": [
    "Asia/Bishkek"
  ],
  "KH": [
    "Asia/Bangkok"
  ],
  "KI": [
    "Pacific/Kanton",
    "Pacific/Kiritimati",
    "Pacific/Tarawa"
  ],
  "KM": [
    "Africa/Nairobi"
  ],
  "KN": [
    "America/Puerto_Rico"
  ],
  "KP": [
    "Asia/Pyongyang"
  ],
  "KR": [
    "Asia/Seoul"
  ],
  "KW": [
    "Asia/Riyadh"
  ],
  "KY": [
    "America/Panama"
  ],
  "KZ": [
    "Asia/Almaty",
    "Asia/Aqtau",
    "Asia/Aqtobe",
    "Asia/Atyrau",
    "Asia/Oral",
    "Asia/Qostanay",
    "Asia/Qyzylorda"
  ],
  "LA": [
    "Asia/Bangkok"
  ],
  "LB": [
    "Asia/Beirut"
  ],
  "LC": [
    "America/Puerto_Rico"
  ],
  "LI": [
    "Europe/Zurich"
  ],
  "LK": [
    "Asia/Colombo"
  ],
  "LR": [
    "Africa/Monrovia"
  ],
  "LS": [
    "Africa/Johannesburg"
  ],
  "LT": [
    "Europe/Vilnius"
  ],
  "LU": [
    "Europe/Brussels"
  ],
  "LV": [
    "Europe/Riga"
  ],
  "LY": [
    "Africa/Tripoli"
  ],
  "MA": [
    "Africa/Casablanca"
  ],
  "MC": [
    "Europe/Paris"
  ],
  "MD": [
    "Europe/Chisinau"
  ],
  "ME": [
    "Europe/Belgrade"
  ],
  "MF": [
    "America/Puerto_Rico"
  ],
  "MG": [
    "Africa/Nairobi"
  ],
  "MH": [
    "Pacific/Kwajalein",
    "Pacific/Tarawa"
  ],
  "MK": [
    "Europe/Belgrade"
  ],
  "ML": [
    "Africa/Abidjan"
  ],
  "MM": [
    "Asia/Yangon"
  ],
  "MN": [
    "Asia/Choibalsan",
    "Asia/Hovd",
    "Asia/Ulaanbaatar"
  ],
  "MO": [
    "Asia/Macau"
  ],
  "MP": [
    "Pacific/Guam"
  ],
  "MQ": [
    "America/Martinique"
  ],
  "MR": [
    "Africa/Abidjan"
  ],
  "MS": [
    "America/Puerto_Rico"
  ],
  "MT": [
    "Europe/Malta"
  ],
  "MU": [
    "Indian/Mauritius"
  ],
  "MV": [
    "Indian/Maldives"
  ],
  "MW": [
    "Africa/Maputo"
  ],
  "MX": [
    "America/Bahia_Banderas",
    "America/Cancun",
    "America/Chihuahua",
    "America/Ciudad_Juarez",
    "America/Hermosillo",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Merida",
    "America/Mexico_City",
    "America/Monterrey",
    "America/Ojinaga",
    "America/Tijuana"
  ],
  "MY": [
    "Asia/Kuching",
    "Asia/Singapore"
  ],
  "MZ": [
    "Africa/Maputo"
  ],
  "NA": [
    "Africa/Windhoek"
  ],
  "NC": [
    "Pacific/Noumea"
  ],
  "NE": [
    "Africa/Lagos"
  ],
  "NF": [
    "Pacific/Norfolk"
  ],
  "NG": [
    "Africa/Lagos"
  ],
  "NI": [
    "America/Managua"
  ],
  "NL": [
    "Europe/Brussels"
  ],
  "NO": [
    "Europe/Berlin"
  ],
  "NP": [
    "Asia/Kathmandu"
  ],
  "NR": [
    "Pacific/Nauru"
  ],
  "NU": [
    "Pacific/Niue"
  ],
  "NZ": [
    "Pacific/Auckland",
    "Pacific/Chatham"
  ],
  "OM": [
    "Asia/Dubai"
  ],
  "PA": [
    "America/Panama"
  ],
  "PE": [
    "America/Lima"
  ],
  "PF": [
    "Pacific/Gambier",
    "Pacific/Marquesas",
    "Pacific/Tahiti"
  ],
  "PG": [
    "Pacific/Bougainville",
    "Pacific/Port_Moresby"
  ],
  "PH": [
    "Asia/Manila"
  ],
  "PK": [
    "Asia/Karachi"
  ],
  "PL": [
    "Europe/Warsaw"
  ],
  "PM": [
    "America/Miquelon"
  ],
  "PN": [
    "Pacific/Pitcairn"
  ],
  "PR": [
    "America/Puerto_Rico"
  ],
  "PS": [
    "Asia/Gaza",
    "Asia/Hebron"
  ],
  "PT": [
    "Atlantic/Azores",
    "Atlantic/Madeira",
    "Europe/Lisbon"
  ],
  "PW": [
    "Pacific/Palau"
  ],
  "PY": [
    "America/Asuncion"
  ],
  "QA": [
    "Asia/Qatar"
  ],
  "RE": [
    "Asia/Dubai"
  ],
  "RO": [
    "Europe/Bucharest"
  ],
  "RS": [
    "Europe/Belgrade"
  ],
  "RU": [
    "Asia/Anadyr",
    "Asia/Barnaul",
    "Asia/Chita",
    "Asia/Irkutsk",
    "Asia/Kamchatka",
    "Asia/Khandyga",
    "Asia/Krasnoyarsk",
    "Asia/Magadan",
    "Asia/Novokuznetsk",
    "Asia/Novosibirsk",
    "Asia/Omsk",
    "Asia/Sakhalin",
    "Asia/Srednekolymsk",
    "Asia/Tomsk",
    "Asia/Ust-Nera",
    "Asia/Vladivostok",
    "Asia/Yakutsk",
    "Asia/Yekaterinburg",
    "Europe/Astrakhan",
    "Europe/Kaliningrad",
    "Europe/Kirov",
    "Europe/Moscow",
    "Europe/Samara",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Ulyanovsk",
    "Europe/Volgograd"
  ],
  "RW": [
    "Africa/Maputo"
  ],
  "SA": [
    "Asia/Riyadh"
  ],
  "SB": [
    "Pacific/Guadalcanal"
  ],
  "SC": [
    "Asia/Dubai"
  ],
  "SD": [
    "Africa/Khartoum"
  ],
  "SE": [
    "Europe/Berlin"
  ],
  "SG": [
    "Asia/Singapore"
  ],
  "SH": [
    "Africa/Abidjan"
  ],
  "SI": [
    "Europe/Belgrade"
  ],
  "SJ": [
    "Europe/Berlin"
  ],
  "SK": [
    "Europe/Prague"
  ],
  "SL": [
    "Africa/Abidjan"
  ],
  "SM": [
    "Europe/Rome"
  ],
  "SN": [
    "Africa/Abidjan"
  ],
  "SO": [
    "Africa/Nairobi"
  ],
  "SR": [
    "America/Paramaribo"
  ],
  "SS": [
    "Africa/Juba"
  ],
  "ST": [
    "Africa/Sao_Tome"
  ],
  "SV": [
    "America/El_Salvador"
  ],
  "SX": [
    "America/Puerto_Rico"
  ],
  "SY": [
    "Asia/Damascus"
  ],
  "SZ": [
    "Africa/Johannesburg"
  ],
  "TC": [
    "America/Grand_Turk"
  ],
  "TD": [
    "Africa/Ndjamena"
  ],
  "TF": [
    "Asia/Dubai",
    "Indian/Maldives"
  ],
  "TG": [
    "Africa/Abidjan"
  ],
  "TH": [
    "Asia/Bangkok"
  ],
  "TJ": [
    "Asia/Dushanbe"
  ],
  "TK": [
    "Pacific/Fakaofo"
  ],
  "TL": [
    "Asia/Dili"
  ],
  "TM": [
    "Asia/Ashgabat"
  ],
  "TN": [
    "Africa/Tunis"
  ],
  "TO": [
    "Pacific/Tongatapu"
  ],
  "TR": [
    "Europe/Istanbul"
  ],
  "TT": [
    "America/Puerto_Rico"
  ],
  "TV": [
    "Pacific/Tarawa"
  ],
  "TW": [
    "Asia/Taipei"
  ],
  "TZ": [
    "Africa/Nairobi"
  ],
  "UA": [
    "Europe/Kyiv",
    "Europe/Simferopol"
  ],
  "UG": [
    "Africa/Nairobi"
  ],
  "UM": [
    "Pacific/Pago_Pago",
    "Pacific/Tarawa"
  ],
  "US": [
    "America/Adak",
    "America/Anchorage",
    "America/Boise",
    "America/Chicago",
    "America/Denver",
    "America/Detroit",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Los_Angeles",
    "America/Menominee",
    "America/Metlakatla",
    "America/New_York",
    "America/Nome",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Phoenix",
    "America/Sitka",
    "America/Yakutat",
    "Pacific/Honolulu"
  ],
  "UY": [
    "America/Montevideo"
  ],
  "UZ": [
    "Asia/Samarkand",
    "Asia/Tashkent"
  ],
  "VA": [
    "Europe/Rome"
  ],
  "VC": [
    "America/Puerto_Rico"
  ],
  "VE": [
    "America/Caracas"
  ],
  "VG": [
    "America/Puerto_Rico"
  ],
  "VI": [
    "America/Puerto_Rico"
  ],
  "VN": [
    "Asia/Bangkok",
    "Asia/Ho_Chi_Minh"
  ],
  "VU": [
    "Pacific/Efate"
  ],
  "WF": [
    "Pacific/Tarawa"
  ],
  "WS": [
    "Pacific/Apia"
  ],
  "YE": [
    "Asia/Riyadh"
  ],
  "YT": [
    "Africa/Nairobi"
  ],
  "ZA": [
    "Africa/Johannesburg"
  ],
  "ZM": [
    "Africa/Maputo"
  ],
  "ZW": [
    "Africa/Maputo"
  ]
} as const;
