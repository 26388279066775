import { countryCodeSchema } from '../world';
import { z } from '../zod-openapi';

export const phoneNumberSchema = z
  .object({
    ext: z
      .string()
      .regex(/^\+?\d{1,5}$/, 'Invalid extension format. It should start with a plus ("+") sign followed by 1-5 digits.')
      .openapi({
        description: 'International country calling code prefix starting with plus ("+") sign',
        example: '+358',
      }),
    number: z
      .string()
      .regex(/^\d{4,15}$/, 'Invalid phone number format. It should be 4-15 digits.')
      .openapi({ description: 'phone number excluding prefix', example: '5554321' }),
  })
  .openapi('PhoneNumber', { description: 'Phone number with separate international calling code `ext`' });

export type PhoneNumber = z.infer<typeof phoneNumberSchema>;

export const emailSchema = z.string().email().openapi('email', { example: 'name@example.com' });

export type Email = z.infer<typeof emailSchema>;

export const postalAddressBaseSchema = z
  .object({
    street: z.string().openapi({ description: 'Street address', example: 'Urho Kekkosen katu 4-6' }),
    postcode: z.string().openapi({ description: 'Postal code (zip code where applicable)', example: '00100' }),
    city: z.string().openapi({ description: 'Name of municipality', example: 'Helsinki' }),
    country: z.string().openapi({ description: 'Name of country, nation, or jurisdiction', example: 'Finland' }),
  })
  .openapi('PostalAddress', { description: 'Human readable parts of a postal address.' });

export type PostalAddressBase = z.infer<typeof postalAddressBaseSchema>;

export const postalAddressIdsSchema = z
  .object({
    cityId: z
      .number()
      .int()
      .openapi('cityId', {
        example: 38770,
        description:
          'From Countries States Cities Database if below one million. Otherwise, an internally mapped postal office identifier.',
        externalDocs: {
          url: 'https://github.com/dr5hn/countries-states-cities-database?tab=readme-ov-file#-countries-states-cities-database',
          description: 'Countries States Cities Database',
        },
      }),
    countryIso2: countryCodeSchema,
  })
  .openapi('PostalAddressIds', { description: 'Identifiers for country and city' });

export const postalAddressWithIdsSchema = postalAddressBaseSchema
  .merge(postalAddressIdsSchema)
  .openapi('PostalAddressWithIds');

export type PostalAddressWithIds = z.infer<typeof postalAddressWithIdsSchema>;

export const postalAddressDraftSchema = postalAddressWithIdsSchema.partial().openapi('PostalAddressDraft');

export type PostalAddressDraft = z.infer<typeof postalAddressDraftSchema>;
