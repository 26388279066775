// Use Zod with OpenAPI extension
export * from '@asteasolutions/zod-to-openapi';
export * from '@hono/zod-openapi';
export { Schema, ZodEffects, ZodObject, ZodSchema, ZodTransformer, ZodTuple, ZodType } from 'zod';
export type { AnyZodObject, AnyZodTuple, SafeParseReturnType, ZodError, ZodIssue, ZodTypeAny, ZodTypeDef } from 'zod';
import { z } from '@hono/zod-openapi';

export type ZodString = ReturnType<typeof z.string>;

/**
 * For use with: OpenAPIHono app.openAPIRegistry.registerComponent
 *
 * Source: https://github.com/OAI/OpenAPI-Specification/blob/main/versions/3.0.3.md#components-object
 */
export type ComponentTypeKey =
  | 'schemas'
  | 'responses'
  | 'parameters'
  | 'examples'
  | 'requestBodies'
  | 'headers'
  | 'securitySchemes'
  | 'links'
  | 'callbacks';

export const zodDateMaybeSerializedSchema = z
  .date()
  .or(z.string().transform((s) => new Date(s)))
  .openapi({
    description: 'Date (possibly serialized as string)',
    example: '2024-06-14T08:48:37.194Z',
  });
