import { Context } from 'hono';
import { oas31 } from 'openapi3-ts';
import { ApiKeyAndAuthorizationHeaderSchema, Permission } from '../iam';
import { AnyZodObject, z, ZodObject, ZodSchema } from '../zod-openapi';
import { httpMethodSchema } from './http';

type LinksObject = oas31.LinksObject;

const zodSchema = z.instanceof(ZodSchema);
const zodObjectSchema = z.instanceof(ZodObject);

const description = z.string().openapi({ description: 'Explain what this is used for' });

const stringKeySchema = z
  .string()
  .regex(/^\w{1,40}$/)
  .openapi({ description: 'Short word-like string for easy use' });

const requestBodySchema = z
  .object({
    content: z.object({ 'application/json': z.object({ schema: zodSchema }) }),
    required: z.boolean().optional(),
    description,
  })
  .strict()
  .openapi({ description: 'Request payload declaration' });

const requestSchema = z
  .object({
    body: requestBodySchema,
    cookies: zodObjectSchema,
    headers: zodObjectSchema,
    params: zodObjectSchema,
    query: zodObjectSchema,
  })
  .strict()
  .partial();

const responseSchema = z
  .object({
    content: z.object({ 'application/json': z.object({ schema: zodSchema }) }).optional(),
    headers: z.instanceof(ZodObject).optional(),
    links: z.object({}).optional(),
    description,
  })
  .openapi({ description: 'Response declaration' });

export type ApiEndpointResponseDeclaration = {
  content?: { 'application/json': { schema: ZodSchema } };
  headers?: AnyZodObject;
  links?: LinksObject;
  description: string;
};

export const apiEndpointDeclarationSchema = z.object({
  path: z.string().regex(/^(\/(:\w{1,20}|\{\w{1,20}}|\w[\w-]{0,19})){1,9}$/),
  method: httpMethodSchema,
  request: requestSchema.optional(),
  responses: z.record(z.string().regex(/^(default|[1-5]\d\d)$/), responseSchema),
  sampleHandler: z
    .function()
    .optional()
    .openapi({ description: 'Obsolete sample function - too much work to fake real impl instead of using it' }),
  tags: z.array(stringKeySchema).optional().openapi({
    description:
      'Endpoints are grouped under each tag in documentation UI. API name is always inserted as first tag there.',
  }),
  description,
  servers: z
    .array(
      z.object({
        url: z.string(),
        description,
        variables: z.record(z.string(), z.object({ default: z.string() })).optional(),
      }),
    )
    .optional(),
});

export type ApiEndpointRequestDeclarationBody = z.infer<typeof requestBodySchema>;

export type ApiEndpointRequestDeclaration = Partial<{
  body: ApiEndpointRequestDeclarationBody;
  cookies: AnyZodObject;
  headers: AnyZodObject;
  params: AnyZodObject;
  query: AnyZodObject;
}>;

export type ApiEndpointResponsesDeclaration = { [statusCode: string]: ApiEndpointResponseDeclaration };

type ApiEndpointDeclarationInferred = z.infer<typeof apiEndpointDeclarationSchema>;

export type ApiEndpointDeclaration = Omit<ApiEndpointDeclarationInferred, 'request' | 'responses' | 'sampleHandler'> & {
  request?: ApiEndpointRequestDeclaration;
  responses: ApiEndpointResponsesDeclaration;
  sampleHandler?: (c: Context) => Response | Promise<Response>;
  permissions?: Permission[];
};

export const apiDeclarationSchema = z.record(
  stringKeySchema.openapi({ description: 'API name', example: 'onions' }),
  apiEndpointDeclarationSchema,
);

export type ApiDeclaration = { [endpointName: string]: ApiEndpointDeclaration };

export const apiDeclarationsSchema = z.record(
  stringKeySchema.openapi({ description: 'Endpoint name', example: 'growOnions' }),
  apiDeclarationSchema,
);

export type ApiDeclarations = { [apiName: string]: ApiDeclaration };

type MaybeZodResult<T> = T extends AnyZodObject ? z.infer<T> : never;

export type ApiEndpointDeclarationRequestPart<
  T extends ApiEndpointDeclaration,
  K extends keyof ApiEndpointRequestDeclaration,
> = T['request'] extends object
  ? K extends 'body'
    ? ApiEndpointDeclarationRequestBody<T['request']['body']>
    : MaybeZodResult<T['request'][K]>
  : never;

export type ApiEndpointDeclarationRequestBody<T extends ApiEndpointRequestDeclarationBody | undefined> =
  T extends ApiEndpointRequestDeclarationBody ? z.infer<T['content']['application/json']['schema']> : never;

export type ApiEndpointDeclarationRequestPartIfDeclared<
  T extends ApiEndpointDeclaration,
  K extends keyof ApiEndpointRequestDeclaration,
> = ApiEndpointDeclarationRequestPart<T, K> extends never ? object : Record<K, ApiEndpointDeclarationRequestPart<T, K>>;

/**
 * It's always ok but optional to inject known `OptionalHeaders` to `fetchFromTpApi` requests.
 */
export type ApiEndpointDeclarationRequestHeaders<
  T extends ApiEndpointDeclaration,
  P = ApiEndpointDeclarationRequestPart<T, 'headers'>,
> = P extends never
  ? Partial<Record<'headers', OptionalHeaders>>
  : keyof Required<Omit<P, keyof ApiKeyAndAuthorizationHeaderSchema>> extends never
    ? Partial<Record<'headers', OptionalHeaders & Omit<P, keyof ApiKeyAndAuthorizationHeaderSchema>>>
    : Record<'headers', OptionalHeaders & Omit<P, keyof ApiKeyAndAuthorizationHeaderSchema>>;

interface OptionalHeaders extends Partial<ApiKeyAndAuthorizationHeaderSchema> {
  'x-request-id'?: string;
}
