// generated by /home/kato/tiketti/tiketti-api/scripts/world-import/generate-currencies.ts on 2024-03-06T09:19:48.724Z

export const currencyCodes = [
  "AAD",
  "AED",
  "AFN",
  "ALL",
  "AMD",
  "ANG",
  "AOA",
  "ARS",
  "AUD",
  "AWG",
  "AZN",
  "BAM",
  "BBD",
  "BDT",
  "BGN",
  "BHD",
  "BIF",
  "BMD",
  "BND",
  "BOB",
  "BRL",
  "BSD",
  "BTN",
  "BWP",
  "BYN",
  "BZD",
  "CAD",
  "CDF",
  "CHF",
  "CLP",
  "CNY",
  "COP",
  "CRC",
  "CUP",
  "CVE",
  "CZK",
  "DJF",
  "DKK",
  "DOP",
  "DZD",
  "EGP",
  "ERN",
  "ETB",
  "EUR",
  "FJD",
  "FKP",
  "GBP",
  "GEL",
  "GHS",
  "GIP",
  "GMD",
  "GNF",
  "GTQ",
  "GYD",
  "HKD",
  "HNL",
  "HRK",
  "HTG",
  "HUF",
  "IDR",
  "ILS",
  "INR",
  "IQD",
  "IRR",
  "ISK",
  "JMD",
  "JOD",
  "JPY",
  "KES",
  "KGS",
  "KHR",
  "KMF",
  "KPW",
  "KRW",
  "KWD",
  "KYD",
  "KZT",
  "LAK",
  "LBP",
  "LKR",
  "LRD",
  "LSL",
  "LYD",
  "MAD",
  "MDL",
  "MGA",
  "MKD",
  "MMK",
  "MNT",
  "MOP",
  "MRO",
  "MUR",
  "MVR",
  "MWK",
  "MXN",
  "MYR",
  "MZN",
  "NAD",
  "NGN",
  "NIO",
  "NOK",
  "NPR",
  "NZD",
  "OMR",
  "PAB",
  "PEN",
  "PGK",
  "PHP",
  "PKR",
  "PLN",
  "PYG",
  "QAR",
  "RON",
  "RSD",
  "RUB",
  "RWF",
  "SAR",
  "SBD",
  "SCR",
  "SDG",
  "SEK",
  "SGD",
  "SHP",
  "SLL",
  "SOS",
  "SRD",
  "SSP",
  "STD",
  "SYP",
  "SZL",
  "THB",
  "TJS",
  "TMT",
  "TND",
  "TOP",
  "TRY",
  "TTD",
  "TWD",
  "TZS",
  "UAH",
  "UGX",
  "USD",
  "UYU",
  "UZS",
  "VES",
  "VND",
  "VUV",
  "WST",
  "XAF",
  "XCD",
  "XOF",
  "XPF",
  "YER",
  "ZAR",
  "ZMW",
  "ZWL"
] as const;

export const currencies = {
  "AFN": {
    "name": "Afghan afghani",
    "symbol": "؋"
  },
  "EUR": {
    "name": "Euro",
    "symbol": "€"
  },
  "ALL": {
    "name": "Albanian lek",
    "symbol": "Lek"
  },
  "DZD": {
    "name": "Algerian dinar",
    "symbol": "دج"
  },
  "USD": {
    "name": "United States dollar",
    "symbol": "$"
  },
  "AOA": {
    "name": "Angolan kwanza",
    "symbol": "Kz"
  },
  "XCD": {
    "name": "Eastern Caribbean dollar",
    "symbol": "$"
  },
  "AAD": {
    "name": "Antarctican dollar",
    "symbol": "$"
  },
  "ARS": {
    "name": "Argentine peso",
    "symbol": "$"
  },
  "AMD": {
    "name": "Armenian dram",
    "symbol": "֏"
  },
  "AWG": {
    "name": "Aruban florin",
    "symbol": "ƒ"
  },
  "AUD": {
    "name": "Australian dollar",
    "symbol": "$"
  },
  "AZN": {
    "name": "Azerbaijani manat",
    "symbol": "m"
  },
  "BHD": {
    "name": "Bahraini dinar",
    "symbol": ".د.ب"
  },
  "BDT": {
    "name": "Bangladeshi taka",
    "symbol": "৳"
  },
  "BBD": {
    "name": "Barbadian dollar",
    "symbol": "Bds$"
  },
  "BYN": {
    "name": "Belarusian ruble",
    "symbol": "Br"
  },
  "BZD": {
    "name": "Belize dollar",
    "symbol": "$"
  },
  "XOF": {
    "name": "West African CFA franc",
    "symbol": "CFA"
  },
  "BMD": {
    "name": "Bermudian dollar",
    "symbol": "$"
  },
  "BTN": {
    "name": "Bhutanese ngultrum",
    "symbol": "Nu."
  },
  "BOB": {
    "name": "Bolivian boliviano",
    "symbol": "Bs."
  },
  "BAM": {
    "name": "Bosnia and Herzegovina convertible mark",
    "symbol": "KM"
  },
  "BWP": {
    "name": "Botswana pula",
    "symbol": "P"
  },
  "NOK": {
    "name": "Norwegian Krone",
    "symbol": "kr"
  },
  "BRL": {
    "name": "Brazilian real",
    "symbol": "R$"
  },
  "BND": {
    "name": "Brunei dollar",
    "symbol": "B$"
  },
  "BGN": {
    "name": "Bulgarian lev",
    "symbol": "Лв."
  },
  "BIF": {
    "name": "Burundian franc",
    "symbol": "FBu"
  },
  "KHR": {
    "name": "Cambodian riel",
    "symbol": "KHR"
  },
  "XAF": {
    "name": "Central African CFA franc",
    "symbol": "FCFA"
  },
  "CAD": {
    "name": "Canadian dollar",
    "symbol": "$"
  },
  "CVE": {
    "name": "Cape Verdean escudo",
    "symbol": "$"
  },
  "KYD": {
    "name": "Cayman Islands dollar",
    "symbol": "$"
  },
  "CLP": {
    "name": "Chilean peso",
    "symbol": "$"
  },
  "CNY": {
    "name": "Chinese yuan",
    "symbol": "¥"
  },
  "COP": {
    "name": "Colombian peso",
    "symbol": "$"
  },
  "KMF": {
    "name": "Comorian franc",
    "symbol": "CF"
  },
  "NZD": {
    "name": "New Zealand dollar",
    "symbol": "$"
  },
  "CRC": {
    "name": "Costa Rican colón",
    "symbol": "₡"
  },
  "HRK": {
    "name": "Croatian kuna",
    "symbol": "kn"
  },
  "CUP": {
    "name": "Cuban peso",
    "symbol": "$"
  },
  "ANG": {
    "name": "Netherlands Antillean guilder",
    "symbol": "ƒ"
  },
  "CZK": {
    "name": "Czech koruna",
    "symbol": "Kč"
  },
  "CDF": {
    "name": "Congolese Franc",
    "symbol": "FC"
  },
  "DKK": {
    "name": "Danish krone",
    "symbol": "Kr."
  },
  "DJF": {
    "name": "Djiboutian franc",
    "symbol": "Fdj"
  },
  "DOP": {
    "name": "Dominican peso",
    "symbol": "$"
  },
  "EGP": {
    "name": "Egyptian pound",
    "symbol": "ج.م"
  },
  "ERN": {
    "name": "Eritrean nakfa",
    "symbol": "Nfk"
  },
  "SZL": {
    "name": "Lilangeni",
    "symbol": "E"
  },
  "ETB": {
    "name": "Ethiopian birr",
    "symbol": "Nkf"
  },
  "FKP": {
    "name": "Falkland Islands pound",
    "symbol": "£"
  },
  "FJD": {
    "name": "Fijian dollar",
    "symbol": "FJ$"
  },
  "XPF": {
    "name": "CFP franc",
    "symbol": "₣"
  },
  "GMD": {
    "name": "Gambian dalasi",
    "symbol": "D"
  },
  "GEL": {
    "name": "Georgian lari",
    "symbol": "ლ"
  },
  "GHS": {
    "name": "Ghanaian cedi",
    "symbol": "GH₵"
  },
  "GIP": {
    "name": "Gibraltar pound",
    "symbol": "£"
  },
  "GTQ": {
    "name": "Guatemalan quetzal",
    "symbol": "Q"
  },
  "GBP": {
    "name": "British pound",
    "symbol": "£"
  },
  "GNF": {
    "name": "Guinean franc",
    "symbol": "FG"
  },
  "GYD": {
    "name": "Guyanese dollar",
    "symbol": "$"
  },
  "HTG": {
    "name": "Haitian gourde",
    "symbol": "G"
  },
  "HNL": {
    "name": "Honduran lempira",
    "symbol": "L"
  },
  "HKD": {
    "name": "Hong Kong dollar",
    "symbol": "$"
  },
  "HUF": {
    "name": "Hungarian forint",
    "symbol": "Ft"
  },
  "ISK": {
    "name": "Icelandic króna",
    "symbol": "kr"
  },
  "INR": {
    "name": "Indian rupee",
    "symbol": "₹"
  },
  "IDR": {
    "name": "Indonesian rupiah",
    "symbol": "Rp"
  },
  "IRR": {
    "name": "Iranian rial",
    "symbol": "﷼"
  },
  "IQD": {
    "name": "Iraqi dinar",
    "symbol": "د.ع"
  },
  "ILS": {
    "name": "Israeli new shekel",
    "symbol": "₪"
  },
  "JMD": {
    "name": "Jamaican dollar",
    "symbol": "J$"
  },
  "JPY": {
    "name": "Japanese yen",
    "symbol": "¥"
  },
  "JOD": {
    "name": "Jordanian dinar",
    "symbol": "ا.د"
  },
  "KZT": {
    "name": "Kazakhstani tenge",
    "symbol": "лв"
  },
  "KES": {
    "name": "Kenyan shilling",
    "symbol": "KSh"
  },
  "KWD": {
    "name": "Kuwaiti dinar",
    "symbol": "ك.د"
  },
  "KGS": {
    "name": "Kyrgyzstani som",
    "symbol": "лв"
  },
  "LAK": {
    "name": "Lao kip",
    "symbol": "₭"
  },
  "LBP": {
    "name": "Lebanese pound",
    "symbol": "£"
  },
  "LSL": {
    "name": "Lesotho loti",
    "symbol": "L"
  },
  "LRD": {
    "name": "Liberian dollar",
    "symbol": "$"
  },
  "LYD": {
    "name": "Libyan dinar",
    "symbol": "د.ل"
  },
  "CHF": {
    "name": "Swiss franc",
    "symbol": "CHf"
  },
  "MOP": {
    "name": "Macanese pataca",
    "symbol": "$"
  },
  "MGA": {
    "name": "Malagasy ariary",
    "symbol": "Ar"
  },
  "MWK": {
    "name": "Malawian kwacha",
    "symbol": "MK"
  },
  "MYR": {
    "name": "Malaysian ringgit",
    "symbol": "RM"
  },
  "MVR": {
    "name": "Maldivian rufiyaa",
    "symbol": "Rf"
  },
  "MRO": {
    "name": "Mauritanian ouguiya",
    "symbol": "MRU"
  },
  "MUR": {
    "name": "Mauritian rupee",
    "symbol": "₨"
  },
  "MXN": {
    "name": "Mexican peso",
    "symbol": "$"
  },
  "MDL": {
    "name": "Moldovan leu",
    "symbol": "L"
  },
  "MNT": {
    "name": "Mongolian tögrög",
    "symbol": "₮"
  },
  "MAD": {
    "name": "Moroccan Dirham",
    "symbol": "MAD"
  },
  "MZN": {
    "name": "Mozambican metical",
    "symbol": "MT"
  },
  "MMK": {
    "name": "Burmese kyat",
    "symbol": "K"
  },
  "NAD": {
    "name": "Namibian dollar",
    "symbol": "$"
  },
  "NPR": {
    "name": "Nepalese rupee",
    "symbol": "₨"
  },
  "NIO": {
    "name": "Nicaraguan córdoba",
    "symbol": "C$"
  },
  "NGN": {
    "name": "Nigerian naira",
    "symbol": "₦"
  },
  "KPW": {
    "name": "North Korean Won",
    "symbol": "₩"
  },
  "MKD": {
    "name": "Denar",
    "symbol": "ден"
  },
  "OMR": {
    "name": "Omani rial",
    "symbol": ".ع.ر"
  },
  "PKR": {
    "name": "Pakistani rupee",
    "symbol": "₨"
  },
  "PAB": {
    "name": "Panamanian balboa",
    "symbol": "B/."
  },
  "PGK": {
    "name": "Papua New Guinean kina",
    "symbol": "K"
  },
  "PYG": {
    "name": "Paraguayan guarani",
    "symbol": "₲"
  },
  "PEN": {
    "name": "Peruvian sol",
    "symbol": "S/."
  },
  "PHP": {
    "name": "Philippine peso",
    "symbol": "₱"
  },
  "PLN": {
    "name": "Polish złoty",
    "symbol": "zł"
  },
  "QAR": {
    "name": "Qatari riyal",
    "symbol": "ق.ر"
  },
  "RON": {
    "name": "Romanian leu",
    "symbol": "lei"
  },
  "RUB": {
    "name": "Russian ruble",
    "symbol": "₽"
  },
  "RWF": {
    "name": "Rwandan franc",
    "symbol": "FRw"
  },
  "SHP": {
    "name": "Saint Helena pound",
    "symbol": "£"
  },
  "WST": {
    "name": "Samoan tālā",
    "symbol": "SAT"
  },
  "STD": {
    "name": "Dobra",
    "symbol": "Db"
  },
  "SAR": {
    "name": "Saudi riyal",
    "symbol": "﷼"
  },
  "RSD": {
    "name": "Serbian dinar",
    "symbol": "din"
  },
  "SCR": {
    "name": "Seychellois rupee",
    "symbol": "SRe"
  },
  "SLL": {
    "name": "Sierra Leonean leone",
    "symbol": "Le"
  },
  "SGD": {
    "name": "Singapore dollar",
    "symbol": "$"
  },
  "SBD": {
    "name": "Solomon Islands dollar",
    "symbol": "Si$"
  },
  "SOS": {
    "name": "Somali shilling",
    "symbol": "Sh.so."
  },
  "ZAR": {
    "name": "South African rand",
    "symbol": "R"
  },
  "KRW": {
    "name": "Won",
    "symbol": "₩"
  },
  "SSP": {
    "name": "South Sudanese pound",
    "symbol": "£"
  },
  "LKR": {
    "name": "Sri Lankan rupee",
    "symbol": "Rs"
  },
  "SDG": {
    "name": "Sudanese pound",
    "symbol": ".س.ج"
  },
  "SRD": {
    "name": "Surinamese dollar",
    "symbol": "$"
  },
  "SEK": {
    "name": "Swedish krona",
    "symbol": "kr"
  },
  "SYP": {
    "name": "Syrian pound",
    "symbol": "LS"
  },
  "TWD": {
    "name": "New Taiwan dollar",
    "symbol": "$"
  },
  "TJS": {
    "name": "Tajikistani somoni",
    "symbol": "SM"
  },
  "TZS": {
    "name": "Tanzanian shilling",
    "symbol": "TSh"
  },
  "THB": {
    "name": "Thai baht",
    "symbol": "฿"
  },
  "BSD": {
    "name": "Bahamian dollar",
    "symbol": "B$"
  },
  "TOP": {
    "name": "Tongan paʻanga",
    "symbol": "$"
  },
  "TTD": {
    "name": "Trinidad and Tobago dollar",
    "symbol": "$"
  },
  "TND": {
    "name": "Tunisian dinar",
    "symbol": "ت.د"
  },
  "TRY": {
    "name": "Turkish lira",
    "symbol": "₺"
  },
  "TMT": {
    "name": "Turkmenistan manat",
    "symbol": "T"
  },
  "UGX": {
    "name": "Ugandan shilling",
    "symbol": "USh"
  },
  "UAH": {
    "name": "Ukrainian hryvnia",
    "symbol": "₴"
  },
  "AED": {
    "name": "United Arab Emirates dirham",
    "symbol": "إ.د"
  },
  "UYU": {
    "name": "Uruguayan peso",
    "symbol": "$"
  },
  "UZS": {
    "name": "Uzbekistani soʻm",
    "symbol": "лв"
  },
  "VUV": {
    "name": "Vanuatu vatu",
    "symbol": "VT"
  },
  "VES": {
    "name": "Bolívar",
    "symbol": "Bs"
  },
  "VND": {
    "name": "Vietnamese đồng",
    "symbol": "₫"
  },
  "YER": {
    "name": "Yemeni rial",
    "symbol": "﷼"
  },
  "ZMW": {
    "name": "Zambian kwacha",
    "symbol": "ZK"
  },
  "ZWL": {
    "name": "Zimbabwe Dollar",
    "symbol": "$"
  }
} as const;
