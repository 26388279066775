import { z } from './zod-openapi';

export const unsetSchema = z.undefined().nullable();

export type Unset = undefined | null;

export const scalarValueSchema = z.union([z.string(), z.number(), z.boolean()]);

export type ScalarValue = string | number | boolean;

export const scalarSchema = z.union([scalarValueSchema, unsetSchema]);

export type Scalar = ScalarValue | Unset;
