import { BlockType } from '../blocks';
import { PageRule, PageType } from './types';

export const pageTypeRules: Record<PageType, PageRule> = {
  [PageType.ARTIST]: {
    notAllowedBlocks: [BlockType.LOCATION, BlockType.ACCESSIBILITY],
    requiredBlocks: [
      BlockType.HEADING,
      BlockType.HEADER_IMAGE,
      BlockType.BODY_TEXT,
      BlockType.CONTENT_GRID, // Content grid 1 – Gigs
    ],
    defaultBlocks: [
      BlockType.SOCIAL_MEDIA,
      // BlockType.EMBED,
      BlockType.CONTENT_GRID, // Content grid 2 – Articles
      BlockType.CONTENT_GRID, // Content grid 3 – Products
    ],
    specificBlockOrder: [],
  },
  [PageType.ARTICLE]: {
    notAllowedBlocks: [BlockType.ACCESSIBILITY],
    requiredBlocks: [BlockType.HEADING, BlockType.HEADER_IMAGE, BlockType.BODY_TEXT],
    defaultBlocks: [BlockType.SOCIAL_MEDIA, BlockType.CONTENT_GRID],
    specificBlockOrder: [BlockType.HEADING, BlockType.HEADER_IMAGE, BlockType.BODY_TEXT],
  },
  [PageType.INFO]: {
    requiredBlocks: [],
    defaultBlocks: [],
    specificBlockOrder: [],
  },
  [PageType.LANDING]: {
    requiredBlocks: [],
    defaultBlocks: [],
    specificBlockOrder: [],
  },
  [PageType.VENUE]: {
    notAllowedBlocks: [],
    requiredBlocks: [
      BlockType.HEADING,
      BlockType.LOCATION,
      BlockType.BODY_TEXT,
      BlockType.CONTENT_GRID,
      BlockType.ACCESSIBILITY,
    ],
    defaultBlocks: [BlockType.HEADER_IMAGE, BlockType.SOCIAL_MEDIA],
    specificBlockOrder: [BlockType.HEADER_IMAGE],
  },
  [PageType.LOCATION]: {
    allowedBlocks: [BlockType.BODY_TEXT],
    requiredBlocks: [
      BlockType.HEADING,
      BlockType.CONTENT_GRID,
      // BlockType.IN_CONTENT_FILTER,
    ],
    defaultBlocks: [],
    specificBlockOrder: [BlockType.HEADING, BlockType.CONTENT_GRID],
  },
};
