import { z } from '../zod-openapi';

export const reservationStateList = ['reserved', 'pending', 'expired', 'failed', 'cancelling', 'cancelled'] as const;

export const reservationStateSchema = z
  .enum(reservationStateList)
  .openapi('reservationState', {
    description: 'A ticket reservation may be in one of these states at any given time.',
  });

export type ReservationState = z.infer<typeof reservationStateSchema>;
