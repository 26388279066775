/**
 * Requests defined in this file can be sent to Cart WebSocket.
 * Their responses will be of associated response type.
 */

import { paymentProvider, paymentResultSchema, paymentSchema } from '../payment';
import { reserveRequestItemSchema, reserveRequestProductSchema } from '../reservation';
import { ticketDraftSchema, ticketSchema } from '../ticket';
import { z } from '../zod-openapi';
import { cartDraftSchema, cartSchema, cartSetSchema } from './cart';
import { feeSchema } from './fee';
import { productDraftSchema } from './product';

/**
 * Names of actions you can request via Cart WebSocket.
 */
export const cartActions = [
  'setCart',
  'getCart',
  'deleteCart',
  'addTickets',
  'removeTickets',
  'setNameOnTicket',
  'startPayment',
  'updatePayment',
  'finishPayment',
  'addFees',
  'addProducts',
] as const;

export type CartAction = (typeof cartActions)[number];

export const cartActionEnumSchema = z.enum(cartActions, {
  description: 'Names of actions you can request via Cart WebSocket',
});

export const CartActionEnum = cartActionEnumSchema.enum;

export const cartActionObjectSchema = z.object({
  action: cartActionEnumSchema,
});

export const cartSetRequestSchema = z.object({
  action: cartActionEnumSchema.extract(['setCart']),
  cart: cartSetSchema,
});

export const cartGetRequestSchema = z.object({
  action: cartActionEnumSchema.extract(['getCart']),
});

export const cartDeleteRequestSchema = z.object({
  action: cartActionEnumSchema.extract(['deleteCart']),
});

export const cartTicketsRequestItemSchema = z.union([reserveRequestItemSchema, ticketDraftSchema]);

export const cartProductsRequestItemSchema = z.union([reserveRequestProductSchema, productDraftSchema]);

export type CartProductsRequestItem = z.infer<typeof cartProductsRequestItemSchema>;
export type CartTicketsRequestItem = z.infer<typeof cartTicketsRequestItemSchema>;

export const cartAddFeesSchema = z.object({
  action: cartActionEnumSchema.extract(['addFees']),
  fees: z.array(feeSchema),
});

export type CartFeesRequestItem = z.infer<typeof feeSchema>;

export const cartAddTicketsRequestSchema = z
  .object({
    action: cartActionEnumSchema.extract(['addTickets']),
    tickets: z.array(cartTicketsRequestItemSchema),
  })
  .strict();

export const cartAddProductsRequestSchema = z
  .object({
    action: cartActionEnumSchema.extract(['addProducts']),
    products: z.array(cartProductsRequestItemSchema),
  })
  .strict();

export const cartRemoveTicketsRequestSchema = z
  .object({
    action: cartActionEnumSchema.extract(['removeTickets']),
    tickets: z.array(ticketSchema),
  })
  .strict();

export const cartSetNameOnTicketRequestSchema = z
  .object({
    action: cartActionEnumSchema.extract(['setNameOnTicket']),
    ticket: ticketSchema,
  })
  .strict();

export const startPaymentParametersSchema = z
  .object({
    provider: paymentProvider,
    browserReturnOnSuccessUrl: z.string().url().openapi({
      description:
        'Browser location after successful payment. Placeholders {orderId} and {paymentId} will be replaced.',
    }),
    browserReturnOnFailureUrl: z.string().url().openapi({
      description: 'Browser location after failed payment.  Placeholders {orderId} and {paymentId} will be replaced.',
    }),
  })
  .strict();

export const cartStartPaymentSchema = z
  .object({
    action: cartActionEnumSchema.extract(['startPayment']),
  })
  .merge(startPaymentParametersSchema);

export type StartPaymentParameters = z.infer<typeof startPaymentParametersSchema>;

export const cartUpdatePaymentSchema = z.object({
  action: cartActionEnumSchema.extract(['updatePayment']),
  payment: paymentSchema.pick({ id: true, selectedProviderId: true, state: true }),
});

export const cartFinishPaymentSchema = z.object({
  action: cartActionEnumSchema.extract(['finishPayment']),
  result: paymentResultSchema,
});

export const cartRequestCommonSchema = z.union([
  cartSetRequestSchema,
  cartGetRequestSchema,
  cartDeleteRequestSchema,
  cartAddTicketsRequestSchema,
  cartAddProductsRequestSchema,
  cartSetNameOnTicketRequestSchema,
  cartStartPaymentSchema,
  cartUpdatePaymentSchema,
  cartFinishPaymentSchema,
  cartAddFeesSchema,
]);

export const cartRequestSchema = z.union([cartRequestCommonSchema, cartRemoveTicketsRequestSchema]);

export type CartRequest = z.infer<typeof cartRequestSchema>;

export const cartRequestSchemas = {
  setCart: cartSetRequestSchema,
  getCart: cartGetRequestSchema,
  deleteCart: cartDeleteRequestSchema,
  addTickets: cartAddTicketsRequestSchema,
  addProducts: cartAddProductsRequestSchema,
  removeTickets: cartRemoveTicketsRequestSchema,
  setNameOnTicket: cartSetNameOnTicketRequestSchema,
  startPayment: cartStartPaymentSchema,
  updatePayment: cartUpdatePaymentSchema,
  finishPayment: cartFinishPaymentSchema,
  addFees: cartAddFeesSchema,
};

export const cartFetchResponseSchema = z
  .object({
    success: z.literal(true),
    data: z.object({ cart: cartDraftSchema }),
  })
  .strict();

export const cartRequestHandledResponseSchema = z
  .object({
    success: z.boolean(),
  })
  .strict();

export const cartTicketsResponseSchema = z
  .object({
    success: z.literal(true),
    data: cartSchema.pick({ tickets: true, totalPrice: true, totalPriceUnpaid: true }),
  })
  .strict();

export const cartUpdateReservationsResponseSchema = cartTicketsResponseSchema.extend({
  action: z.literal('updateReservations'),
});

export type CartUpdateReservationsResponse = z.infer<typeof cartUpdateReservationsResponseSchema>;

export const cartProductsResponseSchema = z
  .object({
    success: z.literal(true),
    data: cartSchema.pick({ products: true, totalPrice: true, totalPriceUnpaid: true }),
  })
  .strict();

export const cartFeesResponseSchema = z
  .object({
    success: z.literal(true),
    data: cartSchema.pick({ fees: true, totalPrice: true, totalPriceUnpaid: true }),
  })
  .strict();
