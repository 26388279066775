import { z } from 'zod';
import { BlockType } from '../enum';
import { CustomBlock } from '../schema/blocks';

export const baseBlockSchema = z
  .object({
    id: z.string().optional(),
    blockId: z.string().optional(),
    type: z.nativeEnum(BlockType),
    order: z.number(),
    organizationId: z.string(),
    settings: z.object({
      actions: z.array(z.string()),
      schedule_start_date: z.string(),
      schedule_end_date: z.string(),
    }),
  })
  .refine((data) => data.id || data.blockId, "Either 'id' or 'blockId' is required");

type BlockSchema = z.ZodType<CustomBlock>;
const blockSchema: z.ZodType = z.lazy(() => combinedBlockSchema);

const subItemSchema: BlockSchema = blockSchema;

const listItemSchema = z.object({
  trigger: z.string().min(1, 'Trigger text is required'),
  order: z.number(),
  subItems: z.array(subItemSchema).nonempty('At least one nested block is required'),
});

const formFieldSchema = z.object({
  type: z.string().min(1, 'Field type is required'),
  label: z.string().min(1, 'Field label is required'),
  order: z.number(),
  required: z.boolean().optional(),
  placeholder: z.string().optional(),
});

const imageContentSchema = z.object({
  imageUrl: z.string().min(1, 'Image source is required'),
  alt_text: z.string().min(1, 'Alt text is required'),
  position: z.string(),
  filename: z.string().min(1, 'Filename is required'),
  folder: z.string().min(1, 'Folder is required'),
  image_size: z.string(),
  isImageNew: z.boolean(),
});

const imageSettingsSchema = z.object({
  alt: z.string().optional(),
  title: z.string().optional(),
  actions: z.array(z.string()).optional(),
  style: z
    .object({
      presentation: z.string().optional(),
      aspectRatio: z.string().optional(),
      itemsCount: z.number().optional(),
      caption: z.string().optional(),
    })
    .optional(),
});

const contentGridItemSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  description: z.string().min(1, 'Description is required'),
  imageUrl: z.string().optional(),
});

const socialMediaLinkSchema = z.object({
  platform: z.string().min(1, 'Platform is required'),
  url: z.string().url('Invalid URL format'),
  order: z.number(),
});

const accessibilityItemSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  description: z.string().min(1, 'Description is required'),
  status: z.number().min(0, 'Status is required'),
  additionalInfo: z.object({
    en: z.string().optional(),
    fi: z.string().optional(),
    sv: z.string().optional(),
  }),
});

const validateHtmlContent = (text: string, fieldName: string = 'Text') => {
  const textWithoutTags = text.replace(/<[^>]*>/g, '').trim();
  return {
    success: textWithoutTags.length > 0,
    message: `${fieldName} cannot be empty or contain only HTML tags`,
  };
};

const bodyTextSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.BODY_TEXT),
  content: z.object({
    text: z
      .string()
      .min(1, 'Body text is required')
      .refine((text) => validateHtmlContent(text, 'Body text').success, {
        message: 'Body text cannot be empty or contain only HTML tags',
      }),
  }),
});

const headingSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.HEADING),
  content: z.object({
    text: z
      .string()
      .min(1, 'Heading text is required')
      .refine((text) => validateHtmlContent(text, 'Heading text').success, {
        message: 'Heading text cannot be empty or contain only HTML tags',
      }),
  }),
});

const accordionSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.ACCORDION),
  content: z.object({
    items: z.array(listItemSchema).nonempty('Accordion must have at least one item'),
  }),
});

const tabsSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.TABS),
  content: z.object({
    items: z.array(listItemSchema).nonempty('Tabs must have at least one item'),
  }),
});

const imageSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.IMAGE),
  content: imageContentSchema,
});

const videoSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.VIDEO),
  content: z.object({
    platform: z.string().min(1, 'Platform is required'),
    videoId: z.string().min(1, 'Video ID is required'),
  }),
});

const formSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.FORM),
  content: z.object({
    form_fields: z.array(formFieldSchema).nonempty('Form must have at least one field'),
  }),
});

const carouselSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.CAROUSEL),
  content: z.object({
    images: z
      .array(
        z.object({
          content: imageContentSchema,
          settings: imageSettingsSchema.optional(),
        }),
      )
      .nonempty('Carousel must have at least one image'),
  }),
});

const listBlockSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.LIST_BLOCK),
  content: z.object({
    text: z
      .string()
      .min(1, 'List items are required')
      .refine((text) => validateHtmlContent(text, 'List items').success, {
        message: 'List items cannot be empty or contain only HTML tags',
      }),
  }),
});

const contentGridSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.CONTENT_GRID),
  content: z.object({
    items: z.array(contentGridItemSchema).nonempty('Content grid must have at least one item'),
  }),
});

const countdownSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.COUNTDOWN),
  content: z.object({
    endDate: z.string().min(1, 'End date is required'),
    endTime: z.string().min(1, 'End time is required'),
  }),
});

const dividerSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.DIVIDER),
  content: z.object({}).optional(),
});

const headerImageSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.HEADER_IMAGE),
  content: imageContentSchema,
});

const imageGallerySchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.IMAGE_GALLERY),
  content: z.object({
    images: z
      .array(
        z.object({
          content: imageContentSchema,
          settings: imageSettingsSchema.optional(),
        }),
      )
      .nonempty('Gallery must have at least one image'),
  }),
});

const linksSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.LINKS),
  content: z.object({
    text: z
      .string()
      .min(1, 'Link text is required')
      .refine((text) => validateHtmlContent(text, 'Link text').success, {
        message: 'Link text cannot be empty or contain only HTML tags',
      }),
  }),
});

const socialMediaSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.SOCIAL_MEDIA),
  content: z.object({
    social_media_links: z.array(socialMediaLinkSchema).nonempty('Social media block must have at least one link'),
  }),
});

const locationSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.LOCATION),
  content: z.object({
    address: z.string().min(1, 'Address is required'),
    city: z.string().min(1, 'City is required'),
    zipCode: z.string().min(1, 'Zip code is required'),
    country: z.string().min(1, 'Country is required'),
  }),
});

const columnsSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.COLUMNS),
  content: z.object({
    items: z.array(listItemSchema).nonempty('Columns must have at least one item'),
  }),
});

const accessibilitySchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.ACCESSIBILITY),
  content: z.object({
    items: z.array(accessibilityItemSchema).nonempty('Accessibility block must have at least one item'),
  }),
});

const purchaseBlockSchema = baseBlockSchema._def.schema.extend({
  type: z.literal(BlockType.PURCHASE_BLOCK),
  settings: z.object({
    bentoVersion: z.string().min(1, 'Bento version is required'),
    rowsCount: z.number().min(1, 'Rows count must be at least 1'),
    colsCount: z.number().min(1, 'Columns count must be at least 1'),
    textPosition: z.string().min(1, 'Text position is required'),
    type: z.string().min(1, 'Type is required'),
    contentType: z.string().min(1, 'Content type is required'),
    purchaseBlockTitle: z.boolean(),
    purchaseBlockVenue: z.boolean(),
    purchaseBlockDate: z.boolean(),
    purchaseBlockTrafficLight: z.boolean(),
    purchaseBlockTicketSelector: z.boolean(),
  }),
});

export const combinedBlockSchema = z.discriminatedUnion('type', [
  bodyTextSchema,
  headingSchema,
  accordionSchema,
  tabsSchema,
  imageSchema,
  videoSchema,
  formSchema,
  carouselSchema,
  listBlockSchema,
  contentGridSchema,
  countdownSchema,
  dividerSchema,
  headerImageSchema,
  imageGallerySchema,
  linksSchema,
  socialMediaSchema,
  locationSchema,
  columnsSchema,
  accessibilitySchema,
  purchaseBlockSchema,
]);
