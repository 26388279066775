import { languageTags } from './generated/languages';
import { z } from '../zod-openapi';

export * from './generated/languages';

export const languageTagSchema = z.enum(languageTags).openapi('LanguageTag', { example: 'fi' });
export const localizationSchema = z.record(languageTagSchema, z.string());

export type LanguageTag = z.infer<typeof languageTagSchema>;
export type Localization = z.infer<typeof localizationSchema>;
