import { z } from '../zod-openapi';
import { countryCodeSchema, localizationSchema } from '../world';

export const citySchema = z.object({
  id: z.number().int().openapi({ example: 123 }),
  countryCode: countryCodeSchema,
  name: localizationSchema.openapi({ example: { fi: 'Helsinki', sv: 'Helsingfors' } }),
});

export type City = z.infer<typeof citySchema>;

export const cityQuerySchema = z.object({
  countryCode: countryCodeSchema.optional(),
  name: z.string().openapi({ description: 'Partial name of city to search for', example: 'Hel' }),
});

export type CityQuery = z.infer<typeof cityQuerySchema>;

export const citiesResultSchema = z.object({
  cities: z.array(citySchema).openapi({ description: 'List of cities' }),
});

export type CitiesResult = z.infer<typeof citiesResultSchema>;
