import { accessControlSchema } from '../accessControl';
import { postalAddressWithIdsSchema } from '../address';
import { ContactCardDraftSchema, ContactCardSchema } from '../card';
import { getIdValueSchema } from '../id';
import { lengthAwarePaginationSchema } from '../pagination';
import { Tag, TagSchema } from '../tag';
import { z } from '../zod-openapi';

/**
 * State of Organization object. Transitions are limited.
 */

export interface IOrganizationSelects {
  intermediaries: Tag[];
  serviceLevels: Tag[];
  salesTargets: Tag[];
  billingCycles: Tag[];
  customerClasses: Tag[];
  priceGroups: Tag[];
}

export interface IOrganizationTags {
  customerGroups: Tag[];
  organizationTypes: Tag[];
  contactRoles: Tag[];
}

export const organizationCreateContractSchema = z.object({
  name: z.string(),
  asset: z.object({
    id: z.string(),
    name: z.string(),
    url: z.string(),
  }),
  contractType: z.enum(['PERMANENT', 'FIXED_TERM_UNTIL_PERMANENT', 'FIXED_TERM']),
  contractStartDate: z.string().nullable(),
  contractEndDate: z.string().nullable(),
  contractTerminationPeriod: z.number().nullable(),
});

export const organizationContractSchema = z
  .object({
    id: z.string(),
  })
  .merge(organizationCreateContractSchema);

export type organizationContract = z.infer<typeof organizationContractSchema>;
export type organizationCreateContract = z.infer<typeof organizationCreateContractSchema>;

const websiteSchema = z.object({ id: z.string(), url: z.string() });
const unofficialNameSchema = z.object({ id: z.string(), name: z.string() });

export enum OrganizationSettingsSettlementInterval {
  afterEvent = 'AFTEREVENT',
  weekly = 'WEEKLY',
  monthly = 'MONTHLY',
  afterSeason = 'AFTERSEASON',
  quartely = 'QUARTERLY',
  everySixMonths = 'EVERYSIXMONTHS',
  annually = 'ANNUALLY',
}

export enum OrganizationSettingsInvoiceDeductionSettings {
  deducted = 'DEDUCTED',
  separately = 'SEPARATELY',
}

export enum OrganizationSettingsAdvanceSettlementInterval {
  monthly = 'MONTHLY',
  separately = 'UPONREQUEST',
}

const settlementInterval = z.nativeEnum(OrganizationSettingsSettlementInterval);

export const organizationSettlementSchema = z
  .object({
    id: getIdValueSchema(8),
    settlementInterval,
    referenceNumber: z.string(),
    referenceText: z.string(),
    referenceAddedPerSettlement: z.boolean(),
    invoiceDeductionSettings: z.nativeEnum(OrganizationSettingsInvoiceDeductionSettings),
    advanceSettlementInterval: z.nativeEnum(OrganizationSettingsAdvanceSettlementInterval),
  })
  .partial();

export type OrganizationSettlement = z.infer<typeof organizationSettlementSchema>;

export const organizationAlwaysSchema = z.object({
  businessId: z.string().min(1),
  name: z.string().min(1),
  unofficialNames: z.array(unofficialNameSchema).nullable().optional(),
  description: z.string().optional(),
  oldId: z.number().nullable().optional(),
  additionalInfo: z.string().nullable().optional(),
  vatId: z.string().nullable().optional(),
  updatedAt: z.date().or(z.string()).optional(),
  createdAt: z.date().or(z.string()).optional(),
  iban: z.string().nullable().optional(),
  bic: z.string().nullable().optional(),
  eInvoicingAddress: z.string().nullable().optional(),
  eInvoiceIntermediaryTagId: z.string().nullable().optional(),
  website: z.array(websiteSchema).nullable().optional(),
  accountManagerId: z.string().nullable().optional(),
  serviceLevelTagId: z.string().nullable().optional(),
  salesTargetTagId: z.string().nullable().optional(),
  billingCycleTagId: z.string().nullable().optional(),
  customerClassTagId: z.string().nullable().optional(),
  organizationTypes: z.array(TagSchema).nullable().optional(),
  customerGroups: z.array(TagSchema).nullable().optional(),
  contracts: z.array(organizationContractSchema).nullable().optional(),
  lockPriceLists: z.boolean().optional(),
  refunds: z.boolean().optional(),
  address: postalAddressWithIdsSchema,
  bankAddress: postalAddressWithIdsSchema.nullable().optional(),
  defaultVenueId: z.string().nullable().optional(),
  accessControl: accessControlSchema.nullable(),
  settlements: z.array(organizationSettlementSchema).optional(),
});

export const organizationDraftSchema = organizationAlwaysSchema
  .merge(
    z.object({
      contactCards: z.array(ContactCardDraftSchema).nullable().optional(),
    }),
  )
  .strict();

export type OrganizationDraft = z.infer<typeof organizationDraftSchema>;

export const organizationSchema = organizationAlwaysSchema
  .merge(
    z.object({
      id: getIdValueSchema(8),
      contactCards: z.array(ContactCardSchema).nullable().optional(),
      isActive: z.boolean(),
    }),
  )
  .strict();
export type Organization = z.infer<typeof organizationSchema>;

export const organizationsListSchema = z.object({
  organizations: z.array(organizationSchema.partial()),
  pagination: lengthAwarePaginationSchema,
});

export type OrganizationsList = z.infer<typeof organizationsListSchema>;
