import { z } from '../zod-openapi';

export const SUPPORTED_LANGUAGE_CODES = ['en', 'fi', 'sv'] as const;

export const TranslateResponseSchema = z.record(z.string(), z.string()).openapi({
  example: { en: 'Example text' },
  description: 'translated string object with language codes as keys',
});

export type TranslateResponse = z.infer<typeof TranslateResponseSchema>;

export const TranslateRequestSchema = z
  .object({
    content: z.string(),
    targetLanguages: z.array(z.enum(SUPPORTED_LANGUAGE_CODES)).min(1),
    sourceLanguage: z.enum(SUPPORTED_LANGUAGE_CODES),
  })
  .openapi({
    example: {
      content: 'Example text',
      targetLanguages: SUPPORTED_LANGUAGE_CODES.filter((lang) => lang !== SUPPORTED_LANGUAGE_CODES[0]),
      sourceLanguage: SUPPORTED_LANGUAGE_CODES[0],
    },
    description: 'Request to translate content to target languages',
  });

export type TranslateRequest = z.infer<typeof TranslateRequestSchema>;
