import { priceSchema } from '../price';
import { z } from '../zod-openapi';

export const feeTypes = ['order', 'ticket', 'product'] as const;

const feeSchemaBase = z.object({
  id: z.string().optional(),
  price: priceSchema.optional(),
  orderId: z.string(),
  typeId: z.string(),
  type: z.enum(feeTypes),
});

export const feeSchema = feeSchemaBase.omit({ id: true }).merge(z.object({ id: z.string() }));

export const feeSchemaDraft = feeSchemaBase;

export type FeeDraft = z.infer<typeof feeSchemaDraft>;
export type Fee = z.infer<typeof feeSchema>;
