import { accessControlSchema } from '../accessControl';
import { AccessibilityDescription, AccessibilityStatus } from '../accessibility';
import { postalAddressWithIdsSchema } from '../address';
import { localizedString, requestListQuerySchema } from '../common/request';
import { getIdAndValueSchema } from '../id';
import { lengthAwarePaginationSchema } from '../pagination';
import { z } from '../zod-openapi';

export enum VenueAreaVisibilityEnum {
  PUBLIC = 'PUBLIC',
  HIDDEN = 'HIDDEN',
  ARCHIVED = 'ARCHIVED',
}

export enum VenueState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export interface SeatMapConfig {
  workspaceKey: string;
  eventKey: string;
  secretKey: string;
  chartKey: string;
}

export const [venueIdSchema, venueIdValueSchema] = getIdAndValueSchema('venue', 8);

export const [venueAreaIdSchema, venueAreaIdValueSchema] = getIdAndValueSchema('venueArea', 8);

export const venueAreaSingleSchema = z.object({
  id: venueAreaIdValueSchema,
  venueId: venueIdValueSchema,
  name: z.string().openapi({ description: 'Mandatory name of the premise', example: 'left balcony' }),
  since: z.string().datetime().openapi({
    description:
      'When the premise became or becomes available. Set to creation time or past when adding a currently active venue.',
  }),
  until: z.string().datetime().or(z.null()).optional().openapi({
    description:
      'When the premise became or becomes unavailable. Do not set unless the venue is known to be unavailable beginning at a certain point in time.',
  }),
});

export type VenueAreaSingle = z.infer<typeof venueAreaSingleSchema> & {
  contains: VenueAreaSingle[];
};

export const venueAreaSchema: z.ZodType<VenueAreaSingle> = venueAreaSingleSchema.extend({
  contains: z.lazy(() => z.array(venueAreaSchema)).openapi({ type: 'array', description: 'list of inner venue areas' }),
});

export const venueAreaSettingsSchema = z.object({
  id: venueAreaIdValueSchema,
  venueId: venueIdValueSchema,
  visibility: z.nativeEnum(VenueAreaVisibilityEnum),
  additionalInfo: z.string().nullable(),
});

export type VenueAreaSettings = z.infer<typeof venueAreaSettingsSchema>;

export const venueSeatMapDataSchema = z.object({
  id: z.string().optional(),
  chartKey: z.string(),
  capacity: z.number(),
  venueSeatMapId: z.string(),
});

export const venueSeatMapSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  venueId: venueIdValueSchema,
  additionalInfo: z.string().nullable(),
  seatMapData: venueSeatMapDataSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const venueSeatMapsWithPaginationSchema = z.object({
  data: z.array(venueSeatMapSchema),
  pagination: lengthAwarePaginationSchema,
});
export type VenueSeatMapsWithPagination = z.infer<typeof venueSeatMapsWithPaginationSchema>;

export type VenueSeatMap = z.infer<typeof venueSeatMapSchema>;

export type VenueArea = z.infer<typeof venueAreaSchema>;

const completeAccessibilitySchema = z.object({
  description: z.nativeEnum(AccessibilityDescription).openapi({
    example: AccessibilityDescription.STEP_FREE_ACCESS,
  }),
  status: z
    .nativeEnum(AccessibilityStatus)
    .openapi({
      example: AccessibilityStatus.AVAILABLE,
    })
    .nullable(),
  additionalInfo: localizedString.nullable(),
});

const accessibilitySchema = z.array(completeAccessibilitySchema);

export const venueSchema = z
  .object({
    id: venueIdValueSchema,
    oldId: z.number().int().or(z.null()).optional().openapi({ description: 'Id of the venue in legacy database' }),
    name: localizedString,
    status: z.nativeEnum(VenueState).default(VenueState.PUBLISHED).optional().openapi({ example: VenueState.DRAFT }),
    phone: z.string().optional().nullable(),
    email: z.string().optional().nullable(),
    address: postalAddressWithIdsSchema,
    websiteUrl: z.string().optional().nullable(),
    unofficialNames: z
      .array(z.object({ id: z.string(), name: z.string() }))
      .nullable()
      .optional(),
    area: venueAreaSchema
      .openapi({
        description: 'Mandatory full area of venue with total capacity, possibly containing multiple smaller areas',
      })
      .optional(),
    seatMaps: venueSeatMapsWithPaginationSchema.optional(),
    accessibility: accessibilitySchema.nullable().optional(),
    settings: venueAreaSettingsSchema,
    createdAt: z.coerce.date().optional(),
    updatedAt: z.coerce.date().optional(),
    accessControl: accessControlSchema.optional(),
  })
  .strict();

// List entry schema with just seatMapIds
export const venueListEntrySchema = venueSchema
  .merge(venueSchema.pick({ oldId: true, address: true, area: true }).partial())
  .extend({
    seatMapIds: z.array(z.string()).optional(),
  })
  .omit({ seatMaps: true })
  .strict();

export type Venue = z.infer<typeof venueSchema>;

export type VenueListEntry = z.infer<typeof venueListEntrySchema>;

export const venueListSchema = z.array(venueListEntrySchema);

export type VenueList = z.infer<typeof venueListSchema>;

export const venueListObjectSchema = z.object({ venues: venueListSchema, pagination: lengthAwarePaginationSchema });

export type VenueListObjectSchema = z.infer<typeof venueListObjectSchema>;

export const venueGetObjectParamsSchema = z.object({
  venueId: venueIdValueSchema,
});
export type VenueGetObjectParams = z.infer<typeof venueGetObjectParamsSchema>;
export const venueGetObjectQuerySchema = requestListQuerySchema
  .extend({
    includeSeatMaps: z.string(),
  })
  .partial();

export type VenueGetObjectQuery = z.infer<typeof venueGetObjectQuerySchema>;

export interface VenueAreaCreate {
  name: string;
  since: Date;
  until?: Date | null;
  contains?: VenueAreaCreate[];
}
const venueAreaSingleCreateSchema = venueAreaSingleSchema.omit({ id: true, venueId: true }).extend({
  since: z.coerce.date(),
  until: z.null().or(z.coerce.date()),
});

export const venueAreaCreateSchema: z.ZodType<VenueAreaCreate> = venueAreaSingleCreateSchema.extend({
  contains: z
    .lazy(() => z.array(venueAreaCreateSchema))
    .optional()
    .default([])
    .openapi({ type: 'array', description: 'list of inner venue areas' }),
});

export const venueCreateObjectSchema = venueSchema
  .omit({ id: true, createdAt: true, updatedAt: true, area: true, settings: true })
  .extend({
    seatMaps: z
      .array(
        venueSeatMapSchema.omit({ venueId: true, seatMapData: true, createdAt: true, updatedAt: true }).extend({
          seatMapData: venueSeatMapDataSchema.pick({ chartKey: true }),
        }),
      )
      .optional(),
    settings: venueAreaSettingsSchema.partial().optional(),
    area: venueAreaCreateSchema.optional(),
  });

export type VenueCreateObject = z.infer<typeof venueCreateObjectSchema>;
export type AugmentedVenueCreateObject = Omit<VenueCreateObject, 'seatMaps'> & {
  seatMaps?: Array<VenueSeatMap>;
};

export const venueUpdateObjectParamsSchema = z.object({
  venueId: venueIdValueSchema,
});
export type VenueUpdateObjectParams = z.infer<typeof venueUpdateObjectParamsSchema>;

export interface VenueAreaUpdate {
  id?: string;
  name?: string;
  since?: Date;
  until?: Date | null;
  contains?: VenueAreaUpdate[];
}

// Create the schema for venue area updates
const venueAreaUpdateSchema: z.ZodType<VenueAreaUpdate> = z
  .object({
    id: venueAreaIdValueSchema.optional(),
    name: z.string().optional(),
    since: z.coerce.date().optional(),
    until: z.null().or(z.coerce.date()),
  })
  .extend({
    contains: z
      .lazy(() => z.array(venueAreaUpdateSchema))
      .optional()
      .default([])
      .openapi({ type: 'array', description: 'list of inner venue areas' }),
  });

export const venueUpdateObjectSchema = venueSchema
  .extend({
    seatMaps: z
      .array(
        venueSeatMapSchema.omit({ venueId: true, seatMapData: true, createdAt: true, updatedAt: true }).extend({
          seatMapData: venueSeatMapDataSchema.pick({ chartKey: true }),
          venueId: z.string().optional(),
        }),
      )
      .optional(),
    settings: venueAreaSettingsSchema.partial().optional(),
    area: venueAreaUpdateSchema.optional(),
  })
  .partial();

export type VenueUpdateObject = z.infer<typeof venueUpdateObjectSchema>;

export type AugmentedVenueUpdateObject = Omit<VenueUpdateObject, 'seatMaps'> & {
  seatMaps?: Array<VenueSeatMap>;
};

export const venueRemoveObjectParamsSchema = z.object({
  venueId: venueIdValueSchema,
});
export type VenueRemoveObjectParams = z.infer<typeof venueRemoveObjectParamsSchema>;
