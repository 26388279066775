import { paymentsSchema } from '../payment';
import { ticketSchema } from '../ticket';
import { currencyCodeSchema } from '../world';
import { z } from '../zod-openapi';
import { customerDraftSchema } from './customer';
import { deliveryDraftSchema, deliverySchema } from './delivery';
import { feeSchemaDraft } from './fee';
import { productSchema } from './product';
import { cartIdValueSchema } from './id';

export const giftCardSchema = z.object({ code: z.string() }).openapi({ title: 'Gift Card' });

export type GiftCard = z.infer<typeof giftCardSchema>;

export const cartOrderSchema = z
  .object({
    id: z.string().openapi({ description: 'nanoId of associated Order once created' }),
  })
  .merge(paymentsSchema.openapi({ description: 'All payment attempts started from this Cart' }))
  .openapi({ description: 'Order information necessary to complete purchase payment once started' });

export type CartOrder = z.infer<typeof cartOrderSchema>;

export const cartSchema = z
  .object({
    id: cartIdValueSchema,
    validUntilSeconds: z.number(),
    customer: customerDraftSchema.optional(),
    currency: currencyCodeSchema,
    tickets: z.array(ticketSchema),
    products: z.array(productSchema),
    fees: z.array(feeSchemaDraft),
    delivery: deliverySchema,
    usedGiftCards: z.array(giftCardSchema),
    order: cartOrderSchema.optional(),
    storedAt: z.string().optional(),
    totalPrice: z.number().int().openapi({ description: 'price in smallest fractions of `currency`', example: 1255 }),
    totalPriceUnpaid: z.number().int().openapi({
      description: 'price remaining to be paid in smallest fractions of `currency`',
      example: 1255,
    }),
  })
  .openapi({ title: 'Cart', description: 'Cart is used to collect information necessary to create an Order.' });

export const cartWithOrderSchema = cartSchema.omit({ order: true }).extend({
  order: cartOrderSchema,
});
export type CartWithOrder = z.infer<typeof cartWithOrderSchema>;

export const cartCompleteSchema = cartWithOrderSchema;

export type CartComplete = z.infer<typeof cartCompleteSchema>;
export type Cart = z.infer<typeof cartSchema>;

export const cartDraftSchema = cartSchema
  .merge(
    z.object({
      delivery: deliveryDraftSchema,
    }),
  )
  .strict()
  .openapi('CartDraft', {
    description:
      'Used to gradually collect information for an order. Whole cart or some parts of it may eventually expire.',
  });

export type CartDraft = z.infer<typeof cartDraftSchema>;

export const cartSetSchema = cartDraftSchema.omit({
  tickets: true,
  fees: true,
  products: true,
  totalPrice: true,
  totalPriceUnpaid: true,
  order: true,
});

export type CartSet = z.infer<typeof cartSetSchema>;

export const cartCreateSchema = cartSetSchema.omit({ id: true });

export type CartCreate = z.infer<typeof cartCreateSchema>;
