import { postalAddressWithIdsSchema } from '../address';
import { z } from '../zod-openapi';

export const organizationOfJurisdictionFiSchema = z
  .object({
    businessId: z.string(),
    name: z.string(),
    www: z.string().optional(),
    vatId: z.string().optional(),
    address: postalAddressWithIdsSchema.omit({ cityId: true, countryIso2: true, country: true }).merge(
      z.object({
        countryIso2: z.literal('FI'),
        country: z.union([z.literal('Suomi'), z.literal('Finland')]),
        cityId: z.number().int().optional(),
      }),
    ),
  })
  .strict();

export const organizationOfJurisdictionSchema = z.union([
  z
    .object({
      jurisdiction: z.literal('FI'),
      organization: organizationOfJurisdictionFiSchema,
    })
    .strict(),
  z
    .object({
      jurisdiction: z.literal('XX'), // union requires at least 2 variants, so this serves as a placeholder for the next option to add.
      organization: organizationOfJurisdictionFiSchema,
    })
    .strict(),
]);

export type OrganizationOfJurisdiction = z.infer<typeof organizationOfJurisdictionSchema>;

export const organizationOfJurisdictionParamSchema = z
  .object({
    countryCode: z.enum(['FI']),
    businessId: z.string(),
  })
  .strict();

export type OrganizationOfJurisdictionParam = z.infer<typeof organizationOfJurisdictionParamSchema>;
