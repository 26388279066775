import { cartSchema, customerSchema, deliverySchema, feeSchema, productSchema } from '../cart';
import { environmentNameSchema } from '../configuration';
import { timestampSchema } from '../date';
import { getIdValueSchema } from '../id';
import { paymentsSchema } from '../payment';
import { z } from '../zod-openapi';
import { orderIdSchema } from './id';

export type OrderIdParameter = z.infer<typeof orderIdSchema>;

const cartWithoutOrderSchema = cartSchema.omit({ order: true });

export const orderEnvSchema = z.object({
  ENVIRONMENT: environmentNameSchema.default('dev'),
  DATABASE_URL: z.string().default(''),
  PAYTRAIL_API_URL: z.string().default(''),
  PAYTRAIL_CALLBACK_SUCCESS_URL: z.string().default(''),
  PAYTRAIL_CALLBACK_CANCEL_URL: z.string().default(''),
  PAYTRAIL_MERCHANT_ID: z.string().default(''),
  PAYTRAIL_SECRET_KEY: z.string().default(''),
  LEGACY_ORDER_API_URL: z.string(),
  LEGACY_ORDER_API_KEY: z.string(),
  LEGACY_ORDER_API_SALT: z.string(),
  LEGACY_ORDER_API_HASH: z.string(),
});

export type OrderEnv = z.infer<typeof orderEnvSchema>;

export const orderSchema = cartWithoutOrderSchema
  .merge(z.object({ created: z.coerce.date().optional(), deleted: z.coerce.date().optional() }))
  .merge(paymentsSchema)
  .openapi({
    description: 'WIP order has some properties like Cart but also prices, payments, delivery, refunds, ...',
  });

export type Order = z.infer<typeof orderSchema>;

export const orderDBOSchema = z
  .object({
    id: getIdValueSchema(8),
    created: timestampSchema,
    cart: cartWithoutOrderSchema,
    customer: customerSchema.optional(),
    delivery: deliverySchema.optional(),
    products: productSchema.optional(),
    fees: z.array(feeSchema).optional(),
  })
  .merge(paymentsSchema);

export type OrderDBO = z.infer<typeof orderDBOSchema>;
