import { z } from '../zod-openapi';

const logFunctionSchema = z.function().returns(z.void());

export const loggerSchema = z.object({
  trace: logFunctionSchema,
  debug: logFunctionSchema,
  log: logFunctionSchema,
  info: logFunctionSchema,
  warn: logFunctionSchema,
  error: logFunctionSchema,
});

type LogFunction = (...args: unknown[]) => void;

/**
 * Console or any other like object that logs any given objects.
 */
export interface Logger {
  trace: LogFunction;
  debug: LogFunction;
  log: LogFunction;
  info: LogFunction;
  warn: LogFunction;
  error: LogFunction;
}
