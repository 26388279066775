import { Tag } from '../tag';

export enum PageStatus {
  SCHEDULED = 'scheduled',
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
  ARCHIVED = 'archived',
}

export enum PageType {
  ARTIST = 'artist_page',
  ARTICLE = 'article',
  INFO = 'info_page',
  LANDING = 'landing_page',
  VENUE = 'venue_page',
  LOCATION = 'location_page',
}

export interface IPageTags {
  pageCategories: Tag[];
  pageTags: Tag[];
}

export interface PageRule {
  defaultBlocks: string[];
  requiredBlocks: string[];
  notAllowedBlocks?: string[];
  allowedBlocks?: string[];
  specificBlockOrder?: string[];
}

export interface PageValidationResult {
  valid: boolean;
  errors: string[];
}
