import { z } from '../zod-openapi';
import { getIdValueSchema } from '../id';

export const priceSchema = z.object({
  priceCentsWithVat: z.number().int().openapi({
    description: 'Price of a single purchase item or a sum of them in euro cents including taxes',
    example: 1255,
  }),
  priceCentsWithoutVat: z.number().int().openapi({
    description: 'Price of a single purchase item or a sum of them in euro cents excluding taxes',
    example: 1000,
  }),
  vat: z.object({
    id: getIdValueSchema(8),
    name: z.string().openapi({ example: '10 %' }),
    percentage: z.number().openapi({
      description:
        'Tax percentage where `100.0` equals original amount, so e.g. Finnish VAT at time of writing is `25.5` (not e.g. `0.255` or `125.5`)',
      example: 25.5,
    }),
  }),
});

export type Price = z.infer<typeof priceSchema>;
