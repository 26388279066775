import { z } from '../zod-openapi';

/**
 * AccessTargets = {
 *   AccessPathElementName: {
 *     allowRoles?: { // limits access to this exact access path to given roles [on given conditions]
 *       RoleCodeName: { useCaseCodeName: [conditionCodeName, ...], ... },
 *       ...
 *     }
 *     contains?: AccessTargets // elements within this one having their own limitations
 *   }
 * }
 */

const accessPathElementNameSchema = z.string().openapi({ description: 'Access path element name', example: '/onions' });
const roleCodeNameSchema = z.string().openapi({ description: 'roleCodeName', example: 'onionEater' });
const useCaseCodeName = z.string().openapi({ description: 'useCaseCodeName', example: 'eatOnions' });
const conditionCodeNameSchema = z.string().openapi({ description: 'conditionCodeName', example: 'areOnionsAvailable' });

export type ConditionCodeNamesList = string[];

const conditionCodeNamesListSchema = z.array(conditionCodeNameSchema);

export interface UseCaseConditionNames {
  [useCaseCodeName: string]: ConditionCodeNamesList;
}

export const useCaseConditionsSchema: z.ZodType<UseCaseConditionNames> = z
  .record(useCaseCodeName, conditionCodeNamesListSchema)
  .openapi({
    description:
      'Each key is a codename for a use case and points to a list of conditions that must pass for access to be allowed under that use case. Empty list denotes unconditional access.',
    example: { askForOnions: [], eatOnions: ['areOnionsAvailable'] },
  });

export interface RoleMap {
  [roleCodeName: string]: UseCaseConditionNames;
}

export const rolesWithConditionsSchema: z.ZodType<RoleMap> = z
  .record(roleCodeNameSchema, useCaseConditionsSchema)
  .openapi({
    description:
      'Each key is a codename for a role and points to a map of use cases with optional conditions under which access is allowed.',
    example: { onionEater: { askForOnions: [], eatOnions: ['areOnionsAvailable'] } },
  });

export interface AccessTarget {
  id: string;
  allowRoles: RoleMap;
}

const accessTargetSchema = z.object({ id: z.string(), allowRoles: rolesWithConditionsSchema }).openapi({
  description: 'Allows some roles optionally conditional access.',
  example: { id: 'NanoId08', allowRoles: { onionEater: { askForOnions: [], eatOnions: ['areOnionsAvailable'] } } },
});

export type AllowRolesWithConditions = z.infer<typeof accessTargetSchema>;
export type AccessTargetPathNode = { contains: AccessTargets };
export type AccessTargetOrPathNode = AccessTarget | AccessTargetPathNode | (AccessTarget & AccessTargetPathNode);
export type AccessTargets = Record<string, AccessTargetOrPathNode>;
export type AccessTargetsZodType = z.ZodType<AccessTargets>;

export const recursiveAccessTargetsSchema = z
  .lazy<AccessTargetsZodType>(() => accessTargetsSchema)
  .openapi({
    type: 'object', // OpenAPI survives recursive declaration by reference to registered component name
    properties: { contains: { $ref: '#/components/schemas/AccessTargets' } },
  });

const accessNodeSchema = z.object({
  contains: recursiveAccessTargetsSchema,
});

const accessTargetOrNodeSchema = z.union([
  accessTargetSchema,
  accessNodeSchema,
  accessTargetSchema.merge(accessNodeSchema),
]);

export const accessTargetsSchema: AccessTargetsZodType = z
  .record(accessPathElementNameSchema, accessTargetOrNodeSchema)
  .openapi('AccessTargets', {
    description:
      'Declares targets with access limited to given roles under optional conditions. They may contain other targets.',
    example: {
      '/onions': {
        id: 'NanoId8b',
        allowRoles: { onionEater: { askForOnions: [] } },
        contains: {
          '/eat': {
            id: 'NanoId08',
            allowRoles: { onionEater: { eatOnions: ['areOnionsAvailable'] } },
          },
        },
      },
    },
  });
