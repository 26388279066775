import { z } from 'zod';
import { localizedString } from '../common/request';

export enum AccessibilityStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  AVAILABILITY_UNKNOWN = 'AVAILABILITY UNKNOWN',
}

export enum AccessibilityDescription {
  STEP_FREE_ACCESS = 'STEP_FREE_ACCESS',
  ACCESSIBLE_ROUTES = 'ACCESSIBLE_ROUTES',
  ACCESSIBLE_TOILET = 'ACCESSIBLE_TOILET',
  FREE_ACCESS_ASSISTANT = 'FREE_ACCESS_ASSISTANT',
  MOBILITY_AIDS = 'MOBILITY_AIDS',
  VIEWING_PLATFORM = 'VIEWING_PLATFORM',
  INDUCTION_LOOP = 'INDUCTION_LOOP',
  TOILET_PASS = 'TOILET_PASS',
  WHEELCHAIR_TICKET = 'WHEELCHAIR_TICKET',
}
// event

export const accessibilityItemSchema = z.object({
  description: z.nativeEnum(AccessibilityDescription).openapi({
    example: AccessibilityDescription.STEP_FREE_ACCESS,
  }),
  status: z
    .nativeEnum(AccessibilityStatus)
    .openapi({
      example: AccessibilityStatus.AVAILABLE,
    })
    .nullable(),
});

export const completeAccessibilityItemSchema = accessibilityItemSchema.extend({
  additionalInfo: localizedString.nullable(),
});

export const accessibilitySchema = z.array(completeAccessibilityItemSchema);
