export enum HonoRequestQuerySortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export type HonoRequestQuerySort = {
  column: string;
  order: HonoRequestQuerySortOrder;
}[];

export type HonoRequestQueryPagination = {
  perPage: number;
  currentPage: number;
};
