import { z } from '../zod-openapi';

export const apiEmptySuccessResponseSchema = z.object({ success: z.literal(true) });

const dataObjectSchema = <T extends z.ZodTypeAny>(dataSchema: T) => z.object({ data: dataSchema });

const errorObjectSchema = <T extends z.ZodType<ApiError>>(errorSchema: T) =>
  z.object({
    error: errorSchema,
  });

export const apiErrorSchema = <T extends object, ZT extends z.ZodType<T>>(context: ZT) =>
  z
    .object({
      message: z.object({ en: z.string() }).or(z.string()), // allow non-localized message string
      context,
      type: z
        .string()
        .optional()
        .openapi({ description: 'Constant error class code name', examples: ['HttpConflictError'] }),
    })
    .strict();

export const apiErrorWithAnyContextSchema = apiErrorSchema(z.object({}).passthrough());

export type ApiError = Omit<z.infer<typeof apiErrorWithAnyContextSchema>, 'context'> & { context: object };

export const apiResponseFailedWithErrorSchema = <T extends z.ZodTypeAny>(errorSchema: T) =>
  z.object({ success: z.literal(false) }).merge(errorObjectSchema(errorSchema));

export const apiResponseFailedWithAnyContextSchema = apiResponseFailedWithErrorSchema(apiErrorWithAnyContextSchema);

export const apiResponseSucceededWithDataSchema = <T extends z.ZodTypeAny>(dataSchema: T) =>
  apiEmptySuccessResponseSchema.merge(dataObjectSchema(dataSchema));

export const apiResponseNoDataSchema = <T extends z.ZodTypeAny>(errorSchema: T) =>
  z.union([apiEmptySuccessResponseSchema.strict(), apiResponseFailedWithErrorSchema(errorSchema).strict()]);

/**
 * This response from API is either `{ success: true, data }` or `{ success: false, error }`.
 * Either type shall be handled separately. (Either type could also contain the other inside it; if so, its handling is still unambiguous: errors in success or data in error case would be purely informational.)
 *
 * For more complicated combinations of response fields see `apiResponseMixedSchema`.
 *
 * @param dataSchema Schema of data from API endpoint
 * @param errorSchema Schema of error from API endpoint, e.g. `apiErrorSchema`
 */
export const apiResponseEitherSchema = <T extends z.ZodTypeAny, E extends z.ZodTypeAny>(
  dataSchema: T,
  errorSchema: E,
) =>
  z.discriminatedUnion('success', [
    apiResponseSucceededWithDataSchema(dataSchema).strict(),
    apiResponseFailedWithErrorSchema(errorSchema).strict(),
  ]);

/**
 * This response from API is either `{ success: true, data }` or `{ success: false, error, data }`.
 *
 * What to do with data in error case depends on what API implementation means by it; developer of API consumer must always ask developer of its provider.
 *
 * For an unambiguous schema see `apiResponseEitherSchema`.
 *
 * @param dataSchema Schema of data from API endpoint
 * @param errorSchema Schema of error from API endpoint, e.g. `apiErrorSchema`
 */
export const apiResponseAlwaysWithDataSchema = <T extends z.ZodTypeAny, E extends z.ZodTypeAny>(
  dataSchema: T,
  errorSchema: E,
) =>
  z.discriminatedUnion('success', [
    apiResponseSucceededWithDataSchema(dataSchema).strict(),
    apiResponseFailedWithErrorSchema(errorSchema).merge(dataObjectSchema(dataSchema)).strict(),
  ]);

export type ApiSuccessResponse<T> = {
  success: true;
  data: T;
};

export type ApiErrorResponse<E extends ApiError = ApiError> = {
  success: false;
  error: E;
};

export type ApiResponse<T, E extends ApiError = ApiError> = ApiSuccessResponse<T> | ApiErrorResponse<E>;
