import { z } from '../zod-openapi';

/**
 * All settings necessary for constructing JWT tokens with buildJwtTokens.
 *
 * Used only in authentication-api,
 * because that's the service responsible for signing all our authorization tokens.
 */
export const jwtBuilderConfigurationSchema = z.object({
    JWT_ISSUER: z.string(),
    JWT_ACCESS_EXPIRE: z.string(),
    JWT_REFRESH_EXPIRE: z.string(),
    JWT_REFRESH_TOKEN_SECRET: z.string(),
    JWT_ACCESS_TOKEN_SECRET: z.string(),
  });

export type JwtBuilderConfiguration = z.infer<typeof jwtBuilderConfigurationSchema>;
