import { priceSchema } from '../price';
import { reservationSchema, reservationStoreUpdateSchema } from '../reservation';
import { z } from '../zod-openapi';

export const ticketDraftSchema = z.object({
  reservationId: z.string().optional(),
  ticketTypeId: z.string(),
  price: priceSchema.optional(),
  nameOnTicket: z
    .object({
      firstName: z.string(),
      lastName: z.string(),
    })
    .optional(),
});

export type TicketDraft = z.infer<typeof ticketDraftSchema>;

export const updateReservationsSchema = z.object({
  reservations: z.array(
    z
      .object({
        price: priceSchema.optional(),
      })
      .merge(reservationStoreUpdateSchema),
  ),
});

export const updateReservationTicketSchema = updateReservationsSchema.strict();

export const ticketSchema = ticketDraftSchema
  .merge(reservationSchema)
  .merge(z.object({ id: z.string() })) // id turned optional in reservationSchema but required for all tickets
  .strict();

export const ticketWithPrice = ticketDraftSchema.omit({ price: true }).extend({ price: priceSchema });

export type TicketWithPrice = z.infer<typeof ticketWithPrice>;
export type Ticket = z.infer<typeof ticketSchema>;
