// generated from https://github.com/unicode-org/cldr-json by /home/kato/tiketti/tiketti-api/scripts/world-import/generate-languages.js on 2024-03-06T09:19:48.446Z

// Locale names identifying human languages, somewhat related to language tags of IETF BCP-47.
export const languageTags = [
  "aa",
  "ab",
  "ace",
  "ach",
  "ada",
  "ady",
  "ae",
  "aeb",
  "af",
  "afh",
  "agq",
  "ain",
  "ak",
  "akk",
  "akz",
  "ale",
  "aln",
  "alt",
  "am",
  "an",
  "ang",
  "ann",
  "anp",
  "ar",
  "ar-001",
  "arc",
  "arn",
  "aro",
  "arp",
  "arq",
  "ars",
  "ars-alt-menu",
  "arw",
  "ary",
  "arz",
  "as",
  "asa",
  "ase",
  "ast",
  "atj",
  "av",
  "avk",
  "awa",
  "ay",
  "az",
  "az-alt-short",
  "ba",
  "bal",
  "ban",
  "bar",
  "bas",
  "bax",
  "bbc",
  "bbj",
  "be",
  "bej",
  "bem",
  "bew",
  "bez",
  "bfd",
  "bfq",
  "bg",
  "bgc",
  "bgn",
  "bho",
  "bi",
  "bik",
  "bin",
  "bjn",
  "bkm",
  "bla",
  "blo",
  "blt",
  "bm",
  "bn",
  "bo",
  "bpy",
  "bqi",
  "br",
  "bra",
  "brh",
  "brx",
  "bs",
  "bss",
  "bua",
  "bug",
  "bum",
  "byn",
  "byv",
  "ca",
  "cad",
  "car",
  "cay",
  "cch",
  "ccp",
  "ce",
  "ceb",
  "cgg",
  "ch",
  "chb",
  "chg",
  "chk",
  "chm",
  "chn",
  "cho",
  "chp",
  "chr",
  "chy",
  "cic",
  "ckb",
  "ckb-alt-menu",
  "ckb-alt-variant",
  "clc",
  "co",
  "cop",
  "cps",
  "cr",
  "crg",
  "crh",
  "crj",
  "crk",
  "crl",
  "crm",
  "crr",
  "crs",
  "cs",
  "csb",
  "csw",
  "cu",
  "cv",
  "cwd",
  "cy",
  "da",
  "dak",
  "dar",
  "dav",
  "de",
  "de-AT",
  "de-CH",
  "del",
  "den",
  "dgr",
  "din",
  "dje",
  "doi",
  "dsb",
  "dtp",
  "dua",
  "dum",
  "dv",
  "dyo",
  "dyu",
  "dz",
  "dzg",
  "ebu",
  "ee",
  "efi",
  "egl",
  "egy",
  "eka",
  "el",
  "elx",
  "en",
  "en-AU",
  "en-CA",
  "en-GB",
  "en-GB-alt-short",
  "en-US",
  "en-US-alt-short",
  "enm",
  "eo",
  "es",
  "es-419",
  "es-ES",
  "es-MX",
  "esu",
  "et",
  "eu",
  "ewo",
  "ext",
  "fa",
  "fa-AF",
  "fan",
  "fat",
  "ff",
  "fi",
  "fil",
  "fit",
  "fj",
  "fo",
  "fon",
  "fr",
  "fr-CA",
  "fr-CH",
  "frc",
  "frm",
  "fro",
  "frp",
  "frr",
  "frs",
  "fur",
  "fy",
  "ga",
  "gaa",
  "gag",
  "gan",
  "gay",
  "gba",
  "gbz",
  "gd",
  "gez",
  "gil",
  "gl",
  "glk",
  "gmh",
  "gn",
  "goh",
  "gom",
  "gon",
  "gor",
  "got",
  "grb",
  "grc",
  "gsw",
  "gu",
  "guc",
  "gur",
  "guz",
  "gv",
  "gwi",
  "ha",
  "hai",
  "hak",
  "haw",
  "hax",
  "hdn",
  "he",
  "hi",
  "hi-Latn",
  "hi-Latn-alt-variant",
  "hif",
  "hil",
  "hit",
  "hmn",
  "hnj",
  "ho",
  "hr",
  "hsb",
  "hsn",
  "ht",
  "hu",
  "hup",
  "hur",
  "hy",
  "hz",
  "ia",
  "iba",
  "ibb",
  "id",
  "ie",
  "ig",
  "ii",
  "ik",
  "ike",
  "ikt",
  "ilo",
  "inh",
  "io",
  "is",
  "it",
  "iu",
  "izh",
  "ja",
  "jam",
  "jbo",
  "jgo",
  "jmc",
  "jpr",
  "jrb",
  "jut",
  "jv",
  "ka",
  "kaa",
  "kab",
  "kac",
  "kaj",
  "kam",
  "kaw",
  "kbd",
  "kbl",
  "kcg",
  "kde",
  "kea",
  "ken",
  "kfo",
  "kg",
  "kgp",
  "kha",
  "kho",
  "khq",
  "khw",
  "ki",
  "kiu",
  "kj",
  "kk",
  "kkj",
  "kl",
  "kln",
  "km",
  "kmb",
  "kn",
  "ko",
  "koi",
  "kok",
  "kos",
  "kpe",
  "kr",
  "krc",
  "kri",
  "krj",
  "krl",
  "kru",
  "ks",
  "ksb",
  "ksf",
  "ksh",
  "ku",
  "kum",
  "kut",
  "kv",
  "kw",
  "kwk",
  "kxv",
  "ky",
  "ky-alt-variant",
  "la",
  "lad",
  "lag",
  "lah",
  "lam",
  "lb",
  "lez",
  "lfn",
  "lg",
  "li",
  "lij",
  "lil",
  "liv",
  "lkt",
  "lmo",
  "ln",
  "lo",
  "lol",
  "lou",
  "loz",
  "lrc",
  "lsm",
  "lt",
  "ltg",
  "lu",
  "lua",
  "lui",
  "lun",
  "luo",
  "lus",
  "luy",
  "lv",
  "lzh",
  "lzz",
  "mad",
  "maf",
  "mag",
  "mai",
  "mak",
  "man",
  "mas",
  "mde",
  "mdf",
  "mdr",
  "men",
  "mer",
  "mfe",
  "mg",
  "mga",
  "mgh",
  "mgo",
  "mh",
  "mi",
  "mic",
  "min",
  "mk",
  "ml",
  "mn",
  "mnc",
  "mni",
  "moe",
  "moh",
  "mos",
  "mr",
  "mrj",
  "ms",
  "mt",
  "mua",
  "mul",
  "mus",
  "mwl",
  "mwr",
  "mwv",
  "my",
  "my-alt-variant",
  "mye",
  "myv",
  "mzn",
  "na",
  "nan",
  "nap",
  "naq",
  "nb",
  "nd",
  "nds",
  "nds-NL",
  "ne",
  "new",
  "ng",
  "nia",
  "niu",
  "njo",
  "nl",
  "nl-BE",
  "nmg",
  "nn",
  "nnh",
  "no",
  "nog",
  "non",
  "nov",
  "nqo",
  "nr",
  "nso",
  "nus",
  "nv",
  "nwc",
  "ny",
  "nym",
  "nyn",
  "nyo",
  "nzi",
  "oc",
  "oj",
  "ojb",
  "ojc",
  "ojg",
  "ojs",
  "ojw",
  "oka",
  "om",
  "or",
  "os",
  "osa",
  "ota",
  "pa",
  "pag",
  "pal",
  "pam",
  "pap",
  "pau",
  "pcd",
  "pcm",
  "pdc",
  "pdt",
  "peo",
  "pfl",
  "phn",
  "pi",
  "pis",
  "pl",
  "pms",
  "pnt",
  "pon",
  "pqm",
  "prg",
  "pro",
  "ps",
  "ps-alt-variant",
  "pt",
  "pt-BR",
  "pt-PT",
  "qu",
  "quc",
  "qug",
  "raj",
  "rap",
  "rar",
  "rgn",
  "rhg",
  "rif",
  "rm",
  "rn",
  "ro",
  "ro-MD",
  "rof",
  "rom",
  "rtm",
  "ru",
  "rue",
  "rug",
  "rup",
  "rw",
  "rwk",
  "sa",
  "sad",
  "sah",
  "sam",
  "saq",
  "sas",
  "sat",
  "saz",
  "sba",
  "sbp",
  "sc",
  "scn",
  "sco",
  "sd",
  "sdc",
  "sdh",
  "se",
  "se-alt-menu",
  "see",
  "seh",
  "sei",
  "sel",
  "ses",
  "sg",
  "sga",
  "sgs",
  "sh",
  "shi",
  "shn",
  "shu",
  "si",
  "sid",
  "sk",
  "sl",
  "slh",
  "sli",
  "sly",
  "sm",
  "sma",
  "sma-alt-menu",
  "smj",
  "smj-alt-menu",
  "smn",
  "smn-alt-menu",
  "sms",
  "sms-alt-menu",
  "sn",
  "snk",
  "so",
  "sog",
  "sq",
  "sr",
  "sr-ME",
  "srn",
  "srr",
  "ss",
  "ssy",
  "st",
  "stq",
  "str",
  "su",
  "suk",
  "sus",
  "sux",
  "sv",
  "sw",
  "sw-CD",
  "swb",
  "syc",
  "syr",
  "szl",
  "ta",
  "tce",
  "tcy",
  "te",
  "tem",
  "teo",
  "ter",
  "tet",
  "tg",
  "tgx",
  "th",
  "tht",
  "ti",
  "tig",
  "tiv",
  "tk",
  "tkl",
  "tkr",
  "tl",
  "tlh",
  "tli",
  "tly",
  "tmh",
  "tn",
  "to",
  "tog",
  "tok",
  "tpi",
  "tr",
  "tru",
  "trv",
  "trw",
  "ts",
  "tsd",
  "tsi",
  "tt",
  "ttm",
  "ttt",
  "tum",
  "tvl",
  "tw",
  "twq",
  "ty",
  "tyv",
  "tzm",
  "udm",
  "ug",
  "ug-alt-variant",
  "uga",
  "uk",
  "umb",
  "und",
  "ur",
  "uz",
  "vai",
  "ve",
  "vec",
  "vep",
  "vi",
  "vls",
  "vmf",
  "vmw",
  "vo",
  "vot",
  "vro",
  "vun",
  "wa",
  "wae",
  "wal",
  "war",
  "was",
  "wbp",
  "wo",
  "wuu",
  "xal",
  "xh",
  "xmf",
  "xnr",
  "xog",
  "yao",
  "yap",
  "yav",
  "ybb",
  "yi",
  "yo",
  "yrl",
  "yue",
  "yue-alt-menu",
  "za",
  "zap",
  "zbl",
  "zea",
  "zen",
  "zgh",
  "zh",
  "zh-alt-long",
  "zh-alt-menu",
  "zh-Hans",
  "zh-Hans-alt-long",
  "zh-Hant",
  "zh-Hant-alt-long",
  "zu",
  "zun",
  "zxx",
  "zza",
  "ajp",
  "crh-alt-secondary",
  "kl-alt-secondary",
  "lad-alt-secondary",
  "skr",
  "af-NA",
  "ar-AE",
  "ar-BH",
  "ar-DJ",
  "ar-DZ",
  "ar-EG",
  "ar-EH",
  "ar-ER",
  "ar-IL",
  "ar-IQ",
  "ar-JO",
  "ar-KM",
  "ar-KW",
  "ar-LB",
  "ar-LY",
  "ar-MA",
  "ar-MR",
  "ar-OM",
  "ar-PS",
  "ar-QA",
  "ar-SA",
  "ar-SD",
  "ar-SO",
  "ar-SS",
  "ar-SY",
  "ar-TD",
  "ar-TN",
  "ar-YE",
  "az-Latn",
  "be-tarask",
  "bn-IN",
  "bs-Latn",
  "ca-AD",
  "ca-ES-valencia",
  "ca-FR",
  "ca-IT",
  "da-GL",
  "de-BE",
  "de-IT",
  "de-LI",
  "de-LU",
  "el-CY",
  "el-polyton",
  "en-001",
  "en-150",
  "en-AE",
  "en-AG",
  "en-AI",
  "en-AS",
  "en-AT",
  "en-BB",
  "en-BE",
  "en-BI",
  "en-BM",
  "en-BS",
  "en-BW",
  "en-BZ",
  "en-CC",
  "en-CH",
  "en-CK",
  "en-CM",
  "en-CX",
  "en-CY",
  "en-DE",
  "en-DG",
  "en-DK",
  "en-DM",
  "en-ER",
  "en-FI",
  "en-FJ",
  "en-FK",
  "en-FM",
  "en-GD",
  "en-GG",
  "en-GH",
  "en-GI",
  "en-GM",
  "en-GU",
  "en-GY",
  "en-HK",
  "en-ID",
  "en-IE",
  "en-IL",
  "en-IM",
  "en-IN",
  "en-IO",
  "en-JE",
  "en-JM",
  "en-KE",
  "en-KI",
  "en-KN",
  "en-KY",
  "en-LC",
  "en-LR",
  "en-LS",
  "en-MG",
  "en-MH",
  "en-MO",
  "en-MP",
  "en-MS",
  "en-MT",
  "en-MU",
  "en-MV",
  "en-MW",
  "en-MY",
  "en-NA",
  "en-NF",
  "en-NG",
  "en-NL",
  "en-NR",
  "en-NU",
  "en-NZ",
  "en-PG",
  "en-PH",
  "en-PK",
  "en-PN",
  "en-PR",
  "en-PW",
  "en-RW",
  "en-SB",
  "en-SC",
  "en-SD",
  "en-SE",
  "en-SG",
  "en-SH",
  "en-SI",
  "en-SL",
  "en-SS",
  "en-SX",
  "en-SZ",
  "en-TC",
  "en-TK",
  "en-TO",
  "en-TT",
  "en-TV",
  "en-TZ",
  "en-UG",
  "en-UM",
  "en-VC",
  "en-VG",
  "en-VI",
  "en-VU",
  "en-WS",
  "en-ZA",
  "en-ZM",
  "en-ZW",
  "es-AR",
  "es-BO",
  "es-BR",
  "es-BZ",
  "es-CL",
  "es-CO",
  "es-CR",
  "es-CU",
  "es-DO",
  "es-EA",
  "es-EC",
  "es-GQ",
  "es-GT",
  "es-HN",
  "es-IC",
  "es-NI",
  "es-PA",
  "es-PE",
  "es-PH",
  "es-PR",
  "es-PY",
  "es-SV",
  "es-US",
  "es-UY",
  "es-VE",
  "fr-BE",
  "fr-BF",
  "fr-BI",
  "fr-BJ",
  "fr-BL",
  "fr-CD",
  "fr-CF",
  "fr-CG",
  "fr-CI",
  "fr-CM",
  "fr-DJ",
  "fr-DZ",
  "fr-GA",
  "fr-GF",
  "fr-GN",
  "fr-GP",
  "fr-GQ",
  "fr-HT",
  "fr-KM",
  "fr-LU",
  "fr-MA",
  "fr-MC",
  "fr-MF",
  "fr-MG",
  "fr-ML",
  "fr-MQ",
  "fr-MR",
  "fr-MU",
  "fr-NC",
  "fr-NE",
  "fr-PF",
  "fr-PM",
  "fr-RE",
  "fr-RW",
  "fr-SC",
  "fr-SN",
  "fr-SY",
  "fr-TD",
  "fr-TG",
  "fr-TN",
  "fr-VU",
  "fr-WF",
  "fr-YT",
  "ga-GB",
  "ha-GH",
  "ha-NE",
  "hr-BA",
  "it-CH",
  "it-SM",
  "it-VA",
  "ko-CN",
  "ko-KP",
  "ms-BN",
  "ms-ID",
  "ms-SG",
  "nb-SJ",
  "ne-IN",
  "nl-AW",
  "nl-BQ",
  "nl-CW",
  "nl-SR",
  "nl-SX",
  "pa-Guru",
  "ps-PK",
  "pt-AO",
  "pt-CH",
  "pt-CV",
  "pt-GQ",
  "pt-GW",
  "pt-LU",
  "pt-MO",
  "pt-MZ",
  "pt-ST",
  "pt-TL",
  "ru-BY",
  "ru-KG",
  "ru-KZ",
  "ru-MD",
  "ru-UA",
  "sd-Arab",
  "so-DJ",
  "so-ET",
  "so-KE",
  "sq-MK",
  "sq-XK",
  "sr-Cyrl-BA",
  "sr-Cyrl-ME",
  "sr-Cyrl-XK",
  "sr-Cyrl",
  "sr-Latn-BA",
  "sr-Latn-ME",
  "sr-Latn-XK",
  "sr-Latn",
  "sv-AX",
  "sv-FI",
  "sw-KE",
  "sw-UG",
  "ta-LK",
  "ta-MY",
  "ta-SG",
  "tr-CY",
  "ur-IN",
  "uz-Latn",
  "yo-BJ",
  "zh-Hans-HK",
  "zh-Hans-MO",
  "zh-Hans-SG",
  "zh-Hant-HK",
  "zh-Hant-MO"
] as const;

// Localized names for language tags in a few selected languages.
export const languageTagNames = {
  "aa": {
    "en": "Afar",
    "fi": "afar",
    "sv": "afar"
  },
  "ab": {
    "en": "Abkhazian",
    "fi": "abhaasi",
    "sv": "abchaziska"
  },
  "ace": {
    "en": "Achinese",
    "fi": "atšeh",
    "sv": "acehnesiska"
  },
  "ach": {
    "en": "Acoli",
    "fi": "atšoli",
    "sv": "acholi"
  },
  "ada": {
    "en": "Adangme",
    "fi": "adangme",
    "sv": "adangme"
  },
  "ady": {
    "en": "Adyghe",
    "fi": "adyge",
    "sv": "adygeiska"
  },
  "ae": {
    "en": "Avestan",
    "fi": "avesta",
    "sv": "avestiska"
  },
  "aeb": {
    "en": "Tunisian Arabic",
    "fi": "tunisianarabia",
    "sv": "tunisisk arabiska"
  },
  "af": {
    "en": "Afrikaans",
    "fi": "afrikaans",
    "sv": "afrikaans"
  },
  "afh": {
    "en": "Afrihili",
    "fi": "afrihili",
    "sv": "afrihili"
  },
  "agq": {
    "en": "Aghem",
    "fi": "aghem",
    "sv": "aghem"
  },
  "ain": {
    "en": "Ainu",
    "fi": "ainu",
    "sv": "ainu"
  },
  "ak": {
    "en": "Akan",
    "fi": "akan",
    "sv": "akan"
  },
  "akk": {
    "en": "Akkadian",
    "fi": "akkadi",
    "sv": "akkadiska"
  },
  "akz": {
    "en": "Alabama",
    "fi": "alabama",
    "sv": "Alabama-muskogee"
  },
  "ale": {
    "en": "Aleut",
    "fi": "aleutti",
    "sv": "aleutiska"
  },
  "aln": {
    "en": "Gheg Albanian",
    "fi": "gegi",
    "sv": "gegiska"
  },
  "alt": {
    "en": "Southern Altai",
    "fi": "altai",
    "sv": "sydaltaiska"
  },
  "am": {
    "en": "Amharic",
    "fi": "amhara",
    "sv": "amhariska"
  },
  "an": {
    "en": "Aragonese",
    "fi": "aragonia",
    "sv": "aragonesiska"
  },
  "ang": {
    "en": "Old English",
    "fi": "muinaisenglanti",
    "sv": "fornengelska"
  },
  "ann": {
    "en": "Obolo",
    "fi": "obolo",
    "sv": "obolo"
  },
  "anp": {
    "en": "Angika",
    "fi": "angika",
    "sv": "angika"
  },
  "ar": {
    "en": "Arabic",
    "fi": "arabia",
    "sv": "arabiska"
  },
  "ar-001": {
    "en": "Modern Standard Arabic",
    "fi": "yleisarabia",
    "sv": "modern standardarabiska"
  },
  "arc": {
    "en": "Aramaic",
    "fi": "valtakunnanaramea",
    "sv": "arameiska"
  },
  "arn": {
    "en": "Mapuche",
    "fi": "mapudungun",
    "sv": "mapudungun"
  },
  "aro": {
    "en": "Araona",
    "fi": "araona",
    "sv": "araoniska"
  },
  "arp": {
    "en": "Arapaho",
    "fi": "arapaho",
    "sv": "arapaho"
  },
  "arq": {
    "en": "Algerian Arabic",
    "fi": "algerianarabia",
    "sv": "algerisk arabiska"
  },
  "ars": {
    "en": "Najdi Arabic",
    "fi": "arabia – najd",
    "sv": "najdiarabiska"
  },
  "ars-alt-menu": {
    "en": "Arabic, Najdi",
    "sv": "arabiska (najdi)"
  },
  "arw": {
    "en": "Arawak",
    "fi": "arawak",
    "sv": "arawakiska"
  },
  "ary": {
    "en": "Moroccan Arabic",
    "fi": "marokonarabia",
    "sv": "marockansk arabiska"
  },
  "arz": {
    "en": "Egyptian Arabic",
    "fi": "egyptinarabia",
    "sv": "egyptisk arabiska"
  },
  "as": {
    "en": "Assamese",
    "fi": "assami",
    "sv": "assamesiska"
  },
  "asa": {
    "en": "Asu",
    "fi": "asu",
    "sv": "asu"
  },
  "ase": {
    "en": "American Sign Language",
    "fi": "amerikkalainen viittomakieli",
    "sv": "amerikanskt teckenspråk"
  },
  "ast": {
    "en": "Asturian",
    "fi": "asturia",
    "sv": "asturiska"
  },
  "atj": {
    "en": "Atikamekw",
    "fi": "atikamekw",
    "sv": "atikamekw"
  },
  "av": {
    "en": "Avaric",
    "fi": "avaari",
    "sv": "avariska"
  },
  "avk": {
    "en": "Kotava",
    "fi": "kotava",
    "sv": "kotava"
  },
  "awa": {
    "en": "Awadhi",
    "fi": "awadhi",
    "sv": "awadhi"
  },
  "ay": {
    "en": "Aymara",
    "fi": "aimara",
    "sv": "aymara"
  },
  "az": {
    "en": "Azerbaijani",
    "fi": "azeri",
    "sv": "azerbajdzjanska"
  },
  "az-alt-short": {
    "en": "Azeri",
    "fi": "azeri",
    "sv": "azeriska"
  },
  "ba": {
    "en": "Bashkir",
    "fi": "baškiiri",
    "sv": "basjkiriska"
  },
  "bal": {
    "en": "Baluchi",
    "fi": "belutši",
    "sv": "baluchiska"
  },
  "ban": {
    "en": "Balinese",
    "fi": "bali",
    "sv": "balinesiska"
  },
  "bar": {
    "en": "Bavarian",
    "fi": "baijeri",
    "sv": "bayerska"
  },
  "bas": {
    "en": "Basaa",
    "fi": "basaa",
    "sv": "basa"
  },
  "bax": {
    "en": "Bamun",
    "fi": "bamum",
    "sv": "bamunska"
  },
  "bbc": {
    "en": "Batak Toba",
    "fi": "batak-toba",
    "sv": "batak-toba"
  },
  "bbj": {
    "en": "Ghomala",
    "fi": "ghomala",
    "sv": "ghomala"
  },
  "be": {
    "en": "Belarusian",
    "fi": "valkovenäjä",
    "sv": "belarusiska"
  },
  "bej": {
    "en": "Beja",
    "fi": "bedža",
    "sv": "beja"
  },
  "bem": {
    "en": "Bemba",
    "fi": "bemba",
    "sv": "bemba"
  },
  "bew": {
    "en": "Betawi",
    "fi": "betawi",
    "sv": "betawiska"
  },
  "bez": {
    "en": "Bena",
    "fi": "bena",
    "sv": "bena"
  },
  "bfd": {
    "en": "Bafut",
    "fi": "fut",
    "sv": "bafut"
  },
  "bfq": {
    "en": "Badaga",
    "fi": "badaga",
    "sv": "bagada"
  },
  "bg": {
    "en": "Bulgarian",
    "fi": "bulgaria",
    "sv": "bulgariska"
  },
  "bgc": {
    "en": "Haryanvi",
    "fi": "haryanvi",
    "sv": "hariyanvi"
  },
  "bgn": {
    "en": "Western Balochi",
    "fi": "länsibelutši",
    "sv": "västbaluchiska"
  },
  "bho": {
    "en": "Bhojpuri",
    "fi": "bhodžpuri",
    "sv": "bhojpuri"
  },
  "bi": {
    "en": "Bislama",
    "fi": "bislama",
    "sv": "bislama"
  },
  "bik": {
    "en": "Bikol",
    "fi": "bikol",
    "sv": "bikol"
  },
  "bin": {
    "en": "Bini",
    "fi": "bini",
    "sv": "bini"
  },
  "bjn": {
    "en": "Banjar",
    "fi": "banjar",
    "sv": "banjariska"
  },
  "bkm": {
    "en": "Kom",
    "fi": "kom",
    "sv": "bamekon"
  },
  "bla": {
    "en": "Siksiká",
    "fi": "siksika",
    "sv": "siksika"
  },
  "blo": {
    "en": "Anii",
    "fi": "anii"
  },
  "blt": {
    "en": "Tai Dam",
    "fi": "tai dam"
  },
  "bm": {
    "en": "Bambara",
    "fi": "bambara",
    "sv": "bambara"
  },
  "bn": {
    "en": "Bangla",
    "fi": "bengali",
    "sv": "bengali"
  },
  "bo": {
    "en": "Tibetan",
    "fi": "tiibet",
    "sv": "tibetanska"
  },
  "bpy": {
    "en": "Bishnupriya",
    "fi": "bišnupria",
    "sv": "bishnupriya"
  },
  "bqi": {
    "en": "Bakhtiari",
    "fi": "bahtiari",
    "sv": "bakhtiari"
  },
  "br": {
    "en": "Breton",
    "fi": "bretoni",
    "sv": "bretonska"
  },
  "bra": {
    "en": "Braj",
    "fi": "bradž",
    "sv": "braj"
  },
  "brh": {
    "en": "Brahui",
    "fi": "brahui",
    "sv": "brahuiska"
  },
  "brx": {
    "en": "Bodo",
    "fi": "bodo",
    "sv": "bodo"
  },
  "bs": {
    "en": "Bosnian",
    "fi": "bosnia",
    "sv": "bosniska"
  },
  "bss": {
    "en": "Akoose",
    "fi": "koose",
    "sv": "bakossi"
  },
  "bua": {
    "en": "Buriat",
    "fi": "burjaatti",
    "sv": "burjätiska"
  },
  "bug": {
    "en": "Buginese",
    "fi": "bugi",
    "sv": "buginesiska"
  },
  "bum": {
    "en": "Bulu",
    "fi": "bulu",
    "sv": "boulou"
  },
  "byn": {
    "en": "Blin",
    "fi": "bilin",
    "sv": "blin"
  },
  "byv": {
    "en": "Medumba",
    "fi": "medumba",
    "sv": "bagangte"
  },
  "ca": {
    "en": "Catalan",
    "fi": "katalaani",
    "sv": "katalanska"
  },
  "cad": {
    "en": "Caddo",
    "fi": "caddo",
    "sv": "caddo"
  },
  "car": {
    "en": "Carib",
    "fi": "karibi",
    "sv": "karibiska"
  },
  "cay": {
    "en": "Cayuga",
    "fi": "cayuga",
    "sv": "cayuga"
  },
  "cch": {
    "en": "Atsam",
    "fi": "atsam",
    "sv": "atsam"
  },
  "ccp": {
    "en": "Chakma",
    "fi": "chakma",
    "sv": "chakma"
  },
  "ce": {
    "en": "Chechen",
    "fi": "tšetšeeni",
    "sv": "tjetjenska"
  },
  "ceb": {
    "en": "Cebuano",
    "fi": "cebuano",
    "sv": "cebuano"
  },
  "cgg": {
    "en": "Chiga",
    "fi": "kiga",
    "sv": "chiga"
  },
  "ch": {
    "en": "Chamorro",
    "fi": "tšamorro",
    "sv": "chamorro"
  },
  "chb": {
    "en": "Chibcha",
    "fi": "tšibtša",
    "sv": "chibcha"
  },
  "chg": {
    "en": "Chagatai",
    "fi": "tšagatai",
    "sv": "chagatai"
  },
  "chk": {
    "en": "Chuukese",
    "fi": "chuuk",
    "sv": "chuukesiska"
  },
  "chm": {
    "en": "Mari",
    "fi": "mari",
    "sv": "mariska"
  },
  "chn": {
    "en": "Chinook Jargon",
    "fi": "chinook-jargon",
    "sv": "chinook"
  },
  "cho": {
    "en": "Choctaw",
    "fi": "choctaw",
    "sv": "choctaw"
  },
  "chp": {
    "en": "Chipewyan",
    "fi": "chipewyan",
    "sv": "chipewyan"
  },
  "chr": {
    "en": "Cherokee",
    "fi": "cherokee",
    "sv": "cherokesiska"
  },
  "chy": {
    "en": "Cheyenne",
    "fi": "cheyenne",
    "sv": "cheyenne"
  },
  "cic": {
    "en": "Chickasaw",
    "fi": "chickasaw"
  },
  "ckb": {
    "en": "Central Kurdish",
    "fi": "soranî",
    "sv": "centralkurdiska"
  },
  "ckb-alt-menu": {
    "en": "Kurdish, Central",
    "fi": "kurdi – soranî",
    "sv": "kurdiska (sorani)"
  },
  "ckb-alt-variant": {
    "en": "Kurdish, Sorani",
    "fi": "soranî",
    "sv": "soranisk kurdiska"
  },
  "clc": {
    "en": "Chilcotin",
    "fi": "chilcotin",
    "sv": "chilcotin"
  },
  "co": {
    "en": "Corsican",
    "fi": "korsika",
    "sv": "korsikanska"
  },
  "cop": {
    "en": "Coptic",
    "fi": "kopti",
    "sv": "koptiska"
  },
  "cps": {
    "en": "Capiznon",
    "fi": "capiznon",
    "sv": "kapisnon"
  },
  "cr": {
    "en": "Cree",
    "fi": "cree",
    "sv": "cree"
  },
  "crg": {
    "en": "Michif",
    "fi": "michif",
    "sv": "michif"
  },
  "crh": {
    "en": "Crimean Tatar",
    "fi": "krimintataari",
    "sv": "krimtatariska"
  },
  "crj": {
    "en": "Southern East Cree",
    "fi": "east cree (eteläinen)",
    "sv": "sydostcree"
  },
  "crk": {
    "en": "Plains Cree",
    "fi": "plains cree",
    "sv": "slättcree"
  },
  "crl": {
    "en": "Northern East Cree",
    "fi": "east cree (pohjoinen)",
    "sv": "nordost-cree"
  },
  "crm": {
    "en": "Moose Cree",
    "fi": "moose cree",
    "sv": "moose cree"
  },
  "crr": {
    "en": "Carolina Algonquian",
    "fi": "pamlico",
    "sv": "Carolina-algonkinska"
  },
  "crs": {
    "en": "Seselwa Creole French",
    "fi": "seychellienkreoli",
    "sv": "seychellisk kreol"
  },
  "cs": {
    "en": "Czech",
    "fi": "tšekki",
    "sv": "tjeckiska"
  },
  "csb": {
    "en": "Kashubian",
    "fi": "kašubi",
    "sv": "kasjubiska"
  },
  "csw": {
    "en": "Swampy Cree",
    "fi": "swampy cree",
    "sv": "träskcree"
  },
  "cu": {
    "en": "Church Slavic",
    "fi": "kirkkoslaavi",
    "sv": "kyrkslaviska"
  },
  "cv": {
    "en": "Chuvash",
    "fi": "tšuvassi",
    "sv": "tjuvasjiska"
  },
  "cwd": {
    "en": "Woods Cree"
  },
  "cy": {
    "en": "Welsh",
    "fi": "kymri",
    "sv": "walesiska"
  },
  "da": {
    "en": "Danish",
    "fi": "tanska",
    "sv": "danska"
  },
  "dak": {
    "en": "Dakota",
    "fi": "dakota",
    "sv": "dakota"
  },
  "dar": {
    "en": "Dargwa",
    "fi": "dargi",
    "sv": "darginska"
  },
  "dav": {
    "en": "Taita",
    "fi": "taita",
    "sv": "taita"
  },
  "de": {
    "en": "German",
    "fi": "saksa",
    "sv": "tyska"
  },
  "de-AT": {
    "en": "Austrian German",
    "fi": "itävallansaksa",
    "sv": "österrikisk tyska"
  },
  "de-CH": {
    "en": "Swiss High German",
    "fi": "sveitsinyläsaksa",
    "sv": "schweizisk högtyska"
  },
  "del": {
    "en": "Delaware",
    "fi": "delaware",
    "sv": "delaware"
  },
  "den": {
    "en": "Slave",
    "fi": "slevi",
    "sv": "slavej"
  },
  "dgr": {
    "en": "Dogrib",
    "fi": "dogrib",
    "sv": "dogrib"
  },
  "din": {
    "en": "Dinka",
    "fi": "dinka",
    "sv": "dinka"
  },
  "dje": {
    "en": "Zarma",
    "fi": "djerma",
    "sv": "zarma"
  },
  "doi": {
    "en": "Dogri",
    "fi": "dogri",
    "sv": "dogri"
  },
  "dsb": {
    "en": "Lower Sorbian",
    "fi": "alasorbi",
    "sv": "lågsorbiska"
  },
  "dtp": {
    "en": "Central Dusun",
    "fi": "dusun",
    "sv": "centraldusun"
  },
  "dua": {
    "en": "Duala",
    "fi": "duala",
    "sv": "duala"
  },
  "dum": {
    "en": "Middle Dutch",
    "fi": "keskihollanti",
    "sv": "medelnederländska"
  },
  "dv": {
    "en": "Divehi",
    "fi": "divehi",
    "sv": "divehi"
  },
  "dyo": {
    "en": "Jola-Fonyi",
    "fi": "jola-fonyi",
    "sv": "jola-fonyi"
  },
  "dyu": {
    "en": "Dyula",
    "fi": "djula",
    "sv": "dyula"
  },
  "dz": {
    "en": "Dzongkha",
    "fi": "dzongkha",
    "sv": "dzongkha"
  },
  "dzg": {
    "en": "Dazaga",
    "fi": "dazaga",
    "sv": "dazaga"
  },
  "ebu": {
    "en": "Embu",
    "fi": "embu",
    "sv": "embu"
  },
  "ee": {
    "en": "Ewe",
    "fi": "ewe",
    "sv": "ewe"
  },
  "efi": {
    "en": "Efik",
    "fi": "efik",
    "sv": "efik"
  },
  "egl": {
    "en": "Emilian",
    "fi": "emilia",
    "sv": "emiliska"
  },
  "egy": {
    "en": "Ancient Egyptian",
    "fi": "muinaisegypti",
    "sv": "fornegyptiska"
  },
  "eka": {
    "en": "Ekajuk",
    "fi": "ekajuk",
    "sv": "ekajuk"
  },
  "el": {
    "en": "Greek",
    "fi": "kreikka",
    "sv": "grekiska"
  },
  "elx": {
    "en": "Elamite",
    "fi": "elami",
    "sv": "elamitiska"
  },
  "en": {
    "en": "English",
    "fi": "englanti",
    "sv": "engelska"
  },
  "en-AU": {
    "en": "Australian English",
    "fi": "australianenglanti",
    "sv": "australisk engelska"
  },
  "en-CA": {
    "en": "Canadian English",
    "fi": "kanadanenglanti",
    "sv": "kanadensisk engelska"
  },
  "en-GB": {
    "en": "British English",
    "fi": "britannianenglanti",
    "sv": "brittisk engelska"
  },
  "en-GB-alt-short": {
    "en": "UK English",
    "fi": "englanti (Britannia)",
    "sv": "brittisk engelska"
  },
  "en-US": {
    "en": "American English",
    "fi": "amerikanenglanti",
    "sv": "amerikansk engelska"
  },
  "en-US-alt-short": {
    "en": "US English",
    "fi": "englanti (USA)",
    "sv": "amerikansk engelska"
  },
  "enm": {
    "en": "Middle English",
    "fi": "keskienglanti",
    "sv": "medelengelska"
  },
  "eo": {
    "en": "Esperanto",
    "fi": "esperanto",
    "sv": "esperanto"
  },
  "es": {
    "en": "Spanish",
    "fi": "espanja",
    "sv": "spanska"
  },
  "es-419": {
    "en": "Latin American Spanish",
    "fi": "amerikanespanja",
    "sv": "latinamerikansk spanska"
  },
  "es-ES": {
    "en": "European Spanish",
    "fi": "euroopanespanja",
    "sv": "europeisk spanska"
  },
  "es-MX": {
    "en": "Mexican Spanish",
    "fi": "meksikonespanja",
    "sv": "mexikansk spanska"
  },
  "esu": {
    "en": "Central Yupik",
    "fi": "alaskanjupik",
    "sv": "centralalaskisk jupiska"
  },
  "et": {
    "en": "Estonian",
    "fi": "viro",
    "sv": "estniska"
  },
  "eu": {
    "en": "Basque",
    "fi": "baski",
    "sv": "baskiska"
  },
  "ewo": {
    "en": "Ewondo",
    "fi": "ewondo",
    "sv": "ewondo"
  },
  "ext": {
    "en": "Extremaduran",
    "fi": "extremadura",
    "sv": "extremaduriska"
  },
  "fa": {
    "en": "Persian",
    "fi": "persia",
    "sv": "persiska"
  },
  "fa-AF": {
    "en": "Dari",
    "fi": "dari",
    "sv": "dari"
  },
  "fan": {
    "en": "Fang",
    "fi": "fang",
    "sv": "fang"
  },
  "fat": {
    "en": "Fanti",
    "fi": "fanti",
    "sv": "fanti"
  },
  "ff": {
    "en": "Fula",
    "fi": "fulani",
    "sv": "fulani"
  },
  "fi": {
    "en": "Finnish",
    "fi": "suomi",
    "sv": "finska"
  },
  "fil": {
    "en": "Filipino",
    "fi": "filipino",
    "sv": "filippinska"
  },
  "fit": {
    "en": "Tornedalen Finnish",
    "fi": "meänkieli",
    "sv": "meänkieli"
  },
  "fj": {
    "en": "Fijian",
    "fi": "fidži",
    "sv": "fijianska"
  },
  "fo": {
    "en": "Faroese",
    "fi": "fääri",
    "sv": "färöiska"
  },
  "fon": {
    "en": "Fon",
    "fi": "fon",
    "sv": "fonspråket"
  },
  "fr": {
    "en": "French",
    "fi": "ranska",
    "sv": "franska"
  },
  "fr-CA": {
    "en": "Canadian French",
    "fi": "kanadanranska",
    "sv": "kanadensisk franska"
  },
  "fr-CH": {
    "en": "Swiss French",
    "fi": "sveitsinranska",
    "sv": "schweizisk franska"
  },
  "frc": {
    "en": "Cajun French",
    "fi": "cajunranska",
    "sv": "cajun-franska"
  },
  "frm": {
    "en": "Middle French",
    "fi": "keskiranska",
    "sv": "medelfranska"
  },
  "fro": {
    "en": "Old French",
    "fi": "muinaisranska",
    "sv": "fornfranska"
  },
  "frp": {
    "en": "Arpitan",
    "fi": "arpitaani",
    "sv": "frankoprovensalska"
  },
  "frr": {
    "en": "Northern Frisian",
    "fi": "pohjoisfriisi",
    "sv": "nordfrisiska"
  },
  "frs": {
    "en": "Eastern Frisian",
    "fi": "itäfriisi",
    "sv": "östfrisiska"
  },
  "fur": {
    "en": "Friulian",
    "fi": "friuli",
    "sv": "friulianska"
  },
  "fy": {
    "en": "Western Frisian",
    "fi": "länsifriisi",
    "sv": "västfrisiska"
  },
  "ga": {
    "en": "Irish",
    "fi": "iiri",
    "sv": "iriska"
  },
  "gaa": {
    "en": "Ga",
    "fi": "ga",
    "sv": "gã"
  },
  "gag": {
    "en": "Gagauz",
    "fi": "gagauzi",
    "sv": "gagauziska"
  },
  "gan": {
    "en": "Gan Chinese",
    "fi": "gan-kiina",
    "sv": "gan"
  },
  "gay": {
    "en": "Gayo",
    "fi": "gajo",
    "sv": "gayo"
  },
  "gba": {
    "en": "Gbaya",
    "fi": "gbaja",
    "sv": "gbaya"
  },
  "gbz": {
    "en": "Zoroastrian Dari",
    "fi": "zoroastrialaisdari",
    "sv": "zoroastrisk dari"
  },
  "gd": {
    "en": "Scottish Gaelic",
    "fi": "gaeli",
    "sv": "skotsk gäliska"
  },
  "gez": {
    "en": "Geez",
    "fi": "ge’ez",
    "sv": "etiopiska"
  },
  "gil": {
    "en": "Gilbertese",
    "fi": "kiribati",
    "sv": "gilbertiska"
  },
  "gl": {
    "en": "Galician",
    "fi": "galicia",
    "sv": "galiciska"
  },
  "glk": {
    "en": "Gilaki",
    "fi": "gilaki",
    "sv": "gilaki"
  },
  "gmh": {
    "en": "Middle High German",
    "fi": "keskiyläsaksa",
    "sv": "medelhögtyska"
  },
  "gn": {
    "en": "Guarani",
    "fi": "guarani",
    "sv": "guaraní"
  },
  "goh": {
    "en": "Old High German",
    "fi": "muinaisyläsaksa",
    "sv": "fornhögtyska"
  },
  "gom": {
    "en": "Goan Konkani",
    "fi": "goankonkani",
    "sv": "Goa-konkani"
  },
  "gon": {
    "en": "Gondi",
    "fi": "gondi",
    "sv": "gondi"
  },
  "gor": {
    "en": "Gorontalo",
    "fi": "gorontalo",
    "sv": "gorontalo"
  },
  "got": {
    "en": "Gothic",
    "fi": "gootti",
    "sv": "gotiska"
  },
  "grb": {
    "en": "Grebo",
    "fi": "grebo",
    "sv": "grebo"
  },
  "grc": {
    "en": "Ancient Greek",
    "fi": "muinaiskreikka",
    "sv": "forngrekiska"
  },
  "gsw": {
    "en": "Swiss German",
    "fi": "sveitsinsaksa",
    "sv": "schweizertyska"
  },
  "gu": {
    "en": "Gujarati",
    "fi": "gudžarati",
    "sv": "gujarati"
  },
  "guc": {
    "en": "Wayuu",
    "fi": "wayuu",
    "sv": "wayuu"
  },
  "gur": {
    "en": "Frafra",
    "fi": "frafra",
    "sv": "farefare"
  },
  "guz": {
    "en": "Gusii",
    "fi": "gusii",
    "sv": "gusii"
  },
  "gv": {
    "en": "Manx",
    "fi": "manksi",
    "sv": "manx"
  },
  "gwi": {
    "en": "Gwichʼin",
    "fi": "gwitšin",
    "sv": "gwichin"
  },
  "ha": {
    "en": "Hausa",
    "fi": "hausa",
    "sv": "hausa"
  },
  "hai": {
    "en": "Haida",
    "fi": "haida",
    "sv": "haida"
  },
  "hak": {
    "en": "Hakka Chinese",
    "fi": "hakka-kiina",
    "sv": "hakka"
  },
  "haw": {
    "en": "Hawaiian",
    "fi": "havaiji",
    "sv": "hawaiiska"
  },
  "hax": {
    "en": "Southern Haida",
    "fi": "haida (eteläinen)",
    "sv": "sydhaida"
  },
  "hdn": {
    "en": "Northern Haida"
  },
  "he": {
    "en": "Hebrew",
    "fi": "heprea",
    "sv": "hebreiska"
  },
  "hi": {
    "en": "Hindi",
    "fi": "hindi",
    "sv": "hindi"
  },
  "hi-Latn": {
    "en": "Hindi (Latin)",
    "sv": "hindi (latinsk)"
  },
  "hi-Latn-alt-variant": {
    "en": "Hinglish",
    "fi": "hinglish",
    "sv": "hinglish"
  },
  "hif": {
    "en": "Fiji Hindi",
    "fi": "fidžinhindi",
    "sv": "Fiji-hindi"
  },
  "hil": {
    "en": "Hiligaynon",
    "fi": "hiligaino",
    "sv": "hiligaynon"
  },
  "hit": {
    "en": "Hittite",
    "fi": "heetti",
    "sv": "hettitiska"
  },
  "hmn": {
    "en": "Hmong",
    "fi": "hmong",
    "sv": "hmongspråk"
  },
  "hnj": {
    "en": "Hmong Njua",
    "fi": "hmong njua"
  },
  "ho": {
    "en": "Hiri Motu",
    "fi": "hiri-motu",
    "sv": "hirimotu"
  },
  "hr": {
    "en": "Croatian",
    "fi": "kroatia",
    "sv": "kroatiska"
  },
  "hsb": {
    "en": "Upper Sorbian",
    "fi": "yläsorbi",
    "sv": "högsorbiska"
  },
  "hsn": {
    "en": "Xiang Chinese",
    "fi": "xiang-kiina",
    "sv": "xiang"
  },
  "ht": {
    "en": "Haitian Creole",
    "fi": "haiti",
    "sv": "haitiska"
  },
  "hu": {
    "en": "Hungarian",
    "fi": "unkari",
    "sv": "ungerska"
  },
  "hup": {
    "en": "Hupa",
    "fi": "hupa",
    "sv": "hupa"
  },
  "hur": {
    "en": "Halkomelem",
    "fi": "halkomelem",
    "sv": "halkomelem"
  },
  "hy": {
    "en": "Armenian",
    "fi": "armenia",
    "sv": "armeniska"
  },
  "hz": {
    "en": "Herero",
    "fi": "herero",
    "sv": "herero"
  },
  "ia": {
    "en": "Interlingua",
    "fi": "interlingua",
    "sv": "interlingua"
  },
  "iba": {
    "en": "Iban",
    "fi": "iban",
    "sv": "ibanska"
  },
  "ibb": {
    "en": "Ibibio",
    "fi": "ibibio",
    "sv": "ibibio"
  },
  "id": {
    "en": "Indonesian",
    "fi": "indonesia",
    "sv": "indonesiska"
  },
  "ie": {
    "en": "Interlingue",
    "fi": "interlingue",
    "sv": "interlingue"
  },
  "ig": {
    "en": "Igbo",
    "fi": "igbo",
    "sv": "igbo"
  },
  "ii": {
    "en": "Sichuan Yi",
    "fi": "sichuanin-yi",
    "sv": "szezuan i"
  },
  "ik": {
    "en": "Inupiaq",
    "fi": "inupiaq",
    "sv": "inupiak"
  },
  "ike": {
    "en": "Eastern Canadian Inuktitut"
  },
  "ikt": {
    "en": "Western Canadian Inuktitut",
    "fi": "Länsi-Kanadan inuktitut",
    "sv": "inuktun"
  },
  "ilo": {
    "en": "Iloko",
    "fi": "iloko",
    "sv": "iloko"
  },
  "inh": {
    "en": "Ingush",
    "fi": "inguuši",
    "sv": "ingusjiska"
  },
  "io": {
    "en": "Ido",
    "fi": "ido",
    "sv": "ido"
  },
  "is": {
    "en": "Icelandic",
    "fi": "islanti",
    "sv": "isländska"
  },
  "it": {
    "en": "Italian",
    "fi": "italia",
    "sv": "italienska"
  },
  "iu": {
    "en": "Inuktitut",
    "fi": "inuktitut",
    "sv": "inuktitut"
  },
  "izh": {
    "en": "Ingrian",
    "fi": "inkeroinen",
    "sv": "ingriska"
  },
  "ja": {
    "en": "Japanese",
    "fi": "japani",
    "sv": "japanska"
  },
  "jam": {
    "en": "Jamaican Creole English",
    "fi": "jamaikankreolienglanti",
    "sv": "jamaikansk engelsk kreol"
  },
  "jbo": {
    "en": "Lojban",
    "fi": "lojban",
    "sv": "lojban"
  },
  "jgo": {
    "en": "Ngomba",
    "fi": "ngomba",
    "sv": "ngomba"
  },
  "jmc": {
    "en": "Machame",
    "fi": "machame",
    "sv": "kimashami"
  },
  "jpr": {
    "en": "Judeo-Persian",
    "fi": "juutalaispersia",
    "sv": "judisk persiska"
  },
  "jrb": {
    "en": "Judeo-Arabic",
    "fi": "juutalaisarabia",
    "sv": "judisk arabiska"
  },
  "jut": {
    "en": "Jutish",
    "fi": "juutti",
    "sv": "jylländska"
  },
  "jv": {
    "en": "Javanese",
    "fi": "jaava",
    "sv": "javanesiska"
  },
  "ka": {
    "en": "Georgian",
    "fi": "georgia",
    "sv": "georgiska"
  },
  "kaa": {
    "en": "Kara-Kalpak",
    "fi": "karakalpakki",
    "sv": "karakalpakiska"
  },
  "kab": {
    "en": "Kabyle",
    "fi": "kabyyli",
    "sv": "kabyliska"
  },
  "kac": {
    "en": "Kachin",
    "fi": "katšin",
    "sv": "kachin"
  },
  "kaj": {
    "en": "Jju",
    "fi": "jju",
    "sv": "jju"
  },
  "kam": {
    "en": "Kamba",
    "fi": "kamba",
    "sv": "kamba"
  },
  "kaw": {
    "en": "Kawi",
    "fi": "kavi",
    "sv": "kawi"
  },
  "kbd": {
    "en": "Kabardian",
    "fi": "kabardi",
    "sv": "kabardinska"
  },
  "kbl": {
    "en": "Kanembu",
    "fi": "kanembu",
    "sv": "kanembu"
  },
  "kcg": {
    "en": "Tyap",
    "fi": "tyap",
    "sv": "tyap"
  },
  "kde": {
    "en": "Makonde",
    "fi": "makonde",
    "sv": "makonde"
  },
  "kea": {
    "en": "Kabuverdianu",
    "fi": "kapverdenkreoli",
    "sv": "kapverdiska"
  },
  "ken": {
    "en": "Kenyang",
    "fi": "kenyang",
    "sv": "kenjang"
  },
  "kfo": {
    "en": "Koro",
    "fi": "norsunluurannikonkoro",
    "sv": "koro"
  },
  "kg": {
    "en": "Kongo",
    "fi": "kongo",
    "sv": "kikongo"
  },
  "kgp": {
    "en": "Kaingang",
    "fi": "kaingang",
    "sv": "kaingang"
  },
  "kha": {
    "en": "Khasi",
    "fi": "khasi",
    "sv": "khasi"
  },
  "kho": {
    "en": "Khotanese",
    "fi": "khotani",
    "sv": "khotanesiska"
  },
  "khq": {
    "en": "Koyra Chiini",
    "fi": "koyra chiini",
    "sv": "Timbuktu-songhai"
  },
  "khw": {
    "en": "Khowar",
    "fi": "khowar",
    "sv": "khowar"
  },
  "ki": {
    "en": "Kikuyu",
    "fi": "kikuju",
    "sv": "kikuyu"
  },
  "kiu": {
    "en": "Kirmanjki",
    "fi": "kirmanjki",
    "sv": "kirmanjki"
  },
  "kj": {
    "en": "Kuanyama",
    "fi": "kuanjama",
    "sv": "kuanyama"
  },
  "kk": {
    "en": "Kazakh",
    "fi": "kazakki",
    "sv": "kazakiska"
  },
  "kkj": {
    "en": "Kako",
    "fi": "kako",
    "sv": "mkako"
  },
  "kl": {
    "en": "Kalaallisut",
    "fi": "kalaallisut",
    "sv": "grönländska"
  },
  "kln": {
    "en": "Kalenjin",
    "fi": "kalenjin",
    "sv": "kalenjin"
  },
  "km": {
    "en": "Khmer",
    "fi": "khmer",
    "sv": "kambodjanska"
  },
  "kmb": {
    "en": "Kimbundu",
    "fi": "kimbundu",
    "sv": "kimbundu"
  },
  "kn": {
    "en": "Kannada",
    "fi": "kannada",
    "sv": "kannada"
  },
  "ko": {
    "en": "Korean",
    "fi": "korea",
    "sv": "koreanska"
  },
  "koi": {
    "en": "Komi-Permyak",
    "fi": "komipermjakki",
    "sv": "komi-permjakiska"
  },
  "kok": {
    "en": "Konkani",
    "fi": "konkani",
    "sv": "konkani"
  },
  "kos": {
    "en": "Kosraean",
    "fi": "kosrae",
    "sv": "kosreanska"
  },
  "kpe": {
    "en": "Kpelle",
    "fi": "kpelle",
    "sv": "kpelle"
  },
  "kr": {
    "en": "Kanuri",
    "fi": "kanuri",
    "sv": "kanuri"
  },
  "krc": {
    "en": "Karachay-Balkar",
    "fi": "karatšai-balkaari",
    "sv": "karachay-balkar"
  },
  "kri": {
    "en": "Krio",
    "fi": "krio",
    "sv": "krio"
  },
  "krj": {
    "en": "Kinaray-a",
    "fi": "kinaray-a",
    "sv": "kinaray-a"
  },
  "krl": {
    "en": "Karelian",
    "fi": "karjala",
    "sv": "karelska"
  },
  "kru": {
    "en": "Kurukh",
    "fi": "kurukh",
    "sv": "kurukh"
  },
  "ks": {
    "en": "Kashmiri",
    "fi": "kašmiri",
    "sv": "kashmiriska"
  },
  "ksb": {
    "en": "Shambala",
    "fi": "shambala",
    "sv": "kisambaa"
  },
  "ksf": {
    "en": "Bafia",
    "fi": "bafia",
    "sv": "bafia"
  },
  "ksh": {
    "en": "Colognian",
    "fi": "kölsch",
    "sv": "kölniska"
  },
  "ku": {
    "en": "Kurdish",
    "fi": "kurdi",
    "sv": "kurdiska"
  },
  "kum": {
    "en": "Kumyk",
    "fi": "kumykki",
    "sv": "kumykiska"
  },
  "kut": {
    "en": "Kutenai",
    "fi": "kutenai",
    "sv": "kutenaj"
  },
  "kv": {
    "en": "Komi",
    "fi": "komi",
    "sv": "kome"
  },
  "kw": {
    "en": "Cornish",
    "fi": "korni",
    "sv": "korniska"
  },
  "kwk": {
    "en": "Kwakʼwala",
    "fi": "kwakʼwala",
    "sv": "kwakʼwala"
  },
  "kxv": {
    "en": "Kuvi",
    "fi": "kuvi"
  },
  "ky": {
    "en": "Kyrgyz",
    "fi": "kirgiisi",
    "sv": "kirgiziska"
  },
  "ky-alt-variant": {
    "en": "Kirghiz"
  },
  "la": {
    "en": "Latin",
    "fi": "latina",
    "sv": "latin"
  },
  "lad": {
    "en": "Ladino",
    "fi": "ladino",
    "sv": "ladino"
  },
  "lag": {
    "en": "Langi",
    "fi": "lango",
    "sv": "langi"
  },
  "lah": {
    "en": "Western Panjabi",
    "fi": "lahnda",
    "sv": "lahnda"
  },
  "lam": {
    "en": "Lamba",
    "fi": "lamba",
    "sv": "lamba"
  },
  "lb": {
    "en": "Luxembourgish",
    "fi": "luxemburg",
    "sv": "luxemburgiska"
  },
  "lez": {
    "en": "Lezghian",
    "fi": "lezgi",
    "sv": "lezghien"
  },
  "lfn": {
    "en": "Lingua Franca Nova",
    "fi": "lingua franca nova",
    "sv": "lingua franca nova"
  },
  "lg": {
    "en": "Ganda",
    "fi": "ganda",
    "sv": "luganda"
  },
  "li": {
    "en": "Limburgish",
    "fi": "limburg",
    "sv": "limburgiska"
  },
  "lij": {
    "en": "Ligurian",
    "fi": "liguuri",
    "sv": "liguriska"
  },
  "lil": {
    "en": "Lillooet",
    "fi": "lillooet",
    "sv": "lillooet"
  },
  "liv": {
    "en": "Livonian",
    "fi": "liivi",
    "sv": "livoniska"
  },
  "lkt": {
    "en": "Lakota",
    "fi": "lakota",
    "sv": "lakota"
  },
  "lmo": {
    "en": "Lombard",
    "fi": "lombardi",
    "sv": "lombardiska"
  },
  "ln": {
    "en": "Lingala",
    "fi": "lingala",
    "sv": "lingala"
  },
  "lo": {
    "en": "Lao",
    "fi": "lao",
    "sv": "laotiska"
  },
  "lol": {
    "en": "Mongo",
    "fi": "mongo",
    "sv": "mongo"
  },
  "lou": {
    "en": "Louisiana Creole",
    "fi": "louisianankreoli",
    "sv": "louisiana-kreol"
  },
  "loz": {
    "en": "Lozi",
    "fi": "lozi",
    "sv": "lozi"
  },
  "lrc": {
    "en": "Northern Luri",
    "fi": "pohjoisluri",
    "sv": "nordluri"
  },
  "lsm": {
    "en": "Saamia",
    "fi": "samia",
    "sv": "saamia"
  },
  "lt": {
    "en": "Lithuanian",
    "fi": "liettua",
    "sv": "litauiska"
  },
  "ltg": {
    "en": "Latgalian",
    "fi": "latgalli",
    "sv": "lettgalliska"
  },
  "lu": {
    "en": "Luba-Katanga",
    "fi": "katanganluba",
    "sv": "luba-katanga"
  },
  "lua": {
    "en": "Luba-Lulua",
    "fi": "luluanluba",
    "sv": "luba-lulua"
  },
  "lui": {
    "en": "Luiseno",
    "fi": "luiseño",
    "sv": "luiseño"
  },
  "lun": {
    "en": "Lunda",
    "fi": "lunda",
    "sv": "lunda"
  },
  "luo": {
    "en": "Luo",
    "fi": "luo",
    "sv": "luo"
  },
  "lus": {
    "en": "Mizo",
    "fi": "lusai",
    "sv": "lushai"
  },
  "luy": {
    "en": "Luyia",
    "fi": "luhya",
    "sv": "luhya"
  },
  "lv": {
    "en": "Latvian",
    "fi": "latvia",
    "sv": "lettiska"
  },
  "lzh": {
    "en": "Literary Chinese",
    "fi": "klassinen kiina",
    "sv": "litterär kineiska"
  },
  "lzz": {
    "en": "Laz",
    "fi": "lazi",
    "sv": "laziska"
  },
  "mad": {
    "en": "Madurese",
    "fi": "madura",
    "sv": "maduresiska"
  },
  "maf": {
    "en": "Mafa",
    "fi": "mafa",
    "sv": "mafa"
  },
  "mag": {
    "en": "Magahi",
    "fi": "magahi",
    "sv": "magahi"
  },
  "mai": {
    "en": "Maithili",
    "fi": "maithili",
    "sv": "maithili"
  },
  "mak": {
    "en": "Makasar",
    "fi": "makassar",
    "sv": "makasar"
  },
  "man": {
    "en": "Mandingo",
    "fi": "mandingo",
    "sv": "mande"
  },
  "mas": {
    "en": "Masai",
    "fi": "maasai",
    "sv": "massajiska"
  },
  "mde": {
    "en": "Maba",
    "fi": "maba",
    "sv": "maba"
  },
  "mdf": {
    "en": "Moksha",
    "fi": "mokša",
    "sv": "moksja"
  },
  "mdr": {
    "en": "Mandar",
    "fi": "mandar",
    "sv": "mandar"
  },
  "men": {
    "en": "Mende",
    "fi": "mende",
    "sv": "mende"
  },
  "mer": {
    "en": "Meru",
    "fi": "meru",
    "sv": "meru"
  },
  "mfe": {
    "en": "Morisyen",
    "fi": "morisyen",
    "sv": "mauritansk kreol"
  },
  "mg": {
    "en": "Malagasy",
    "fi": "malagassi",
    "sv": "malagassiska"
  },
  "mga": {
    "en": "Middle Irish",
    "fi": "keski-iiri",
    "sv": "medeliriska"
  },
  "mgh": {
    "en": "Makhuwa-Meetto",
    "fi": "makua-meetto",
    "sv": "makhuwa-meetto"
  },
  "mgo": {
    "en": "Metaʼ",
    "fi": "meta’",
    "sv": "meta’"
  },
  "mh": {
    "en": "Marshallese",
    "fi": "marshall",
    "sv": "marshalliska"
  },
  "mi": {
    "en": "Māori",
    "fi": "maori",
    "sv": "maori"
  },
  "mic": {
    "en": "Mi'kmaw",
    "fi": "micmac",
    "sv": "mi’kmaq"
  },
  "min": {
    "en": "Minangkabau",
    "fi": "minangkabau",
    "sv": "minangkabau"
  },
  "mk": {
    "en": "Macedonian",
    "fi": "makedonia",
    "sv": "makedonska"
  },
  "ml": {
    "en": "Malayalam",
    "fi": "malajalam",
    "sv": "malayalam"
  },
  "mn": {
    "en": "Mongolian",
    "fi": "mongoli",
    "sv": "mongoliska"
  },
  "mnc": {
    "en": "Manchu",
    "fi": "mantšu",
    "sv": "manchuriska"
  },
  "mni": {
    "en": "Manipuri",
    "fi": "manipuri",
    "sv": "manipuri"
  },
  "moe": {
    "en": "Innu-aimun",
    "fi": "innu-aimun",
    "sv": "innu-aimun"
  },
  "moh": {
    "en": "Mohawk",
    "fi": "mohawk",
    "sv": "mohawk"
  },
  "mos": {
    "en": "Mossi",
    "fi": "mossi",
    "sv": "mossi"
  },
  "mr": {
    "en": "Marathi",
    "fi": "marathi",
    "sv": "marathi"
  },
  "mrj": {
    "en": "Western Mari",
    "fi": "vuorimari",
    "sv": "västmariska"
  },
  "ms": {
    "en": "Malay",
    "fi": "malaiji",
    "sv": "malajiska"
  },
  "mt": {
    "en": "Maltese",
    "fi": "malta",
    "sv": "maltesiska"
  },
  "mua": {
    "en": "Mundang",
    "fi": "mundang",
    "sv": "mundang"
  },
  "mul": {
    "en": "Multiple languages",
    "fi": "useita kieliä",
    "sv": "flera språk"
  },
  "mus": {
    "en": "Muscogee",
    "fi": "creek",
    "sv": "muskogee"
  },
  "mwl": {
    "en": "Mirandese",
    "fi": "mirandeesi",
    "sv": "mirandesiska"
  },
  "mwr": {
    "en": "Marwari",
    "fi": "marwari",
    "sv": "marwari"
  },
  "mwv": {
    "en": "Mentawai",
    "fi": "mentawai",
    "sv": "mentawai"
  },
  "my": {
    "en": "Burmese",
    "fi": "burma",
    "sv": "burmesiska"
  },
  "my-alt-variant": {
    "en": "Myanmar Language"
  },
  "mye": {
    "en": "Myene",
    "fi": "myene",
    "sv": "myene"
  },
  "myv": {
    "en": "Erzya",
    "fi": "ersä",
    "sv": "erjya"
  },
  "mzn": {
    "en": "Mazanderani",
    "fi": "mazandarani",
    "sv": "mazanderani"
  },
  "na": {
    "en": "Nauru",
    "fi": "nauru",
    "sv": "nauruanska"
  },
  "nan": {
    "en": "Min Nan Chinese",
    "fi": "min nan -kiina",
    "sv": "min nan"
  },
  "nap": {
    "en": "Neapolitan",
    "fi": "napoli",
    "sv": "napolitanska"
  },
  "naq": {
    "en": "Nama",
    "fi": "nama",
    "sv": "nama"
  },
  "nb": {
    "en": "Norwegian Bokmål",
    "fi": "norjan bokmål",
    "sv": "norskt bokmål"
  },
  "nd": {
    "en": "North Ndebele",
    "fi": "pohjois-ndebele",
    "sv": "nordndebele"
  },
  "nds": {
    "en": "Low German",
    "fi": "alasaksa",
    "sv": "lågtyska"
  },
  "nds-NL": {
    "en": "Low Saxon",
    "fi": "alankomaidenalasaksa",
    "sv": "lågsaxiska"
  },
  "ne": {
    "en": "Nepali",
    "fi": "nepali",
    "sv": "nepalesiska"
  },
  "new": {
    "en": "Newari",
    "fi": "newari",
    "sv": "newariska"
  },
  "ng": {
    "en": "Ndonga",
    "fi": "ndonga",
    "sv": "ndonga"
  },
  "nia": {
    "en": "Nias",
    "fi": "nias",
    "sv": "nias"
  },
  "niu": {
    "en": "Niuean",
    "fi": "niue",
    "sv": "niueanska"
  },
  "njo": {
    "en": "Ao Naga",
    "fi": "ao naga",
    "sv": "ao-naga"
  },
  "nl": {
    "en": "Dutch",
    "fi": "hollanti",
    "sv": "nederländska"
  },
  "nl-BE": {
    "en": "Flemish",
    "fi": "flaami",
    "sv": "flamländska"
  },
  "nmg": {
    "en": "Kwasio",
    "fi": "kwasio",
    "sv": "kwasio"
  },
  "nn": {
    "en": "Norwegian Nynorsk",
    "fi": "norjan nynorsk",
    "sv": "nynorska"
  },
  "nnh": {
    "en": "Ngiemboon",
    "fi": "ngiemboon",
    "sv": "bamileké-ngiemboon"
  },
  "no": {
    "en": "Norwegian",
    "fi": "norja",
    "sv": "norska"
  },
  "nog": {
    "en": "Nogai",
    "fi": "nogai",
    "sv": "nogai"
  },
  "non": {
    "en": "Old Norse",
    "fi": "muinaisnorja",
    "sv": "fornnordiska"
  },
  "nov": {
    "en": "Novial",
    "fi": "novial",
    "sv": "novial"
  },
  "nqo": {
    "en": "N’Ko",
    "fi": "n’ko",
    "sv": "n-kå"
  },
  "nr": {
    "en": "South Ndebele",
    "fi": "etelä-ndebele",
    "sv": "sydndebele"
  },
  "nso": {
    "en": "Northern Sotho",
    "fi": "pohjoissotho",
    "sv": "nordsotho"
  },
  "nus": {
    "en": "Nuer",
    "fi": "nuer",
    "sv": "nuer"
  },
  "nv": {
    "en": "Navajo",
    "fi": "navajo",
    "sv": "navaho"
  },
  "nwc": {
    "en": "Classical Newari",
    "fi": "klassinen newari",
    "sv": "klassisk newariska"
  },
  "ny": {
    "en": "Nyanja",
    "fi": "njandža",
    "sv": "nyanja"
  },
  "nym": {
    "en": "Nyamwezi",
    "fi": "nyamwezi",
    "sv": "nyamwezi"
  },
  "nyn": {
    "en": "Nyankole",
    "fi": "nyankole",
    "sv": "nyankole"
  },
  "nyo": {
    "en": "Nyoro",
    "fi": "nyoro",
    "sv": "nyoro"
  },
  "nzi": {
    "en": "Nzima",
    "fi": "nzima",
    "sv": "nzima"
  },
  "oc": {
    "en": "Occitan",
    "fi": "oksitaani",
    "sv": "occitanska"
  },
  "oj": {
    "en": "Ojibwa",
    "fi": "odžibwa",
    "sv": "odjibwa"
  },
  "ojb": {
    "en": "Northwestern Ojibwa",
    "fi": "luoteis-odžibwa",
    "sv": "nordvästojibwa"
  },
  "ojc": {
    "en": "Central Ojibwa",
    "fi": "keskiojibwa",
    "sv": "ojibwa"
  },
  "ojg": {
    "en": "Eastern Ojibwa"
  },
  "ojs": {
    "en": "Oji-Cree",
    "fi": "oji-cree",
    "sv": "oji-cree"
  },
  "ojw": {
    "en": "Western Ojibwa",
    "fi": "länsi-odžibwa",
    "sv": "västojibwe"
  },
  "oka": {
    "en": "Okanagan",
    "fi": "okanagan",
    "sv": "okanagan"
  },
  "om": {
    "en": "Oromo",
    "fi": "oromo",
    "sv": "oromo"
  },
  "or": {
    "en": "Odia",
    "fi": "orija",
    "sv": "oriya"
  },
  "os": {
    "en": "Ossetic",
    "fi": "osseetti",
    "sv": "ossetiska"
  },
  "osa": {
    "en": "Osage",
    "fi": "osage",
    "sv": "osage"
  },
  "ota": {
    "en": "Ottoman Turkish",
    "fi": "osmani",
    "sv": "ottomanska"
  },
  "pa": {
    "en": "Punjabi",
    "fi": "pandžabi",
    "sv": "punjabi"
  },
  "pag": {
    "en": "Pangasinan",
    "fi": "pangasinan",
    "sv": "pangasinan"
  },
  "pal": {
    "en": "Pahlavi",
    "fi": "pahlavi",
    "sv": "medelpersiska"
  },
  "pam": {
    "en": "Pampanga",
    "fi": "pampanga",
    "sv": "pampanga"
  },
  "pap": {
    "en": "Papiamento",
    "fi": "papiamentu",
    "sv": "papiamento"
  },
  "pau": {
    "en": "Palauan",
    "fi": "palau",
    "sv": "palau"
  },
  "pcd": {
    "en": "Picard",
    "fi": "picardi",
    "sv": "pikardiska"
  },
  "pcm": {
    "en": "Nigerian Pidgin",
    "fi": "nigerianpidgin",
    "sv": "nigeriansk pidgin"
  },
  "pdc": {
    "en": "Pennsylvania German",
    "fi": "pennsylvaniansaksa",
    "sv": "Pennsylvaniatyska"
  },
  "pdt": {
    "en": "Plautdietsch",
    "fi": "plautdietsch",
    "sv": "mennonitisk lågtyska"
  },
  "peo": {
    "en": "Old Persian",
    "fi": "muinaispersia",
    "sv": "fornpersiska"
  },
  "pfl": {
    "en": "Palatine German",
    "fi": "pfaltsi",
    "sv": "Pfalz-tyska"
  },
  "phn": {
    "en": "Phoenician",
    "fi": "foinikia",
    "sv": "feniciska"
  },
  "pi": {
    "en": "Pali",
    "fi": "paali",
    "sv": "pali"
  },
  "pis": {
    "en": "Pijin",
    "fi": "pijin",
    "sv": "pidginspråk"
  },
  "pl": {
    "en": "Polish",
    "fi": "puola",
    "sv": "polska"
  },
  "pms": {
    "en": "Piedmontese",
    "fi": "piemonte",
    "sv": "piemontesiska"
  },
  "pnt": {
    "en": "Pontic",
    "fi": "pontoksenkreikka",
    "sv": "pontiska"
  },
  "pon": {
    "en": "Pohnpeian",
    "fi": "pohnpei",
    "sv": "pohnpeiska"
  },
  "pqm": {
    "en": "Maliseet-Passamaquoddy",
    "fi": "maliseet-passamaquoddy",
    "sv": "maliseet-passamaquoddy"
  },
  "prg": {
    "en": "Prussian",
    "fi": "muinaispreussi",
    "sv": "fornpreussiska"
  },
  "pro": {
    "en": "Old Provençal",
    "fi": "muinaisprovensaali",
    "sv": "fornprovensalska"
  },
  "ps": {
    "en": "Pashto",
    "fi": "paštu",
    "sv": "afghanska"
  },
  "ps-alt-variant": {
    "en": "Pushto",
    "sv": "pashto"
  },
  "pt": {
    "en": "Portuguese",
    "fi": "portugali",
    "sv": "portugisiska"
  },
  "pt-BR": {
    "en": "Brazilian Portuguese",
    "fi": "brasilianportugali",
    "sv": "brasiliansk portugisiska"
  },
  "pt-PT": {
    "en": "European Portuguese",
    "fi": "euroopanportugali",
    "sv": "europeisk portugisiska"
  },
  "qu": {
    "en": "Quechua",
    "fi": "ketšua",
    "sv": "quechua"
  },
  "quc": {
    "en": "Kʼicheʼ",
    "fi": "kʼicheʼ",
    "sv": "quiché"
  },
  "qug": {
    "en": "Chimborazo Highland Quichua",
    "fi": "chimborazonylänköketšua",
    "sv": "Chimborazo-höglandskichwa"
  },
  "raj": {
    "en": "Rajasthani",
    "fi": "radžastani",
    "sv": "rajasthani"
  },
  "rap": {
    "en": "Rapanui",
    "fi": "rapanui",
    "sv": "rapanui"
  },
  "rar": {
    "en": "Rarotongan",
    "fi": "rarotonga",
    "sv": "rarotonganska"
  },
  "rgn": {
    "en": "Romagnol",
    "fi": "romagnoli",
    "sv": "romagnol"
  },
  "rhg": {
    "en": "Rohingya",
    "fi": "rohinga",
    "sv": "ruáingga"
  },
  "rif": {
    "en": "Riffian",
    "fi": "tarifit",
    "sv": "riffianska"
  },
  "rm": {
    "en": "Romansh",
    "fi": "retoromaani",
    "sv": "rätoromanska"
  },
  "rn": {
    "en": "Rundi",
    "fi": "rundi",
    "sv": "rundi"
  },
  "ro": {
    "en": "Romanian",
    "fi": "romania",
    "sv": "rumänska"
  },
  "ro-MD": {
    "en": "Moldavian",
    "fi": "moldova",
    "sv": "moldaviska"
  },
  "rof": {
    "en": "Rombo",
    "fi": "rombo",
    "sv": "rombo"
  },
  "rom": {
    "en": "Romany",
    "fi": "romani",
    "sv": "romani"
  },
  "rtm": {
    "en": "Rotuman",
    "fi": "rotuma",
    "sv": "rotumänska"
  },
  "ru": {
    "en": "Russian",
    "fi": "venäjä",
    "sv": "ryska"
  },
  "rue": {
    "en": "Rusyn",
    "fi": "ruteeni",
    "sv": "rusyn"
  },
  "rug": {
    "en": "Roviana",
    "fi": "roviana",
    "sv": "rovianska"
  },
  "rup": {
    "en": "Aromanian",
    "fi": "aromania",
    "sv": "arumänska"
  },
  "rw": {
    "en": "Kinyarwanda",
    "fi": "ruanda",
    "sv": "kinjarwanda"
  },
  "rwk": {
    "en": "Rwa",
    "fi": "rwa",
    "sv": "rwa"
  },
  "sa": {
    "en": "Sanskrit",
    "fi": "sanskrit",
    "sv": "sanskrit"
  },
  "sad": {
    "en": "Sandawe",
    "fi": "sandawe",
    "sv": "sandawe"
  },
  "sah": {
    "en": "Yakut",
    "fi": "jakuutti",
    "sv": "jakutiska"
  },
  "sam": {
    "en": "Samaritan Aramaic",
    "fi": "samarianaramea",
    "sv": "samaritanska"
  },
  "saq": {
    "en": "Samburu",
    "fi": "samburu",
    "sv": "samburu"
  },
  "sas": {
    "en": "Sasak",
    "fi": "sasak",
    "sv": "sasak"
  },
  "sat": {
    "en": "Santali",
    "fi": "santali",
    "sv": "santali"
  },
  "saz": {
    "en": "Saurashtra",
    "fi": "sauraštri",
    "sv": "saurashtra"
  },
  "sba": {
    "en": "Ngambay",
    "fi": "ngambay",
    "sv": "ngambay"
  },
  "sbp": {
    "en": "Sangu",
    "fi": "sangu",
    "sv": "sangu"
  },
  "sc": {
    "en": "Sardinian",
    "fi": "sardi",
    "sv": "sardinska"
  },
  "scn": {
    "en": "Sicilian",
    "fi": "sisilia",
    "sv": "sicilianska"
  },
  "sco": {
    "en": "Scots",
    "fi": "skotti",
    "sv": "skotska"
  },
  "sd": {
    "en": "Sindhi",
    "fi": "sindhi",
    "sv": "sindhi"
  },
  "sdc": {
    "en": "Sassarese Sardinian",
    "fi": "sassarinsardi",
    "sv": "sassaresisk sardiska"
  },
  "sdh": {
    "en": "Southern Kurdish",
    "fi": "eteläkurdi",
    "sv": "sydkurdiska"
  },
  "se": {
    "en": "Northern Sami",
    "fi": "pohjoissaame",
    "sv": "nordsamiska"
  },
  "se-alt-menu": {
    "en": "Sami, Northern"
  },
  "see": {
    "en": "Seneca",
    "fi": "seneca",
    "sv": "seneca"
  },
  "seh": {
    "en": "Sena",
    "fi": "sena",
    "sv": "sena"
  },
  "sei": {
    "en": "Seri",
    "fi": "seri",
    "sv": "seri"
  },
  "sel": {
    "en": "Selkup",
    "fi": "selkuppi",
    "sv": "selkup"
  },
  "ses": {
    "en": "Koyraboro Senni",
    "fi": "koyraboro senni",
    "sv": "songhai"
  },
  "sg": {
    "en": "Sango",
    "fi": "sango",
    "sv": "sango"
  },
  "sga": {
    "en": "Old Irish",
    "fi": "muinaisiiri",
    "sv": "forniriska"
  },
  "sgs": {
    "en": "Samogitian",
    "fi": "samogiitti",
    "sv": "samogitiska"
  },
  "sh": {
    "en": "Serbo-Croatian",
    "fi": "serbokroaatti",
    "sv": "serbokroatiska"
  },
  "shi": {
    "en": "Tachelhit",
    "fi": "tašelhit",
    "sv": "tachelhit"
  },
  "shn": {
    "en": "Shan",
    "fi": "shan",
    "sv": "shan"
  },
  "shu": {
    "en": "Chadian Arabic",
    "fi": "tšadinarabia",
    "sv": "Tchad-arabiska"
  },
  "si": {
    "en": "Sinhala",
    "fi": "sinhala",
    "sv": "singalesiska"
  },
  "sid": {
    "en": "Sidamo",
    "fi": "sidamo",
    "sv": "sidamo"
  },
  "sk": {
    "en": "Slovak",
    "fi": "slovakki",
    "sv": "slovakiska"
  },
  "sl": {
    "en": "Slovenian",
    "fi": "sloveeni",
    "sv": "slovenska"
  },
  "slh": {
    "en": "Southern Lushootseed",
    "fi": "lushootseed (eteläinen)",
    "sv": "sydlushootseed"
  },
  "sli": {
    "en": "Lower Silesian",
    "fi": "sleesiansaksa",
    "sv": "lågsilesiska"
  },
  "sly": {
    "en": "Selayar",
    "fi": "selayar",
    "sv": "selayar"
  },
  "sm": {
    "en": "Samoan",
    "fi": "samoa",
    "sv": "samoanska"
  },
  "sma": {
    "en": "Southern Sami",
    "fi": "eteläsaame",
    "sv": "sydsamiska"
  },
  "sma-alt-menu": {
    "en": "Sami, Southern"
  },
  "smj": {
    "en": "Lule Sami",
    "fi": "luulajansaame",
    "sv": "lulesamiska"
  },
  "smj-alt-menu": {
    "en": "Sami, Lule"
  },
  "smn": {
    "en": "Inari Sami",
    "fi": "inarinsaame",
    "sv": "enaresamiska"
  },
  "smn-alt-menu": {
    "en": "Sami, Inari"
  },
  "sms": {
    "en": "Skolt Sami",
    "fi": "koltansaame",
    "sv": "skoltsamiska"
  },
  "sms-alt-menu": {
    "en": "Sami, Skolt"
  },
  "sn": {
    "en": "Shona",
    "fi": "šona",
    "sv": "shona"
  },
  "snk": {
    "en": "Soninke",
    "fi": "soninke",
    "sv": "soninke"
  },
  "so": {
    "en": "Somali",
    "fi": "somali",
    "sv": "somaliska"
  },
  "sog": {
    "en": "Sogdien",
    "fi": "sogdi",
    "sv": "sogdiska"
  },
  "sq": {
    "en": "Albanian",
    "fi": "albania",
    "sv": "albanska"
  },
  "sr": {
    "en": "Serbian",
    "fi": "serbia",
    "sv": "serbiska"
  },
  "sr-ME": {
    "en": "Montenegrin"
  },
  "srn": {
    "en": "Sranan Tongo",
    "fi": "sranan",
    "sv": "sranan tongo"
  },
  "srr": {
    "en": "Serer",
    "fi": "serer",
    "sv": "serer"
  },
  "ss": {
    "en": "Swati",
    "fi": "swazi",
    "sv": "swati"
  },
  "ssy": {
    "en": "Saho",
    "fi": "saho",
    "sv": "saho"
  },
  "st": {
    "en": "Southern Sotho",
    "fi": "eteläsotho",
    "sv": "sydsotho"
  },
  "stq": {
    "en": "Saterland Frisian",
    "fi": "saterlandinfriisi",
    "sv": "saterfrisiska"
  },
  "str": {
    "en": "Straits Salish",
    "fi": "straits-salish",
    "sv": "sundsalishanska"
  },
  "su": {
    "en": "Sundanese",
    "fi": "sunda",
    "sv": "sundanesiska"
  },
  "suk": {
    "en": "Sukuma",
    "fi": "sukuma",
    "sv": "sukuma"
  },
  "sus": {
    "en": "Susu",
    "fi": "susu",
    "sv": "susu"
  },
  "sux": {
    "en": "Sumerian",
    "fi": "sumeri",
    "sv": "sumeriska"
  },
  "sv": {
    "en": "Swedish",
    "fi": "ruotsi",
    "sv": "svenska"
  },
  "sw": {
    "en": "Swahili",
    "fi": "swahili",
    "sv": "swahili"
  },
  "sw-CD": {
    "en": "Congo Swahili",
    "fi": "kongonswahili",
    "sv": "Kongo-swahili"
  },
  "swb": {
    "en": "Comorian",
    "fi": "komori",
    "sv": "shimaoré"
  },
  "syc": {
    "en": "Classical Syriac",
    "fi": "muinaissyyria",
    "sv": "klassisk syriska"
  },
  "syr": {
    "en": "Syriac",
    "fi": "syyria",
    "sv": "syriska"
  },
  "szl": {
    "en": "Silesian",
    "fi": "sleesia",
    "sv": "silesiska"
  },
  "ta": {
    "en": "Tamil",
    "fi": "tamili",
    "sv": "tamil"
  },
  "tce": {
    "en": "Southern Tutchone",
    "fi": "etelätutchone",
    "sv": "sydtutchone"
  },
  "tcy": {
    "en": "Tulu",
    "fi": "tulu",
    "sv": "tulu"
  },
  "te": {
    "en": "Telugu",
    "fi": "telugu",
    "sv": "telugu"
  },
  "tem": {
    "en": "Timne",
    "fi": "temne",
    "sv": "temne"
  },
  "teo": {
    "en": "Teso",
    "fi": "teso",
    "sv": "teso"
  },
  "ter": {
    "en": "Tereno",
    "fi": "tereno",
    "sv": "tereno"
  },
  "tet": {
    "en": "Tetum",
    "fi": "tetum",
    "sv": "tetum"
  },
  "tg": {
    "en": "Tajik",
    "fi": "tadžikki",
    "sv": "tadzjikiska"
  },
  "tgx": {
    "en": "Tagish",
    "fi": "tagish",
    "sv": "tagish"
  },
  "th": {
    "en": "Thai",
    "fi": "thai",
    "sv": "thailändska"
  },
  "tht": {
    "en": "Tahltan",
    "fi": "tahlta",
    "sv": "tahltan"
  },
  "ti": {
    "en": "Tigrinya",
    "fi": "tigrinja",
    "sv": "tigrinja"
  },
  "tig": {
    "en": "Tigre",
    "fi": "tigre",
    "sv": "tigré"
  },
  "tiv": {
    "en": "Tiv",
    "fi": "tiv",
    "sv": "tivi"
  },
  "tk": {
    "en": "Turkmen",
    "fi": "turkmeeni",
    "sv": "turkmeniska"
  },
  "tkl": {
    "en": "Tokelau",
    "fi": "tokelau",
    "sv": "tokelauiska"
  },
  "tkr": {
    "en": "Tsakhur",
    "fi": "tsahuri",
    "sv": "tsakhur"
  },
  "tl": {
    "en": "Tagalog",
    "fi": "tagalog",
    "sv": "tagalog"
  },
  "tlh": {
    "en": "Klingon",
    "fi": "klingon",
    "sv": "klingonska"
  },
  "tli": {
    "en": "Tlingit",
    "fi": "tlingit",
    "sv": "tlingit"
  },
  "tly": {
    "en": "Talysh",
    "fi": "tališi",
    "sv": "talysh"
  },
  "tmh": {
    "en": "Tamashek",
    "fi": "tamašek",
    "sv": "tamashek"
  },
  "tn": {
    "en": "Tswana",
    "fi": "tswana",
    "sv": "tswana"
  },
  "to": {
    "en": "Tongan",
    "fi": "tonga",
    "sv": "tonganska"
  },
  "tog": {
    "en": "Nyasa Tonga",
    "fi": "malawintonga",
    "sv": "nyasatonganska"
  },
  "tok": {
    "en": "Toki Pona",
    "fi": "toki pona",
    "sv": "toki pona"
  },
  "tpi": {
    "en": "Tok Pisin",
    "fi": "tok-pisin",
    "sv": "tok pisin"
  },
  "tr": {
    "en": "Turkish",
    "fi": "turkki",
    "sv": "turkiska"
  },
  "tru": {
    "en": "Turoyo",
    "fi": "turojo",
    "sv": "turoyo"
  },
  "trv": {
    "en": "Taroko",
    "fi": "taroko",
    "sv": "taroko"
  },
  "trw": {
    "en": "Torwali",
    "fi": "torwali"
  },
  "ts": {
    "en": "Tsonga",
    "fi": "tsonga",
    "sv": "tsonga"
  },
  "tsd": {
    "en": "Tsakonian",
    "fi": "tsakonia",
    "sv": "tsakodiska"
  },
  "tsi": {
    "en": "Tsimshian",
    "fi": "tsimši",
    "sv": "tsimshian"
  },
  "tt": {
    "en": "Tatar",
    "fi": "tataari",
    "sv": "tatariska"
  },
  "ttm": {
    "en": "Northern Tutchone",
    "fi": "pohjoinen tutchone",
    "sv": "nordtutchone"
  },
  "ttt": {
    "en": "Muslim Tat",
    "fi": "tati",
    "sv": "muslimsk tatariska"
  },
  "tum": {
    "en": "Tumbuka",
    "fi": "tumbuka",
    "sv": "tumbuka"
  },
  "tvl": {
    "en": "Tuvalu",
    "fi": "tuvalu",
    "sv": "tuvaluanska"
  },
  "tw": {
    "en": "Twi",
    "fi": "twi",
    "sv": "twi"
  },
  "twq": {
    "en": "Tasawaq",
    "fi": "tasawaq",
    "sv": "tasawaq"
  },
  "ty": {
    "en": "Tahitian",
    "fi": "tahiti",
    "sv": "tahitiska"
  },
  "tyv": {
    "en": "Tuvinian",
    "fi": "tuva",
    "sv": "tuviniska"
  },
  "tzm": {
    "en": "Central Atlas Tamazight",
    "fi": "keskiatlaksentamazight",
    "sv": "centralmarockansk tamazight"
  },
  "udm": {
    "en": "Udmurt",
    "fi": "udmurtti",
    "sv": "udmurtiska"
  },
  "ug": {
    "en": "Uyghur",
    "fi": "uiguuri",
    "sv": "uiguriska"
  },
  "ug-alt-variant": {
    "en": "Uighur",
    "sv": "östturkiska"
  },
  "uga": {
    "en": "Ugaritic",
    "fi": "ugarit",
    "sv": "ugaritiska"
  },
  "uk": {
    "en": "Ukrainian",
    "fi": "ukraina",
    "sv": "ukrainska"
  },
  "umb": {
    "en": "Umbundu",
    "fi": "mbundu",
    "sv": "umbundu"
  },
  "und": {
    "en": "Unknown language",
    "fi": "tuntematon kieli",
    "sv": "obestämt språk"
  },
  "ur": {
    "en": "Urdu",
    "fi": "urdu",
    "sv": "urdu"
  },
  "uz": {
    "en": "Uzbek",
    "fi": "uzbekki",
    "sv": "uzbekiska"
  },
  "vai": {
    "en": "Vai",
    "fi": "vai",
    "sv": "vaj"
  },
  "ve": {
    "en": "Venda",
    "fi": "venda",
    "sv": "venda"
  },
  "vec": {
    "en": "Venetian",
    "fi": "venetsia",
    "sv": "venetianska"
  },
  "vep": {
    "en": "Veps",
    "fi": "vepsä",
    "sv": "veps"
  },
  "vi": {
    "en": "Vietnamese",
    "fi": "vietnam",
    "sv": "vietnamesiska"
  },
  "vls": {
    "en": "West Flemish",
    "fi": "länsiflaami",
    "sv": "västflamländska"
  },
  "vmf": {
    "en": "Main-Franconian",
    "fi": "maininfrankki",
    "sv": "Main-frankiska"
  },
  "vmw": {
    "en": "Makhuwa",
    "fi": "makhuwa"
  },
  "vo": {
    "en": "Volapük",
    "fi": "volapük",
    "sv": "volapük"
  },
  "vot": {
    "en": "Votic",
    "fi": "vatja",
    "sv": "votiska"
  },
  "vro": {
    "en": "Võro",
    "fi": "võro",
    "sv": "võru"
  },
  "vun": {
    "en": "Vunjo",
    "fi": "vunjo",
    "sv": "vunjo"
  },
  "wa": {
    "en": "Walloon",
    "fi": "valloni",
    "sv": "vallonska"
  },
  "wae": {
    "en": "Walser",
    "fi": "walser",
    "sv": "walsertyska"
  },
  "wal": {
    "en": "Wolaytta",
    "fi": "wolaitta",
    "sv": "walamo"
  },
  "war": {
    "en": "Waray",
    "fi": "waray",
    "sv": "waray"
  },
  "was": {
    "en": "Washo",
    "fi": "washo",
    "sv": "washo"
  },
  "wbp": {
    "en": "Warlpiri",
    "fi": "warlpiri",
    "sv": "warlpiri"
  },
  "wo": {
    "en": "Wolof",
    "fi": "wolof",
    "sv": "wolof"
  },
  "wuu": {
    "en": "Wu Chinese",
    "fi": "wu-kiina",
    "sv": "wu"
  },
  "xal": {
    "en": "Kalmyk",
    "fi": "kalmukki",
    "sv": "kalmuckiska"
  },
  "xh": {
    "en": "Xhosa",
    "fi": "xhosa",
    "sv": "xhosa"
  },
  "xmf": {
    "en": "Mingrelian",
    "fi": "mingreli",
    "sv": "mingrelianska"
  },
  "xnr": {
    "en": "Kangri",
    "fi": "kangri"
  },
  "xog": {
    "en": "Soga",
    "fi": "soga",
    "sv": "lusoga"
  },
  "yao": {
    "en": "Yao",
    "fi": "jao",
    "sv": "kiyao"
  },
  "yap": {
    "en": "Yapese",
    "fi": "japi",
    "sv": "japetiska"
  },
  "yav": {
    "en": "Yangben",
    "fi": "yangben",
    "sv": "yangben"
  },
  "ybb": {
    "en": "Yemba",
    "fi": "yemba",
    "sv": "bamileké-jemba"
  },
  "yi": {
    "en": "Yiddish",
    "fi": "jiddiš",
    "sv": "jiddisch"
  },
  "yo": {
    "en": "Yoruba",
    "fi": "joruba",
    "sv": "yoruba"
  },
  "yrl": {
    "en": "Nheengatu",
    "fi": "ñeengatú",
    "sv": "nheengatu"
  },
  "yue": {
    "en": "Cantonese",
    "fi": "kantoninkiina",
    "sv": "kantonesiska"
  },
  "yue-alt-menu": {
    "en": "Chinese, Cantonese",
    "fi": "kiina (kantonin)",
    "sv": "kantonesiska"
  },
  "za": {
    "en": "Zhuang",
    "fi": "zhuang",
    "sv": "zhuang"
  },
  "zap": {
    "en": "Zapotec",
    "fi": "zapoteekki",
    "sv": "zapotek"
  },
  "zbl": {
    "en": "Blissymbols",
    "fi": "blisskieli",
    "sv": "blissymboler"
  },
  "zea": {
    "en": "Zeelandic",
    "fi": "seelanti",
    "sv": "zeeländska"
  },
  "zen": {
    "en": "Zenaga",
    "fi": "zenaga",
    "sv": "zenaga"
  },
  "zgh": {
    "en": "Standard Moroccan Tamazight",
    "fi": "vakioitu tamazight",
    "sv": "marockansk standard-tamazight"
  },
  "zh": {
    "en": "Chinese",
    "fi": "kiina",
    "sv": "kinesiska"
  },
  "zh-alt-long": {
    "en": "Mandarin Chinese"
  },
  "zh-alt-menu": {
    "en": "Chinese, Mandarin",
    "fi": "kiina (mandariini)",
    "sv": "mandarin"
  },
  "zh-Hans": {
    "en": "Simplified Chinese",
    "sv": "förenklad kinesiska"
  },
  "zh-Hans-alt-long": {
    "en": "Simplified Mandarin Chinese",
    "fi": "mandariinikiina (yksinkertaistettu)",
    "sv": "förenklad kinesiska"
  },
  "zh-Hant": {
    "en": "Traditional Chinese",
    "sv": "traditionell kinesiska"
  },
  "zh-Hant-alt-long": {
    "en": "Traditional Mandarin Chinese",
    "fi": "mandariinikiina (perinteinen)",
    "sv": "traditionell kinesiska"
  },
  "zu": {
    "en": "Zulu",
    "fi": "zulu",
    "sv": "zulu"
  },
  "zun": {
    "en": "Zuni",
    "fi": "zuni",
    "sv": "zuni"
  },
  "zxx": {
    "en": "No linguistic content",
    "fi": "ei kielellistä sisältöä",
    "sv": "inget språkligt innehåll"
  },
  "zza": {
    "en": "Zaza",
    "fi": "zaza",
    "sv": "zazaiska"
  },
  "ajp": {
    "fi": "urduni"
  },
  "crh-alt-secondary": {
    "fi": "kriminturkki"
  },
  "kl-alt-secondary": {
    "fi": "grönlanti"
  },
  "lad-alt-secondary": {
    "fi": "juutalaisespanja"
  },
  "skr": {
    "fi": "saraiki"
  },
  "af-NA": {
    "en": "Afrikaans (NA)",
    "fi": "afrikaans (NA)",
    "sv": "afrikaans (NA)"
  },
  "ar-AE": {
    "en": "Arabic (AE)",
    "fi": "arabia (AE)",
    "sv": "arabiska (AE)"
  },
  "ar-BH": {
    "en": "Arabic (BH)",
    "fi": "arabia (BH)",
    "sv": "arabiska (BH)"
  },
  "ar-DJ": {
    "en": "Arabic (DJ)",
    "fi": "arabia (DJ)",
    "sv": "arabiska (DJ)"
  },
  "ar-DZ": {
    "en": "Arabic (DZ)",
    "fi": "arabia (DZ)",
    "sv": "arabiska (DZ)"
  },
  "ar-EG": {
    "en": "Arabic (EG)",
    "fi": "arabia (EG)",
    "sv": "arabiska (EG)"
  },
  "ar-EH": {
    "en": "Arabic (EH)",
    "fi": "arabia (EH)",
    "sv": "arabiska (EH)"
  },
  "ar-ER": {
    "en": "Arabic (ER)",
    "fi": "arabia (ER)",
    "sv": "arabiska (ER)"
  },
  "ar-IL": {
    "en": "Arabic (IL)",
    "fi": "arabia (IL)",
    "sv": "arabiska (IL)"
  },
  "ar-IQ": {
    "en": "Arabic (IQ)",
    "fi": "arabia (IQ)",
    "sv": "arabiska (IQ)"
  },
  "ar-JO": {
    "en": "Arabic (JO)",
    "fi": "arabia (JO)",
    "sv": "arabiska (JO)"
  },
  "ar-KM": {
    "en": "Arabic (KM)",
    "fi": "arabia (KM)",
    "sv": "arabiska (KM)"
  },
  "ar-KW": {
    "en": "Arabic (KW)",
    "fi": "arabia (KW)",
    "sv": "arabiska (KW)"
  },
  "ar-LB": {
    "en": "Arabic (LB)",
    "fi": "arabia (LB)",
    "sv": "arabiska (LB)"
  },
  "ar-LY": {
    "en": "Arabic (LY)",
    "fi": "arabia (LY)",
    "sv": "arabiska (LY)"
  },
  "ar-MA": {
    "en": "Arabic (MA)",
    "fi": "arabia (MA)",
    "sv": "arabiska (MA)"
  },
  "ar-MR": {
    "en": "Arabic (MR)",
    "fi": "arabia (MR)",
    "sv": "arabiska (MR)"
  },
  "ar-OM": {
    "en": "Arabic (OM)",
    "fi": "arabia (OM)",
    "sv": "arabiska (OM)"
  },
  "ar-PS": {
    "en": "Arabic (PS)",
    "fi": "arabia (PS)",
    "sv": "arabiska (PS)"
  },
  "ar-QA": {
    "en": "Arabic (QA)",
    "fi": "arabia (QA)",
    "sv": "arabiska (QA)"
  },
  "ar-SA": {
    "en": "Arabic (SA)",
    "fi": "arabia (SA)",
    "sv": "arabiska (SA)"
  },
  "ar-SD": {
    "en": "Arabic (SD)",
    "fi": "arabia (SD)",
    "sv": "arabiska (SD)"
  },
  "ar-SO": {
    "en": "Arabic (SO)",
    "fi": "arabia (SO)",
    "sv": "arabiska (SO)"
  },
  "ar-SS": {
    "en": "Arabic (SS)",
    "fi": "arabia (SS)",
    "sv": "arabiska (SS)"
  },
  "ar-SY": {
    "en": "Arabic (SY)",
    "fi": "arabia (SY)",
    "sv": "arabiska (SY)"
  },
  "ar-TD": {
    "en": "Arabic (TD)",
    "fi": "arabia (TD)",
    "sv": "arabiska (TD)"
  },
  "ar-TN": {
    "en": "Arabic (TN)",
    "fi": "arabia (TN)",
    "sv": "arabiska (TN)"
  },
  "ar-YE": {
    "en": "Arabic (YE)",
    "fi": "arabia (YE)",
    "sv": "arabiska (YE)"
  },
  "az-Latn": {
    "en": "Azerbaijani (Latn)",
    "fi": "azeri (Latn)",
    "sv": "azerbajdzjanska (Latn)"
  },
  "be-tarask": {
    "en": "Belarusian (tarask)",
    "fi": "valkovenäjä (tarask)",
    "sv": "belarusiska (tarask)"
  },
  "bn-IN": {
    "en": "Bangla (IN)",
    "fi": "bengali (IN)",
    "sv": "bengali (IN)"
  },
  "bs-Latn": {
    "en": "Bosnian (Latn)",
    "fi": "bosnia (Latn)",
    "sv": "bosniska (Latn)"
  },
  "ca-AD": {
    "en": "Catalan (AD)",
    "fi": "katalaani (AD)",
    "sv": "katalanska (AD)"
  },
  "ca-ES-valencia": {
    "en": "Catalan (ES-valencia)",
    "fi": "katalaani (ES-valencia)",
    "sv": "katalanska (ES-valencia)"
  },
  "ca-FR": {
    "en": "Catalan (FR)",
    "fi": "katalaani (FR)",
    "sv": "katalanska (FR)"
  },
  "ca-IT": {
    "en": "Catalan (IT)",
    "fi": "katalaani (IT)",
    "sv": "katalanska (IT)"
  },
  "da-GL": {
    "en": "Danish (GL)",
    "fi": "tanska (GL)",
    "sv": "danska (GL)"
  },
  "de-BE": {
    "en": "German (BE)",
    "fi": "saksa (BE)",
    "sv": "tyska (BE)"
  },
  "de-IT": {
    "en": "German (IT)",
    "fi": "saksa (IT)",
    "sv": "tyska (IT)"
  },
  "de-LI": {
    "en": "German (LI)",
    "fi": "saksa (LI)",
    "sv": "tyska (LI)"
  },
  "de-LU": {
    "en": "German (LU)",
    "fi": "saksa (LU)",
    "sv": "tyska (LU)"
  },
  "el-CY": {
    "en": "Greek (CY)",
    "fi": "kreikka (CY)",
    "sv": "grekiska (CY)"
  },
  "el-polyton": {
    "en": "Greek (polyton)",
    "fi": "kreikka (polyton)",
    "sv": "grekiska (polyton)"
  },
  "en-001": {
    "en": "English (001)",
    "fi": "englanti (001)",
    "sv": "engelska (001)"
  },
  "en-150": {
    "en": "English (150)",
    "fi": "englanti (150)",
    "sv": "engelska (150)"
  },
  "en-AE": {
    "en": "English (AE)",
    "fi": "englanti (AE)",
    "sv": "engelska (AE)"
  },
  "en-AG": {
    "en": "English (AG)",
    "fi": "englanti (AG)",
    "sv": "engelska (AG)"
  },
  "en-AI": {
    "en": "English (AI)",
    "fi": "englanti (AI)",
    "sv": "engelska (AI)"
  },
  "en-AS": {
    "en": "English (AS)",
    "fi": "englanti (AS)",
    "sv": "engelska (AS)"
  },
  "en-AT": {
    "en": "English (AT)",
    "fi": "englanti (AT)",
    "sv": "engelska (AT)"
  },
  "en-BB": {
    "en": "English (BB)",
    "fi": "englanti (BB)",
    "sv": "engelska (BB)"
  },
  "en-BE": {
    "en": "English (BE)",
    "fi": "englanti (BE)",
    "sv": "engelska (BE)"
  },
  "en-BI": {
    "en": "English (BI)",
    "fi": "englanti (BI)",
    "sv": "engelska (BI)"
  },
  "en-BM": {
    "en": "English (BM)",
    "fi": "englanti (BM)",
    "sv": "engelska (BM)"
  },
  "en-BS": {
    "en": "English (BS)",
    "fi": "englanti (BS)",
    "sv": "engelska (BS)"
  },
  "en-BW": {
    "en": "English (BW)",
    "fi": "englanti (BW)",
    "sv": "engelska (BW)"
  },
  "en-BZ": {
    "en": "English (BZ)",
    "fi": "englanti (BZ)",
    "sv": "engelska (BZ)"
  },
  "en-CC": {
    "en": "English (CC)",
    "fi": "englanti (CC)",
    "sv": "engelska (CC)"
  },
  "en-CH": {
    "en": "English (CH)",
    "fi": "englanti (CH)",
    "sv": "engelska (CH)"
  },
  "en-CK": {
    "en": "English (CK)",
    "fi": "englanti (CK)",
    "sv": "engelska (CK)"
  },
  "en-CM": {
    "en": "English (CM)",
    "fi": "englanti (CM)",
    "sv": "engelska (CM)"
  },
  "en-CX": {
    "en": "English (CX)",
    "fi": "englanti (CX)",
    "sv": "engelska (CX)"
  },
  "en-CY": {
    "en": "English (CY)",
    "fi": "englanti (CY)",
    "sv": "engelska (CY)"
  },
  "en-DE": {
    "en": "English (DE)",
    "fi": "englanti (DE)",
    "sv": "engelska (DE)"
  },
  "en-DG": {
    "en": "English (DG)",
    "fi": "englanti (DG)",
    "sv": "engelska (DG)"
  },
  "en-DK": {
    "en": "English (DK)",
    "fi": "englanti (DK)",
    "sv": "engelska (DK)"
  },
  "en-DM": {
    "en": "English (DM)",
    "fi": "englanti (DM)",
    "sv": "engelska (DM)"
  },
  "en-ER": {
    "en": "English (ER)",
    "fi": "englanti (ER)",
    "sv": "engelska (ER)"
  },
  "en-FI": {
    "en": "English (FI)",
    "fi": "englanti (FI)",
    "sv": "engelska (FI)"
  },
  "en-FJ": {
    "en": "English (FJ)",
    "fi": "englanti (FJ)",
    "sv": "engelska (FJ)"
  },
  "en-FK": {
    "en": "English (FK)",
    "fi": "englanti (FK)",
    "sv": "engelska (FK)"
  },
  "en-FM": {
    "en": "English (FM)",
    "fi": "englanti (FM)",
    "sv": "engelska (FM)"
  },
  "en-GD": {
    "en": "English (GD)",
    "fi": "englanti (GD)",
    "sv": "engelska (GD)"
  },
  "en-GG": {
    "en": "English (GG)",
    "fi": "englanti (GG)",
    "sv": "engelska (GG)"
  },
  "en-GH": {
    "en": "English (GH)",
    "fi": "englanti (GH)",
    "sv": "engelska (GH)"
  },
  "en-GI": {
    "en": "English (GI)",
    "fi": "englanti (GI)",
    "sv": "engelska (GI)"
  },
  "en-GM": {
    "en": "English (GM)",
    "fi": "englanti (GM)",
    "sv": "engelska (GM)"
  },
  "en-GU": {
    "en": "English (GU)",
    "fi": "englanti (GU)",
    "sv": "engelska (GU)"
  },
  "en-GY": {
    "en": "English (GY)",
    "fi": "englanti (GY)",
    "sv": "engelska (GY)"
  },
  "en-HK": {
    "en": "English (HK)",
    "fi": "englanti (HK)",
    "sv": "engelska (HK)"
  },
  "en-ID": {
    "en": "English (ID)",
    "fi": "englanti (ID)",
    "sv": "engelska (ID)"
  },
  "en-IE": {
    "en": "English (IE)",
    "fi": "englanti (IE)",
    "sv": "engelska (IE)"
  },
  "en-IL": {
    "en": "English (IL)",
    "fi": "englanti (IL)",
    "sv": "engelska (IL)"
  },
  "en-IM": {
    "en": "English (IM)",
    "fi": "englanti (IM)",
    "sv": "engelska (IM)"
  },
  "en-IN": {
    "en": "English (IN)",
    "fi": "englanti (IN)",
    "sv": "engelska (IN)"
  },
  "en-IO": {
    "en": "English (IO)",
    "fi": "englanti (IO)",
    "sv": "engelska (IO)"
  },
  "en-JE": {
    "en": "English (JE)",
    "fi": "englanti (JE)",
    "sv": "engelska (JE)"
  },
  "en-JM": {
    "en": "English (JM)",
    "fi": "englanti (JM)",
    "sv": "engelska (JM)"
  },
  "en-KE": {
    "en": "English (KE)",
    "fi": "englanti (KE)",
    "sv": "engelska (KE)"
  },
  "en-KI": {
    "en": "English (KI)",
    "fi": "englanti (KI)",
    "sv": "engelska (KI)"
  },
  "en-KN": {
    "en": "English (KN)",
    "fi": "englanti (KN)",
    "sv": "engelska (KN)"
  },
  "en-KY": {
    "en": "English (KY)",
    "fi": "englanti (KY)",
    "sv": "engelska (KY)"
  },
  "en-LC": {
    "en": "English (LC)",
    "fi": "englanti (LC)",
    "sv": "engelska (LC)"
  },
  "en-LR": {
    "en": "English (LR)",
    "fi": "englanti (LR)",
    "sv": "engelska (LR)"
  },
  "en-LS": {
    "en": "English (LS)",
    "fi": "englanti (LS)",
    "sv": "engelska (LS)"
  },
  "en-MG": {
    "en": "English (MG)",
    "fi": "englanti (MG)",
    "sv": "engelska (MG)"
  },
  "en-MH": {
    "en": "English (MH)",
    "fi": "englanti (MH)",
    "sv": "engelska (MH)"
  },
  "en-MO": {
    "en": "English (MO)",
    "fi": "englanti (MO)",
    "sv": "engelska (MO)"
  },
  "en-MP": {
    "en": "English (MP)",
    "fi": "englanti (MP)",
    "sv": "engelska (MP)"
  },
  "en-MS": {
    "en": "English (MS)",
    "fi": "englanti (MS)",
    "sv": "engelska (MS)"
  },
  "en-MT": {
    "en": "English (MT)",
    "fi": "englanti (MT)",
    "sv": "engelska (MT)"
  },
  "en-MU": {
    "en": "English (MU)",
    "fi": "englanti (MU)",
    "sv": "engelska (MU)"
  },
  "en-MV": {
    "en": "English (MV)",
    "fi": "englanti (MV)",
    "sv": "engelska (MV)"
  },
  "en-MW": {
    "en": "English (MW)",
    "fi": "englanti (MW)",
    "sv": "engelska (MW)"
  },
  "en-MY": {
    "en": "English (MY)",
    "fi": "englanti (MY)",
    "sv": "engelska (MY)"
  },
  "en-NA": {
    "en": "English (NA)",
    "fi": "englanti (NA)",
    "sv": "engelska (NA)"
  },
  "en-NF": {
    "en": "English (NF)",
    "fi": "englanti (NF)",
    "sv": "engelska (NF)"
  },
  "en-NG": {
    "en": "English (NG)",
    "fi": "englanti (NG)",
    "sv": "engelska (NG)"
  },
  "en-NL": {
    "en": "English (NL)",
    "fi": "englanti (NL)",
    "sv": "engelska (NL)"
  },
  "en-NR": {
    "en": "English (NR)",
    "fi": "englanti (NR)",
    "sv": "engelska (NR)"
  },
  "en-NU": {
    "en": "English (NU)",
    "fi": "englanti (NU)",
    "sv": "engelska (NU)"
  },
  "en-NZ": {
    "en": "English (NZ)",
    "fi": "englanti (NZ)",
    "sv": "engelska (NZ)"
  },
  "en-PG": {
    "en": "English (PG)",
    "fi": "englanti (PG)",
    "sv": "engelska (PG)"
  },
  "en-PH": {
    "en": "English (PH)",
    "fi": "englanti (PH)",
    "sv": "engelska (PH)"
  },
  "en-PK": {
    "en": "English (PK)",
    "fi": "englanti (PK)",
    "sv": "engelska (PK)"
  },
  "en-PN": {
    "en": "English (PN)",
    "fi": "englanti (PN)",
    "sv": "engelska (PN)"
  },
  "en-PR": {
    "en": "English (PR)",
    "fi": "englanti (PR)",
    "sv": "engelska (PR)"
  },
  "en-PW": {
    "en": "English (PW)",
    "fi": "englanti (PW)",
    "sv": "engelska (PW)"
  },
  "en-RW": {
    "en": "English (RW)",
    "fi": "englanti (RW)",
    "sv": "engelska (RW)"
  },
  "en-SB": {
    "en": "English (SB)",
    "fi": "englanti (SB)",
    "sv": "engelska (SB)"
  },
  "en-SC": {
    "en": "English (SC)",
    "fi": "englanti (SC)",
    "sv": "engelska (SC)"
  },
  "en-SD": {
    "en": "English (SD)",
    "fi": "englanti (SD)",
    "sv": "engelska (SD)"
  },
  "en-SE": {
    "en": "English (SE)",
    "fi": "englanti (SE)",
    "sv": "engelska (SE)"
  },
  "en-SG": {
    "en": "English (SG)",
    "fi": "englanti (SG)",
    "sv": "engelska (SG)"
  },
  "en-SH": {
    "en": "English (SH)",
    "fi": "englanti (SH)",
    "sv": "engelska (SH)"
  },
  "en-SI": {
    "en": "English (SI)",
    "fi": "englanti (SI)",
    "sv": "engelska (SI)"
  },
  "en-SL": {
    "en": "English (SL)",
    "fi": "englanti (SL)",
    "sv": "engelska (SL)"
  },
  "en-SS": {
    "en": "English (SS)",
    "fi": "englanti (SS)",
    "sv": "engelska (SS)"
  },
  "en-SX": {
    "en": "English (SX)",
    "fi": "englanti (SX)",
    "sv": "engelska (SX)"
  },
  "en-SZ": {
    "en": "English (SZ)",
    "fi": "englanti (SZ)",
    "sv": "engelska (SZ)"
  },
  "en-TC": {
    "en": "English (TC)",
    "fi": "englanti (TC)",
    "sv": "engelska (TC)"
  },
  "en-TK": {
    "en": "English (TK)",
    "fi": "englanti (TK)",
    "sv": "engelska (TK)"
  },
  "en-TO": {
    "en": "English (TO)",
    "fi": "englanti (TO)",
    "sv": "engelska (TO)"
  },
  "en-TT": {
    "en": "English (TT)",
    "fi": "englanti (TT)",
    "sv": "engelska (TT)"
  },
  "en-TV": {
    "en": "English (TV)",
    "fi": "englanti (TV)",
    "sv": "engelska (TV)"
  },
  "en-TZ": {
    "en": "English (TZ)",
    "fi": "englanti (TZ)",
    "sv": "engelska (TZ)"
  },
  "en-UG": {
    "en": "English (UG)",
    "fi": "englanti (UG)",
    "sv": "engelska (UG)"
  },
  "en-UM": {
    "en": "English (UM)",
    "fi": "englanti (UM)",
    "sv": "engelska (UM)"
  },
  "en-VC": {
    "en": "English (VC)",
    "fi": "englanti (VC)",
    "sv": "engelska (VC)"
  },
  "en-VG": {
    "en": "English (VG)",
    "fi": "englanti (VG)",
    "sv": "engelska (VG)"
  },
  "en-VI": {
    "en": "English (VI)",
    "fi": "englanti (VI)",
    "sv": "engelska (VI)"
  },
  "en-VU": {
    "en": "English (VU)",
    "fi": "englanti (VU)",
    "sv": "engelska (VU)"
  },
  "en-WS": {
    "en": "English (WS)",
    "fi": "englanti (WS)",
    "sv": "engelska (WS)"
  },
  "en-ZA": {
    "en": "English (ZA)",
    "fi": "englanti (ZA)",
    "sv": "engelska (ZA)"
  },
  "en-ZM": {
    "en": "English (ZM)",
    "fi": "englanti (ZM)",
    "sv": "engelska (ZM)"
  },
  "en-ZW": {
    "en": "English (ZW)",
    "fi": "englanti (ZW)",
    "sv": "engelska (ZW)"
  },
  "es-AR": {
    "en": "Spanish (AR)",
    "fi": "espanja (AR)",
    "sv": "spanska (AR)"
  },
  "es-BO": {
    "en": "Spanish (BO)",
    "fi": "espanja (BO)",
    "sv": "spanska (BO)"
  },
  "es-BR": {
    "en": "Spanish (BR)",
    "fi": "espanja (BR)",
    "sv": "spanska (BR)"
  },
  "es-BZ": {
    "en": "Spanish (BZ)",
    "fi": "espanja (BZ)",
    "sv": "spanska (BZ)"
  },
  "es-CL": {
    "en": "Spanish (CL)",
    "fi": "espanja (CL)",
    "sv": "spanska (CL)"
  },
  "es-CO": {
    "en": "Spanish (CO)",
    "fi": "espanja (CO)",
    "sv": "spanska (CO)"
  },
  "es-CR": {
    "en": "Spanish (CR)",
    "fi": "espanja (CR)",
    "sv": "spanska (CR)"
  },
  "es-CU": {
    "en": "Spanish (CU)",
    "fi": "espanja (CU)",
    "sv": "spanska (CU)"
  },
  "es-DO": {
    "en": "Spanish (DO)",
    "fi": "espanja (DO)",
    "sv": "spanska (DO)"
  },
  "es-EA": {
    "en": "Spanish (EA)",
    "fi": "espanja (EA)",
    "sv": "spanska (EA)"
  },
  "es-EC": {
    "en": "Spanish (EC)",
    "fi": "espanja (EC)",
    "sv": "spanska (EC)"
  },
  "es-GQ": {
    "en": "Spanish (GQ)",
    "fi": "espanja (GQ)",
    "sv": "spanska (GQ)"
  },
  "es-GT": {
    "en": "Spanish (GT)",
    "fi": "espanja (GT)",
    "sv": "spanska (GT)"
  },
  "es-HN": {
    "en": "Spanish (HN)",
    "fi": "espanja (HN)",
    "sv": "spanska (HN)"
  },
  "es-IC": {
    "en": "Spanish (IC)",
    "fi": "espanja (IC)",
    "sv": "spanska (IC)"
  },
  "es-NI": {
    "en": "Spanish (NI)",
    "fi": "espanja (NI)",
    "sv": "spanska (NI)"
  },
  "es-PA": {
    "en": "Spanish (PA)",
    "fi": "espanja (PA)",
    "sv": "spanska (PA)"
  },
  "es-PE": {
    "en": "Spanish (PE)",
    "fi": "espanja (PE)",
    "sv": "spanska (PE)"
  },
  "es-PH": {
    "en": "Spanish (PH)",
    "fi": "espanja (PH)",
    "sv": "spanska (PH)"
  },
  "es-PR": {
    "en": "Spanish (PR)",
    "fi": "espanja (PR)",
    "sv": "spanska (PR)"
  },
  "es-PY": {
    "en": "Spanish (PY)",
    "fi": "espanja (PY)",
    "sv": "spanska (PY)"
  },
  "es-SV": {
    "en": "Spanish (SV)",
    "fi": "espanja (SV)",
    "sv": "spanska (SV)"
  },
  "es-US": {
    "en": "Spanish (US)",
    "fi": "espanja (US)",
    "sv": "spanska (US)"
  },
  "es-UY": {
    "en": "Spanish (UY)",
    "fi": "espanja (UY)",
    "sv": "spanska (UY)"
  },
  "es-VE": {
    "en": "Spanish (VE)",
    "fi": "espanja (VE)",
    "sv": "spanska (VE)"
  },
  "fr-BE": {
    "en": "French (BE)",
    "fi": "ranska (BE)",
    "sv": "franska (BE)"
  },
  "fr-BF": {
    "en": "French (BF)",
    "fi": "ranska (BF)",
    "sv": "franska (BF)"
  },
  "fr-BI": {
    "en": "French (BI)",
    "fi": "ranska (BI)",
    "sv": "franska (BI)"
  },
  "fr-BJ": {
    "en": "French (BJ)",
    "fi": "ranska (BJ)",
    "sv": "franska (BJ)"
  },
  "fr-BL": {
    "en": "French (BL)",
    "fi": "ranska (BL)",
    "sv": "franska (BL)"
  },
  "fr-CD": {
    "en": "French (CD)",
    "fi": "ranska (CD)",
    "sv": "franska (CD)"
  },
  "fr-CF": {
    "en": "French (CF)",
    "fi": "ranska (CF)",
    "sv": "franska (CF)"
  },
  "fr-CG": {
    "en": "French (CG)",
    "fi": "ranska (CG)",
    "sv": "franska (CG)"
  },
  "fr-CI": {
    "en": "French (CI)",
    "fi": "ranska (CI)",
    "sv": "franska (CI)"
  },
  "fr-CM": {
    "en": "French (CM)",
    "fi": "ranska (CM)",
    "sv": "franska (CM)"
  },
  "fr-DJ": {
    "en": "French (DJ)",
    "fi": "ranska (DJ)",
    "sv": "franska (DJ)"
  },
  "fr-DZ": {
    "en": "French (DZ)",
    "fi": "ranska (DZ)",
    "sv": "franska (DZ)"
  },
  "fr-GA": {
    "en": "French (GA)",
    "fi": "ranska (GA)",
    "sv": "franska (GA)"
  },
  "fr-GF": {
    "en": "French (GF)",
    "fi": "ranska (GF)",
    "sv": "franska (GF)"
  },
  "fr-GN": {
    "en": "French (GN)",
    "fi": "ranska (GN)",
    "sv": "franska (GN)"
  },
  "fr-GP": {
    "en": "French (GP)",
    "fi": "ranska (GP)",
    "sv": "franska (GP)"
  },
  "fr-GQ": {
    "en": "French (GQ)",
    "fi": "ranska (GQ)",
    "sv": "franska (GQ)"
  },
  "fr-HT": {
    "en": "French (HT)",
    "fi": "ranska (HT)",
    "sv": "franska (HT)"
  },
  "fr-KM": {
    "en": "French (KM)",
    "fi": "ranska (KM)",
    "sv": "franska (KM)"
  },
  "fr-LU": {
    "en": "French (LU)",
    "fi": "ranska (LU)",
    "sv": "franska (LU)"
  },
  "fr-MA": {
    "en": "French (MA)",
    "fi": "ranska (MA)",
    "sv": "franska (MA)"
  },
  "fr-MC": {
    "en": "French (MC)",
    "fi": "ranska (MC)",
    "sv": "franska (MC)"
  },
  "fr-MF": {
    "en": "French (MF)",
    "fi": "ranska (MF)",
    "sv": "franska (MF)"
  },
  "fr-MG": {
    "en": "French (MG)",
    "fi": "ranska (MG)",
    "sv": "franska (MG)"
  },
  "fr-ML": {
    "en": "French (ML)",
    "fi": "ranska (ML)",
    "sv": "franska (ML)"
  },
  "fr-MQ": {
    "en": "French (MQ)",
    "fi": "ranska (MQ)",
    "sv": "franska (MQ)"
  },
  "fr-MR": {
    "en": "French (MR)",
    "fi": "ranska (MR)",
    "sv": "franska (MR)"
  },
  "fr-MU": {
    "en": "French (MU)",
    "fi": "ranska (MU)",
    "sv": "franska (MU)"
  },
  "fr-NC": {
    "en": "French (NC)",
    "fi": "ranska (NC)",
    "sv": "franska (NC)"
  },
  "fr-NE": {
    "en": "French (NE)",
    "fi": "ranska (NE)",
    "sv": "franska (NE)"
  },
  "fr-PF": {
    "en": "French (PF)",
    "fi": "ranska (PF)",
    "sv": "franska (PF)"
  },
  "fr-PM": {
    "en": "French (PM)",
    "fi": "ranska (PM)",
    "sv": "franska (PM)"
  },
  "fr-RE": {
    "en": "French (RE)",
    "fi": "ranska (RE)",
    "sv": "franska (RE)"
  },
  "fr-RW": {
    "en": "French (RW)",
    "fi": "ranska (RW)",
    "sv": "franska (RW)"
  },
  "fr-SC": {
    "en": "French (SC)",
    "fi": "ranska (SC)",
    "sv": "franska (SC)"
  },
  "fr-SN": {
    "en": "French (SN)",
    "fi": "ranska (SN)",
    "sv": "franska (SN)"
  },
  "fr-SY": {
    "en": "French (SY)",
    "fi": "ranska (SY)",
    "sv": "franska (SY)"
  },
  "fr-TD": {
    "en": "French (TD)",
    "fi": "ranska (TD)",
    "sv": "franska (TD)"
  },
  "fr-TG": {
    "en": "French (TG)",
    "fi": "ranska (TG)",
    "sv": "franska (TG)"
  },
  "fr-TN": {
    "en": "French (TN)",
    "fi": "ranska (TN)",
    "sv": "franska (TN)"
  },
  "fr-VU": {
    "en": "French (VU)",
    "fi": "ranska (VU)",
    "sv": "franska (VU)"
  },
  "fr-WF": {
    "en": "French (WF)",
    "fi": "ranska (WF)",
    "sv": "franska (WF)"
  },
  "fr-YT": {
    "en": "French (YT)",
    "fi": "ranska (YT)",
    "sv": "franska (YT)"
  },
  "ga-GB": {
    "en": "Irish (GB)",
    "fi": "iiri (GB)",
    "sv": "iriska (GB)"
  },
  "ha-GH": {
    "en": "Hausa (GH)",
    "fi": "hausa (GH)",
    "sv": "hausa (GH)"
  },
  "ha-NE": {
    "en": "Hausa (NE)",
    "fi": "hausa (NE)",
    "sv": "hausa (NE)"
  },
  "hr-BA": {
    "en": "Croatian (BA)",
    "fi": "kroatia (BA)",
    "sv": "kroatiska (BA)"
  },
  "it-CH": {
    "en": "Italian (CH)",
    "fi": "italia (CH)",
    "sv": "italienska (CH)"
  },
  "it-SM": {
    "en": "Italian (SM)",
    "fi": "italia (SM)",
    "sv": "italienska (SM)"
  },
  "it-VA": {
    "en": "Italian (VA)",
    "fi": "italia (VA)",
    "sv": "italienska (VA)"
  },
  "ko-CN": {
    "en": "Korean (CN)",
    "fi": "korea (CN)",
    "sv": "koreanska (CN)"
  },
  "ko-KP": {
    "en": "Korean (KP)",
    "fi": "korea (KP)",
    "sv": "koreanska (KP)"
  },
  "ms-BN": {
    "en": "Malay (BN)",
    "fi": "malaiji (BN)",
    "sv": "malajiska (BN)"
  },
  "ms-ID": {
    "en": "Malay (ID)",
    "fi": "malaiji (ID)",
    "sv": "malajiska (ID)"
  },
  "ms-SG": {
    "en": "Malay (SG)",
    "fi": "malaiji (SG)",
    "sv": "malajiska (SG)"
  },
  "nb-SJ": {
    "en": "Norwegian Bokmål (SJ)",
    "fi": "norjan bokmål (SJ)",
    "sv": "norskt bokmål (SJ)"
  },
  "ne-IN": {
    "en": "Nepali (IN)",
    "fi": "nepali (IN)",
    "sv": "nepalesiska (IN)"
  },
  "nl-AW": {
    "en": "Dutch (AW)",
    "fi": "hollanti (AW)",
    "sv": "nederländska (AW)"
  },
  "nl-BQ": {
    "en": "Dutch (BQ)",
    "fi": "hollanti (BQ)",
    "sv": "nederländska (BQ)"
  },
  "nl-CW": {
    "en": "Dutch (CW)",
    "fi": "hollanti (CW)",
    "sv": "nederländska (CW)"
  },
  "nl-SR": {
    "en": "Dutch (SR)",
    "fi": "hollanti (SR)",
    "sv": "nederländska (SR)"
  },
  "nl-SX": {
    "en": "Dutch (SX)",
    "fi": "hollanti (SX)",
    "sv": "nederländska (SX)"
  },
  "pa-Guru": {
    "en": "Punjabi (Guru)",
    "fi": "pandžabi (Guru)",
    "sv": "punjabi (Guru)"
  },
  "ps-PK": {
    "en": "Pashto (PK)",
    "fi": "paštu (PK)",
    "sv": "afghanska (PK)"
  },
  "pt-AO": {
    "en": "Portuguese (AO)",
    "fi": "portugali (AO)",
    "sv": "portugisiska (AO)"
  },
  "pt-CH": {
    "en": "Portuguese (CH)",
    "fi": "portugali (CH)",
    "sv": "portugisiska (CH)"
  },
  "pt-CV": {
    "en": "Portuguese (CV)",
    "fi": "portugali (CV)",
    "sv": "portugisiska (CV)"
  },
  "pt-GQ": {
    "en": "Portuguese (GQ)",
    "fi": "portugali (GQ)",
    "sv": "portugisiska (GQ)"
  },
  "pt-GW": {
    "en": "Portuguese (GW)",
    "fi": "portugali (GW)",
    "sv": "portugisiska (GW)"
  },
  "pt-LU": {
    "en": "Portuguese (LU)",
    "fi": "portugali (LU)",
    "sv": "portugisiska (LU)"
  },
  "pt-MO": {
    "en": "Portuguese (MO)",
    "fi": "portugali (MO)",
    "sv": "portugisiska (MO)"
  },
  "pt-MZ": {
    "en": "Portuguese (MZ)",
    "fi": "portugali (MZ)",
    "sv": "portugisiska (MZ)"
  },
  "pt-ST": {
    "en": "Portuguese (ST)",
    "fi": "portugali (ST)",
    "sv": "portugisiska (ST)"
  },
  "pt-TL": {
    "en": "Portuguese (TL)",
    "fi": "portugali (TL)",
    "sv": "portugisiska (TL)"
  },
  "ru-BY": {
    "en": "Russian (BY)",
    "fi": "venäjä (BY)",
    "sv": "ryska (BY)"
  },
  "ru-KG": {
    "en": "Russian (KG)",
    "fi": "venäjä (KG)",
    "sv": "ryska (KG)"
  },
  "ru-KZ": {
    "en": "Russian (KZ)",
    "fi": "venäjä (KZ)",
    "sv": "ryska (KZ)"
  },
  "ru-MD": {
    "en": "Russian (MD)",
    "fi": "venäjä (MD)",
    "sv": "ryska (MD)"
  },
  "ru-UA": {
    "en": "Russian (UA)",
    "fi": "venäjä (UA)",
    "sv": "ryska (UA)"
  },
  "sd-Arab": {
    "en": "Sindhi (Arab)",
    "fi": "sindhi (Arab)",
    "sv": "sindhi (Arab)"
  },
  "so-DJ": {
    "en": "Somali (DJ)",
    "fi": "somali (DJ)",
    "sv": "somaliska (DJ)"
  },
  "so-ET": {
    "en": "Somali (ET)",
    "fi": "somali (ET)",
    "sv": "somaliska (ET)"
  },
  "so-KE": {
    "en": "Somali (KE)",
    "fi": "somali (KE)",
    "sv": "somaliska (KE)"
  },
  "sq-MK": {
    "en": "Albanian (MK)",
    "fi": "albania (MK)",
    "sv": "albanska (MK)"
  },
  "sq-XK": {
    "en": "Albanian (XK)",
    "fi": "albania (XK)",
    "sv": "albanska (XK)"
  },
  "sr-Cyrl-BA": {
    "en": "Serbian (Cyrl-BA)",
    "fi": "serbia (Cyrl-BA)",
    "sv": "serbiska (Cyrl-BA)"
  },
  "sr-Cyrl-ME": {
    "en": "Serbian (Cyrl-ME)",
    "fi": "serbia (Cyrl-ME)",
    "sv": "serbiska (Cyrl-ME)"
  },
  "sr-Cyrl-XK": {
    "en": "Serbian (Cyrl-XK)",
    "fi": "serbia (Cyrl-XK)",
    "sv": "serbiska (Cyrl-XK)"
  },
  "sr-Cyrl": {
    "en": "Serbian (Cyrl)",
    "fi": "serbia (Cyrl)",
    "sv": "serbiska (Cyrl)"
  },
  "sr-Latn-BA": {
    "en": "Serbian (Latn-BA)",
    "fi": "serbia (Latn-BA)",
    "sv": "serbiska (Latn-BA)"
  },
  "sr-Latn-ME": {
    "en": "Serbian (Latn-ME)",
    "fi": "serbia (Latn-ME)",
    "sv": "serbiska (Latn-ME)"
  },
  "sr-Latn-XK": {
    "en": "Serbian (Latn-XK)",
    "fi": "serbia (Latn-XK)",
    "sv": "serbiska (Latn-XK)"
  },
  "sr-Latn": {
    "en": "Serbian (Latn)",
    "fi": "serbia (Latn)",
    "sv": "serbiska (Latn)"
  },
  "sv-AX": {
    "en": "Swedish (AX)",
    "fi": "ruotsi (AX)",
    "sv": "svenska (AX)"
  },
  "sv-FI": {
    "en": "Swedish (FI)",
    "fi": "ruotsi (FI)",
    "sv": "svenska (FI)"
  },
  "sw-KE": {
    "en": "Swahili (KE)",
    "fi": "swahili (KE)",
    "sv": "swahili (KE)"
  },
  "sw-UG": {
    "en": "Swahili (UG)",
    "fi": "swahili (UG)",
    "sv": "swahili (UG)"
  },
  "ta-LK": {
    "en": "Tamil (LK)",
    "fi": "tamili (LK)",
    "sv": "tamil (LK)"
  },
  "ta-MY": {
    "en": "Tamil (MY)",
    "fi": "tamili (MY)",
    "sv": "tamil (MY)"
  },
  "ta-SG": {
    "en": "Tamil (SG)",
    "fi": "tamili (SG)",
    "sv": "tamil (SG)"
  },
  "tr-CY": {
    "en": "Turkish (CY)",
    "fi": "turkki (CY)",
    "sv": "turkiska (CY)"
  },
  "ur-IN": {
    "en": "Urdu (IN)",
    "fi": "urdu (IN)",
    "sv": "urdu (IN)"
  },
  "uz-Latn": {
    "en": "Uzbek (Latn)",
    "fi": "uzbekki (Latn)",
    "sv": "uzbekiska (Latn)"
  },
  "yo-BJ": {
    "en": "Yoruba (BJ)",
    "fi": "joruba (BJ)",
    "sv": "yoruba (BJ)"
  },
  "zh-Hans-HK": {
    "en": "Chinese (Hans-HK)",
    "fi": "kiina (Hans-HK)",
    "sv": "kinesiska (Hans-HK)"
  },
  "zh-Hans-MO": {
    "en": "Chinese (Hans-MO)",
    "fi": "kiina (Hans-MO)",
    "sv": "kinesiska (Hans-MO)"
  },
  "zh-Hans-SG": {
    "en": "Chinese (Hans-SG)",
    "fi": "kiina (Hans-SG)",
    "sv": "kinesiska (Hans-SG)"
  },
  "zh-Hant-HK": {
    "en": "Chinese (Hant-HK)",
    "fi": "kiina (Hant-HK)",
    "sv": "kinesiska (Hant-HK)"
  },
  "zh-Hant-MO": {
    "en": "Chinese (Hant-MO)",
    "fi": "kiina (Hant-MO)",
    "sv": "kinesiska (Hant-MO)"
  }
} as const;
