import { apiErrorWithAnyContextSchema, apiResponseEitherSchema } from '../api';
import { priceSchema } from '../price';
import { z } from '../zod-openapi';
import { reservationStoreProductSchema, reservationStoreSchema } from './reservation';
import { reserveResponseSchema } from './reserve';
import { reservationStateSchema } from './state';

export const reservationResultItemSchema = z.object({
  ticketTypeId: z.string(),
  id: z.string(),
  validUntil: z.string(),
  state: reservationStateSchema,
  reservationId: z.string().optional(),
  price: priceSchema.optional(),
  nameOnTicket: z
    .object({
      firstName: z.string(),
      lastName: z.string(),
    })
    .optional(),
});

export type ReservationResultItem = z.infer<typeof reservationResultItemSchema>;

export const reservationResultSchema = z.object({
  cartId: z.string(),
  reservations: z.array(z.union([reservationStoreSchema, reservationStoreProductSchema])),
});

export type ReservationResult = z.infer<typeof reservationResultSchema>;

export const reservationApiReserveResponseSchema = apiResponseEitherSchema(
  reserveResponseSchema,
  apiErrorWithAnyContextSchema,
);

export const reservationStatusUpdateSchema = z.object({
  action: z.enum(['updateReservation']),
  token: z.string(),
  reservations: z.array(reservationResultItemSchema),
});

export const receiveReservationResultSchema = z.object({
  tickets: z.array(
    z
      .object({
        id: z.string(),
        state: reservationStateSchema,
      })
      .optional(),
  ),
});

export type ReceiveReservationResult = z.infer<typeof receiveReservationResultSchema>;
export type ReservationStateUpdate = z.infer<typeof reservationStatusUpdateSchema>;
