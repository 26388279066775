import { PageStatus, PageType } from '../../page';
import { lengthAwarePaginationSchema } from '../../pagination';
import { z } from '../../zod-openapi';
import { BlockType } from '../enum';

// ID Parameter Schema Helper
const idParamSchema = (paramName: string) =>
  z.object({
    [paramName]: z
      .string()
      .length(8)
      .openapi({
        param: {
          name: paramName,
          in: 'path',
        },
      }),
  });

// Common ID Parameters
export const organizationIdParamSchema = idParamSchema('organizationId');
export const pageIdParamSchema = idParamSchema('pageId');
export const blockIdParamSchema = idParamSchema('blockId');

export const pageParamsSchema = organizationIdParamSchema.merge(pageIdParamSchema);
export const customBlockParamSchema = organizationIdParamSchema.merge(blockIdParamSchema);

// Content Schema for Requests (without imageUrl and presignedUrl)
const requestContentSchema = z
  .object({
    folder: z.string().nullable().optional(),
    filename: z.string().nullable().optional(),
    isImageNew: z.boolean().optional(),
    items: z.array(z.any()).optional(),
    images: z.array(z.any()).optional(),
    location: z.any(),
    social_media_links: z.array(z.any()).optional(),
    activeItem: z
      .object({
        id: z.string().optional(),
        trigger: z.string().optional(),
        order: z.number().optional(),
        subItems: z.array(z.any()).optional(),
      })
      .optional(),
  })
  .passthrough();

// Content Schema for Responses (adds imageUrl and presignedUrl)
const responseContentSchema = requestContentSchema
  .extend({
    imageUrl: z.string().nullable().optional(),
    presignedUrl: z.string().nullable().optional(),
  })
  .passthrough();

// Block Schemas
export const blockSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(BlockType),
  order: z.number().int(),
  defaultSettings: z.record(z.any()).optional(),
  settings: z.record(z.any()).optional(),
  tempId: z.string().optional(),
  content: responseContentSchema.optional(),
  isUpdated: z.boolean().optional(),
  errors: z.array(z.string()).optional(),
});
export type Block = z.infer<typeof blockSchema>;

export const customBlockSchema = blockSchema.extend({
  organizationId: z.string(),
  settings: z.record(z.any()).optional(),
  content: requestContentSchema.optional(),
});
export type CustomBlock = z.infer<typeof customBlockSchema>;

// Create/Update Block Schemas (requests only)
export const createBlockSchema = blockSchema.omit({ id: true, content: true }).extend({
  content: requestContentSchema.optional(),
});
export type CreateBlock = z.infer<typeof createBlockSchema>;

export const createCustomBlockSchema = customBlockSchema.extend({
  folder: z.string().nullable().optional(),
  filename: z.string().nullable().optional(),
});
export type CreateCustomBlock = z.infer<typeof createCustomBlockSchema>;

// Create Custom Block Response Schema (adds imageUrl and presignedUrl for responses)
export const createCustomBlockResponseSchema = createCustomBlockSchema.extend({
  imageUrl: z.string().nullable().optional(),
  presignedUrl: z.string().nullable().optional(),
});
export type CreateCustomBlockResponse = z.infer<typeof createCustomBlockResponseSchema>;

// Update Block Schemas
export const updateCustomBlockSchema = customBlockSchema.partial();
export type UpdateCustomBlock = z.infer<typeof updateCustomBlockSchema>;

// Internal database schema (not exposed via API)
export const pageDbSchema = z.object({
  id: z.string(),
  organizationId: z.string(),
  settings: z.record(z.any()),
  content: z.object({
    blocks: z.array(z.any()),
  }),
  slug: z.string().optional(),
  previous_slugs: z.array(z.string()).optional(),
  createdAt: z.union([z.string().datetime(), z.date()]),
  updatedAt: z.union([z.string().datetime(), z.date()]),
  pageType: z.nativeEnum(PageType),
  title: z.string().optional(),
  pageStatus: z.nativeEnum(PageStatus).default(PageStatus.UNPUBLISHED),
  likes: z.number().int().default(0),
});

// API schema (without previous_slugs)
export const pageSchema = pageDbSchema.omit({ previous_slugs: true });

export type Page = z.infer<typeof pageSchema>;
export type PageDb = z.infer<typeof pageDbSchema>;

export const createPageSchema = pageSchema.omit({ id: true, createdAt: true, updatedAt: true, likes: true });
export const createPageResponseSchema = pageSchema.omit({ id: true }).extend({
  content: z
    .object({
      blocks: z.array(createCustomBlockResponseSchema),
    })
    .optional(),
});
export type CreatePage = z.infer<typeof createPageSchema>;

// List Schemas
export const defaultBlocksListSchema = z.object({
  data: z.array(blockSchema),
  pagination: lengthAwarePaginationSchema,
});
export type BlocksList = z.infer<typeof defaultBlocksListSchema>;

export const customBlockListSchema = z.object({
  data: z.array(createCustomBlockResponseSchema),
  pagination: lengthAwarePaginationSchema,
});
export type CustomBlocksList = z.infer<typeof customBlockListSchema>;

export const pagesListSchema = z.object({
  data: z.array(pageSchema),
  pagination: lengthAwarePaginationSchema,
});
export type PagesList = z.infer<typeof pagesListSchema>;

// List Query and Healthcheck Schemas
export const listQuerySchema = z
  .object({
    page: z.number().optional(),
    perPage: z.number().optional(),
    currentPage: z.number().optional(),
    sort: z
      .string()
      .regex(/^[a-zA-Z_]+:(asc|desc)$/)
      .optional(),
    search: z.string().optional(),
  })
  .strict();
export type ListQuery = z.infer<typeof listQuerySchema>;

export const healthcheckSchema = z.object({
  status: z.string(),
  priceVersion: z.string(),
});
