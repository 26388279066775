import { z } from '../zod-openapi';

export const jwtBearerRegex = /^Bearer ([\w-]+\.[\w-]+\.[\w-]+)$/;

export const authorizationHeaderSchema = z.object({
  authorization: z
    .string()
    .regex(jwtBearerRegex)
    .openapi({ description: 'JWT token of the actor making this request' }),
});

export const apiKeyHeaderSchema = z.object({
  'x-api-key': z
    .string()
    .min(1)
    .openapi({ description: 'Static API key for client authentication' }),
});

export const apiKeyAndAuthorizationHeaderSchema = apiKeyHeaderSchema.merge(authorizationHeaderSchema);

export type ApiKeyAndAuthorizationHeaderSchema = z.infer<typeof apiKeyAndAuthorizationHeaderSchema>;
